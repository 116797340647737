import { StyleSheet } from "react-native";
import { images } from "../../../../constantes";

const styles = StyleSheet.create({
  //GrandBox: {
    //flex: 1,
    //height: 200,
  //},
  container: {
    flex: 1,
  },

  row: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    margin: 10,
  },
  text: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    fontSize: 13,
  
  },
  Text: {
    fontWeight: "bold",
    alignItems: "center",
    marginLeft: "3.5%",
    top:40,
    padding: 10
  },

  card: {
    flex: 1,
    alignItems: "center",
    padding: 15,
    
    // Add this line
  },
  img: {
    width: 200,
    height: 200,
    marginTop: "-38%",
    marginBottom: "-40%",
    position: 'relative',right: "-20%",
  },
  imgsmall: {
    width: 75,
    height: 75,
    marginTop: "-19.5%",
    marginBottom: "-15%",
    fontSize: "2em",
    position: 'relative',right: "15%",
  },
  textbutton: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color:"white",
    marginTop: "-4%",
    marginBottom: "5%",
    marginLeft: '9%',
    fontSize: 13.5,
  },
  cardContent: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    padding: 10,
  },
  button: {
    borderWidth: "20%",
    borderColor: "#fff",
    borderRadius: "40px",
    marginTop: "5%",
    padding: "10%", // Augmenter la valeur du padding
    backgroundImage: `url(${images.EmeraldBorder})`, // Assurez-vous que images.GoldenStar contient le chemin correct de l'image
    // Autres styles pour ajuster le positionnement de l'image
    backgroundRepeat: 'no-repeat',
        // Ajuster la taille de l'image pour qu'elle couvre entièrement la vue
    backgroundSize: "67%",
    backgroundPositionX: "-10%", // Vous pouvez ajuster la valeur selon vos besoins
    backgroundPositionY: "60%",// Vous pouvez ajuster la valeur selon vos besoins
    width: "90%",
    height:'100%',
    overflow : "hidden",
    shadowColor:'#000',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,
    position: 'relative',right: "-5%",
  
  },
  backgroundImage: {
    
    width: '80%',
    height: '105%',
    // position: 'relative',right: "-0%"
   
    // Autres styles pour ajuster la taille et le positionnement de l'image
  },
  buttonGrey: {
    borderWidth: "20%",
    borderColor: "#fff",
    borderRadius: "20px",
    marginTop: "-40%",
    padding: "10%", // Augmenter la valeur du padding

    height:'5%',
    overflow : "hidden",
    shadowColor:'#000',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,
    position: 'relative',right: "0%",
    backgroundColor: "#505050",
    
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  textCard: {
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
  },
  cardButton: {
    width: "100%",
    alignItems: "center", // Aligner le contenu au centre
    backgroundColor: "#fff",
  
  },
  separator: {
    borderTopWidth: 2,
    borderTopColor: 'blue', // Couleur du trait
    marginVertical: 10,
    width: '50%', // Largeur du trait
    
  },
  CarTabCentre: {
    backgroundColor: "white",
    alignItems: "center",
    width: "100%", // Ajuster la largeur à 100%
  },
  cardTab: {
    flexDirection: "row", // Add this line
    backgroundColor: "#888",
    borderRadius: 15,
    alignItems: "center",
    margin: 5,
  },
  cardTextTab: {
    flex: 1,
    alignItems: "center",
    padding: 25,
    marginLeft: 29,
  },
  cardTabStyle: {
    backgroundColor: "white",
    alignItems: "center",
    marginBottom: 0,
    flexDirection: "row",
  },
  
  table: {
    borderColor: "#000",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    padding: 10,
    width: "50%",
  },
  cellText: {
    textAlign: "center",
    color: "white",
  },
  cellTextCentre: {
    textAlign: "center",
    color: "black",
    fontSize: 11,
  },
  rowTab: {
    flexDirection: "row",
    backgroundColor: "#888",
    borderRadius: 10,
    paddingLeft: 27,
  },
  SeparatorNoir: {
    width: 2,
    height: "100%",
    backgroundColor: "#888",
    transform: "rotate(180deg)",
    marginLeft: 51,
  },
  rowText: {
    alignItems: "center",
    fontSize: 10,
    padding: 10,
  },

 
  emerald: {
    fontWeight: "bold",
    alignItems: "top",
    padding: 10,
    color: "#42DB71" ,
    textAlign:'center',
    marginTop: '-11%',
    marginBottom: '1%',
    fontSize: 20,
  
  },
 
 
  
 
 
  emeraldtitle: {
    fontWeight: "bold",
    fontSize: 20,
    alignItems: "top",
    padding: 10,
    padding: 10,
    color: "#42DB71" ,
    textAlign:'center',
    marginTop: '30%',
    
  },
  
 
  separatorE: {
 
    borderTopColor: '#42DB71', 
    borderTopWidth: 2,  
    width: '85%', 
    marginLeft: "7%",
    marginTop: "0%",
    marginBottom: "10%", 
  },
 


 

});

export default styles;