//🇫🇷 Imports nécessaires pour MongoDB et Mongoose
//🇬🇧 Necessary Imports for MongoDB and Mongoose
import { Icon } from "@rneui/themed";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import { format } from "date-fns";

// Styles et images
import { images } from "../../constantes";
import styles from "./DateTimePickerCss";

//🇫🇷 Import de Mongoose (à adapter selon votre structure de projet)
//🇬🇧 Mongoose import (to be adapted according to your project structure)
//🇫🇷 import mongoose from "mongoose";  //🇬🇧 import mongoose from "mongoose";

//🇫🇷 Theme personnalisé pour le composant //🇬🇧 Custom Theme for the component
const theme = {
  ...DefaultTheme,
  colors: {
    primary: "#59c09b",
    primaryContainer: "black",
    secondary: "#008078",
    secondaryContainer: "white",
    tertiary: "black",
    tertiaryContainer: "white",
    surface: "white",
    surfaceVariant: "#dcf1ea",
    surfaceDisabled: "#ccc",
    background: "skyblue",
    error: "crimson",
    errorContainer: "crimson",
    onPrimary: "white",
    onPrimaryContainer: "black",
    onSecondary: "#008078",
    onSecondaryContainer: "white",
    onTertiary: "white",
    onTertiaryContainer: "white",
    onSurface: "black",
    onSurfaceVariant: "black",
    onSurfaceDisabled: "#ccc",
    onError: "crimson",
    onErrorContainer: "crimson",
    onBackground: "white",
    outline: "aqua",
    outlineVariant: "#008078",
    inverseSurface: "white",
    inverseOnSurface: "black",
    inversePrimary: "#59c09b",
    shadow: "#008078",
    scrim: "white",
    backdrop: "rgba(0,0,0,0.3)",
  },
};

//🇫🇷 Composant DateTimePicker //🇬🇧 DateTimePicker Component
const DateTimePicker = ({
  title,
  date,
  setDate,
  time,
  setTime,
  event,
  scr,
}) => {
  // console.log("DateTimePicker scr:", scr);

  //🇫🇷 Initialisations et gestion de la date   //🇬🇧 Initializations and date management
  const resetDate = null;
  const resetTime = null;
  var todayDate = new Date(Date.now());
  var startingDate = new Date(todayDate);
  // console.log(date);
  let dater;
  let parts;
  let reversedDate;

  //🇫🇷 Effet pour gérer le format de la date   //🇬🇧 Effect to handle date format
  useEffect(() => {
    if (date) {
      let parts = date.split("-");
      let reversedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      setFormattedEventDate(new Date(reversedDate));
    }
  }, [date]);

  var dateEvent = new Date(reversedDate);
  // console.log(dateEvent);
  var day = startingDate.getDate();
  var month = startingDate.getMonth() + 1;
  var year = startingDate.getFullYear();
  const [formattedDate, setFormattedDate] = useState(null);
  const [formattedEventDate, setFormattedEventDate] = useState(null);

  //🇫🇷 Effet pour définir la date formatée par défaut //🇬🇧 Effect to define the default formatted date
  useEffect(() => {
    if (formattedDate === "" && formattedEventDate === "") {
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      setFormattedDate(day + "-" + month + "-" + year); //🇫🇷 Modification du format de la date //🇬🇧 Changing the date format
    }
  });

  // console.log(formattedDate);
  // console.log(formattedEventDate);

  //🇫🇷 Logique pour définir la date de début //🇬🇧 Logic to set the start date
  if (formattedDate === formattedEventDate) {
    startingDate.setDate(startingDate.getDate());
  } else {
    startingDate.setDate(startingDate.getDate() + 1);
  }

  //🇫🇷 États pour les sélecteurs de date et d'heure //🇬🇧 States for the date and time selectors
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [isTimePickerVisible, setTimePickerVisibility] = useState(false);

  //🇫🇷 Fonctions pour afficher et masquer les sélecteurs //🇬🇧 Functions to show and hide the selectors
  const showDatePicker = () => {
    if (date !== null || time !== null) {
      setDate(resetDate);
      setTime(resetTime);
    }
    setDatePickerVisibility(!isDatePickerVisible);
  };

  const hideDatePicker = () => {
    setDatePickerVisibility(!isDatePickerVisible);
  };

  const showTimePicker = () => {
    setTimePickerVisibility(!isTimePickerVisible);
  };

  const hideTimePicker = () => {
    setTimePickerVisibility(!isTimePickerVisible);
  };

  //🇫🇷 Fonctions pour gérer les confirmations de date et d'heure//🇬🇧 Functions to handle date and time confirmations
  const handleDateConfirm = ({ date }) => {
    //🇫🇷 Convertir la chaîne de date en objet Date //🇬🇧 Convert the date string into a Date object
    let chosenDate = new Date(date);

    //🇫🇷 Formater la date au format ISO avec l'année, le mois et le jour
    //🇬🇧 Format the date into ISO format with year, month, and day
    let isoDateWithoutUtc = chosenDate.toISOString().split("T")[0];
    //let isoDateWithoutUtc = chosenDate.toISOString();

    //🇫🇷 Utiliser la date formatée comme nécessaire (par exemple, l'envoyer à votre backend)
    //🇬🇧 Use the formatted date as needed (e.g., send it to your backend)
    // console.log("iso", isoDateWithoutUtc);

    //🇫🇷 Mettez à jour votre état avec la date formatée sans UTC
    //🇬🇧 Update your state with the formatted date without UTC
    setDate(isoDateWithoutUtc);

    hideDatePicker();
    showTimePicker();
  };

  const handleTimeConfirm = ({ hours, minutes }) => {
    let chosenTime;
    if (minutes <= 0 || minutes < 10) {
      chosenTime = hours.toString() + ":0" + minutes.toString();
    } else {
      chosenTime = hours.toString() + ":" + minutes.toString();
    }

    //🇫🇷 Créez une nouvelle date en utilisant la date actuelle //🇬🇧 Create a new date using the current date
    const selectedDate = new Date(date);

    //🇫🇷 Ajustez les heures et les minutes en utilisant les méthodes UTC //🇬🇧 Adjust the hours and minutes using UTC methods
    selectedDate.setUTCHours(hours);
    selectedDate.setUTCMinutes(minutes);

    //🇫🇷 Utilisez la fonction `format` pour formater la date sans UTC //🇬🇧 Use the `format` function to format the date without UTC
    const isoDateWithoutUtc = format(selectedDate, "yyyy-MM-dd'T'HH:mm", {
      awareOfUnicodeTokens: true,
    });

    //🇫🇷 Mettez à jour l'état avec la nouvelle date sans UTC //🇬🇧 Update the state with the new date without UTC
    setDate(isoDateWithoutUtc);
    setTime(chosenTime);
    hideTimePicker();
  };

  //🇫🇷 Rendu du composant //🇬🇧 Rendering of the component
  return (
    <>
      <Pressable
        style={styles.container}
        onPress={() => {
          showDatePicker(true);
        }}
      >
        <View style={styles.row}>
          <Text style={styles.boldTitle}>{title}</Text>
          <View style={{ flexDirection: "row" }}>
            <Image source={images.ScheduleIcon} style={styles.icon} />
          </View>
          {date === null && time === null ? (
            <Text style={styles.placeholder}>__ / __ /____ à __ : __</Text>
          ) : (
            <Text style={styles.dateTime}>
              {moment(date).format("DD/MM/YYYY")} à {time}
            </Text>
          )}
        </View>
      </Pressable>

      <PaperProvider theme={theme}>
        <DatePickerModal
          locale="en"
          mode="single"
          visible={isDatePickerVisible}
          onDismiss={hideDatePicker}
          date={date}
          onConfirm={handleDateConfirm}
          onChange={handleDateConfirm}
          closeIcon={
            <Icon name="close" type="font-awesome" color="#ccc" size={28} />
          }
          validRange={{ startDate: startingDate }}
          startYear={todayDate.getFullYear}
          saveLabel={
            <Icon name="check" type="font-awesome" color="#58c09b" size={28} />
          }
          label="Select Date"
        />
        <TimePickerModal
          visible={isTimePickerVisible}
          onDismiss={hideTimePicker}
          hours={18}
          minutes={30}
          format="HH:mm"
          onConfirm={handleTimeConfirm}
          closeIcon={
            <Icon name="close" type="font-awesome" color="#ccc" size={28} />
          }
        />
      </PaperProvider>
    </>
  );
};

export default DateTimePicker;
