import React from "react";
import { Text, View, Image, Pressable, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components
import styles from "./StylesWeb";

// import des images
import { images } from "../../../constantes";



const RewardsExplanation = (props) => {
  const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
  const JsonAchievementsPages = scr.JsonAchievementsPages || {};
  const JsonConceptScreen = scr.JsonConceptScreen || {};
  

  const navigation = useNavigation();

  {/* Separator de la couleur emerald  */}
  const SeparatorE = () => {
  return <View style={styles.separatorE} />;}



 
{/* Valeur des SponsorshipNumber qu'il faut pour les récompenses */}
  const GoldNumber =10;
  const RubyNumber =20;
  const EmeraldNumber =50;
  const DiamondNumber =100;

   {/* Cette variable modifie toute la page, elle doit être modifiée via le back-end */}
  let SponsorshipNumber = 0;
  
  {/* Affiche le nombre de SponsorshipNumber qui manque pour les récompenses */}
  let GoldMissing = GoldNumber - SponsorshipNumber;
  let RubyMissing = RubyNumber - SponsorshipNumber;
  let EmeraldMissing = EmeraldNumber - SponsorshipNumber;
  let DiamondMissing = DiamondNumber - SponsorshipNumber;


  {/* Condition pour savoir qu'est-ce qui q'affiche */}
  const GoldResultat = GoldNumber <= SponsorshipNumber ? SponsorshipNumber : GoldMissing;
  const RubyResultat = RubyNumber <= SponsorshipNumber ? SponsorshipNumber : RubyMissing;
  const EmeraldResultat = EmeraldNumber <= SponsorshipNumber ? SponsorshipNumber : EmeraldMissing;
  const DiamondResultat = DiamondNumber <= SponsorshipNumber ? SponsorshipNumber : DiamondMissing;

  {/* Condition image  */}
  const imgGift = DiamondNumber <= SponsorshipNumber ? <Image source={images.GiftDiamondStars} style={styles.img} /> : EmeraldNumber <= SponsorshipNumber ? <Image source={images.GiftEmeraldStars} style={styles.img} /> : RubyNumber <= SponsorshipNumber ? <Image source={images.GiftRubyStars} style={styles.img} /> : GoldNumber <= SponsorshipNumber ? <Image source={images.GiftGoldStars} style={styles.img} /> :<Image source={images.FreeGift} style={styles.img} />;
 
{/* Condition pour les json missing ou completed  */}


{/* Condition pour pages gold  */}
  const GoldBox= SponsorshipNumber == 0 ? styles.buttonGoldOff : GoldResultat >= GoldNumber ? styles.buttonGoldOn : styles.buttonGoldOff;
  const GoldText= SponsorshipNumber == 0 ? styles.TextBoxGoldOff : GoldResultat >= GoldNumber ? styles.TextBoxGoldOn : styles.TextBoxGoldOff;
  const MissingOrCompletedGold= SponsorshipNumber == 0 ? <Text style={GoldText} >{GoldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text> :GoldResultat >= GoldNumber ? <Text style={GoldText} >{GoldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipCompleted}</Text> : <Text style={GoldText} >{GoldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text>;

{/* Condition pour pages Ruby  */}
const RubyBox= SponsorshipNumber == 0 ? styles.boxRubyOff : RubyResultat >= RubyNumber ? styles.boxRubyOn : styles.boxRubyOff;
const RubyText= SponsorshipNumber == 0 ? styles.TextBoxRubyOff : RubyResultat >= RubyNumber ? styles.TextBoxRubyOn : styles.TextBoxRubyOff;
const MissingOrCompletedRuby = SponsorshipNumber == 0 ? <Text style={RubyText} >{RubyResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text>: RubyResultat >= RubyNumber ? <Text style={RubyText} >{RubyResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipCompleted}</Text>: <Text style={RubyText} >{RubyResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text>;

{/* Condition pour pages Emerald  */}
const EmeraldBox=  SponsorshipNumber == 0 ?  styles.buttonEmeraldOff : EmeraldResultat >= EmeraldNumber ? styles.buttonEmeraldOn : styles.buttonEmeraldOff;
const EmeraldText=  SponsorshipNumber == 0 ? styles.TextBoxEmeraldOff : EmeraldResultat >= EmeraldNumber ? styles.TextBoxEmeraldOn : styles.TextBoxEmeraldOff;
const MissingOrCompletedEmerald= SponsorshipNumber == 0 ? <Text style={EmeraldText} > {EmeraldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing} </Text> : EmeraldResultat >= EmeraldNumber ? <Text style={EmeraldText} > {EmeraldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipCompleted} </Text>:<Text style={EmeraldText} > {EmeraldResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing} </Text>;

{/* Condition pour pages Diamond  */}
const DiamondBox=  SponsorshipNumber == 0 ? styles.boxDiamondOff :DiamondResultat >= DiamondNumber ? styles.boxDiamondOn : styles.boxDiamondOff;
const DiamondText=  SponsorshipNumber == 0 ? styles.TextBoxDiamondOff :DiamondResultat >= DiamondNumber ? styles.TextBoxDiamondOn : styles.TextBoxDiamondOff;
const MissingOrCompletedDiamond=  SponsorshipNumber == 0 ? <Text style={DiamondText} >{DiamondResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text> :DiamondResultat >= DiamondNumber ? <Text style={DiamondText} >{DiamondResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipCompleted}</Text>:<Text style={DiamondText} >{DiamondResultat} {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipMissing}</Text>;



{/* Partie haut de la page   */}
  return ( 
    <ScrollView>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>
            
            {JsonAchievementsPages.JsonSponsorshipScreen.JsonGetVipPacks}

        </Text>


          {imgGift}
          <Text style={styles.text}>

            {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorCode}
            680HA
          </Text>
          <Text style={styles.textSponsorShip}>
             {JsonAchievementsPages.JsonSponsorshipScreen.JsonSponsorshipNumber} : {SponsorshipNumber}
          </Text>

          {/* Box 1 */}
          <View style={styles.Box}>
            <Text style={styles.NumberBox}>
              1
            </Text>
            <Text style={styles.TextBox}>
              {JsonAchievementsPages.JsonSponsorshipScreen.JsonShareSponsorCodeToFriend}
            </Text>

             <Text style={styles.share}>
               {JsonConceptScreen.JsonShareButton}
             </Text>
          </View>

          {/* Box 2 */}
          <View style={styles.Box}>
            <Text style={styles.NumberBox}>
              2
            </Text>
            <Text style={styles.TextBox}>
              {JsonAchievementsPages.JsonSponsorshipScreen.JsonAccumulateSponsorship}
            </Text>
          </View>

          {/* Box 3 */}
          <View style={styles.Box}>
            <Text style={styles.NumberBox}>
              3
            </Text>
            <Text style={styles.TextBox}>
              {JsonAchievementsPages.JsonSponsorshipScreen.JsonClickOnTheGift}
            </Text>
          </View>


          {/* partie inferieur*/}

          <Text style={styles.Text}>

            {JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards}

          </Text>

          <SeparatorE />
          
{/* gérer les boutons dans un tableau */}

    {/* gérer le bouton golden star*/}
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cell}>
            <Pressable style={GoldBox}   
                  onPress={() => navigation.navigate('Rewards')}
                >
                
                  <Text style={styles.buttonTextGold}>
                    {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonGoldLevel}
                  </Text>
                  <Image source={images.GoldStar} style={styles.buttonimg} />
                    {MissingOrCompletedGold}
                
                </Pressable>
            </View>
            
            {/* gérer le bouton ruby star*/}
            <View style={styles.cell}>
            <Pressable style={RubyBox}   
                  onPress={() => navigation.navigate('Rewards')}
                >
                       
                  <Text style={styles.buttonTextRuby}>
                    {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonRubyLevel}
                  </Text>
                  <Image source={images.RubyStar} style={styles.buttonimgRuby} />
                    {MissingOrCompletedRuby}
                </Pressable>
            </View>
          </View>

       
         {/* gérer le bouton emerald star*/}
          <View style={styles.row}>
            <View style={styles.cell}>
            <Pressable style={EmeraldBox}   
                 onPress={() => navigation.navigate('Rewards')}
                >
                
                <Text style={styles.ButtonTextEmerald}>
                  {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonEmeraldLevel}
                </Text>
                <Image source={images.EmeraldStar} style={styles.buttonimg} />
                
                {MissingOrCompletedEmerald}
                  
               
            </Pressable>
            </View>
            
             {/* gérer le bouton diamond star*/}
            <View style={styles.cell}>
            <Pressable style={DiamondBox}   
                 onPress={() => navigation.navigate('Rewards')}
             >
                
                <Text style={styles.buttontextdiamond}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDiamondLevel}
                </Text>
                <Image source={images.DiamandStar} style={styles.buttonimgdiamond} />
                
                {MissingOrCompletedDiamond}
                
                
            </Pressable>
            </View>


          </View>

          
         

          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default RewardsExplanation;
