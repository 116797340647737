// 🇫🇷 Ce fichier gère la bottom navigation
// 🇬🇧 This file manage the bottom navigation

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React from "react";

// Components
import BurgerButton from "../components/BurgerButton/BurgerButton";
import ActivitiesScreen from "../pages/ActivityPages/ActivitiesScreen/ActivitiesScreen";
import ConceptScreen from "../pages/ConceptScreen/ConceptScreen";
import ContactScreen from "../pages/ConctactScreen/ContactScreen";
import MembersScreen from "../pages/MembersPages/MembersScreen/MembersScreen";
import NotifScreen from "../pages/NotificationPages/NotificationScreen/NotificationScreen";
import News from "../pages/News/News";

// images
import { images } from "../constantes";

const BottomNav = ({ user, scr, shouldShowContactScreen }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonBottomNav, JsonTopNav } = scr;

  const BottomTabNavigator = createBottomTabNavigator();

  return (
    <BottomTabNavigator.Navigator
      initialRouteName={shouldShowContactScreen ? "Contact" : "Concept"}
      screenOptions={{
        headerShown: false,
        tabBarStyle: { height: 70 },
        tabBarItemStyle: { flexDirection: "column" },
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "white",
        tabBarActiveBackgroundColor: "#F48225",
        tabBarInactiveBackgroundColor: "#59C09B",
      }}
    >
      {/* Activities Bottom Navigator */}
      <BottomTabNavigator.Screen
        name="Activities"
        //🇫🇷 On passe ActivitiesScreen à BottomNav pour qu'il puisse l'afficher
        //🇬🇧 We pass ActivitiesScreen to BottomNav so he display it
        children={() => (
          <ActivitiesScreen user={user} scr={scr} fromStack="calendar" />
        )}
        options={{
          title: JsonBottomNav.JsonActivitiesTab,
          //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
          //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
          tabBarIcon: ({ focused }) => (
            <img src={focused ? images.CalendarOn : images.CalendarOff} />
          ),
          tabBarIconStyle: { height: 30 },
          tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
          headerShown: true,
          headerTitle: JsonTopNav.JsonFirstLine,
          headerTitleAlign: "center",
          //🇫🇷 Le bouton dans le header pour afficher le DrawerNavigation
          //🇬🇧 Te button in the header to display the DrawerNavigation
          headerLeft: () => <BurgerButton />,
          headerStyle: { backgroundColor: "#59C09B" },
          headerTitleStyle: {
            color: "white",
            fontWeight: "bold",
            fontSize: 30,
          },
        }}
      />

      {/* Members Bottom Navigator */}
      <BottomTabNavigator.Screen
        name="Members"
        //🇫🇷 On passe MembersScreen à BottomNav pour qu'il puisse l'afficher
        //🇬🇧 We pass MembersScreen to BottomNav so he display it
        children={() => <MembersScreen scr={scr} />}
        options={{
          title: JsonBottomNav.JsonMembersTab,
          //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
          //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
          tabBarIcon: ({ focused }) => (
            <img src={focused ? images.MembersOn : images.MembersOff} />
          ),
          tabBarIconStyle: { height: 30 },
          tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
          headerShown: true,
          headerStyle: { backgroundColor: "#59C09B" },
          headerTitleStyle: {
            color: "white",
            fontWeight: "bold",
            fontSize: 30,
          },
          headerTitleAlign: "center",
          //🇫🇷 Le bouton dans le header pour afficher le DrawerNavigation
          //🇬🇧 Te button in the header to display the DrawerNavigation
          headerLeft: () => <BurgerButton />,
        }}
      />

      {/* Concept Bottom Navigator */}
      <BottomTabNavigator.Screen
        name="Concept"
        //🇫🇷 On passe ConceptScreen à BottomNav pour qu'il puisse l'afficher
        //🇬🇧 We pass ConceptScreen to BottomNav so he display it
        children={() => <ConceptScreen scr={scr} />}
        options={{
          title: JsonBottomNav.JsonConceptTab,
          //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
          //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
          tabBarIcon: ({ focused }) => (
            <img src={focused ? images.ConceptOn : images.ConceptOff} />
          ),
          tabBarIconStyle: { height: 30 },
          tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
          headerShown: false,
        }}
      />

      {/* News Bottom Navigator */}
      <BottomTabNavigator.Screen
        name="News"
        //🇫🇷 On passe News à BottomNav pour qu'il puisse l'afficher
        //🇬🇧 We pass News to BottomNav so he display it
        children={() => <News scr={scr} />}
        options={{
          title: JsonBottomNav.JsonNewsTab,
          //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
          //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
          tabBarIcon: ({ focused }) => (
            <img src={focused ? images.NewsOn : images.NewsOff} />
          ),
          tabBarIconStyle: { height: 30 },
          tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
          headerShown: true,
          headerStyle: { backgroundColor: "#59C09B" },
          headerTitleStyle: {
            color: "white",
            fontWeight: "bold",
            fontSize: 30,
          },
          headerTitleAlign: "center",
          //🇫🇷 Le bouton dans le header pour afficher le DrawerNavigation
          //🇬🇧 Te button in the header to display the DrawerNavigation
          headerLeft: () => <BurgerButton />,
        }}
      />
 {/* <BottomTabNavigator.Screen
  name="Notification"
  //🇫🇷 On passe NotifScreen à BottomNav pour qu'il puisse l'afficher
  //🇬🇧 We pass NotifScreen to BottomNav so he display it
  children={() => <NotifScreen scr={scr} />}
  options={{
    title: scr && scr.JsonBottomNav && scr.JsonBottomNav.JsonNotifTab ? scr.JsonBottomNav.JsonNotifTab : "Notifications",
    //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
    //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
    tabBarIcon: ({ focused }) => (
      <img src={focused ? images.NotifBelOn : images.NotifBelOff} />
    ),
    tabBarIconStyle: { height: 30 },
    tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
    headerShown: true,
    headerStyle: { backgroundColor: "#59C09B" },
    headerTitleStyle: {
      color: "white",
      fontWeight: "bold",
      fontSize: 30,
    },
    headerTitleAlign: "center",
    //🇫🇷 Le bouton dans le header pour afficher le DrawerNavigation
    //🇬🇧 The button in the header to display the DrawerNavigation
    headerLeft: () => <BurgerButton />,
  }}
/> */}

 
      {/* Contact Bottom Navigator */}
      <BottomTabNavigator.Screen
        name="Contact"
        //🇫🇷 On passe ContactScreen à BottomNav pour qu'il puisse l'afficher
        //🇬🇧 We pass ContactScreen to BottomNav so he display it
        children={() => <ContactScreen scr={scr} />}
        options={{
          title: JsonBottomNav.JsonContactUsTab,
          //🇫🇷 Le paramètre "focused" sert à gérer l'affichage de l'icone dans la BottomNav si on est sur cette page ou pas
          //🇬🇧 The "focused" parameter is used to manage the display of the icon in the BottomNav if we are on this page or not
          tabBarIcon: ({ focused }) => (
            <img src={focused ? images.ContactOn : images.ContactOff} />
          ),
          tabBarIconStyle: { height: 30 },
          tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
          headerShown: true,
          headerStyle: { backgroundColor: "#59C09B" },
          headerTitleStyle: {
            color: "white",
            fontWeight: "bold",
            fontSize: 30,
          },
          headerTitleAlign: "center",
          //🇫🇷 Le bouton dans le header pour afficher le DrawerNavigation
          //🇬🇧 Te button in the header to display the DrawerNavigation
          headerLeft: () => <BurgerButton />,
        }}
      />
    </BottomTabNavigator.Navigator>
  );
};

export default BottomNav;
