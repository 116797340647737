import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer, useNavigation } from "@react-navigation/native"; // (fr) page de navigation // (en) navigation page
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { StyleSheet, useWindowDimensions } from "react-native";
import {
  GestureHandlerRootView,
  TouchableOpacity,
} from "react-native-gesture-handler";
import { enGB, registerTranslation } from "react-native-paper-dates";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

// import du provider et de configureStore
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { ActivityProvider } from "./src/pages/ActivityPages/ActivityScreen/ActivityContext.js";
import reducers from "./src/reducers";

//----------------Backend-----------------//
import { hostname } from "../front-mobile/backendconnect/hostname.js";

//---------------Components---------------//
import DrawerNavigation from "./src/navigation/DrawerNavigation";
import ErrorFallback from "./src/pages/Error/ErrorFallback";
import HomeScreen from "./src/pages/HomeScreen/HomeScreen.js";
import InviteFriendsScreen from "./src/pages/InviteFriendsScreen/InviteFriendsScreen.js";
import SettingsScreen from "./src/pages/SettingsScreen/SettingsScreen.js";
import Update from "./src/pages/Update/Update";

// Login/Register
import EmailScreen from "./src/pages/LoginRegisterPages/EmailScreen/EmailScreen.js";
import PasswordScreenExistingEmail from "./src/pages/LoginRegisterPages/PasswordScreenExistingEmail/PasswordScreenExistingEmail.js";
import PasswordScreenNoEmail from "./src/pages/LoginRegisterPages/PasswordScreenNoEmail/PasswordScreenNoEmail.js";
import VerificationMailScreenRoleUpdate from "./src/pages/LoginRegisterPages/VerificationMailScreenRoleUpdate/VerificationMailScreenRoleUpdate.js";
import VerificationNewUserScreen from "./src/pages/LoginRegisterPages/VerificationNewUserScreen/VerificationNewUserScreen.js";
import VerificationPasswordUpdateScreen from "./src/pages/LoginRegisterPages/VerificationPasswordUpdateScreen/VerificationPasswordUpdateScreen.js";
import VerificationScreen from "./src/pages/LoginRegisterPages/VerificationScreen(Suppresion_à_vérifier)/VerificationScreen(Suppresion_à_vérifier).js";

// Create Profile
import CreateProfileScreen from "./src/pages/CreateProfilePages/CreateProfileScreen/CreateProfileScreen.js";
import CreateProfileScreenStepFour from "./src/pages/CreateProfilePages/CreateProfileScreenStepFour/CreateProfileScreenStepFour.js";
import CreateProfileScreenStepThree from "./src/pages/CreateProfilePages/CreateProfileScreenStepThree/CreateProfileScreenStepThree.js";
import CreateProfileScreenStepTwo from "./src/pages/CreateProfilePages/CreateProfileScreenStepTwo/CreateProfileScreenStepTwo.js";

// Activity
import ActivityScreen from "./src/pages/ActivityPages/ActivityScreen/ActivityScreen";
import CopyActivityScreen from "./src/pages/ActivityPages/CopyActivityScreen/CopyActivityScreen.js";
import CreateActivityScreen from "./src/pages/ActivityPages/CreateActivityScreen/CreateActivityScreen.js";
import UpdateActivityScreen from "./src/pages/ActivityPages/UpdateActivityScreen/UpadateActivityScreen.js";

// Profile
import EditProfileScreen from "./src/pages/ProfilePages/EditProfileScreen/EditProfileScreen.js";
import MyProfileScreen from "./src/pages/ProfilePages/MyProfileScreen/MyProfileScreen.js";
import DeleteAccountScreen from "./src/pages/SettingsPages/deleteAccountScreen/deleteAccountScreen.js";



import CupsAndAwards from "./src/pages/AchievementPages/CupsAndAwards/CupsAndAwards.js";
import VipBadges from "./src/pages/AchievementPages/VipBadgesPages/VipBadgeExplanationSelector/VipBadgeExplanationSelector.js";
import VipBadgeGold from "./src/pages/AchievementPages/VipBadgesPages/VipBadgeAdvantagesGold/VipBadgeAdvantagesGold.js";
import VipBadgeRuby from "./src/pages/AchievementPages/VipBadgesPages/VipBadgeAdvantagesRuby/VipBadgeAdvantagesRuby.js";
import VipBadgeEmerald from "./src/pages/AchievementPages/VipBadgesPages/VipBadgeAdvantagesEmerald/VipBadgeAdvantagesEmerald.js";
import VipBadgeDiamand from "./src/pages/AchievementPages/VipBadgesPages/VipBadgeAdvantagesDiamond/VipBadgeAdvantagesDiamond.js";
import VipBoardExplanation from "./src/pages/AchievementPages/VipBoardExplanation/VipBoardExplanation.js";
import RewardsExplanation from "./src/pages/AchievementPages/RewardsExplanation/RewardsExplanation.js";
import Rewards from "./src/pages/AchievementPages/Reward/Rewards.js";



// import DeleteAccountScreen from "./src/pages/SettingsPages/deleteAccountScreen";
// import Parrainage from "./src/pages/Parrainage";
// import ChatList from "./src/pages/ChatPrivePages/ChatList";
// import ChatDetail from "./src/pages/ChatPrivePages/ChatDetail";
// import MedalScreen from "./src/pages/SettingsPages/medalScreen";
// import ClosedGiftScreen from "./src/pages/SettingsPages/closedGiftScreen";

// import PrivacyPolicyScreen from "./src/pages/SettingsPages/privacyPolicyScreen";
// import TermsOfSalesScreen from "./src/pages/SettingsPages/termsOfSalesScreen";
// import LegalNoticeScreen from "./src/pages/SettingsPages/legalNoticeScreen";
// import BlockedUserScreen from "./src/pages/SettingsPages/blockedUserScreen";
// import LikedUsersScreen from "./src/pages/SettingsPages/likedUsersScreen";

//----------------Assets----------------//
import Json from "./../front-mobile/src/assets/json/en.json";

// import des images
import { images } from "./src/constantes";

registerTranslation("en-GB", enGB);

const Stack = createNativeStackNavigator(); // (fr) pile de navigation // (en) navigation stack

const store = configureStore({
  reducer: reducers,
});

export default function App() {
  const { height, width } = useWindowDimensions();

  const [userToken, setUserToken] = useState(null);
  const [switch1, setSwitch1] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [user, setUser] = useState({});
  const [shouldShowContactScreen, setShouldShowContactScreen] = useState(false);

  const [sostLanguage, setSostLanguage] = useState("English");

  useEffect(() => {
    if (user) {
      if (user.nativeLanguage) {
        setSostLanguage(user.nativeLanguage);
      }
    }
  }, [user]);

  //Create Profile State
  const [gender, setGender] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [city, setCity] = useState(null);
  const [nativeLanguage, setLanguage] = useState(null);
  const [flags, setFlags] = useState(null);
  const [role, setRole] = useState({});
  const [userNativeLanguage, setUserNativeLanguage] = useState(null);

  //le number on le changera, la variable number va permettre d'appeler le screen verifemail et passer sur create profiles
  const [number, setNumber] = useState(0);
  //le registerMailCall va permettre de recuperer l'email du register et pour l'utiliser dans le verif screen
  const [registerMailCall, setRegisterMailCall] = useState();
  //Card mode représente le design de l'ActivityCard (voir ActivityCards, dans le dossier components). D'après le figma, ce n'est utilisé que dans le dossier reactnative...mais au cas où, le composant est déjà prêt à l'emploi
  const [cardMode, setCardMode] = useState("small");

  const [rolesList, setRolesList] = useState(null);
  //Pour afficher le message de VerificationScreen
  const [skipWarning, displaySkipWarning] = useState(false);
  //test
  //const navigation =useNavigation()
  //console.log('navigation is :',navigation)
  //end test
  const profileState = {
    // (fr) état du profil // (en) profile state // profilState c'est utilisé pour stoker les donnés d'enregistrement pour nouveau utilisateur
    gender,
    setGender,
    accountType,
    setAccountType,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    nickName,
    setNickName,
    city,
    setCity,
    nativeLanguage,
    setLanguage,
    role,
    setRole,
  };

  nativeLanguage && console.log("native language in app.js", nativeLanguage);
  // console.log("etat de profilState", profileState);

  const switchBtn = () => {
    if (switch1 === false) {
      setSwitch1(true);
    } else if (switch1 === true) {
      setSwitch1(false);
    }
  };

  const setToken = async (token) => {
    // console.log("App.setToken = ", token);
    if (token) {
      await AsyncStorage.setItem("userToken", token);
      setUserToken(token);
    } else {
      await AsyncStorage.removeItem("userToken");
    }
  };

  const setProfile = async (profile) => {
    if (profile) {
      await AsyncStorage.setItem("userProfile", profile);
    } else {
      await AsyncStorage.removeItem("userProfile");
    }
    setUserProfile(profile);
  };

  //j'ai aussi envoyer ça sur burger

  useEffect(() => {
    const fetchToken = async () => {
      const receivedUserToken = await AsyncStorage.getItem("userToken");
      setUserToken(receivedUserToken);
    };
    fetchToken();
  }, []);

  useEffect(() => {
    AsyncStorage.getItem("user").then((suser) => {
      if (suser != "undefined") {
        setUser(JSON.parse(suser));
        // console.log("user pt", user, JSON.parse(suser));
        // console.log(suser);
      } else {
        // AsyncStorage.removeItem("suser");
        // setUser("rrrrrrrrrrr");
        // console.log(user);
      }
    });
  }, []);

  useEffect(() => {
    AsyncStorage.getItem("user").then((suser) => {
      if (suser != "undefined") {
        setUser(JSON.parse(suser));
        // console.log("user pt", user, JSON.parse(suser));
        // console.log(suser);
      } else {
        // AsyncStorage.removeItem("suser");
        // setUser("rrrrrrrrrrr");
        // console.log(user);
      }
    });
  }, [
    profileState.city,
    profileState.firstName,
    profileState.lastName,
    profileState.gender,
  ]);

  useEffect(() => {
    const fetchProfileStep = async () => {
      const getProfileStep = await AsyncStorage.getItem("userProfile");
      setUserProfile(getProfileStep);
    };
    fetchProfileStep();
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await axios.get(`${hostname}/api/v1/get-roles/`);
        setRolesList(response.data);
      } catch (error) {
        //console.log(error.message);
      }
    };
    try {
      getRoles();
      //  console.log(rolesList);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const nonVerifiedUser =
    rolesList &&
    rolesList.find((role) => role.name === "user without confirmation");

  //passage de la langue selectionnée dans l'homescreen, aux children
  //////////////////////////////////////////////////////////////////////
  const [concept, setConcept] = useState("Concept"); ///
  const [scr, setScr] = useState(Json); ///
  const [scrEdit, setScrEdit] = useState(Json); ///
  const [title, setTitle] = useState(""); //language not selected, check app.js
  const [count, setCount] = useState(0); //// data flow et
  const [countEdit, setCountEdit] = useState(0); //// data flow et

  const [countPays, setCountPays] = useState(0); ////
  const [payscountry, setPayscountry] = useState("");
  // console.log(user);
  useEffect(() => {
    if (payscountry == "" || payscountry == "undefined") {
      setPayscountry(sostLanguage);
    } //en cas de bug le nome du pays envoye de default est english
  });
  const pays = (pa) => {
    //function messanger envoyée à l'homescreen pour recuperer
    setCountPays(1); //le nom du pays
    setPayscountry(pa.language);
  };
  let paysC = "English"; //si y a un bug le nom de la langue de deafault et l'English
  if (countPays >= 1) {
    //garde en sessionstorage le nome du pays choisi par l'user
    sessionStorage.removeItem("Pays"); // au demarrage de l'app
    sessionStorage.setItem("Pays", JSON.stringify(payscountry));
  }
  if (sessionStorage.Pays != null) {
    paysC = JSON.parse(sessionStorage.getItem("Pays")); //props nom du pays envoyé a EdiProfilScreen
  }

  const increment = (gred) => {
    //function messeger entre homescreen et l'app permet de recuperer la langue selectionnée au demarrage de l'APP
    //// reverse data flow
    setCount(count + 1); /// avec l'homescreen
    setConcept(
      gred.JsonLoginRegisterPages.JsonEmailScreen.JsonHeaderTitleWelcome
    );
    setScr(gred);
    // console.log(gred);

    return scr, count;
  };

  const inc = (gre) => {
    //function messeger entre App et EditProfileScreen
    useEffect(() => {
      setCountEdit(countEdit + 1);
    }, [gre]);
    // console.log(gre);
    setScrEdit(gre); // Permet de recuperer la langue de l'app settée dans l'EditProfileScreen
    // console.log(scrEdit);
    // console.log(scr);
    // console.log(countEdit);
  };

  //garde la langue selected en session
  let x = scr;
  if (count >= 1) {
    sessionStorage.setItem("keyy", JSON.stringify(scr));
  }
  if (sessionStorage.keyy != null) {
    x = JSON.parse(sessionStorage.getItem("keyy"));
  } else {
    x = scr;
  }

  let y = scr;
  if (scrEdit && countEdit != 0) {
    // console.log(scrEdit);
    sessionStorage.setItem("keyyEdit", JSON.stringify(scrEdit));
    sessionStorage.setItem("keyy", JSON.stringify(scrEdit));
  }
  if (sessionStorage.keyyEdit != null && countEdit != 0) {
    // console.log(sessionStorage.keyyEdit);
    // console.log(sessionStorage.keyy);
    y = JSON.parse(sessionStorage.getItem("keyyEdit")); //scrEdit=>langue selectionnée en EditProfile; y variable de la langue selectionnée
    // console.log(y);
    x = y; //passage de la langue selectionnée en EditProfile aux components
  } else {
    y = scrEdit;
  }
  // console.log(sessionStorage)
  // console.log(count);
  // console.log(x);

  // console.log(nativeLanguage);

  //
  /////////////////////////////////////////////////////////////////////////

  // console.log("user", user);
  // console.log("profile", profile);
  // console.log("UserProfile", userProfile);
  // console.log(registerMailCall);

  const handleReset = () => {
    setShouldShowContactScreen(true);
    localStorage.setItem("shouldShowContactScreen", JSON.stringify(true));
    window.location.reload();
  };

  useEffect(() => {
    // Retrieve the value from local storage to check if the ContactScreen should be shown
    const shouldShowContactScreen = localStorage.getItem(
      "shouldShowContactScreen"
    );

    if (shouldShowContactScreen) {
      // Parse the stored value
      const parsedShouldShowContactScreen = JSON.parse(shouldShowContactScreen);

      // Set the state variable based on the retrieved value
      setShouldShowContactScreen(parsedShouldShowContactScreen);

      // Clear the value from local storage since it has been used
      localStorage.removeItem("shouldShowContactScreen");
    }
  }, []);

  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();
  // apres l'actualisationle user reste sur la meme page
  useEffect(() => {
    // Récupérer l'état sauvegardé lors du démarrage de l'application
    const restoreState = async () => {
      try {
        const savedStateString = await AsyncStorage.getItem("NAVIGATION_STATE");
        if (savedStateString) {
          const state = JSON.parse(savedStateString);
          setInitialState(state);
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null; // Ou un écran de chargement
  }

  // const Stack = createStackNavigator();
  // const [initialRoute, setInitialRoute] = useState("Home"); // Par défaut, on commence par l'écran d'accueil
  // useEffect(() => {
  //   const fetchRegistrationStep = async () => {
  //     const step = await AsyncStorage.getItem("registrationStep");
  //     if (step) {
  //       setInitialRoute(step);
  //     }
  //   };

  //   fetchRegistrationStep();
  // }, []);

  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <GestureHandlerRootView
          style={{
            width: width >= 450 ? 450 : width,
            flex: 1,
            marginHorizontal: "auto",
          }}
        >
          <SafeAreaView
            style={{
              width: width >= 450 ? 450 : width,
              flex: 1,
              marginHorizontal: "auto",
            }}
          >
            <NavigationContainer
              initialState={initialState}
              onStateChange={
                (state) =>
                  AsyncStorage.setItem(
                    "NAVIGATION_STATE",
                    JSON.stringify(state)
                  ) // Sauvegarder l'état de la navigation NAVIGATION_STATE dans le local storage
              }
            >
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={handleReset}
              >
                <ActivityProvider>
                <Stack.Navigator>
                  {/* initialRouteName={initialRoute} */}
                  {userToken === null ? ( //Par défaut, on commence par l'écran d'accueil
                    // sans token, l'utilisateur est invité a se connecter
                    <>
                      {/* HomeScreen */}
                      <Stack.Screen
                        name="Home"
                        options={{ headerShown: false }}
                      >
                        {(props) => (
                          <HomeScreen
                            {...props}
                            flags={flags}
                            onClick={increment}
                            pays={pays}
                            count={count}
                            setUserNativeLanguage={setUserNativeLanguage}
                          />
                        )}
                      </Stack.Screen>

                      {/* EmailScreen */}
                      <Stack.Screen
                        name="LogIn"
                        options={{
                          title: title,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                        {(props) => <EmailScreen {...props} scr={x} />}
                      </Stack.Screen>

                      {/* VerificationPasswordUpdateScreen */}
                      <Stack.Screen
                        name="VerificationIdentity"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonVerificationCode
                              .JsonTitleVerification,
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {() => (
                          <VerificationPasswordUpdateScreen
                            setToken={setToken}
                            setUser={setUser}
                            scr={x}
                          />
                        )}
                      </Stack.Screen>

                      {/* PasswordScreenNoEmail */}
                      <Stack.Screen
                        name="PasswordNoEmail"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonPasswordScreen
                              .JsonHeaderTitlePassword,
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                        {(props) => (
                          <PasswordScreenNoEmail
                            {...props}
                            setToken={setToken}
                            setProfile={setProfile}
                            setRole={setRole}
                            rolesList={rolesList}
                            setNumber={setNumber}
                            setRegisterMailCall={setRegisterMailCall}
                            scr={x}
                          />
                        )}
                      </Stack.Screen>

                      {/* PasswordScreenExistingEmail */}
                      <Stack.Screen
                        name="PasswordExistingEmail"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonPasswordScreen
                              .JsonHeaderTitlePassword,
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                        {(props) => (
                          <PasswordScreenExistingEmail
                            {...props}
                            setToken={setToken}
                            setUser={setUser}
                            scr={x}
                          />
                        )}
                      </Stack.Screen>

                      {/* VerificationScreen */}
                      <Stack.Screen
                        name="Verification"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonVerificationCode
                              .JsonTitleVerification,
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {() => (
                          <VerificationScreen
                            setNumber={setNumber}
                            scr={x}
                            rolesList={rolesList}
                          />
                        )}
                      </Stack.Screen>
                    </>
                  ) : number === 1 ? (
                    <>
                      {/* VerificationNewUser */}
                      <Stack.Screen
                        name="VerificationMail"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonVerificationCode
                              .JsonTitleVerification,
                          headerShadowVisible: false,
                          headerStyle: {
                            backgroundColor: "#59C09B",
                            color: "white",
                            marginRight: 10,
                          },
                          headerRight: () => {
                            const navigation = useNavigation();
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  if (!skipWarning) {
                                    displaySkipWarning(true);
                                  } else {
                                    setNumber(2);
                                    setTimeout(() => {
                                      navigation.navigate("Step1", {
                                        role: nonVerifiedUser,
                                        nativeLanguage: userNativeLanguage,
                                      });
                                    }, 0);
                                    displaySkipWarning(false);
                                  }
                                }}
                                style={{ marginRight: 10, cursor: "pointer" }}
                              >
                                <img src={images.SkipButton} />
                              </TouchableOpacity>
                            );
                          },
                        }}
                      >
                        {/* Le setTimeout dans le else au dessus est la seule entourloupe trouvée pour s'assurer que le number prenne la valeur de 2, condition nécessaire pour une navigation sans bug vers la page Step1. Pour ce qui est de l'explication logique à ça...désolée, sur ce coup-là, je vais vous répondre "tais-toi, c'est magique"...si vous trouvez mieux, n'hésitez pas! */}
                        {() => (
                          <VerificationNewUserScreen
                            registerMailCall={registerMailCall}
                            setNumber={setNumber}
                            scr={x}
                            rolesList={rolesList}
                            skipWarning={skipWarning}
                          />
                        )}
                      </Stack.Screen>
                    </>
                  ) : userProfile !== "done" && number === 2 ? (
                    <>
                      {/* CreateProfileScreen */}
                      <Stack.Screen
                        name="Step1"
                        options={{
                          title:
                            x.JsonCreateProfilePages.JsonTitleCreateProfile,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {(props) => (
                          <CreateProfileScreen
                            scr={x}
                            profileState={profileState}
                            userToken={userToken}
                            user={user}
                            setUser={setUser}
                            role={role}
                            userNativeLanguage={userNativeLanguage}
                            {...props}
                          />
                        )}
                      </Stack.Screen>

                      {/* VerificationNewUser */}
                      <Stack.Screen
                        name="VerificationMail"
                        options={{
                          title: "Verification",
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerRight: () => {
                            const navigation = useNavigation();
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  if (!skipWarning) {
                                    displaySkipWarning(true);
                                  } else {
                                    setNumber(2);
                                    setTimeout(() => {
                                      navigation.navigate("Step1", {
                                        role: nonVerifiedUser,
                                        nativeLanguage: userNativeLanguage,
                                      });
                                    }, 0);
                                    displaySkipWarning(false);
                                  }
                                }}
                                style={{ marginRight: 10, cursor: "pointer" }}
                              >
                                <img src={images.SkipButton} />
                              </TouchableOpacity>
                            );
                          },
                        }}
                      >
                        {() => (
                          <VerificationNewUserScreen
                            registerMailCall={registerMailCall}
                            setNumber={setNumber}
                            scr={x}
                            rolesList={rolesList}
                            skipWarning={skipWarning}
                          />
                        )}
                      </Stack.Screen>

                      {/* CreateProfileScreenStepTwo */}
                      <Stack.Screen
                        name="Step2"
                        options={{
                          title:
                            x.JsonCreateProfilePages.JsonTitleCreateProfile,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {(props) => (
                          <CreateProfileScreenStepTwo
                            flags={flags}
                            scr={x}
                            profileState={profileState}
                            userToken={userToken}
                            user={user}
                            setUser={setUser}
                            {...props}
                          />
                        )}
                      </Stack.Screen>

                      {/* CreateProfileScreenStepThree */}
                      <Stack.Screen
                        name="Step3"
                        options={{
                          title:
                            x.JsonCreateProfilePages.JsonTitleCreateProfile,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {(props) => (
                          <CreateProfileScreenStepThree
                            scr={x}
                            profileState={profileState}
                            userToken={userToken}
                            user={user}
                            setUser={setUser}
                            {...props}
                          />
                        )}
                      </Stack.Screen>

                      {/* CreateProfileScreenStepFour */}
                      <Stack.Screen
                        name="Step4"
                        options={{
                          title:
                            x.JsonCreateProfilePages.JsonTitleCreateProfile,
                          headerStyle: { backgroundColor: "#59C09B" },
                        }}
                      >
                        {(props) => (
                          <CreateProfileScreenStepFour
                            profileState={profileState}
                            setProfile={setProfile}
                            setUserProfile={setUserProfile}
                            userToken={userToken}
                            user={user}
                            setUser={setUser}
                            scr={x}
                            {...props}
                          />
                        )}
                      </Stack.Screen>
                    </>
                  ) : (
                    <>
                      {/* The stack for the DrawerNavigation */}
                      <Stack.Screen
                        name={"Drawer"}
                        options={{ headerShown: false }}
                      >
                        {() => (
                          <DrawerNavigation
                            setToken={setToken}
                            user={user}
                            setUser={setUser}
                            concept={concept}
                            gender={gender}
                            setGender={setGender}
                            accountType={accountType}
                            setAccountType={setAccountType}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            nickName={nickName}
                            setNickName={setNickName}
                            city={city}
                            setCity={setCity}
                            nativeLanguage={nativeLanguage}
                            setNativeLanguage={setLanguage}
                            role={role}
                            setRole={setRole}
                            scr={x}
                            shouldShowContactScreen={shouldShowContactScreen}
                          />
                        )}
                      </Stack.Screen>

                      {/* ActivityScreen */}
                      <Stack.Screen
                        name="Activity"
                        options={{
                          //🇫🇷 le titre du header transmis depuis le composant ActivityCard par react router, ensuite on utilise useLayoutEffect sur ActivityScreen pour la modification de title dans le header🇫🇷
                          //🇬🇧 the title of the header transmitted from the ActivityCard component by react router, then we useLayoutEffect on ActivityScreen for the modification of title in the header🇬🇧
                          //title: x.activity.title,
                          tabBarLabel: "Activity",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                        }}
                      >
                        {/* user={user} permet de transférer les infos de l'utilisateur connecté. Utiliser les props ne le fait pas, étrangement (ex: dans une activité que l'on a pas créé, on est considéré comme un user, mais c'est parce que route.params récupère les infos du créateur...c'est à se demander si les props servent à quelque chose, pour cette page...) */}
                        {(props) => (
                          <ActivityScreen {...props} user={user} scr={x} />
                        )}
                      </Stack.Screen>

                      {/* CreateActivityScreen */}
                      <Stack.Screen
                        name="Create Activity"
                        options={{
                          title:
                            x.JsonActivityPages.JsonActivityManagement
                              .JsonTitleCreateActivity,
                          tabBarLabel: "Create Activity",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {/*🇫🇷 Pour l'instant, un nouveau CreateActivityScreen est en train d'être créé, le "CreateActivityManageDisplayScreen" étant le nouveau */}
                        {/*🇬🇧 For now, a new CreateActivityScreen is being created, with the "CreateActivityManageDisplayScreen" being the new */}
                        {(props) => <CreateActivityScreen {...props} scr={x} />}
                        {/* {() => <CreateActivityManageDisplayScreen scr={x} />} */}
                      </Stack.Screen>

                      {/* CopyActivityScreen */}
                      <Stack.Screen
                        name="Copy Activity"
                        options={{
                          title:
                            x.JsonActivityPages.JsonActivityManagement
                              .JsonTitleCopyActivity,
                          tabBarLabel: "Copy Activity",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => (
                          <CopyActivityScreen
                            {...props}
                            userToken={userToken}
                            user={user}
                            scr={scr}
                          />
                        )}
                      </Stack.Screen>

                      {/* UpdateActivityScreen */}
                      <Stack.Screen
                        name="Modify Activity"
                        options={{
                          title:
                            x.JsonActivityPages.JsonActivityManagement
                              .JsonTitleModifyActivity,
                          tabBarLabel: "Modify Activity",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => (
                          <UpdateActivityScreen user={user} scr={x} />
                        )}
                      </Stack.Screen>

                      {/* MyProfileScreen */}
                      <Stack.Screen
                        name="Profile"
                        options={{
                          title:
                            x.JsonProfilePages.JsonMyProfileScreen
                              .JsonTitleProfile,
                          tabBarLabel: "Profile",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => <MyProfileScreen user={user} scr={x} />}
                      </Stack.Screen>

                      {/* EditProfileScreen */}
                      <Stack.Screen
                        name="Edit Profile"
                        options={{
                          title:
                            x.JsonProfilePages.JsonEditProfileScreen
                              .JsonTitleEditProfile,
                          tabBarLabel: "Edit Profile",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => (
                          <EditProfileScreen
                            user={user}
                            scr={x}
                            token={userToken}
                            setUser={setUser}
                            onClick={inc}
                            payscountry={paysC}
                          />
                        )}
                      </Stack.Screen>

                      {/* VerificationMailScreenRoleUpdate */}
                      <Stack.Screen
                        name="VerificationMailRole"
                        options={{
                          title:
                            x.JsonLoginRegisterPages.JsonVerificationCode
                              .JsonTitleVerification,
                          headerShadowVisible: false,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                        }}
                      >
                        {() => (
                          <VerificationMailScreenRoleUpdate
                            scr={x}
                            user={user}
                            rolesList={rolesList}
                          />
                        )}
                      </Stack.Screen>

                      {/* Update */}
                      <Stack.Screen
                        name="Update"
                        options={{
                          title: "Update",
                          headerTitleAlign: "center",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                        }}
                      >
                        {() => <Update scr={x} />}
                      </Stack.Screen>

                      {/* Delete my Account */}
                      <Stack.Screen
                        name="DeleteAccountScreen"
                        options={{
                          title: "Delete Account",
                          headerTitleAlign: "center",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                        }}
                      >
                        {() => <DeleteAccountScreen scr={scr} />}
                      </Stack.Screen>

                      {/* Settings */}
                      <Stack.Screen
                        name="Settings"
                        options={{
                          title: "Settings",
                          headerTitleAlign: "center",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                        }}
                      >
                        {(props) => <SettingsScreen scr={x} {...props} />}
                      </Stack.Screen>

                      {/* MemberShipScreen */}
                      <Stack.Screen
                        name="CupsAndAwards"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleCupsAndAwards,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <CupsAndAwards {...props} scr={x} />}
                      </Stack.Screen>

                      {/* InviteFriendsScreen */}
                      <Stack.Screen
                        name="Invite Friends"
                        options={{
                          title: "Invite Friends",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => <InviteFriendsScreen {...props} scr={x} />}
                      </Stack.Screen>

                      <Stack.Screen
                        name="HomeScreen"
                        component={HomeScreen}
                        options={{ path: "/" }} // path défini à '/'
                      />

                      <Stack.Screen
                        name="ErrorFallback"
                        options={{
                          title: "Error",
                          headerStyle: { backgroundColor: "#ff0000" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",
                        }}
                      >
                        {(props) => <ErrorFallback {...props} />}
                      </Stack.Screen>

                      {/* test */}
                      <Stack.Screen
                        name="VipBadges"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleVipBadges,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBadges {...props} scr={x} />}
                      </Stack.Screen>

                      {/* test */}
                      <Stack.Screen
                        name="VipBadgeGold"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleVipBadgesAdvantages,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBadgeGold {...props} scr={x} />}
                      </Stack.Screen>
                     
                      {/* test */}
                      <Stack.Screen
                        name="VipBadgeRuby"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleVipBadgesAdvantages,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBadgeRuby {...props} scr={x} />}
                      </Stack.Screen>
                      
                       {/* test */}
                       <Stack.Screen
                        name="VipBadgeEmerald"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleVipBadgesAdvantages,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBadgeEmerald {...props} scr={x} />}
                      </Stack.Screen>
                       {/* test */}
                       <Stack.Screen
                        name="VipBadgeDiamand"
                        options={{
                          title: x.JsonAchievementsPages.JsonTitleVipBadgesAdvantages,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBadgeDiamand {...props} scr={x} />}
                      </Stack.Screen>


                      {/* VipBoardExplanation */}
                      <Stack.Screen
                        name="VipBoardExplanation"
                        options={{
                          title: x.JsonAchievementsPages.JsonSponsorshipScreen.JsonVIPBoardExplanation,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <VipBoardExplanation {...props} scr={x} />}
                      </Stack.Screen>

                       {/* Rewards */}
                       <Stack.Screen
                        name="Rewards"
                        options={{
                          title: x.JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards,
                          headerShadowVisible: true,
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTintColor: "#FFFFFF",
                          headerTitleStyle: {
                            fontWeight: "bold",
                            fontSize: 20,
                          },
                        }}
                      >
                      {(props) => <Rewards {...props} scr={x} />}
                      </Stack.Screen>

                      {/* <Stack.Screen
                            name="BlockedUserScreen"
                            component={BlockedUserScreen}
                            options={{
                              title: "Utilisateurs bloqués",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          /> */}
                      {/* {
                          <Stack.Screen
                            name="LikedUsersScreen"
                            component={LikedUsersScreen}
                            options={{
                              title: "Utilisateurs aimés",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="EditProfile"
                            component={EditProfileScreenSettings}
                            options={{
                              title: "Modifier le profil",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="Medal"
                            component={MedalScreen}
                            options={{
                              title: "Médailles",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="ClosedGiftScreen"
                            component={ClosedGiftScreen}
                            options={{
                              title: "Cadeaux fermés",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="DeleteAccount"
                            component={DeleteAccountScreen}
                            options={{
                              title: "Supprimer le compte",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="PrivacyPolicy"
                            component={PrivacyPolicyScreen}
                            options={{
                              title: "Politique de confidentialité",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="TermsOfSales"
                            component={TermsOfSalesScreen}
                            options={{
                              title: "Conditions de vente",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          />
                          
                          <Stack.Screen
                            name="LegalNotice"
                            component={LegalNoticeScreen}
                            options={{
                              title: "Mentions légales",
                              //...autres options de l'en-tête si nécessaire...
                            }}
                          /> */}

                      {/* <Stack.Screen
                        name="ChatList"
                        options={({ navigation }) => ({
                          title: "Personal Inbox",
                          tabBarLabel: "Personal Inbox",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",

                          headerLeft: () => <BurgerButton />,

                          headerRight: () => (
                            <Pressable
                              onPress={() => {
                                // Handle the button press here
                                // For example, you can navigate to a settings screen
                                navigation.navigate("Socializus");
                              }}
                              style={{ marginRight: 10 }}
                            >
                              <Icon
                                name="ellipsis-vertical"
                                size={24}
                                color="white"
                              />
                            </Pressable>
                          ),
                          //...other header options if needed...
                        })}
                      >
                        {() => <ChatList />}
                      </Stack.Screen> */}

                      {/* <Stack.Screen
                        name="ChatDetail"
                        options={({ navigation }) => ({
                          title: "ChatDetail",
                          tabBarLabel: "ChatDetail",
                          headerStyle: { backgroundColor: "#59C09B" },
                          headerTitleStyle: {
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 30,
                          },
                          headerTitleAlign: "center",

                          headerLeft: () => <BurgerButton />,

                          headerRight: () => (
                            <Pressable
                              onPress={() => {
                                // Handle the button press here
                                // For example, you can navigate to a settings screen
                                navigation.navigate("Socializus");
                              }}
                              style={{ marginRight: 10 }}
                            >
                              <Icon
                                name="ellipsis-vertical"
                                size={24}
                                color="white"
                              />
                            </Pressable>
                          ),
                          //...other header options if needed...
                        })}
                      >
                        {() => <ChatDetail />}
                      </Stack.Screen> */}
                    </>
                  )}
                </Stack.Navigator>
                </ActivityProvider>
              </ErrorBoundary>
            </NavigationContainer>
          </SafeAreaView>
        </GestureHandlerRootView>
      </SafeAreaProvider>
    </Provider>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    width: 450,
    "@media (max-width: 450px)": {
      width: "100%",
    },
    flex: 1,
    marginHorizontal: "auto",
  },
});
