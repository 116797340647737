import { Icon } from "@rneui/themed";
import React from "react";
import { Modal, Pressable, ScrollView, Text, View } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import styles from "./ActivityAttendeesCss";

import AttendeeCard from "../../../../components/AttendeeCard/AttendeeCard.js";

const ActivityAttendees = ({
  connectedUserRole,
  isOrganizer,
  waitingListModalVisible,
  isWaitingListModalVisible,
  scr,
  event,
  setEvent,
  attendees,
  setAttendees,
  fullAttendeeListModalVisible,
  isFullAttendeeListModalVisible,
}) => {
  const { JsonActivityPages } = scr;
  //  const route = useRoute();
  // const [event, setEvent] = useState(route.params.event);
  // const [attendees, setAttendees] = useState([]);
  // const [fullAttendeeListModalVisible, isFullAttendeeListModalVisible] =
  //   useState(false);
   const handleUnsubscribeUser = (userId) => {
    setAttendees((prevAttendees) =>
      prevAttendees.filter((attendee) => attendee._id !== userId)
     );
   };

  return (
    <>
      {/* Modal for seeing the full attendee list */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={fullAttendeeListModalVisible}
        onRequestClose={() => {
          isFullAttendeeListModalVisible(!fullAttendeeListModalVisible);
        }}
      >
        <View style={styles.listModalContainer}>
          <View style={[styles.listModalView, { backgroundColor: "#D8EDE6" }]}>
            <Pressable
              style={{ margin: 5, alignSelf: "flex-end" }}
              onPress={() =>
                isFullAttendeeListModalVisible(!fullAttendeeListModalVisible)
              } //(fr) onPress permet de définir une action à effectuer lorsqu'on appuie sur l'élément.(fr)
            >
              <MaterialCommunityIcons name="close" color="#787878" size={28} />
            </Pressable>
            <Text style={[styles.listModalTitle, { color: "#59c09b" }]}>
              {/* {activity.t2022_AttendeeList} */}
            </Text>
            <ScrollView>
              <View style={styles.listModalScrollView}>
                {/* J'ai mis la liste des participants pour tester, mettez la waiting list quand vous arriverez à la récupérer et dé-commentez le code en dessous */}
                {attendees.map(
                  (
                    user,
                    index //// clee unique pour eviter :avertissement "Each child in a list should have a unique 'key' prop".
                  ) => (
                    <Text key={`attendee_${index}`}>
                      <AttendeeCard
                        // key={index}
                        event={event}
                        user={user}
                        setAttendees={setAttendees}
                        handleUnsubscribeUser={handleUnsubscribeUser}
                        setEvent={setEvent}
                        connectedUserRole={connectedUserRole}
                        isOrganizer={isOrganizer}
                        fullAttendeeListModalVisible={
                          fullAttendeeListModalVisible
                        }
                        isFullAttendeeListModalVisible={
                          isFullAttendeeListModalVisible
                        }
                      />
                    </Text>
                  )
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
      {/* Modal for seeing the waiting List */}
      <Modal
        animationType="fade"
        transparent={true}
        visible={waitingListModalVisible}
        onRequestClose={() => {
          isWaitingListModalVisible(!waitingListModalVisible);
        }}
      >
        <View style={styles.listModalContainer}>
          <View style={[styles.listModalView, { backgroundColor: "#FFFCF3" }]}>
            <Pressable
              style={{ margin: 5, alignSelf: "flex-end" }}
              onPress={() =>
                isWaitingListModalVisible(!waitingListModalVisible)
              }
            >
              <MaterialCommunityIcons name="close" color="#787878" size={28} />
            </Pressable>
            <Text style={[styles.listModalTitle, { color: "#FFC107" }]}>
              {
                JsonActivityPages.JsonActivityScreen
                  .JsonActivityParticipantsScreen.JsonWaitingList
              }
            </Text>
            <ScrollView>
              <View style={styles.listModalScrollView}>
                {/* J'ai mis la liste des participants pour tester, mettez la waiting list quand vous arriverez à la récupérer et dé-commentez le code en dessous */}
                {event.waitingList &&
                  event.waitingList.map((user, index) => {
                    console.log("waitingList: ", event.waitingList);

                    return (
                      <View key={index}>
                        <AttendeeCard
                          //key={index}
                          key={`comment_${index}`} // clee unique pour eviter :avertissement "Each child in a list should have a unique 'key' prop".
                          event={event}
                          user={user}
                          setAttendees={setAttendees}
                          handleUnsubscribeUser={handleUnsubscribeUser}
                          setEvent={setEvent}
                          connectedUserRole={connectedUserRole}
                          isOrganizer={isOrganizer}
                          waitingListModalVisible={waitingListModalVisible}
                          isWaitingListModalVisible={isWaitingListModalVisible}
                        />
                      </View>
                    );
                  })}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>

      <View style={{ width: "90%" }}>
        <View style={styles.text}>
          {event.attendees && (
            <>
              <View style={{ flex: 1, flexDirection: "row" }}>
                <Text style={styles.activityAttendeesTxt}>
                  {
                    JsonActivityPages.JsonActivityScreen
                      .JsonActivityParticipantsScreen.JsonAttendees
                  }
                  :
                </Text>

                <Text style={styles.activityAttendeesTxtTwo}>
                  {event.nbFriends
                    ? event.attendees.length + event.nbFriends
                    : event.attendees.length}
                  {event.attendeeLimit === 1000000
                    ? null
                    : "/" + event.attendeeLimit}
                </Text>
              </View>
            </>
          )}

          {/* ************** VOIR AVEC BACK END POUR AFFICHAGE LIEN AU PROFIL************** */}
          {attendees.length > 12 && (
            <Pressable
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={() =>
                isFullAttendeeListModalVisible(!fullAttendeeListModalVisible)
              }
            >
              <Text style={{ fontWeight: "bold", marginRight: 5 }}>
                {
                  JsonActivityPages.JsonActivityScreen
                    .JsonActivityParticipantsScreen.JsonSeeAll
                }
              </Text>
              <Icon
                name="chevron-right"
                type="font-awesome"
                size={16}
                color="black"
              />
            </Pressable>
          )}
        </View>

        <View style={styles.attendeesView}>
          {attendees.map(
            (
              user,
              index // clee unique pour eviter :avertissement "Each child in a list should have a unique 'key' prop".
            ) => (
              <Text key={`attendee_${index}`}>
                <AttendeeCard
                  // key={index}
                  event={event}
                  user={user}
                  setAttendees={setAttendees}
                  handleUnsubscribeUser={handleUnsubscribeUser}
                  setEvent={setEvent}
                  connectedUserRole={connectedUserRole}
                  isOrganizer={isOrganizer}
                  fullAttendeeListModalVisible={fullAttendeeListModalVisible}
                  isFullAttendeeListModalVisible={
                    isFullAttendeeListModalVisible
                  }
                />
              </Text>
            )
          )}
        </View>
        {/*FR La balise View qui englobe les trois premières Cards des participants a la propriété "flexWrap",
        donc il n'y a pas besoin de rajouter d'autres Views en dessous. On peut simplement ajouter des
        balises Pressable dans la View.
        Un composant AttendeeCard pour afficher un participant a été ajouté. Essayez d'en afficher
        plusieurs dans une liste pour réduire la taille de ce code. */}
        {/*GB As the view surrounding the three first attendee cards has "flexWrap" in its properties,
         there's no need to create more views under it. Just adding more TouchableOpacities in the View
        will do the job just as fine. I created an "AttendeeCard" component as well. Try to generate a list
        of attendees with it so we can have a bit less code in this long-a*s page." */}

        <View
          style={[
            styles.organizerOptions,
            {
              borderBottomWidth: 0,
              borderRadius: 15,
              backgroundColor: "white",
            },
          ]}
        >
          <Text style={{ marginVertical: 20, fontSize: 15 }}>
            {
              JsonActivityPages.JsonActivityScreen
                .JsonActivityParticipantsScreen.JsonWaitingList
            }{" "}
            - ({event.waitingList ? event.waitingList.length : 0})
          </Text>
          {event.waitingList && event.waitingList.length !== 0 && (
            <Pressable
              style={{ flexDirection: "row", alignItems: "center" }}
              onPress={() =>
                isWaitingListModalVisible(!waitingListModalVisible)
              }
            >
              <Text style={{ fontWeight: "bold", marginRight: 5 }}>
                {
                  JsonActivityPages.JsonActivityScreen
                    .JsonActivityParticipantsScreen.JsonSeeAll
                }
              </Text>
              <Icon
                name="chevron-right"
                type="font-awesome"
                size={16}
                color="black"
              />
            </Pressable>
          )}
        </View>
      </View>
    </>
  );
};

export default ActivityAttendees;
