import React, { useState } from "react";
import { Image, Text, Pressable, View } from "react-native";

import styles from "./ActivityAddressCss.js";

// Components
import CountriesListRectFlags from "../../../../components/CountriesList/CountriesList.js";
import EventButton from "../../../../components/EventButton/EventButton.js";
import { images } from "../../../../constantes/index.js";

const ActivityAddress = ({ route, navigation, scr }) => {
  const { JsonActivityPages } = scr;
  const [event, setEvent] = useState(route.params.event); //🇫🇷 Passsage d'info activité🇫🇷//🇬🇧 info activity passed 🇬🇧

  const [country, setCountry] = useState({
    language: "French",
    flag: <Image source={images.France} style={{ width: 36, height: 36 }} />,
  });

  const [findUsReadMoreBtn, setFindUsReadMoreBtn] = useState(false);

  return (
    <View style={{ marginTop: 20, width: "100%" }}>
      <View style={styles.aboutActView}>
        <Text style={styles.about}>
          {JsonActivityPages.JsonActivityScreen.JsonActivityAddressScreen
            .JsonHowToFindUs
            ? JsonActivityPages.JsonActivityScreen.JsonActivityAddressScreen
                .JsonHowToFindUs
            : null}
        </Text>
        <CountriesListRectFlags
          country={country}
          setCountry={setCountry}
          scr={scr}
        />
      </View>
      <View style={[styles.longText]}>
        <Text
          style={[
            styles.findUsTxt,
            {
              height: findUsReadMoreBtn ? null : 100,
              overflow: findUsReadMoreBtn ? null : "hidden",
            },
          ]}
        >
          {event.howToFind}
        </Text>
        {event.description.length > 100 && (
          <Pressable
            onPress={() => setFindUsReadMoreBtn(!findUsReadMoreBtn)}
            style={styles.findUsBtn}
          >
            <Text style={styles.readMoreButton}>
              {findUsReadMoreBtn
                ? JsonActivityPages.JsonActivityScreen
                    .JsonActivityDescriptionScreen.JsonReadLess
                : JsonActivityPages.JsonActivityScreen
                    .JsonActivityDescriptionScreen.JsonReadMore}
            </Text>
          </Pressable>
        )}
      </View>

      <View style={styles.inviteOrParticipate}>
        <EventButton
          title={JsonActivityPages.JsonActivityScreen.JsonCopyPasteActivity}
          type="Copy & Paste event"
          bgColor={"lightgray"}
          onPress={() =>
            navigation.navigate("Copy Activity", {
              event,
              type: "copy",
            })
          }
        />
      </View>
    </View>
  );
};

export default ActivityAddress;
