//🇫🇷 Ce fichier gère la page où on rentre notre email lors du login/register (FrameFigma3)
//🇬🇧 This file manage the page where we type our email during login/register (FrameFigma3)

import React, { useState, useEffect } from "react"; //🇫🇷 Importation des hooks useState et useEffect/ (gb) importing useState and useEffect hooks
import {
  Text,
  View,
  Linking,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { useRoute } from "@react-navigation/native";
import axios from "axios"; //🇫🇷 Importation de la librairie axios pour les requêtes API/ (gb) importing axios library for API requests

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./EmailScreenCss.js";
import Fields from "../../../components/Fields/Fields.js";

const EmailScreen = ({ navigation, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonLoginRegisterPages } = scr;

  //(🇫🇷) Déclaration des variables d'état/ (gb) declaration of state variables
  const [email, setEmail] = useState("");
  const [msgUser, setMsgUser] = useState("");
  const [errorMsg, setErrormsg] = useState("");
  const [emptyFields, setEmptyFields] = useState(null); //🇫🇷 Message d'erreur si les champs sont vides/ (gb) error message if fields are empty
  const [visible, setVisible] = useState(true); //🇫🇷 Permet de rendre visible ou non le conteneur en fonction de la valeur booléene de visble/ (gb) Permit to make visible or not the container depend of the boolean value of the visible variable
  const { height, width } = useWindowDimensions();
  //🇫🇷 Extraction de l'objet "emailscreen" dans en.json
  //🇬🇧 Extracting "emailscreen" object from en.json
  const [btnDisable, setBtnDisable] = useState(true); //🇫🇷 Désactivation du bouton si les champs sont vides/ (gb) disabling button if fields are empty
  const [backgroundColor, setBackgroundColor] = useState(""); //🇫🇷 Couleur du bouton de connexion/ (gb) color of login button
  const [btnTouch, setBtnTouch] = useState(false);
  //(fr) Déclaration de la variable permettant la lecture des données envoyé par la navigation/ (gb) declaration of the variable who permit to read the data send by navigation
  const route = useRoute();

  useEffect(() => {
    //🇫🇷 Gestion de l'état du bouton de connexion
    //🇬🇧 Manage of the login button state
    if (!isValidEmail(email)) {
      setBackgroundColor("gray");
      setBtnDisable(false);
    } else {
      setBackgroundColor("#59c09b");
    }

    //🇫🇷 Vérification des données reçu par la navigation des pages via route
    //🇬🇧 Verification of the data received by the navigation of pages via route
    if (
      route.params.MsgE ===
      JsonLoginRegisterPages.JsonEmailScreen.JsonHeaderTitleResetPassword
    ) {
      //🇫🇷 Mettre à jour le titre du header pour le reset password
      //🇬🇧 Update the title of the header for the reset password
      navigation.setOptions({
        title: route.params.MsgE,
      });
      //🇫🇷 Personnalisation du message à l'utilisateur ainsi que du message d'erreur et gestion de l'affichage du Forget your password
      //🇬🇧 Customization of the message of the user and the error message and manage the display of the forget your password
      setMsgUser(
        JsonLoginRegisterPages.JsonEmailScreen.JsonResetPasswordMessage
      );
      setErrormsg(
        JsonLoginRegisterPages.JsonEmailScreen
          .JsonResetPasswordMessageEnterEmail
      );
      setVisible(false);
    }
  }, [email, route.params.MsgE]);

  useEffect(() => {
    //🇫🇷 Initialisation du titre du header ainsi que le message à affiché à l'utilisateur
    //🇬🇧 Inititialization of the title of the header as well as the message displayed to the user
    const initializeValues = () => {
      if (
        route.params.MsgE !==
        JsonLoginRegisterPages.JsonEmailScreen.JsonHeaderTitleResetPassword
      ) {
        setMsgUser(
          JsonLoginRegisterPages.JsonEmailScreen.JsonCreateOrConnectAccount
        );
        navigation.setOptions({
          title: route.params.MsgE,
        });
      }
    };
    initializeValues();
  }, []);

  //🇫🇷 Verifie si le format d'adresse email saisi est valide
  //🇬🇧 Checks if the email address format entered is valid
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
    return emailRegex.test(email) && !email.includes(" ");
  };

  //🇫🇷 Fonction pour tester l'email saisi
  //🇬🇧 Function to test the entered email
  const testEmail = async () => {
    //🇫🇷 Vérifier d'abord si l'e-mail est valide
    //🇬🇧 First check if the email is valid
    if (!isValidEmail(email)) {
      setErrormsg(JsonLoginRegisterPages.JsonEmailScreen.JsonEmailErrorMessage);
      setTimeout(() => {
        //🇫🇷 Cacher la notification après 5 secondes
        //🇬🇧 Hide notification after 5 seconds
        setErrormsg("");
      }, 5000);
      return;
    }
    //🇫🇷 Fonction qui permet de tester si le mail est enregistré dans la base de données ou pas
    //🇬🇧 Function that allows to check if email is in data base or not
    try {
      //🇫🇷 Mettre le mail en minuscule
      //🇬🇧 Put email in lowercase
      const emailLowercase = email.toLowerCase();
      // console.log("before", email);
      const response = await axios.post(`${hostname}/api/v1/test-email`, {
        //🇫🇷 Requête API pour tester si le mail en minuscule est enregistré dans la base de données
        //🇬🇧 API request for check if the lowercase email is in data base
        email: emailLowercase,
      });

      // console.log("response", response.data.result, email);

      if (response.data.result) {
        //🇫🇷 Si l'email existe dans la base de donnée alors on sera redirigé vers l'écran de mot de passe pour se connecter (PasswordExistingEmail)
        //🇬🇧 If the email exists in the database then we will be redirected to the password screen to log in (PasswordExistingEmail)
        navigation.navigate("PasswordExistingEmail", { email: email });
      } else {
        //🇫🇷 Si l'email n'existe pas dans la base de donnée alors on sera redirigé vers l'écran de mot de passe pour créer un nouveau mot de passe pour le nouveau email (PasswordNoEmail)
        //🇬🇧 If the email does not exist in the database then we will be redirected to the password screen to create a new password for the new email (PasswordNoEmail)
        navigation.navigate("PasswordNoEmail", { email: email });
      }
    } catch (e) {
      //🇫🇷 Message d'erreur si le mail n'est pas valide
      //🇬🇧 Error message if email is not valid
      setErrormsg(JsonLoginRegisterPages.JsonEmailScreen.JsonEmailErrorMessage);
    }
  };

  return (
    <View style={styles.container}>
      {/*🇫🇷 Affichage du message d'erreur si l'email n'est pas valide */}
      {/*🇬🇧 Error message display if the email is not valid */}
      {errorMsg ? (
        <View style={styles.errorCard}>
          <Text style={styles.error}>{errorMsg}</Text>
        </View>
      ) : null}
      {/*🇫🇷 Affichage du message utilisateur */}
      {/*🇬🇧 display of the user message */}
      <Text style={styles.subtitle}>{msgUser}</Text>
      <Text style={styles.titlemail}>
        {JsonLoginRegisterPages.JsonEmailScreen.JsonEmailRequirement}
      </Text>
      <View style={styles.email}>
        {/*🇫🇷 Champ de remplissage du mail" */}
        {/*🇬🇧 Field for input email */}
        <Fields
          onSubmitEditing={testEmail}
          text={JsonLoginRegisterPages.JsonEmailScreen.JsonEmailPlaceholder}
          icon={"arobase"}
          state={email}
          setState={(text) => setEmail(text.toLowerCase())}
          scr={scr}
        />
        {/*🇫🇷 Affichage du message d'erreur si les champs sont vides */}
        {/*🇬🇧 Display of the error message if the fields are empty */}
        {!email && emptyFields && (
          <View>
            <Text style={styles.error}>{emptyFields}</Text>
          </View>
        )}
      </View>
      <View style={[styles.btn, { width: (width <= 450 ? width : 400) * 0.8 }]}>
        <Pressable
          style={{
            height: 50,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            width: "100%",
            backgroundColor: backgroundColor,
          }}
          disabled={btnDisable} //🇫🇷 Désactivation du bouton si les champs sont vides/ (gb) disabling button if fields are empty
          onPress={() => {
            setBtnTouch(true);
            testEmail();
          }}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonEmailScreen.JsonNextButton}
          </Text>
        </Pressable>
      </View>

      {/*🇫🇷 Affichage des conditions d'utilisation et de la politique de confidentialité */}
      {/*🇬🇧 displaying terms and conditions and privacy policy */}
      <View style={styles.centerTerms}>
        <View style={styles.termsContainer}>
          <Text style={styles.terms}>
            {JsonLoginRegisterPages.JsonEmailScreen.JsonDisclaimer}
          </Text>
          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonEmailScreen.JsonTermsAndConditions}
            </Text>
          </Pressable>

          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonEmailScreen.JsonPrivacyPolicy}
            </Text>
          </Pressable>
        </View>
      </View>

      {/*🇫🇷 Affichage de la partie pour la réinitialisation de mot de passe */}
      {/*🇬🇧 Display of the reset password part */}
      {visible ? (
        <View style={styles.centerBot}>
          <View style={styles.bottomBoxTop}>
            <View>
              <Text style={styles.bottomText}>
                {JsonLoginRegisterPages.JsonEmailScreen.JsonForgotPassword}
              </Text>
            </View>
            <Pressable
              onPress={() => {
                //🇫🇷 Changement du message pour l'utilisateur dans le cas d'une réinitialisation d'un mot de passe
                //🇬🇧 Changing the message for the user in case of a password reset
                setMsgUser(
                  JsonLoginRegisterPages.JsonEmailScreen
                    .JsonResetPasswordMessage
                );
                setErrormsg(
                  JsonLoginRegisterPages.JsonEmailScreen
                    .JsonResetPasswordMessageEnterEmail
                );
                //🇫🇷 Permet de rendre cette partie invisible
                //🇬🇧 Permit to make this part invisible
                setVisible(false);
                navigation.setOptions({
                  title:
                    JsonLoginRegisterPages.JsonEmailScreen
                      .JsonHeaderTitleResetPassword,
                });
              }}
              style={[styles.smallBtn]}
            >
              <Text style={styles.smallBtnText}>
                {JsonLoginRegisterPages.JsonEmailScreen.JsonResetPasswordButton}
              </Text>
            </Pressable>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default EmailScreen;
