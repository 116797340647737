//🇫🇷 Page de paramètre (FrameFigma54)
//🇬🇧 Parameter Page (FrameFigma54)

// Importation des modules et composants nécessaires
import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Image,
  Linking,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
} from "react-native";

// Importation des styles spécifiques au composant
import styles from "./SettingsScreenCss";

// Importation des images constantes
import { images } from "../../constantes";

// Composant fonctionnel SettingsScreen
const SettingsScreen = (props) => {
  // Destructuration des props
  const { JsonSettingsScreen } = props.scr;

  // Initialisation de la constante de navigation
  const navigation = useNavigation();

  // Définition des options de l'écran
  const optionsData = [
    {
      title: JsonSettingsScreen.JsonEditProfile,
      image: images.editProfile,
      screen: "Edit Profile",
    },
    {
      title: JsonSettingsScreen.JsonContactUs,
      image: images.contactUs,
      screen: "Contact",
    },
    { title: JsonSettingsScreen.JsonNews, image: images.news, screen: "News" },
    {
      title: JsonSettingsScreen.JsonDeleteMyAccount,
      image: images.deleteAccount,
      screen: "DeleteAccountScreen",
    },
    {
      title: JsonSettingsScreen.JsonError,
      image: images.news,
      screen: "ErrorFallback",
    },
    {
      title: JsonSettingsScreen.JsonTermsOfSales,
      image: images.termsOfSales,
      onPress: () =>
        navigateToExternalLink(
          "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
        ),
    },
    // Ajoutez d'autres options selon le même modèle
  ];

  // Fonction de navigation vers un écran spécifié
  const navigateToScreen = (screenName) => {
    navigation.navigate(screenName);
  };

  // Fonction de navigation vers un lien externe
  const navigateToExternalLink = (url) => {
    Linking.openURL(url);
  };

  // Rendu principal du composant
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: "white" }}>
        <View style={styles.content}>
          <View style={styles.logoContainer}>
            <Image source={images.settings} style={styles.logo} />
          </View>
          <View style={styles.socialContainer}>
            {optionsData.map((option, index) => (
              <Pressable
                key={index}
                onPress={
                  option.onPress || (() => navigateToScreen(option.screen))
                }
              >
                <View style={styles.social}>
                  <Image source={option.image} style={styles.socialImage} />
                  <Text style={styles.txt}>{option.title}</Text>
                  <View style={styles.arrowContainer}>
                    <Image source={images.next2} style={styles.arrowImage} />
                  </View>
                </View>
              </Pressable>
            ))}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

// Exportation du composant SettingsScreen
export default SettingsScreen;
