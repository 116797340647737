import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  text: {
    flexDirection: "row",
    marginTop: 30,
    marginBottom: 15,
    justifyContent: "space-between",
  },
  container: {
    flex: 1,
  },
  map: {
    height: 260,
  },
  image: {
    flex: 1,
  },
  listModalContainer: {
    flex: 1,
    marginHorizontal: "auto",
    width: "100%",
    height: "100%",
    maxWidth: 450,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  listModalView: {
    width: "95%",
    height: "95%",
    paddingTop: 5,
    paddingBottom: 10,
    paddingHorizontal: 5,
    borderRadius: 15,
  },
  listModalTitle: {
    marginTop: 5,
    marginBottom: 20,
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
  listModalScrollView: {
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 20,
    flexflow: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "stretch",
  },
  image: {
    width: 26,
    height: 26,
  },
  activityAttendeesTxt: {
    fontWeight: "bold",
    flexWrap: "wrap",
  },
  activityAttendeesTxtTwo: {
    marginLeft: 15,
    fontWeight: "bold",
    flexWrap: "wrap",
  },
  attendeesView: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    alignItems: "stretch",
    marginBottom: 20,
  },
  organizerOptions: {
    paddingHorizontal: 15,
    paddingTop: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
});

export default styles;