import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  Pressable,
  View,
} from "react-native";

// Backend
import { hostname } from "../../../../../../front-mobile/backendconnect/hostname";

// Components
import { fetchFriends } from "../../../../../utils/FetchFriendList";

function NetworkProfile({ userId }) {
  const [participants, setParticipants] = useState([]);
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("all");
  const navigation = useNavigation();

  const fetchFriendList = async () => {
    const userToken = await AsyncStorage.getItem("userToken");
    const userString = await AsyncStorage.getItem("user");
    const user = JSON.parse(userString);

    if (userToken && user._id) {
      try {
        const friendsData = await fetchFriends(userToken, user._id);

        if (friendsData && friendsData.length > 0) {
          setFriends(friendsData.map((friend) => friend._id));
        } else {
          console.error(
            "Erreur lors de la récupération des amis :",
            friendsData
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des amis :", error);
      }
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchFriendList();
      await fetchParticipants();
    };

    fetchAllData();
  }, []);
  const fetchUserInfos = async (ids, token) => {
    const response = await axios.get(
      `${hostname}/api/v1/user/getUserListByIds?id=${ids.join(",")}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  };

  const fetchParticipants = async () => {
    try {
      const userToken = await AsyncStorage.getItem("userToken");
      const userString = await AsyncStorage.getItem("user");
      const user = JSON.parse(userString);

      if (!userToken || !user?._id) {
        setError(
          "Vous devez vous connecter pour voir la liste des participants."
        );
        setLoading(false);
        return;
      }

      const userId = user._id;

      const response = await axios.get(
        `${hostname}/api/v1/user/${userId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const allActivities = response.data;
      if (!allActivities || allActivities.length === 0) {
        setError("Aucune activité trouvée pour cet utilisateur.");
        setLoading(false);
        return;
      }

      let allAttendees = allActivities
        .map((activity) => {
          if (
            activity.attendees &&
            activity.attendees.length > 0 &&
            typeof activity.attendees[0] === "object"
          ) {
            return activity.attendees
              .map((att) => att._id.toString())
              .filter((id) => id !== userId);
          }
          return activity.attendees;
        })
        .flat();

      // Ajout des amis à la liste des participants
      const combinedList = [...allAttendees, ...friends];
      const uniqueAttendees = [...new Set(combinedList)];

      const attendeeInfos = await fetchUserInfos(uniqueAttendees, userToken);

      setParticipants(attendeeInfos);
    } catch (err) {
      console.error("Erreur lors de la récupération des participants :", err);
    } finally {
      setLoading(false);
    }
  };

  const ParticipantItem = ({ item, column }) => {
    const getNameColor = (sexe) => {
      switch (sexe) {
        case "male":
          return "#001AFF";
        case "female":
          return "#FF13CB";
        default:
          return "gray"; // Couleur par défaut si le sexe n'est pas défini ou autre
      }
    };

    return (
      <Pressable
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          padding: 10,
        }}
        onPress={() => {
          navigation.navigate("Profile", { user: item });
        }}
      >
        {/* Avatar */}
        {item.avatar ? (
          <Image
            source={{ uri: item.avatar }}
            style={{ width: 50, height: 50, borderRadius: 25 }}
          />
        ) : (
          <View
            style={{
              width: 50,
              height: 50,
              borderRadius: 25,
              backgroundColor: "gray",
            }}
          />
        )}

        {/* Name */}
        <Text
          style={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 5,
            fontSize: 10,
            color: getNameColor(item.sexe),
          }}
        >
          {item.userName?.charAt(0)?.toUpperCase() +
            item.userName?.slice(1).toLowerCase()}
        </Text>
      </Pressable>
    );
  };

  if (loading) return <ActivityIndicator size="large" color="#0000ff" />;
  if (error) return <Text>Error: {error}</Text>;

  return (
    <View style={{ flex: 1, paddingTop: 20 }}>
      <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 10 }}>
        Network <text style={{ color: "#59C09B" }}>{participants.length}</text>
      </Text>
      <FlatList
        data={participants}
        keyExtractor={(item) => item._id}
        renderItem={({ item, index }) => (
          <ParticipantItem item={item} column={index % 4} />
        )}
        numColumns={4}
        ItemSeparatorComponent={() => <View />}
        onEndReached={() => {}}
        onEndReachedThreshold={0.5}
      />
      );
    </View>
  );
}

export default NetworkProfile;
