import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 0,
    marginHorizontal: "auto",
    width: "100%",
    maxWidth: 450,
  },
  dateHeader: {
    fontSize: 16,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: "white",
  },
  activityCard: {
    marginTop: 5,
    backgroundColor: "#D8EDE6",
    flexDirection: "row",
    fontWeight: "bold",
    height: 100,
    marginVertical: 5,
    marginHorizontal: 10,
    borderWidth: 1,
    borderColor: "#DDDDDD",
    borderRadius: 20,
  },
  fab: {
    position: "absolute",
    right: 30,
    bottom: 30,
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#59C09B',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 3,
  },
  fabIcon: {
    fontSize: 70,
    color: 'white',
    textAlign: 'center',
    lineHeight: 56,
  },
});

export default styles;