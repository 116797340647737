
import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import AddressMap from '../../../../components/AddressMap/AddressMap.js';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker.js';
import Fields from '../../../../components/Fields/Fields.js';
import InputField from "../../../../components/InputField/InputField.js";
import LogButton from '../../../../components/LogButtons/LogButtons.js';
import { OptionButton } from '../../../../components/SelectionElements/SelectionElements.js';
import { OneValueSlider } from '../../../../components/Sliders/Sliders.js';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn.js';

//Styles
import styles from '../UpdateActivityScreenCss.js';

const UpdateActivityScreenStepOne = ({title,
    setTitle,
    scr,
    buttonSelected,
    setButtonSelected,
    location,
    setLocation,
    date,
    setDate,
    startTime,
    setStartTime,
    name,
    isAttendeeLimited,
    setIsAttendeeLimited,
    attendeeLimit,
    setAttendeeLimit,
    hasPrice,
    setHasPrice,
    ticketLink,
    setTicketLink,
    nbFriends,
    setNbFriends,
    premiumRoles,
    step,
    setStep,
    errorMessage,
    setErrorMessage,
    setAddress,}) => {
        const {JsonActivityPages} = scr;
        return(
            <ScrollView
            style={styles.container}
            keyboardShouldPersistTaps="handled"
            listViewDisplayed={false}
          >
            {/* ----------------Titles---------------- */}
            <View>
              <Text
                style={[
                  styles.boldTitle,
                  { width: "100%", textAlign: "center", marginVertical: 10 },
                ]}
              >
                {/*🇫🇷 La variable createActivity.step1.information définit l'affichage en "Les informations principales".
                    🇬🇧 The variable createActivity.step1.information displays "Main information"*/}
                {
                  JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonMainInformation
                }
              </Text>
            </View>
            <View style={styles.fields}>
              {/*🇫🇷 La variable createActivity.step1.activity définit l'affichage en "Les informations principales".
                    🇬🇧 The variable createActivity.step1.activity displays "Activity title"*/}
              <InputField
                title={
                  JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonActivityTitle
                }
                text={
                  JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonActivityTitle
                }
                state={title}
                setState={(newTitle) => {
                  setTitle(newTitle.substring(0, 65));
                }}
                specialHeight={60}
              />
            </View>
            <View style={styles.localizationRow}>
              {/*🇫🇷 La variable createActivity.step1.address définit l'affichage en "Adresse.
                    🇬🇧 The variable createActivity.step1.address displays "Address"*/}
              <OptionButton
                title={
                  JsonActivityPages.JsonActivityManagement
                    .JsonStandardActivityChooseAddressScreen.JsonAddress
                }
                buttonSelected={buttonSelected}
                setButtonSelected={setButtonSelected}
                buttonselect={false}
                scr={scr}
              />
    
            </View>
    
            <View style={{ height: 360, position: "relative" }}>
              <AddressMap
                upperTitle={buttonSelected}
                address={
                  JsonActivityPages.JsonActivityManagement
                    .JsonStandardActivityChooseAddressScreen.JsonAddressPlaceholder
                }
                setAddress={setAddress}
                location={location}
                setLocation={setLocation}
                scr={scr}
              />
              {/* {" "} */}
              <Text> :</Text>
            </View>
    
            {/* 🇫🇷 La variable createActivity.step1.date définit l'affichage en
              "Date". 🇬🇧 The variable createActivity.step1.date displays "Date" */}
    
            <View style={{ marginTop: 100 }}>
              <DateTimePicker
                title={
                  JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonDateTime
                }
                date={date}
                setDate={setDate}
                time={startTime}
                event={event}
                setTime={setStartTime}
                scr={scr}
              />
            </View>
            {/*🇫🇷 Les utilisateurs avec premium roles ont la possibilité de faire assitants ilimités
                            🇬🇧 Users with premium roles have the ability to make unlimited assistantss*/}
            {premiumRoles.includes(name[0]) && (
              <View style={styles.switchRow}>
                <Text style={styles.boldTitle}>
                  {/*🇫🇷 La variable createActivity.step1.unlimited définit l'affichage en "Participants illimités".
                        🇬🇧 The variable createActivity.step1.unlimited displays "Unlimited attendees"*/}
                  {
                    JsonActivityPages.JsonActivityManagement
                      .JsonGeneralOptionsScreen.JsonUnlimitedAttendees
                  }
                </Text>
                <SwitchBtn
                  state={isAttendeeLimited}
                  setState={setIsAttendeeLimited}
                />
              </View>
            )}
    
            <Text style={styles.stepOneTxt}>
              {/*🇫🇷 La variable createActivity.step1.theOnly définit l'affichage en "(la seule application sans restrictions pour les organisateurs)".
                      🇬🇧 The variable createActivity.step1.theOnly displays "(The only map without restrictions for organizers)"*/}
              {
                JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                  .JsonOnlyAppWithoutRestrictionForOrganizers
              }
            </Text>
            {/* "theOnly": "(The only map without restrictions for organizers)" */}
            {!isAttendeeLimited && (
              <View style={styles.fields}>
                <Text style={styles.boldTitle}>
                  {
                    JsonActivityPages.JsonActivityManagement
                      .JsonGeneralOptionsScreen.JsonAttendeesLimitation
                  }
                </Text>
                {/* "attendee": "Attendee limitation" */}
                <OneValueSlider
                  minVal={2}
                  maxVal={premiumRoles.includes(name[0]) ? 100 : 20}
                  state={attendeeLimit === 1000000 ? 2 : attendeeLimit}
                  setState={setAttendeeLimit}
                />
              </View>
            )}
            {/*FR Bouton du prix */}
            {/*GB Price button */}
            <View style={styles.switchRow}>
              <Text style={styles.boldTitle}>
                {/*🇫🇷 La variable createActivity.step1.price définit l'affichage en "Prix".
                        🇬🇧 The variable createActivity.step1.price displays "Price"*/}
                {
                  JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                    .JsonPrice
                }
              </Text>
              <SwitchBtn state={hasPrice} setState={setHasPrice} />
            </View>
            {/*FR Les champs ci-dessous s'affichent si le bouton est activé(si le state hasPrice a une valeur*/}
            {/*GB The fields below only display when the button is on (if the state hasPrice contains a value*/}
            <View style={styles.fields}>
              {hasPrice && (
                <>
                  <View style={styles.fields}>
                    {/*🇫🇷 La variable createActivity.step1.price définit l'affichage en "Prix".
                        🇬🇧 The variable createActivity.step1.price displays "Price"*/}
                    {/* <Fields
                      text={JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
                      upperText={JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
                      state={priceValue}
                      setState={setPriceValue}
                    /> */}
                  </View>
                  <View style={styles.fields}>
                    {/*🇫🇷 La variable createActivity.step1.buyTicket définit l'affichage en "Url de la billeterie".
                        🇬🇧 The variable createActivity.step1.buyTicket displays "Buy ticket link"*/}
                    <Fields
                      text={
                        JsonActivityPages.JsonActivityManagement
                          .JsonGeneralOptionsScreen.JsonBuyTicket
                      }
                      state={ticketLink === "null" ? "" : ticketLink}
                      setState={setTicketLink}
                      scr={scr}
                    />
                  </View>
                </>
              )}
            </View>
            <View style={styles.fields}>
              <Text style={styles.boldTitle}>
                {/*🇫🇷 La variable createActivity.step1.howMany définit l'affichage en "How many friends with me".
                        🇬🇧 The variable createActivity.step1.howMany displays "Combien d'amis avec moi"*/}
                {
                  JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                    .JsonHowMuchFriends
                }
              </Text>
              <OneValueSlider
                minVal={0}
                maxVal={10}
                state={nbFriends}
                setState={setNbFriends}
              />
            </View>
    
            {/* The code about "Help for organizers" has been saved in the "help_codes" folder. It was too annoying to comment all the code because of the comments... */}
            {/* ------------Save or Continue------------ */}
            <Text style={styles.errorMsgTxt}>{errorMessage}</Text>
            {/*FR Bouton pour passer à l'étape suivante*/}
            {/*GB Button to enter next step*/}
            <View style={{ margin: 15, alignSelf: "center" }}>
              <LogButton
                text={JsonActivityPages.JsonActivityManagement.JsonContinueButton}
                width={150}
                backgroundColor={"#59c09b"}
                func={(n) => {
                  setErrorMessage("");
                  setStep(n);
                }}
                arg={step + 1}
              />
            </View>
          </ScrollView>
        )
    }

    export default UpdateActivityScreenStepOne;