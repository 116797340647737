import Picker from "emoji-picker-react";
import React, { useRef, useState } from "react";
import { Image, Text, TextInput, Pressable, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

//component
import ActivityPhotoForChat from "../../../../components/ActivityPhotoForChat/ActivityPhotoForChat.js";
import CommentBubble from "../../../../components/CommentBubble/CommentBubble.js";

//Styles
import styles from "./ActivityCommentsCss.js";

const ActivityComments = ({
  connectedUser,
  scr,
  isParticipating,
  comments,
  cmtText,
  selectOption,
  activityImage,
  currentLatitude,
  currentLongitude,
  sendComment,
  setCmtText,
  setSelectOption,
  address,
  connectedUserRole,
  isOrganizer,
  deleteComment,
  premiumRoles,
  images,
  setActivityImage,
  setDisbaleButton,
}) => {
  const [chosenEmoji, setChosenEmoji] = useState(false);
  const { JsonActivityPages } = scr;
  const ref = useRef(null);
  return (
    <View style={styles.commentsView}>
      {/* <Text style={styles.commentsTxt}>{activity.t2022_comments}</Text> */}

      {isParticipating || premiumRoles.includes(connectedUserRole) ? (
        <>
          <View>
            {/* 🇫🇷Aller chercher le user à partir de son message (ou à l'aide de la liste des participants?) et envoyer les différentes données pour bien afficher la bulle de message via le composant.
          Le isOrganizer est true si l'utilisateur est le créateur de l'activité.
          */}
            {/* 🇬🇧Get the user from his message (or using the list of participants?) and send the different data to properly display the message bubble via the component.
          The isOrganizer is true if the user is the creator of the activity.
          */}

            {comments.map((comment, index) => (
              <CommentBubble
                //key={index}
                key={`comment_${index}`} // clee unique pour eviter Each child in a list should have a unique 'key' prop".
                comment={comment}
                location={location}
                address={address}
                connectedUser={connectedUser}
                connectedUserRole={connectedUserRole}
                isOrganizer={isOrganizer}
                handleDeleteComment={deleteComment}
              />
            ))}
          </View>
          {/** 🇫🇷notre view de  messageContainer*/}
          {/*** 🇬🇧our messageContainer view */}{" "}
          <View style={styles.messageContainer}>
            <View style={styles.messageInput}>
              {/** 🇫🇷saisi de textInput*/}
              {/** 🇬🇧entered from textInput*/}{" "}
              <TextInput
                ref={ref}
                style={styles.messageBox}
                onChangeText={(text) => {
                  setChosenEmoji(false);
                  setCmtText(text);
                }}
                value={cmtText}
                placeholder={
                  JsonActivityPages.JsonActivityScreen.JsonActivityChatScreen
                    .JsonTypeMessagePlaceholder
                }
                autoFocus={true}
              />
              {/** 🇫🇷ToucheOpacity est mis ici pourque l'utilisateur puisse cliquer sur notre MaterialCommunityIcons  */}
              {/** 🇬🇧ToucheOpacity is put here so the user can click on our MaterialCommunityIcons */}
              {selectOption === false && (
                <>
                  <Pressable
                    onPress={() => {
                      setSelectOption(!selectOption);
                    }}
                    style={styles.selectOptionBtn}
                    //🇫🇷clique de l'icone plus
                    //🇬🇧click the icons
                  >
                    {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                    {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                    <MaterialCommunityIcons
                      name="plus"
                      color="#fffff"
                      size={28}
                    />
                  </Pressable>
                </>
              )}
              {selectOption === true &&
                currentLatitude === null &&
                currentLongitude === null &&
                activityImage === null && (
                  <>
                    <Pressable
                      onPress={() => {
                        setChosenEmoji(!chosenEmoji);
                      }}
                      style={styles.chosenEmojiBtn}
                      //🇫🇷clique de l'icone plus
                      //🇬🇧click the icons
                    >
                      {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                      {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                      <Image
                        source={chosenEmoji ? images.HappyON : images.HappyOFF}
                        style={styles.image}
                      />{" "}
                    </Pressable>
                    <Pressable
                      onPress={() => {
                        getOneTimeLocation();
                      }}
                      style={styles.locationBtn}
                      //🇫🇷clique de l'icone plus
                      //🇬🇧click the icons
                    >
                      {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                      {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                      <Image source={images.locationOff} style={styles.image} />
                    </Pressable>
                    <View style={styles.activityPhotView}>
                      <ActivityPhotoForChat
                        activityImage={activityImage}
                        setActivityImage={setActivityImage}
                        setDisabled={setDisbaleButton}
                        scr={scr}
                      />
                    </View>
                  </>
                )}
              {/** 🇫🇷ToucheOpacity est mis ici pourque l'utilisateur puisse cliquer sur notre MaterialCommunityIcons  */}
              {/** 🇬🇧ToucheOpacity is put here so the user can click on our MaterialCommunityIcons */}
              {activityImage !== null && (
                <>
                  <Pressable
                    onPress={() => {
                      setChosenEmoji(!chosenEmoji);
                    }}
                    style={styles.chosenEmojiBtnTwo}
                    //🇫🇷clique de l'icone plus
                    //🇬🇧click the icons
                  >
                    {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                    {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                    <Image
                      source={chosenEmoji ? HappyON : HappyOFF}
                      style={styles.image}
                    />{" "}
                  </Pressable>
                  <Pressable style={styles.locationPinBtn}>
                    {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                    {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                    <Image source={images.locationPin} style={styles.image} />
                  </Pressable>
                </>
              )}
              {currentLatitude !== null &&
                currentLongitude !== null &&
                sendLocation !== null && (
                  <>
                    <Pressable
                      onPress={() => {
                        setChosenEmoji(!chosenEmoji);
                      }}
                      style={styles.chosenEmojiBtnThree}
                      //🇫🇷clique de l'icone plus
                      //🇬🇧click the icons
                    >
                      {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                      {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                      <Image
                        source={chosenEmoji ? HappyON : HappyOFF}
                        style={styles.image}
                      />{" "}
                    </Pressable>
                    <Pressable
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      {/** 🇫🇷le nom de notre image est plus dans materialcommunityIcons  */}
                      {/** 🇬🇧 our image name is no longer in materialcommunityIcons */}
                      <Image source={images.locationOn} style={styles.image} />{" "}
                    </Pressable>
                  </>
                )}
            </View>
            {/* 🇬🇧If the message sent is the first of the list of messages of the day, there will be the need to create a new sectionHeader */}
            {/* 🇫🇷 Si le message envoyé est le premier de la liste des messages du jour, il sera nécessaire de créer une nouvelle sectionHeader */}
            <Pressable
              //🇫🇷 clique du boutton dans sendCommennt
              onPress={sendComment}
              style={styles.sendButton}
            >
              <Feather
                name="send"
                type="font-awesome"
                color="white"
                size={24}
              />
            </Pressable>
          </View>
          {chosenEmoji === true && (
            <>
              <View style={styles.messageContainer}>
                <View style={styles.emojiInput}>
                  <Picker
                    style={styles.emojiInput}
                    onEmojiClick={(emojiObject) => {
                      onEmojiClick(emojiObject);
                    }}
                  />
                </View>
              </View>
            </>
          )}
          {/** 🇫🇷fin view de  messageContainer*/}
        </>
      ) : (
        <Text style={styles.participantsCommentsTxt}>
          {/* {activity.t2022_commentsVisibleOnlyForParticipants} */}
        </Text>
      )}
    </View>
  );
};

export default ActivityComments;
