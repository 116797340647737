import React from 'react';
import { Image, Modal, Pressable, Text, View } from 'react-native';
import IntlPhoneInput from 'react-native-intl-phone-input';
import { images } from "../../../constantes";
import styles from '../MyProfileScreenCss';

const ConfirmPhoneModal = ({
  phoneModalVisible,
  setPhoneModalVisible,
  setAddFriendModalVisible,
  friendPhone,
  setFriendPhone,
  handleAddFriend,
  setModalVisible,
  scr,
  isInputEmpty
}) => {

    const JsonConfirmFriendshipPopup = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={phoneModalVisible}
      onRequestClose={() => {
        setPhoneModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.confirmEmailModalContainer}>
          <Pressable
            style={styles.backButtonWrapper}
            onPress={() => {
              setPhoneModalVisible(false);
              setAddFriendModalVisible(true);
            }}
          >
            <Image source={images.leftArrow} style={styles.leftArrowIcon} />
          </Pressable>
          <View style={styles.iconWrapper}>
            <Image source={images.PhoneIcon} style={styles.addFriendButtonIcon} />
          </View>
          <Text style={styles.confirmEmailModalText}>
            {JsonConfirmFriendshipPopup.JsonPopupEnterPhoneNumber}
          </Text>

          <View style={styles.phoneInputContainer}>
            <IntlPhoneInput
              containerStyle={styles.phoneInput}
              value={friendPhone}
              onChangeText={(number) => {
                setFriendPhone(number);
                setInputEmpty(!number);
              }}
              defaultCountry="FR"
              placeholder={
                JsonConfirmFriendshipPopup.JsonPlaceholderTelephone
              }
              placeholderTextColor="#808080"
              style={isInputEmpty ? { fontStyle: "italic" } : {}}
              flagStyle={styles.countryFlag}
              textComponent={({ value }) => (
                <View style={styles.countryTextContainer}>
                  <Text style={styles.countryText}>{value}</Text>
                </View>
              )}
            />
          </View>
          <Pressable
            style={styles.confirmLastNameButton}
            onPress={async () => {
              await handleAddFriend(
                friendPhone.unmaskedPhoneNumber,
                "phoneNumber"
              );
              setPhoneModalVisible(false);
              setModalVisible(false);
            }}
          >
            <Text style={styles.confirmLastNameButtonText}>
              {JsonConfirmFriendshipPopup.JsonButtonConfirm}
            </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmPhoneModal;
