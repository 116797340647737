import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import Fields from '../../../../components/Fields/Fields';
import LogButton from '../../../../components/LogButtons/LogButtons';

import styles from '../CreateActivityScreenCss';

const CreateActivityScreenStepFour = ({
  scr,
  setStep,
  step,
  createAnActivity,
  whatsappLink,
  setWhatsappLink,
  fbPageLink,
  setFbPageLink,
  fbGroupLink,
  setFbGroupLink,
  meetupLink,
  setMeetupLink,
  telegramLink,
  setTelegramLink,
  otherLink,
  setOtherLink,
}) => {

    const {JsonActivityPages} = scr;
    
  return (
    <ScrollView style={styles.container}>
    {/* ----------------Titles---------------- */}
    <Text
      style={[
        styles.boldTitle,
        { alignSelf: "center", marginVertical: 10 },
      ]}
    >
      {/*🇫🇷 Afficher ce texte dans une nouvelle variable dans en.json si nécessaire*/}
      {/*🇬🇧 The text should be stored in a new variable inside en.json */}
      {
        JsonActivityPages.JsonActivityManagement
          .JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures
      }
    </Text>
    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonWhatsapp
        }
        upperIcon={"whatsapp"}
        state={whatsappLink}
        setState={setWhatsappLink}
        scr={scr}
      />
    </View>

    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbPage
        }
        upperIcon={"fbPage"}
        state={fbPageLink}
        setState={setFbPageLink}
        scr={scr}
      />
    </View>

    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbGroup
        }
        upperIcon={"fbGroup"}
        state={fbGroupLink}
        setState={setFbGroupLink}
        scr={scr}
      />
    </View>

    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonMeetup
        }
        upperIcon={"meetup"}
        state={meetupLink}
        setState={setMeetupLink}
        scr={scr}
      />
    </View>

    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonTelegram
        }
        upperIcon={"telegram"}
        state={telegramLink}
        setState={setTelegramLink}
        scr={scr}
      />
    </View>

    <View style={[styles.fields, { marginHorizontal: 10 }]}>
      <Fields
        text={""}
        upperText={
          JsonActivityPages.JsonActivityManagement
            .JsonAdminAndPremiumAccountAdditionalFeatures.JsonOtherLink
        }
        state={otherLink}
        setState={setOtherLink}
        scr={scr}
      />
    </View>

    {/*🇬🇧 The rest of the code has been saved in the "help_codes" folder */}
    {/*🇫🇷 Le reste du code a été enregistré dans le dossier "help_codes" */}

    {/* 🇬🇧------------Save or Continue------------ */}

    {/*🇫🇷------------Enregistrer ou Continuer------------ */}
    <View style={[styles.savOrConButtons, { marginTop: 40 }]}>
      <LogButton
        text={JsonActivityPages.JsonActivityManagement.JsonPreviousButton}
        width={150}
        backgroundColor={"#59c09b"}
        func={setStep}
        arg={step - 1}
      />
      {/*🇫🇷Envoi des données du formulaire au back-end*/}
      {/*🇬🇧 Sending form data to the back-end*/}

      <LogButton
        text={JsonActivityPages.JsonActivityManagement.JsonPublishButton}
        width={150}
        backgroundColor={"#59c09b"}
        func={() => createAnActivity()}
        // arg={null}
      />
    </View>
  </ScrollView>
  );
};
export default CreateActivityScreenStepFour;