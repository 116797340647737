import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Text, View } from "react-native";

// Backend
import { hostname } from "../../../../../../front-mobile/backendconnect/hostname";

// Components
import ActivityCard_small from "../../../../components/ActivityCards/ActivityCards";

const MyOrganizedActivitiesScreen = ({ scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonTopNav, JsonActivityPages } = scr;

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connectedUser, setConnectedUser] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchActivities = async (userId) => {
    setLoading(true);
    setRefreshing(true);
    try {
      const response = await axios.get(`${hostname}/api/v1/activities/list`);
      if (Array.isArray(response.data.data)) {
        const myOrganizedActivities = response.data.data.filter(
          (activity) => activity.author === userId
        );
        setActivities(myOrganizedActivities);
      } else {
        console.error("Data received is not an array:", response.data.data);
      }
    } catch (error) {
      console.error(
        "Error fetching activities:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const jsonValue = await AsyncStorage.getItem("user");
        const userData = jsonValue != null ? JSON.parse(jsonValue) : null;
        setConnectedUser(userData);
        if (userData?._id) {
          await fetchActivities(userData._id);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchInitialData();
  }, []);

  const onRefresh = useCallback(() => {
    if (connectedUser?._id) {
      fetchActivities(connectedUser._id);
    }
  }, [connectedUser]);

  const renderActivity = ({ item }) => {
    const authorId = item.author || null;

    return (
      <ActivityCard_small
        event={item}
        authorId={authorId}
        connectedUser={connectedUser}
        // scr={{ activity: item }}
        scr={scr}
      />
    );
  };

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={{ flex: 1, padding: 10 }}>
      <Text
        style={{
          fontSize: 20,
          fontWeight: "bold",
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        {JsonTopNav.JsonMyActivitiesExplanationOrganized}
      </Text>
      {activities.length > 0 ? (
        <FlatList
          data={activities}
          keyExtractor={(item) => item._id}
          renderItem={renderActivity}
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text style={{ textAlign: "center", fontSize: 20 }}>
            {
              JsonActivityPages.JsonMyActivitiesScreen
                .JsonOrganizedActivityEmpty
            }
          </Text>
        </View>
      )}
    </View>
  );
};

export default MyOrganizedActivitiesScreen;
