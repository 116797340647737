/*
 * hostname.js
 * 🇫🇷 Ce fichier Hostname facilite l'utilisation de divers sites web tels que socializus.eu, socializ.us ou socializus.app.
 * Il permet de connecter l'application au backend en modifiant la variable settings.devRunMode. N'oubliez pas d'importer et d'utiliser hostname ou hostname-and-config dans tous vos appels d'API, par exemple : `${hostname}/api/...`.
 *
 * 🇬🇧 The Hostname file enables the usage of various websites like socializus.eu, socializ.us, or socializus.app.
 * It connects the application to the backend by modifying the settings.devRunMode variable. Remember to import and use hostname or hostname-and-config in all your API calls, for instance: `${hostname}/api/...`.
 */

//(DO_NOT_REMOVE_START)
const LOCALHOST_IP = "http://54.38.1.61"; //"http://192.168.UVW.XYZ"; Automatically modified by Backend on 2024-03-16T20:11:21.506Z
//(DO_NOT_REMOVE_END)

//🇫🇷 Configuration générale de l'application en cours d'execution
//🇬🇧 General configuration of the currently running application
let settings = {
  /*
      🇫🇷 La valeur devRunMode est comprise entre 0 et 5 pour Ubuntu depuis le serveur OVH. La valeur 2 est le mode de développement par défaut pour le "Serveur Gros test", mais vous pouvez utiliser des valeurs de 1 à 99 - voir ligne 52
      🇬🇧 The devRunMode value ranges from 0 to 5 for Ubuntu from OVH server. The value 2 is the default development mode for the "Serveur Gros test" - refer to line 52
  */
  //🇫🇷 La valeur devRunMode: 100 est utilisée pour exécuter le backend en local- voir ligne 52
  // 🇬🇧 The devRunMode value: 100 is used to run the backend locally - refer to line 52
  devRunMode: 2
  ,
  //🇫🇷 On utilise les valeurs de 1 à 4 pour sélectionner l'URL du serveur d'images, la valeur 2 est par défaut - voir ligne 124
  //🇬🇧 Values 1 through 4 are used to select the image server URL,value 2 is default - refer to line 124
  imageServer: 2,
  /*
      🇫🇷 Lorsque la réponse est : false - La console est bloquée, aucun affichage de débogage avec VSCode ou autres - voir ligne 100
      🇬🇧 When the response is: false - Console is blocked, no debug display from VSCode or others - see line 100
      🇫🇷 Lorsque la réponse est : true - Autorisation par défaut d'affichage des logs dans la console - La console est autorisée, affichage de débogage avec VSCode ou autres - voir ligne 100
      🇬🇧 When the response is: true - Default permission for console log display - Console is allowed, debug display from VSCode or others - see line 100
  */
  withConsole: true,
};

//🇫🇷 Variable pour stocker l'adresse du serveur
//🇬🇧 Variable to store the server address
let hostname = "";

//🇫🇷 Logique pour déterminer l'adresse du serveur en fonction du mode de développement
//🇬🇧 Logic to determine the server address based on the development mode
switch (settings.devRunMode) {
  default:
    if (settings.devRunMode < 100) {
      // 🇫🇷 La valeur par défaut est toujours le 2 pour le "serveur gros test"
      // 🇬🇧 Default value is always set to 2 for the "serveur gros test"
      hostname = "https://backforest.socializus.fr";
    } else {
      //🇫🇷 Utilisation de l'adresse IP locale pour les modes de test, développement et débogage
      //🇬🇧 Using the local IP address for test, development, and debugging modes
      hostname = `${LOCALHOST_IP}:3000`;
    }
    break;
  //🇫🇷 Autres cas pour différents modes de développement: voir 'Instance et identifiants' dans la "doc du projet Socializus" pour plus de détails
  //🇬🇧 Other cases different development modes: Refer to 'Instance and credentials' within the "Socializus Project Documentation" for details
  case 1:
    hostname = "https://forestadmin.socializus.net";
    break;
  case 2:
    hostname = "https://backforest.socializus.fr";
    break;
  case 3:
    hostname = "https://backoffice.socializus.com";
    break;
  //🇫🇷 Autres cas pour différents modes de développement
  //🇬🇧 Other cases for different development modes
  case 100:
    hostname = `${LOCALHOST_IP}:3000`;
    break;
  case 110:
    hostname = `${LOCALHOST_IP}:3010`;
    break;
  case 120:
    hostname = `${LOCALHOST_IP}:3100`;
    break;
  case 130: //(Postman)
    hostname = `${LOCALHOST_IP}:3310`;
    break;
}

//🇫🇷 Bloc pour restreindre l'affichage des logs dans la console en fonction de la configuration
//🇬🇧 Block to restrict console log display based on the configuration
if (settings.withConsole == false) {
  //🇫🇷 Blocage de la fonction console.log pour certaines adresses avec VSCode ou autres
  //🇬🇧 Blocking console.log function for certain addresses from VSCode or others
  console.log = new Proxy(console.log, {
    apply: function (target, thisArg, argumentsList) {
      if (JSON.stringify(argumentsList).includes(hostname)) {
        Reflect.apply(target, thisArg, argumentsList);
      }
    },
  });
}

//🇫🇷 Fonction pour déterminer si l'application est en mode production ou non
//🇬🇧 Function to determine if the application is in production mode or not
function isProduction() {
  if (settings.devRunMode < 100) {
    return true;
  } else {
    return false;
  }
}

//🇫🇷 Fonction pour obtenir l'url du serveur d'images en fonction de la configuration
//🇬🇧 Function to get the image server URL based on the configuration
function imageServerUrl() {
  let urlImage = "";
  switch (settings.imageServer) {
    default:
    case 1:
      //🇫🇷 Utilisations du serveur d'images par défaut: section verte: Voir 'Instance et identifiants' dans la "doc du projet Socializus"
      //🇬🇧 Default Image Server Usages: Green Section: Refer to 'Instance and Credentials' within the "Socializus Project Documentation"
      urlImage = "https://images.socializ.us/server-image/";
      break;
    case 2:
      //🇫🇷  Autres cas pour différents serveurs d'images: section orange : voir 'Instance et identifiants'
      //🇬🇧 Other cases for different image servers: Orange section: Refer to 'Instance and credentials'
      urlImage = "https://images.socializus.eu/server-image/";
      break;
    case 3:
      //🇫🇷 Autres cas pour différents serveurs d'images: section rouge : voir 'Instance et identifiants'
      //🇬🇧 Other cases for different image servers: Red section: Refer to 'Instance and credentials'
      urlImage = "https://images.socializus.app/server-image/";
      break;
    case -1:
      urlImage = `${LOCALHOST_IP}:3000/`;
      break;
  }
  return urlImage;
}

//🇫🇷 Fonction pour lire la configuration actuelle de l'application
//🇬🇧 Function to read the current application configuration
function readSettings() {
  let settings2 = { ...settings };
  settings2.LOCALHOST_IP = LOCALHOST_IP;
  return settings2;
}

//🇫🇷 Fonction pour obtenir l'adresse actuelle du serveur
//🇬🇧 Function to get the current server adress
function getHostname() {
  return hostname;
}

//🇫🇷 Export des différentes fonctions et variables
//🇬🇧 Export of different functions and variables
module.exports = {
  devRunMode: settings.devRunMode,
  settings,
  hostname,
  isProduction,
  imageServerUrl,
  readSettings,
  getHostname,
};
