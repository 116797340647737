import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "@rneui/base";
import React, { useEffect, useState } from "react";
import { ImageBackground, Text, View } from "react-native";

// Assets
import Json from "../../../../front-mobile/src/assets/json/en.json";

const ErrorFallback = ({ error, resetErrorBoundary, navigation, ...props }) => {
  const handleRefresh = () => {
    resetErrorBoundary?.();
    //🇫🇷 Navigation vers l'écran de contact
    //🇬🇧 Navigation to Contact screen
    navigation?.navigate("Contact");
    //🇫🇷 Effectuer toutes les actions nécessaires pour actualiser l'application
    //🇬🇧 Perform any necessary actions to refresh the app
  };
  // const errorMessage = Json.errorPage;
  const [user, setUser] = useState({});
  const [scr, setScr] = useState(Json); ///
  const errorPage = Json.errorPage;
  const menu = Json.menu;

  //🇫🇷 garde la langue selected en session //🇬🇧 Keep the selected language in session
  let x = scr;
  if (sessionStorage.keyy != null) {
    x = JSON.parse(sessionStorage.getItem("keyy"));
  } else {
    x = scr;
  }

  // console.log("ErrorFallback::keyy for langage", x);

  useEffect(() => {
    // console.log("ErrorFallback::useEffect::A");
    AsyncStorage.getItem("user").then((suser) => {
      // console.log("ErrorFallback::useEffect::B");
      if (suser != "undefined") {
        setUser(JSON.parse(suser));
        // console.log("ErrorFallback::useEffect::C", user, JSON.parse(suser));
        // console.log(suser);
      } else {
        //
      }
    });
  }, []);

  const styles = {
    errorMessageContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 20,
      marginHorizontal: 30,
    },
    errorMessage: {
      textAlign: "center",
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 20,
      marginBottom: 10,
    },
    button: {
      marginTop: 10,
    },
    afraidEmoji: {
      marginTop: 10,
    },
  };

  return (
    <View style={styles.errorMessageContainer}>
      <View style={{ backgroundColor: "blue", width: "55%", height: 180 }}>
        <ImageBackground
          source={require("../../assets/images/afraidEmoji.jpg")}
          resizeMode={"cover"}
          style={{ flex: 1, padding: 10 }}
        ></ImageBackground>
      </View>
      <Text style={styles.errorMessage}>{x.JsonErrorScreen.JsonErrorMessage}</Text>

      <Button
        title={x.JsonErrorScreen.JsonNextButton}
        onPress={handleRefresh}
        style={styles.button}
      />

      <Button
        title={x.JsonErrorScreen.JsonLogout}
        onPress={async () => {
          try {
            await Promise.all([
              props && props.setToken && props.setToken(null),
              localStorage.removeItem("userToken"),
              props && props.setUser && props.setUser("undefined"),
              localStorage.removeItem("user"),
              props && props.setGender && props.setGender(null),
              localStorage.removeItem("gender"),
              props && props.setAccountType && props.setAccountType(null),
              localStorage.removeItem("accountType"),
              props && props.setFirstName && props.setFirstName(null),
              localStorage.removeItem("firstName"),
              props && props.setLastName && props.setLastName(null),
              localStorage.removeItem("lastName"),
              props && props.setNickName && props.setNickName(null),
              localStorage.removeItem("nickName"),
              props && props.setCity && props.setCity(null),
              localStorage.removeItem("city"),
              props && props.setNativeLanguage && props.setNativeLanguage(null),
              localStorage.removeItem("nativeLanguage"),
              props && props.setRole && props.setRole(null),
              localStorage.removeItem("role"),
              localStorage.removeItem("userProfile"),
            ]);

            // console.log("Successfully logged out");

            //🇫🇷 Recharger la page entière
            //🇬🇧 Reload the entire page
            window.location.reload();
          } catch (e) {
            console.error("Error logging out:", e);
          }
        }}
        style={styles.button}
      />
    </View>
  );
};

export default ErrorFallback;
