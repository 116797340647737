//🇫🇷 Affichage des boutons switch on/off (FrameFigma31-FrameFigma35)
//🇬🇧 Display of switch buttons on/off (FrameFigma31-FrameFigma35)

import React from "react";
import { View, Text, Pressable } from "react-native";

// Components
import styles from "./SwitchBtnCss";

const SwitchBtn = ({ state, setState }) => {
  return (
    <Pressable
      style={[
        styles.container,
        { backgroundColor: state ? "#4caf50" : "#E64C3C" },
      ]}
      onPress={() => {
        setState(!state);
      }}
    >
      <View style={[styles.circle, { marginLeft: state ? 23 : 2 }]} />
      <Text style={[styles.switchInfo, state ? { right: 22 } : { left: 20 }]}>
        {state ? "ON" : "OFF"}
      </Text>
    </Pressable>
  );
};

export default SwitchBtn;
