//🇫🇷 Page de contact (FrameFigma25)
//🇬🇧 Contact Page (FrameFigma25)

import React from "react";
import {
  Image,
  Linking,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
} from "react-native";

// Components
import styles from "./ContactScreenCss";

// import des images
import { images } from "../../constantes";

const ContactScreen = (props) => {
    //🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue
    //🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language

  const { JsonContactScreen } = props.scr;

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: "white" }}>
        <View
          style={{ backgroundColor: "white", width: "100%", height: "100%" }}
        >
          {/*🇫🇷 Affichage du message de contact WhatsApp ou téléphone */}
          {/*🇬🇧 Display of the WhatsApp or phone contact message */}
          <Text style={styles.txt}>
            {JsonContactScreen.JsonWhatsappOrPhoneContactMessage}
          </Text>
          {/* 🇫🇷 Affichage du message de contact différent pour WhatsApp */}
          {/* 🇬🇧 Display of the different contact message for WhatsApp */}
          <Text style={styles.txt}>
            {JsonContactScreen.JsonWhatsappDifferentContactMessage}
          </Text>
          {/* 🇫🇷 Liens vers les différents groupes WhatsApp avec icônes */}
          {/* 🇬🇧 Links to different WhatsApp groups with icons */}
          <View style={styles.phoneIcones}>
            <Image
              style={{ height: 51, width: 50 }}
              source={images.Phone}
              onClick={() =>
                Linking.openURL(
                  "https://chat.whatsapp.com/LCy2cTDrQ1SC9fwtjMm7XZ"
                )
              }
            />
            <Image
              style={{ height: 51, width: 50 }}
              source={images.Orga}
              onClick={() =>
                Linking.openURL(
                  "https://chat.whatsapp.com/KtR79S8Obfs3WTPTIBsoCm"
                )
              }
            />
            <Image
              style={{ height: 51, width: 50 }}
              source={images.Bug}
              onClick={() =>
                Linking.openURL(
                  "https://chat.whatsapp.com/ELTWw58icvs6BAujghGg0R"
                )
              }
            />
          </View>

          {/*🇫🇷 Variables associées au fichier en.json en dessous: contact.photo: "Photo", contact.orga: "Orga", contact.bug: "Bug"*/}
          {/*🇬🇧 Variables related to en.json file below: contact.photo: "Photo", contact.orga: "Orga", contact.bug: "Bug"*/}
          <View style={styles.subtitle}>
            <Text style={styles.line}>{JsonContactScreen.JsonPicture}</Text>
            <Text style={styles.line1}>{JsonContactScreen.JsonOrganizer}</Text>
            <Text style={styles.line}>{JsonContactScreen.JsonBug}</Text>
          </View>

          {/*🇫🇷 Liens Telegram manquant*/}
          {/*🇬🇧 Telegram link missing*/}
          <View style={styles.phoneIcones}>
            <Image
              style={{ height: 51, width: 50 }}
              source={images.Discord}
              onClick={() => Linking.openURL("https://discord.gg/bJsFfG7ntU")}
            />
            <Image
              style={{ height: 75, width: 74 }}
              source={images.Telegram}
              onClick={() =>
                Linking.openURL("https://chat.whatsapp.com/KtR79S8Obfs3WTPTIBsoCm")}
            />
            <Image
              style={{ height: 78.02, width: 76.5 }}
              source={images.Facebook}
              onClick={() =>Linking.openURL("https://www.facebook.com/groups/socializus")}
            />
          </View>

          <View style={styles.subtitle}>
            <Text style={styles.line}>Discord</Text>
            <Text style={styles.line1}>Telegram</Text>
            <Text style={styles.line}>Facebook</Text>
          </View>

          <View style={styles.phoneNumber}>
            {/* 🇫🇷 Affichage du message de taxe pour le numéro de téléphone */}
            {/* 🇬🇧 Display of the tax message for the phone number */}
            <Text style={styles.txt}>{JsonContactScreen.JsonPhoneTax}</Text>
            {/* 🇫🇷 Affichage du numéro de téléphone de contact */}
            {/* 🇬🇧 Display of the contact phone number */}
            <Text style={styles.txt}>{JsonContactScreen.JsonPhone}</Text>
            {/* 🇫🇷 Affichage du message de détails du service téléphonique */}
            {/* 🇬🇧 Display of the phone service details message */}
            <Text style={styles.txt}>{JsonContactScreen.JsonPhoneServiceDetailsMessage}</Text>
            {/* 🇫🇷 Affichage du message de remerciement */}
            {/* 🇬🇧 Display of the gratitude message */}
            <Text style={styles.txt}>{JsonContactScreen.JsonThanks}</Text>
            {/* 🇫🇷 Affichage de l'équipe Socializus */}
            {/* 🇬🇧 Display of the Socializus team */}
            <Text style={styles.txt}>{JsonContactScreen.JsonSocializusTeam}</Text>
          </View>
        </View>
        <Pressable
          onPress={() => {
            /*navigation.navigate("Contact Us") */
          }}
        ></Pressable>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ContactScreen;
