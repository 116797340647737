//🇫🇷 Ce fichier gère le deuxième page de création de profil sur le prénom et le nom (FrameFigma10)
//🇬🇧 This file manages the second page of profile creation on the firstname and the lastname (FrameFigma10)

import React, { useState, useEffect } from "react";
import { ScrollView, View, Text } from "react-native";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

//Composants
import styles from "./CreateProfileScreenStepTwoCss.js";
import Fields from "../../../components/Fields/Fields.js";
import LogButton from "../../../components/LogButtons/LogButtons.js";

const CreateProfileScreenStepTwo = ({
  profileState,
  navigation,
  scr,
  userToken,
  user,
  setUser,
}) => {
  //🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.
  //🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language

  const { JsonCreateProfilePages } = scr;

  const {
    setFirstName,
    setLastName,
    firstName,
    lastName,
    accountType,
    setNickName,
    nickName,
  } = profileState;

  const [pressed, setPressed] = useState(false);
  const [profilData, setProfilData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(true); //🇫🇷 Permet de verifier si les données sont chargées
  const [errorMessageFirstName, setErrorMessageFirstName] = useState("");
  const [errorMessageLastName, setErrorMessageLastName] = useState("");
  const [skip, setSkip] = useState(0);
  const limit = 1;
  const [btnDisable, setBtnDisable] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");

  // console.log(accountType);

  //🇫🇷 Mise à jour des données utilisateur
  //🇬🇧 Updating user data
  useEffect(() => {
    UpdateData();
  }, []);

  const UpdateData = async () => {
    //🇫🇷 Récupération des données utilisateur dans le storage
    //🇬🇧 Retrieve user data from the storage
    try {
      let res = JSON.parse(await AsyncStorage.getItem("id"));
      setProfilData(res);
      // console.log(res);

      //🇫🇷 Récupère les informations de l'utilisateur sur la base de donnée
      //🇬🇧 Fetch the user info from the database
      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${res._id}`
      );
      // console.log(response.data.user);

      //🇫🇷 Convertit les informations utilisateur en chaîne et les stocke dans AsyncStorage
      //🇬🇧 Convert the user info to a string and store it in AsyncStorage
      const resultat = JSON.stringify(response.data.user);
      // console.log(resultat);
      await AsyncStorage.setItem("Profile", resultat);
      await AsyncStorage.setItem("user", resultat);
    } catch (error) {
      console.error(error);
    }
  };

  //🇫🇷 Fonction permettant de verifier et de creer un nouveau nom d'utilisateur
  //🇬🇧 Function who permit to verify and create a new userName
  const VerifyUserName = async () => {
    const response = await axios.get(
      `${hostname}/api/v1/user/getuserlist?limit=${limit}&skip=${skip}`
    );
    var c = 0;
    var v = 0;

    //🇫🇷 Récupère une liste de tous les noms d'utilisateur pour une comparaison plus rapide
    //🇬🇧 Retrieves a list of all user names for faster comparison
    var existingUserNames = response.data.map((user) => user.userName);

    for (var i = 0; i < response.data.length; i++) {
      if (profileState.firstName.length < 15) {
        //🇫🇷 Si la longueur du prénom est inférieur à 15, le pseudo de l'utilisateur sera le prenom + . + la première lettre du nom au majuscule + un chiffre
        //🇬🇧 If the length of the first name is less than 15, the user’s nickname will be the firstname + . + the first letter of the capitalized name + a number
        var userNom =
          profileState.firstName +
          "." +
          profileState.lastName.charAt(0).toUpperCase() +
          v;

        //🇫🇷 Si le pseudo existe déjà, remplace le chiffre
        //🇬🇧 If the nickname already exists, replace the number
        if (
          profileState.firstName === response.data[i].userName ||
          existingUserNames.includes(userNom)
        ) {
          c++;
          //🇫🇷 Essaye chaque nombre de 1 à 99 jusqu'à ce qu'il trouve un pseudonyme disponible
          //🇬🇧 Try each number from 1 to 99 until he finds an available alias
          for (let j = 1; j <= 99; j++) {
            let potentialNickName =
              profileState.firstName +
              "." +
              profileState.lastName.charAt(0).toUpperCase() +
              j;
            if (!existingUserNames.includes(potentialNickName)) {
              v = j;
              setNickName(potentialNickName);
              break;
            }
          }
        }

        //🇫🇷 Si le pseudo n'existe pas, le pseudo de l'utilisateur sera le prenom + . + la première lettre du nom au majuscule
        //🇬🇧 If the nickname does not exist, the user’s nickname will be the firstname + . + the first letter of the name capitalized
        if (v === 0 && i === response.data.length - 1) {
          setNickName(
            profileState.firstName +
              "." +
              profileState.lastName.charAt(0).toUpperCase()
          );
        }
      } else {
        //🇫🇷 Si la longueur du prénom est supérieur ou égal à 15, le pseudo de l'utilisateur sera les 11 premières lettres du prenom + . + la première lettre du nom au majuscule + un chiffre
        //🇬🇧 If the length of the first name is greater than or equal to 15, the username of the user will be the first 11 letters of the first name + . + the first capitalized letter of the name + a number
        var userNomSup15 =
          profileState.firstName.substring(0, 11) +
          "." +
          profileState.lastName.charAt(0).toUpperCase() +
          v;

        var userNomSup15v1 =
          profileState.firstName.substring(0, 12) +
          "." +
          profileState.lastName.charAt(0).toUpperCase();

        if (
          userNomSup15v1 === response.data[i].userName ||
          userNomSup15 === response.data[i].userName
        ) {
          c++;
          //🇫🇷 Essaye chaque nombre de 1 à 99 jusqu'à ce qu'il trouve un pseudonyme disponible
          //🇬🇧 Try each number from 1 to 99 until he finds an available alias
          for (let j = 1; j <= 99; j++) {
            let potentialNickName =
              profileState.firstName.substring(0, 13) +
              "." +
              profileState.lastName.charAt(0).toUpperCase() +
              j;
            if (!existingUserNames.includes(potentialNickName)) {
              v = j;
              setNickName(potentialNickName);
              break;
            }
          }
        }

        //🇫🇷 Si le pseudo n'existe pas, le pseudo de l'utilisateur sera les 11 premières lettres du prenom + . + la première lettre du nom au majuscule
        //🇬🇧 If the nickname does not exist, the user’s nickname will be the first 11 letters of the name + . + the first letter of the capitalized name
        if (v === 0 && i === response.data.length - 1) {
          setNickName(userNomSup15v1);
        }
      }
    }
    setSkip(skip + limit);
  };

  //🇫🇷 Cette fonction envoie les données utilisateur à la base de donnée
  //🇬🇧 This function sends user data to the database
  const sendInfoEditProfile = async () => {
    // console.log(nickName);

    //🇫🇷 Crée le corps de la requête avec les informations de profil mises à jour
    //🇬🇧 Create the request body with updated profile information
    const editProfilBody = {
      firstName: firstName,
      lastName: lastName,
      userName: nickName,
    };

    // console.log("ID utilisateur", profilData);
    // console.log("TOKEN", userToken);
    //id pour test: 64394c41879131c2c43375e0
    // token pour test: vyGhGEE6JA6ERnhHOid1eaEphgdb3YCN
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(editProfilBody),
      };

      //🇫🇷 Envoi de la demande de mise à jour du profil utilisateur
      //🇬🇧 Send the request to update the user profile
      const response = await fetch(
        `${hostname}/api/v1/user/info/${profilData._id}`,
        requestOptions
      );
      const data = await response.json();
      // console.log("data", data);
      //🇫🇷 remplacement de données sur user , et apres sur le JSON user et Profile  dans le storage
      //🇬🇧 replacement of data on user , and after on the JSON user and Profile in the storage
      Object.assign(user, editProfilBody);
      UpdateData();
      setUser(user);
    } catch (error) {
      console.log("CATCH :", error);
    }
  };

  const obj = {
    width: 318,
    pressed,
    setPressed,
    setErrorMsg,
    navigate: navigation.navigate,
    path: "Step3",
    firstName,
    lastName,
    currentPage: "Step2",
    setError,
  };

  useEffect(() => {
    if (
      //🇫🇷 Si le nom ou le prénom est vide ou contient moins de 2 caractères, le bouton est désactivé
      //🇬🇧 If the first name or last name is empty or contains less than 2 characters, the button is disabled
      profileState.firstName == null ||
      profileState.lastName == null ||
      profileState.firstName.length < 2 ||
      profileState.lastName.length < 2 ||
      !/^[A-Za-z]+(?:[- ][A-Za-z]+)*$/.test(profileState.firstName) ||
      !/^[A-Za-z]+(?:[- ][A-Za-z]+)*$/.test(profileState.lastName)
    ) {
      setBtnDisable(true);
      setBackgroundColor("grey");
      setErrorMessageFirstName("a");
      setErrorMessageLastName("a");
    } else {
      setErrorMessageFirstName("");
      setErrorMessageLastName("");
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }

    //🇫🇷 Si le nom ou le prénom contient des caractères spéciaux, le bouton est désactivé
    //🇬🇧 If the name or first name contains special characters, the button is disabled
    if (!/^[A-Za-z]+(?:[- ][A-Za-z]+)*$/.test(profileState.firstName)) {
      setErrorMessageFirstName(JsonCreateProfilePages.JsonErrorFirstName);
      setBtnDisable(true);
      setBackgroundColor("grey");
    } else {
      setErrorMessageFirstName("");
    }

    if (!/^[A-Za-z]+(?:[- ][A-Za-z]+)*$/.test(profileState.lastName)) {
      setErrorMessageLastName(JsonCreateProfilePages.JsonErrorLastName);
      setBtnDisable(true);
      setBackgroundColor("grey");
    } else {
      setErrorMessageLastName("");
    }
  });

  useEffect(() => {
    //🇫🇷 Vérifie si le prénom et le nom sont définis, puis appelle la fonction VerifyUserName
    //🇬🇧 Checks if first and last name are set, then calls the VerifyUserName function
    if (firstName && lastName) {
      VerifyUserName();
    }
  }, [firstName, lastName]);

  return (
    <ScrollView
      style={styles.profil}
      contentContainerStyle={{ alignItems: "center" }}
    >
      {/*🇫🇷 Affichage du champs pour saisir son prénom */}
      {/*🇬🇧 Display of the field to entre our first name */}
      <Fields
        icon={"textFrame"}
        text={JsonCreateProfilePages.JsonFirstNamePlaceholder}
        state={firstName}
        setState={setFirstName}
        scr={scr}
      />

      {/*🇫🇷 Affichage du message d'erreur pour le champs du prénom */}
      {/*🇬🇧 Error message display for first name field */}
      {errorMessageFirstName && (
        <Text style={{ color: "red", marginBottom: 10 }}>
          {errorMessageFirstName}
        </Text>
      )}

      <View style={{ height: 15 }}></View>

      {/*🇫🇷 Affichage du champs pour saisir son nom */}
      {/*🇬🇧 Display of the field to entre our last name */}
      <Fields
        upperText={JsonCreateProfilePages.secretField}
        icon={"textFrame"}
        text={JsonCreateProfilePages.JsonLastNamePlaceholder}
        state={lastName}
        setState={setLastName}
        scr={scr}
      />

      {/*🇫🇷 Affichage du message d'erreur pour le champs du nom */}
      {/*🇬🇧 Error message display for last name field */}
      {errorMessageLastName && (
        <Text style={{ color: "red", marginBottom: 10 }}>
          {errorMessageLastName}
        </Text>
      )}

      {/*🇫🇷 Affichage du message indiquant que notre nom reste secret */}
      {/*🇬🇧 Displaying the message that our name remains secret */}
      <View>
        <Text style={styles.text}>
          {JsonCreateProfilePages.JsonSecretNameMessage}
        </Text>
      </View>

      {/*🇫🇷 Affichage du bouton pour continuer vers la page 3 de creation de profil */}
      {/*🇬🇧 Display of the button to continue to page 3 of profile creation */}
      <View style={styles.btn}>
        <LogButton
          text={JsonCreateProfilePages.JsonContinueButton}
          {...obj}
          disabled={btnDisable}
          backgroundColor={backgroundColor}
          VerifyUserName={VerifyUserName}
          dataSave={sendInfoEditProfile}
        />
      </View>
    </ScrollView>
  );
};

export default CreateProfileScreenStepTwo;
