//🇫🇷 Ce fichier gère le premier page de création de profil sur la sélection du genre et du type de compte (FrameFigma9)
//🇬🇧 This file manages the first page of profile creation on the selection of the gender and type of account (FrameFigma9)

import React, { useState, useEffect } from "react";
import { SafeAreaView, View } from "react-native";
import axios from "axios";
import { useRoute } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname";

// Components
import styles from "./CreateProfileScreenCss";
import LogButton from "../../../components/LogButtons/LogButtons";
import TwinSelectButton from "../../../components/TwinSelectButton/TwinSelectButton";

const CreateProfileScreen = ({
  profileState,
  navigation,
  scr,
  userToken,
  user,
  setUser,
}) => {
  //🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.
  //🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language

  const { JsonCreateProfilePages } = scr;

  //🇫🇷 On récupère les valeurs passés en paramètre lors de la navigation vers la première page de création de profil
  //🇬🇧 We retrieve the values passed as parameters when navigating to the first page of profile creation
  const { role, nativeLanguage } = useRoute().params;
  const { setRole, setLanguage } = profileState;
  setRole(role);
  setLanguage(nativeLanguage);

  const image =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

  const [pressed, setPressed] = useState(false);
  const [isSelectOne, setIsSelectOne] = useState(false);
  const [isSelectTwo, setIsSelectTwo] = useState(false);
  const [firstLeftActive, setFirstLeftActive] = useState(false);
  const [firstRightActive, setFirstRightActive] = useState(false);
  const [secondLeftActive, setSecondLeftActive] = useState(false);
  const [secondRightActive, setSecondRightActive] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [profile, setProfile] = useState(false);
  const [profilData, setProfilData] = useState();

  useEffect(() => {
    //🇫🇷 Vérification de l'état des boutons pour activer le bouton de validation
    //🇬🇧 Check the status of the buttons to activate the validation button
    if (isSelectOne === false || isSelectTwo === false) {
      setBtnDisable(true); //🇫🇷 Désactivation du bouton de validation
      setBackgroundColor("grey");
    } else {
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }
    if (profile === false) {
      //🇫🇷 Vérification de l'état du profil utilisateur
      //🇬🇧 Checking the user profile state
      // console.log("false");
      createUserProfile();
    }
  });

  const UpdateData = async () => {
    if (profile === true) {
      try {
        //🇫🇷 Récupérer les données utilisateur du serveur à l'aide du jeton utilisateur
        //🇬🇧 Retrieve user data from the server using the user token
        var token = userToken;
        const response = await axios.post(
          `${hostname}/api/v1/user/get-user-by-token`,
          { token }
        );
        // console.log(response.data);
        //🇫🇷 Enregistrement des données utilisateur dans le storage
        //🇬🇧 Saving user data in storage
        const userData = JSON.stringify(response.data.user);
        setProfilData(JSON.parse(userData));
        await AsyncStorage.setItem("Profile", userData);
        await AsyncStorage.setItem("user", userData);
        await AsyncStorage.setItem("id", userData);
        // console.log(JSON.parse(userData));
      } catch (error) {
        console.error(error);
      }
    }
  };

  //🇫🇷 Mise à jour des données utilisateur
  //🇬🇧 Updating user data
  useEffect(() => {
    UpdateData();
  }, [profile]);

  //🇫🇷 Fonction permettant de mettre à jour les données enrégistré par l'utilisateur
  //🇬🇧 Function who permit to update the data register by the user
  const createUserProfile = async () => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("sexe", "male");
    formData.append("isPersonalAccount", true);
    formData.append("firstName", null);
    formData.append("lastName", null);
    formData.append("userName", null);
    formData.append("city", null);
    formData.append(
      "nativeLanguage",
      !nativeLanguage ? "English" : nativeLanguage
    );
    // console.log(role);
    formData.append("roleName", role.name ? role.name : role);
    try {
      //🇫🇷 Enregistrement des données utilisateur sur le serveur
      //🇬🇧 Saving user data on the server
      const response = await axios.post(
        `${hostname}/api/v1/profile/createprofile`,
        formData,
        {
          headers: {
            authorization: "Bearer " + userToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("ok");
      const userData = JSON.stringify(response.data.user);
      setUser(response.data.user);
      // console.log("UserData: ", userData);
      // console.log("Profile enregistré");
      setProfile(true);
      // console.log(profileState);
      //setProfile("done");
    } catch (error) {
      console.log(error);
    }
  };

  //🇫🇷 Cette fonction envoie les données utilisateur à la base de donnée
  //🇬🇧 This function sends user data to the database
  const sendInfoEditProfile = async () => {
    //🇫🇷 Crée le corps de la requête avec les informations de profil mises à jour
    //🇬🇧 Create the request body with updated profile information
    const editProfilBody = {
      sexe: profileState.gender,
      isPersonalAccount:
        profileState.accountType === JsonCreateProfilePages.JsonPersonal
          ? true
          : false,
    };

    // console.log("ID utilisateur", profilData._id);
    // console.log("TOKEN", userToken);
    //id pour test: 64394c41879131c2c43375e0
    // token pour test: vyGhGEE6JA6ERnhHOid1eaEphgdb3YCN
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(editProfilBody),
      };
      const response = await fetch(
        `${hostname}/api/v1/user/info/${profilData._id}`,
        requestOptions
      );
      const data = await response.json();
      // console.log("data", data);
      //🇫🇷 remplacement de données sur user
      //🇬🇧 replacement of data on user
      Object.assign(user, editProfilBody);
      setUser(user);
    } catch (error) {
      console.log("CATCH :", error);
    }
  };

  return (
    <SafeAreaView style={styles.profil}>
      {/*🇫🇷 Affichage du double bouton de sélection entre un homme et une femme */}
      {/*🇬🇧 Display of the double selection button between a man and a woman */}
      <View style={styles.gender}>
        <TwinSelectButton
          firstTitle={JsonCreateProfilePages.JsonMale}
          secondTitle={JsonCreateProfilePages.JsonFemale}
          profileState={profileState}
          setIsSelect={setIsSelectOne}
          active={firstLeftActive}
          setActive={setFirstLeftActive}
          secondActive={firstRightActive}
          setSecondActive={setFirstRightActive}
          currentPAge={"Profile"}
          scr={scr}
        />
      </View>

      {/*🇫🇷 Affichage du double bouton de sélection entre un compte personnel et un compte professionnel */}
      {/*🇬🇧 Display of the double selection button between a personal account and a profesionnal account */}
      <View style={styles.account}>
        <TwinSelectButton
          style={styles.account}
          firstTitle={JsonCreateProfilePages.JsonPersonal}
          secondTitle={JsonCreateProfilePages.JsonProfessional}
          profileState={profileState}
          setIsSelect={setIsSelectTwo}
          active={secondLeftActive}
          setActive={setSecondLeftActive}
          secondActive={secondRightActive}
          setSecondActive={setSecondRightActive}
          currentPAge={"Profile"}
          scr={scr}
        />
      </View>

      {/*🇫🇷 Affichage du bouton pour continuer vers la page 2 de creation de profil */}
      {/*🇬🇧 Display of the button to continue to page 2 of profile creation */}
      <View style={styles.btn}>
        <LogButton
          text={JsonCreateProfilePages.JsonContinueButton}
          width={318}
          profileState={profileState}
          isSelectOne={isSelectOne}
          isSelectTwo={isSelectTwo}
          setPressed={setPressed}
          currentPage={"Profile"}
          navigate={navigation.navigate}
          path={"Step2"}
          disabled={btnDisable}
          backgroundColor={backgroundColor}
          dataSave={() => sendInfoEditProfile()}
          token={userToken}
        />
      </View>
    </SafeAreaView>
  );
};

export default CreateProfileScreen;
