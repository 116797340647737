import { StyleSheet } from "react-native";
import { images } from "../../../constantes";

// Styles for various components in the application
const styles = StyleSheet.create({
  // Style for the textSponsorShip component
  textSponsorShip: {
    // Border styling
    border: '1px solid #59C09B',
    // Width of the element
    width: '53%',
    // Text alignment
    textAlign: 'center',
    // Padding for the top
    paddingTop:  '1%',
    // Height of the element
    height: 35,
    // Font size
    fontSize: 14,
    // Border radius for rounded corners
    borderRadius: 20 / 2,
    // Text color
    color: 'white',
    // Font weight
    fontWeight: 'bold',
    // Background color
    backgroundColor: '#59C09B',
  },

  // Style for the share component
  share: {
    // Border styling
    border: '1px solid #FFFFFF',
    // Width of the element
    width: 145,
    // Text alignment
    textAlign: 'center',
    // Padding for the top
    paddingTop:  '1%',
    // Margin from the left
    marginLeft: '35%',
    // Margin from the bottom
    marginBottom: '2%',
    // Margin from the top
    marginTop: '-3%',
    // Height of the element
    height: 40,
    // Font size
    fontSize: 14,
    // Text color
    color: '#59C09B',
    // Font weight
    fontWeight: 'bold',
    // Background color
    backgroundColor: '#FFFFFF',
  },

  // Style for the container component
  container: {
    flex: 1,
  },

  // Style for the Box component
  Box: {
    // Border styling
    border: '1px solid #59C09B',
    // Margin from the top
    marginTop: "5%",
    // Text alignment
    textAlign: 'center',
    // Padding for the top
    paddingTop:  '5%',
    // Width of the element
    width: '110%',
    // Font size
    fontSize: 14,
    // Negative margin for slight overlap
    marginBottom: "-2%",
    // Text color
    color: 'white',
    // Font weight
    fontWeight: 'bold',
    // Background color
    backgroundColor: '#59C09B',
  },

  // Styles for TextBox with various states (On and Off) and different gem colors
  TextBoxGoldOn: { 
    color: '#FFD700',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-10%",
  },

  TextBoxGoldOff: { 
    color: 'white',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-10%",
  },

  TextBoxRubyOn: { 
    color: "#E84857",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-10%"  

},

  TextBoxRubyOff: { 
    color: 'white',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-7.5%",
  },

  TextBoxEmeraldOn: { 
    color: "#42DB71",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-10%",  
  },

  TextBoxEmeraldOff: {  
    color: 'white',
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-1%"
  },

  TextBoxDiamondOn: { 
    color: "#7ACEFA",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginTop: "-10%", 
    marginBottom: "-3%",
},
  TextBoxDiamondOff: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: "-4%",
    marginTop: "-0.5%",
    color: 'white',
},

  // Style for generic TextBox
  TextBox: { 
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 15,
    marginBottom: "5%",
    marginTop: "-7%",
    color: 'white',     
  },

  // Style for NumberBox
  NumberBox: { 
    fontSize: 40,
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',right: "-5%",
    marginTop: "-5%",
  },

  // Style for row component
  row: { 
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    margin: 10,  
},

  // Style for text component
  text: { 
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    fontSize: 13  
},

  // Style for Text component with larger font size
  Text: { 
    fontWeight: "bold",
    alignItems: "center",
    top:40,
    padding: 10,
    fontSize: 30,
    marginBottom: "6%",  
    marginTop:"-5%"
},

  // Style for image component
  img: { 
    width: 125,
    height: 160,
    justifyContent: 'center',  
},

  // Styles for buttonimg components with different gem colors
  buttonimg: { 
    width: 80,
    height: 80,
    position: 'relative',right: "-28%",
    marginTop: "-20%",
    marginLeft: "-5%"  
  },

  buttonimgRuby: { 
    width: 80,
    height: 80,
    position: 'relative',right: "-28%",
    marginTop: "-20%",
    marginLeft: "-10%"
  },

  buttonimgdiamond: { 
    width: 80,
    height: 80,
    position: 'relative',right: "-28%",
    marginTop: "-17%",
    marginLeft: "-10%"
  },

  // Styles for buttons with different gem colors and states (On and Off)
  ButtonTextEmerald: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
    color: "#42DB71" ,
  },

  buttonTextRuby: { 
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
    color: "#E84857" ,  
  },

  buttonTextGold: { 
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
    color: "#FFD700" ,  
  },

  buttontextdiamond: { 
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
    color: "#7ACEFA" ,
     
  },

  // Styles for enabled and disabled buttons (Gold, Ruby, Emerald, Diamond)
  boxRubyOn: { borderWidth: "20%",
      borderColor: "#fff",
      borderRadius: "20px",
      marginTop: "13%",
      padding: "10%", // Augmenter la valeur du padding
      width: "100%",
      overflow : "hidden",
      border: '3.5px solid #E84857',
},
  boxRubyOff: { borderWidth: "20%",
     borderColor: "#fff",
     borderRadius: "20px",
     marginTop: "13%",
     padding: "10%", // Augmenter la valeur du padding
     width: "105%",
     overflow : "hidden",
     shadowColor:'#000',
     shadowOffset: {width: 0, height:8},
     shadowOpacity:0.5,
     shadowRadius: 10,
     backgroundColor: "#505050",
     border: '3.5px solid #505050', 
},

  boxDiamondOn: { 
  borderWidth: "20%",
  borderColor: "#fff",
  borderRadius: "20px",
  padding: "10%", // Augmenter la valeur du padding
  width: "100%",
  overflow : "hidden",
  marginTop: "5%",
  border: '3.5px solid #7ACEFA',
  },

  boxDiamondOff: { 
    borderWidth: "20%",
    borderColor: "#fff",
    borderRadius: "20px",
    padding: "10%", // Augmenter la valeur du padding
    width: "100%",
    overflow : "hidden",
    shadowColor:'#000',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,
    backgroundColor: "#505050",
    marginTop: "13.5%",
    border: '3.5px solid #505050',
  },

  buttonGoldOn: {  
    borderWidth: "20%",
    borderRadius: "20px",
    marginTop: "13%",
    padding: "10%", // Augmenter la valeur du padding
    width: "100%",
    overflow : "hidden",
    border: '3.5px solid #FABE2C',
},

  buttonGoldOff: { 
    borderWidth: "20%",
    borderRadius: "20px",
    marginTop: "13%",
    padding: "10%", // Augmenter la valeur du padding
    width: "100%",
    overflow : "hidden",
    shadowColor:'black',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,
    backgroundColor: "#505050",
    border: '3.5px solid #505050',
  },

  buttonEmeraldOff: {  
    borderWidth: "20%",  
    borderRadius: "20px",
    marginTop: "13%",
    padding: "10%", // Augmenter la valeur du padding
    width: "100%",
    overflow : "hidden",
    shadowColor:'black',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,
    backgroundColor: "#505050",
    border: '3.5px solid #505050',
  },

  buttonEmeraldOn: {
    borderWidth: "20%",
    borderRadius: "20px",
    padding: "10%", // Augmenter la valeur du padding
    width: "100%",
    overflow : "hidden",
    marginTop: "5%",
    border: '3.5px solid #42DB71', 
},

  // Styles for button text with different gem colors
  buttonText: { 
    color: "#FABE2C",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "-10%",
    fontSize: 15,
},

  // Styles for row components
  row: {  
     flexDirection: "row",
},

  rowTab: {  
    flexDirection: "row",
    backgroundColor: "#888",
    borderRadius: 10,
    paddingLeft: 27,
},

  rowText: { 
    alignItems: "center",
    fontSize: 10,
    padding: 10,
  },

  // Styles for separators and tables
  separatorE: { 
    borderTopWidth: 2,
    borderTopColor: '#59C09B', 
    width: '60%', 
    marginTop: "2%"
},

  table: { 
    borderColor: "#000",
    width: "95%",
    marginLeft: "3%",
  },

  cell: {
    flex: 1,
    padding: 5,
    width: "50%",
  },

});

export default styles;