import React from 'react';
import { Image, Modal, Pressable, Text, TextInput, View } from 'react-native';
import { images } from "../../../constantes";
import styles from '../MyProfileScreenCss';

const ConfirmMemberNumberModal = ({
  memberNumberModalVisible,
  setMemberNumberModalVisible,
  setAddFriendModalVisible,
  friendMemberNumber,
  setFriendMemberNumber,
  handleAddFriend,
  setModalVisible,
  scr
}) => {

  const JsonConfirmFriendshipPopup = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={memberNumberModalVisible}
      onRequestClose={() => {
        setMemberNumberModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.confirmEmailModalContainer}>
          <Pressable
            style={styles.backButtonWrapper}
            onPress={() => {
              setMemberNumberModalVisible(false);
              setAddFriendModalVisible(true);
            }}
          >
            <Image source={images.leftArrow} style={styles.leftArrowIcon} />
          </Pressable>
          <View style={styles.iconWrapper}>
            <Image
              source={images.users}
              style={{
                width: 48,
                height: 48,
                resizeMode: "contain",
              }}
            />
          </View>
          <Text style={styles.confirmEmailModalText}>
            {JsonConfirmFriendshipPopup.JsonPopupEnterMemberNumber}
          </Text>

          {/*
            Logique pour récupérer le numéro de membre
          */}
          <View style={styles.phoneInputContainer}>
            <TextInput
              style={styles.phoneInput}
              value={friendMemberNumber}
              onChangeText={(number) => {
                setFriendMemberNumber(number);
              }}
              placeholder={
                JsonConfirmFriendshipPopup.JsonPlaceholderMemberNumber
              }
            />
          </View>
          <Pressable
            style={styles.confirmLastNameButton}
            onPress={async () => {
              await handleAddFriend(friendMemberNumber, "memberId");
              setMemberNumberModalVisible(false);
              setModalVisible(false);
            }}
          >
            <Text style={styles.confirmLastNameButtonText}>
              {JsonConfirmFriendshipPopup.JsonButtonConfirm}
            </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmMemberNumberModal;
