//🇫🇷 Affichage du bouton "Participer" (FrameFigma41-FrameFigma42)
//🇬🇧 Display of the button "Participate" (FrameFigma41-FrameFigma42)

import React from "react";
import { Text, Pressable } from "react-native";

// Components
import styles from "./SubUnsubButtonCss";

const SubButton = ({
  isParticipating,
  subscribe,
  cancelParticipationDialogVisible,
  setCancelParticipationDialogVisible,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  const onPressHandler = () => {
    if (isParticipating) {
      setCancelParticipationDialogVisible(!cancelParticipationDialogVisible);
    } else {
      subscribe();
    }
  };

  return (
    <Pressable
      style={[
        styles.button,
        { backgroundColor: isParticipating ? "red" : "#59c09b" },
      ]}
      onPress={onPressHandler}
      activeOpacity={0.4}
    >
      <Text style={styles.text}>
        {isParticipating
          ? JsonActivityPages.JsonActivityScreen.JsonUnsubscribe
          : JsonActivityPages.JsonActivityScreen.JsonParticipate}
        {/*"Unsubscribe" : "Participate"*/}
      </Text>
    </Pressable>
  );
};

export default SubButton;
