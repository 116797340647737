// CreateActivityScreenStepTwo.js

import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { ActivityTypesGrid_OneTopic } from '../../../../components/ActivityTypesGrids/ActivityTypesGrids.js';
import LogButton from '../../../../components/LogButtons/LogButtons.js';

import styles from '../CreateActivityScreenCss.js';

const CreateActivityScreenStepTwo = ({
  topic,
  setTopic,
  step,
  setStep,
  errorMessage,
  setErrorMessage,
  scr
}) => {

    const {JsonActivityPages} = scr;
  return (
    <ScrollView>
    {/* ----------------Titles---------------- */}
    <Text style={[styles.boldTitle, { paddingHorizontal: 20 }]}>
      {
        JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
          .JsonChooseTopic
      }
    </Text>
    {/* "choose": "Choose a Topic :" */}
    <Text style={styles.createActivityStep2Inorder}>
      {
        JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
          .JsonIncreaseYourActivityInterestMessage
      }
    </Text>
    {/* "inOrder": "Your event will look more exciting with a nice picture!" */}
    {/*FR Grille de la liste des thèmes d'activités. Le props selectionMode limite 
    le choix à un seul.*/}
    {/*GB Activity topic grid. The props selectionMode limits our choice to one.*/}
    <ActivityTypesGrid_OneTopic
      topic={topic}
      setTopic={setTopic}
      selectionMode={"one"}
    />

    {/* ------------Save or Continue------------ */}
    <Text style={styles.errorMessageText2}>{errorMessage}</Text>

    <View style={[styles.savOrConButtons, { paddingHorizontal: 15 }]}>
      <LogButton
        text={JsonActivityPages.JsonActivityManagement.JsonPreviousButton}
        width={150}
        backgroundColor={"#59c09b"}
        func={(n) => {
          setErrorMessage("");
          setStep(n);
        }}
        arg={step - 1}
      />

      <LogButton
        text={JsonActivityPages.JsonActivityManagement.JsonContinueButton}
        width={150}
        backgroundColor={topic !== null ? "#59c09b" : "grey"}
        func={(n) => {
          if (topic !== null) {
            setErrorMessage("");
            setStep(n);
          }
        }}
        arg={step + 1}
        disabled={topic === null}
      />
    </View>
  </ScrollView>
  );
};

export default CreateActivityScreenStepTwo;