//🇫🇷 Ce fichier gère la page de vérification pour le mail pendant la création d'un compte, on doit rentrer un code à 6 chiffres (FrameFigma6)
//🇬🇧 This file manages the verification page for the email while creating an account, we must enter a 6-digit code (FrameFigma6)

import React, { useRef, useState } from "react";
import { Text, View, Pressable, ScrollView, TextInput } from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./VerificationNewUserScreenCss.js";

const VerificationNewUserScreen = ({
  registerMailCall,
  setNumber,
  rolesList,
  skipWarning,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonLoginRegisterPages } = scr;
  const navigation = useNavigation();

  const verifiedUser =
    rolesList && rolesList.find((role) => role.name === "user"); //🇫🇷 Variable permettant de vérifier si le rôle de l'utilisateur est "user"

  //🇫🇷 Chiffres du code de vérification
  //🇬🇧 The numbers of verification code
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);
  const pin5Ref = useRef(null);
  const pin6Ref = useRef(null);

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [error, setError] = useState(null);

  //🇫🇷 Cette requête API envoie le mail à l'utilisateur avec le code de vérification à 6 chiffres.
  //🇬🇧 This API request sends a message to the user's email with the 6-digit verification code.
  const requestCode = async () => {
    await axios.post(`${hostname}/api/v1/sendcode`, {
      email: registerMailCall,
      subject: JsonLoginRegisterPages.JsonVerificationCode.JsonEmailSubject,
      message:
        JsonLoginRegisterPages.JsonVerificationCode.JsonChangePasswordMessage,
    });
  };

  //🇫🇷 Cette requête API traite le code de vérification envoyé par l'utilisateur.
  //🇬🇧 This API request checks the 6-digit verification code sent by the user.
  const requestPassword = async () => {
    setError(null);

    // console.log(pin1Ref + pin2Ref + pin3Ref + pin4Ref + pin5Ref + pin6Ref);
    let code = `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`;
    try {
      const response = await axios.post(`${hostname}/api/v1/verify-email`, {
        email: registerMailCall,
        code: code,
      });
      if (response.data.result === "OK") {
        //🇫🇷 Si le code est correct, alors on sera dans la partie de creation de profil et on sera redirigé vers la première page de création de profil
        //🇬🇧 If the code is correct, then we will be in the profile creation part and we will be redirected to the first profile creation page
        setNumber(2);
        navigation.navigate("Step1", {
          email: registerMailCall,
          code: code,
          role: verifiedUser,
        });
      }
    } catch (error) {
      //🇫🇷 Si le code est incorrect, un message d'erreur est affiché
      //🇬🇧 If the code is incorrect, an error message is displayed
      // console.log(error.response);
      if (error.response.status === 400) {
        setError(JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
      } else if (error.response.status === 404) {
        setError(JsonLoginRegisterPages.JsonVerificationCode.JsonEmailError);
      }
    }
  };

  return (
    <ScrollView style={styles.forgotPage}>
      <Text style={styles.title}>
        {JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification}
      </Text>

      {/*🇫🇷 Affichage du message indiquant qu'on peut passer cette étape mais qu'il faudra confirmer l'email pour participer à des activités */}
      {/*🇬🇧 Display of the message indicating that you can skip this step but you will need to confirm the email to participate in activities */}
      <View style={!skipWarning ? styles.container : styles.warningContainer}>
        {skipWarning ? (
          <Text style={styles.warningText}>
            {
              JsonLoginRegisterPages.JsonVerificationCode
                .JsonEmailConfirmationMessage
            }
          </Text>
        ) : (
          <Text style={styles.text}>
            {
              JsonLoginRegisterPages.JsonVerificationCode
                .JsonEnterVerificationCode
            }
          </Text>
        )}
      </View>

      {/*🇫🇷 Les champs pour saisir le code de vérification envoyé par mail */}
      {/*🇬🇧 The fields to enter the verification code sent by email */}
      <View style={styles.textInputView}>
        <TextInput
          ref={pin1Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin1) => {
            setPin1(pin1);
            if (pin1) {
              pin2Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin2Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin2) => {
            setPin2(pin2);
            if (pin2) {
              pin3Ref.current.focus();
            } else {
              pin1Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin3Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin3) => {
            setPin3(pin3);
            if (pin3) {
              pin4Ref.current.focus();
            } else {
              pin2Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin4Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin4) => {
            setPin4(pin4);
            if (pin4) {
              pin5Ref.current.focus();
            } else {
              pin3Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin5Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin5) => {
            setPin5(pin5);
            if (pin5) {
              pin6Ref.current.focus();
            } else {
              pin4Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin6Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin6) => {
            setPin6(pin6);
            if (!pin6) {
              pin5Ref.current.focus();
            }
          }}
        />
      </View>

      <View style={styles.btnContainer}>
        {/*🇫🇷 Affichage du message d'erreur */}
        {/*🇬🇧 Display of the error message */}
        {error && (
          <Text
            style={
              error ===
              JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError
                ? styles.errorText
                : null
            }
          >
            {error}
          </Text>
        )}

        {/*🇫🇷 Affichage du bouton "Vérifier" */}
        {/*🇬🇧 Display of the "Verify" button */}
        <Pressable
          onPress={() => {
            requestPassword();
            setNumber(2);
          }}
          style={styles.bigBtn}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonVerifyButton}
          </Text>
        </Pressable>

        {/*🇫🇷 Affichage de la partie pour renvoyer le code de vérification */}
        {/*🇬🇧 Display of the resend of the verification code part */}
        <View style={styles.verificationCodeView}>
          <Text
            style={[
              styles.text,
              { width: 250, textAlign: "center", marginBottom: 0 },
            ]}
          >
            {JsonLoginRegisterPages.JsonVerificationCode.JsonCodeNotReceved}
          </Text>
        </View>
        <Pressable
          onPress={() => {
            requestCode();
          }}
          style={styles.smallBtn}
        >
          <Text style={styles.smallBtnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonResendButton}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default VerificationNewUserScreen;
