//🇫🇷 Page d'acceuil de l'application quand on est pas connecté sur un compte (FrameFigma2)
//🇬🇧 Home page of the application when you are not connected to an account (FrameFigma2)

import React, { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Modal,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
  useWindowDimensions,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useDispatch, useSelector } from "react-redux"; //🇫🇷 Permet de récupérer les données du state //🇬🇧 Allows to retrieve data from the state.

// Components
import styles from "./HomeScreenCss.js";

//Assets
import JSON from "../../../../front-mobile/src/assets/json/en.json"; //🇫🇷 Fichier JSON pour la traduction. //🇬🇧 Get text variables from JSON file for translation
import { countriesListCircleFlags } from "../../assets/countriesListCircleFlags.js";

// import des images
import { images } from "../../constantes";

const HomeScreen = ({ navigation, onClick, setUserNativeLanguage, pays }) => {
  const { height, width } = useWindowDimensions();
  const { JsonHomeScreen } = JSON;
  //🇫🇷 Permet de passer des actions, ici le changement des langues //🇬🇧 Allows to pass actions (managing language switch)
  const dispatch = useDispatch();
  //🇫🇷 Voir le dossier reducers en directory src ou il sont
  //🇫🇷 emportées les langues pour le rendre disponible globalment
  const [language, setLanguage] = useState(JsonHomeScreen.JsonNativeLanguage);

  const [countryModalVisible, isCountryModalVisible] = useState(false); //🇫🇷 countryModalVisible est une variable d'état qui est égale à false. isCountryModalVisible est une fonction qui permet de modifier la valeur de countryModalVisible
  //🇬🇧 countryModalVisible is a state variable that is equal to false. isCountryModalVisible is a function that allows modifying the value of countryModalVisible
  const [errorMessage, setErrorMessage] = useState(""); //🇫🇷 errorMessage est une variable d'état qui est égale à "". setErrorMessage est une fonction qui permet de modifier la valeur de errorMessage.
  //🇬🇧 errorMessage is a state variable that equals "". setErrorMessage is a function that allows modifying the value of errorMessage
  const [imageBGHome, setImageBGHome] = useState("HomepageBR"); //🇫🇷 imageBGHome est une variable d'état qui est égale à "HomepageBR". setImageBGHome est une fonction qui permet de modifier la valeur de imageBGHome.
  //🇫🇷 imageBGHome is a state variable that equals "HomepageBR". setImageBGHome is a function that allows modifying the value of imageBGHome.
  const [marginField, setMarginField] = useState(-30); //🇫🇷 marginField est une variable d'état qui est égale à -30. setMarginField est une fonction qui permet de modifier la valeur de marginField.
  //🇬🇧 marginField is a state variable set to -30. setMarginField is a function used to modify the value of marginField.
  const [languages, setLanguages] = useState(countriesListCircleFlags); //🇫🇷 languages est une variable d'état qui est égale à countriesListCircleFlags. setLanguages est une fonction qui permet de modifier la valeur de languages
  //🇬🇧 'languages' is a state variable equal to 'countriesListCircleFlags'. 'setLanguages' is a function that allows modifying the value of 'languages'.
  const [country, setCountry] = useState({
    language: "",
    flag: null,
    bigram: "",
  });

  const filterLanguages = (text) => {
    //🇫🇷 est une fonction qui prend un argument appelé text et qui retourne une valeur. Le comportement de cette fonction dépend du code qui se trouve à l'intérieur de la fonction
    //🇬🇧 is a function that takes an argument called text and returns a value. The behavior of this function depends on the code inside the function
    let tmp = countriesListCircleFlags.filter((c) =>
      c.language.toLowerCase().includes(text.toLowerCase())
    );
    setLanguages(tmp);
  };
  const langue = useSelector((state) => state.langue); //🇫🇷 la variable langue est égale à la valeur de la variable langue du state.
  //🇬🇧 the variable language is equal to the value of the language variable of the state.
  const updateLangue = (langue) => ({
    type: "UPDATE_LANGUE",
    payload: langue,
  });

  //console.log(langue);
  ///Dieuveille_test

  useEffect(() => {
    //🇫🇷 useEffect est un hook qui permet d'effectuer des actions à chaque fois que la valeur d'une variable change.//🇬🇧 useEffect is a hook that allows to perform actions every time the value of a variable changes.
    dispatch({ type: country?.language });
  }, [country]);
  // NOT WORKING YET: Bulgarian(empty), Romanian, Serbian(empty), Slovakian(empty), Ukrainian
  const handlePress = (e) => {
    if (language === langue.JsonHomeScreen.JsonNativeLanguage) {
      isCountryModalVisible(!countryModalVisible);
    } else {
      setErrorMessage("");
      navigation.navigate("LogIn", {
        MsgE: langue.JsonLoginRegisterPages.JsonEmailScreen
          .JsonHeaderTitleWelcome,
      });

      if (onClick) {
        onClick(langue);
      }
      if (pays) {
        pays(country);
      }

      dispatch(updateLangue(langue));
    }
  };
  return (
    <View style={styles.container}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={countryModalVisible}
        onRequestClose={() => {
          isCountryModalVisible(!countryModalVisible);
        }}
        style={{ width: 450 }}
      >
        <View
          style={{
            flex: 1,
            width: width >= 450 ? 450 : width,
            marginHorizontal: "auto",
          }}
        >
          <View style={styles.countryModalView}>
            <Pressable
              style={{ margin: 5, alignSelf: "flex-end" }} //🇫🇷 alignSelf permet de définir l'alignement de l'élément par rapport à son parent.
              //🇬🇧 alignSelf allows to define the alignment of the element relative to its parent.
              onPress={() => isCountryModalVisible(!countryModalVisible)}
              //🇫🇷 onPress permet de définir une action à effectuer lorsqu'on appuie sur l'élément.
            >
              <Icon name="close" color="white" size={0} />
            </Pressable>
            <Text style={styles.countryModalTitle}>
              {langue.JsonHomeScreen.JsonSelectLanguage}:
            </Text>
            <View style={{ position: "relative" }}>
              {/*🇫🇷 position permet de définir la position de l'élément par rapport à son parent.*/}
              {/*🇬🇧 'position' defines the element's position relative to its parent. */}
              <TextInput
                style={styles.searchBarInput}
                state={language}
                setState={setLanguage}
                onChangeText={filterLanguages}
              />
              <View style={styles.searchIcon}>
                {/*🇫🇷 permet de définir la position de l'élément par rapport à son parent. */}
                {/*🇬🇧 'position' defines the element's position relative to its parent. */}
                <Icon name="search" color="#ccc" size={16} />
              </View>
            </View>
            <ScrollView>
              <View style={styles.slide}>
                <View style={styles.countryModalScrollView}>
                  {languages.map((country, index) => {
                    return (
                      <Pressable
                        key={index}
                        style={styles.countryModalItem}
                        onPress={() => {
                          setCountry({
                            language: country.language,
                            bigram: country.bigram,
                            flag: country.flag,
                          });
                          setLanguage(country.language); //🇫🇷 setLanguage permet de modifier la valeur de language. //🇬🇧 setLanguage allows to modify the value of language.
                          setImageBGHome("home_page_illustrationBR");
                          setMarginField(15);
                          setUserNativeLanguage(country.language);
                          isCountryModalVisible(!countryModalVisible); //🇫🇷 isCountryModalVisible permet de modifier la valeur de countryModalVisible. //🇬🇧 isCountryModalVisible allows to modify the value of countryModalVisible.🇬🇧
                        }}
                      >
                        <View style={{ width: 50, height: 50 }}>
                          {country.flag}
                        </View>
                        <Text style={styles.countryName}>
                          {/** 🇫🇷 style permet de définir le style d'un élément. //🇬🇧 style allows to define the style of an element. */}
                          {country.language}
                        </Text>
                      </Pressable>
                    );
                  })}
                </View>
              </View>
            </ScrollView>
            {/* </Swiper> */}
          </View>
        </View>
      </Modal>
      {/* ******************* Background image Homepage***********************************/}

      <ImageBackground
        source={require("../../assets/images/" + imageBGHome + ".png")}
        resizeMode="stretch"
        style={{ flex: 1, width: width >= 450 ? 450 : width }}
      >
        {/* ********************* *********************************************************/}
        {/* 🇬🇧 Ici pour changer la langue */}
        {/* 🇬🇧 Here to Change the language */}
        <Pressable
          style={[styles.countryModalBtn, { marginTop: marginField }]}
          onPress={() => {
            isCountryModalVisible(!countryModalVisible);
          }}
        >
          <Text style={{ paddingLeft: "10%", fontWeight: "bold" }}>
            {language}
          </Text>
          <Icon name="caret-down" color={"black"} size={14} />
        </Pressable>

          {country.language ? (
          <View style={[styles.signInUpContainer, {marginVertical: '50%'}]}>

            <Text
              style={[
                styles.signInUpTxt,
                { fontSize: (width >= 450 ? 450 : width) * 0.065 },
              ]}
            >
              {langue.JsonHomeScreen.JsonShareActivities}
            </Text>
            <Pressable
              onPress={() => {
                handlePress(); //🇫🇷 Vers la page de connexion //🇬🇧 To the login page
              }}
              style={styles.signInUpButton}
            >
              <Text
                style={[
                  styles.startBtnTxt,
                  { fontSize: width >= 450 ? 450 * 0.06 : width * 0.06 },
                ]}
              >
                {langue.JsonHomeScreen.JsonStart}
              </Text>
            </Pressable>
          </View>
          ) : (
          <View>
            <View
                  style={{ marginBottom: height <= 520 ? "15%" : 90, height: 266.64 }}
                >
                  <Image
                    source={images.LogoErasmusEvent}
                    style={styles.logo}
                    resizeMode="contain"
                  />
            </View>
            <View style={{alignItems: 'center'}}>
              <Pressable
                onPress={() => {
                  handlePress(); //🇫🇷 Vers la page de connexion //🇬🇧 To the login page
                }}
                style={{ alignItems: "center" }}
              >
                <Image source={images.ButtonWorldStart} style={{width: 200, height: 60}}/>
              </Pressable>
            </View>
          </View>
          )}

        {errorMessage !== "" && (
          <View style={styles.errorMsgView}>
            <Text style={styles.errorMsgTxt}>{errorMessage}</Text>
          </View>
        )}
      </ImageBackground>
    </View>
  );
};
//🇫🇷 Les styles ont été changés pour pouvoir afficher le contenu des boutons car le texte fait une taille différente selon la langue.
//🇬🇧 Changed button styles so they fit the text inside depending on the language

export default HomeScreen;
