import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import ActivityPhoto from "../../../../components/ActivityPhoto/ActivityPhoto.js";
import LogButton from '../../../../components/LogButtons/LogButtons.js';
import MultilineFields from '../../../../components/MultilineFields/MultilineFields.js';

import styles from '../CreateActivityScreenCss.js';

const CreateActivityScreenStepThree = ({
  topic,
  activityImage,
  setActivityImage,
  disableButton,
  setDisbaleButton,
  scr,
  description,
  setDescription,
  howToFind,
  setHowToFind,
  step,
  setStep,
  errorMessage,
  premiumRoles,
  name,
  createAnActivity,
  setErrorMessage
}) => {

    const {JsonActivityPages} = scr;

  return (
    <ScrollView style={styles.container}>
        {/* ----------------Titles---------------- */}
        <Text style={[styles.boldTitle, { marginVertical: 10 }]}>
          {
            JsonActivityPages.JsonActivityManagement
              .JsonExplainYourActivityScreen.JsonExplainYourActivityMessage
          }
        </Text>
        {/* "please": "Tell us more about your activity:" */}

        <ActivityPhoto
          topic={topic}
          activityImage={activityImage}
          setActivityImage={setActivityImage}
          disabled={disableButton}
          setDisabled={setDisbaleButton}
          scr={scr}
        />

        <View style={[styles.fields, { marginVertical: 30 }]}>
          <MultilineFields
            title={
              JsonActivityPages.JsonActivityManagement
                .JsonExplainYourActivityScreen.JsonDescription
            } // 🇫🇷"description": "Description"
            state={description}
            setState={setDescription}
            lines={15}
          />
        </View>
        <View style={styles.fields}>
          <MultilineFields
            title={
              JsonActivityPages.JsonActivityManagement
                .JsonExplainYourActivityScreen.JsonHowToFindMe
            } // 🇬🇧 "how": "How to find me"
            //  🇫🇷comment : "Comment me trouver"
            state={howToFind}
            setState={setHowToFind}
            lines={10}
          />
        </View>

        {/* 🇫🇷 ------------Enregistrer ou Continuer------------ */}
        {/* 🇬🇧 ------------Save or Continue------------ */}

        <Text style={styles.errorMessageText3}>{errorMessage}</Text>

        <View style={styles.savOrConButtons}>
          <LogButton
            text={JsonActivityPages.JsonActivityManagement.JsonPreviousButton}
            width={150}
            backgroundColor={"#59c09b"}
            func={(n) => {
              setErrorMessage("");
              setStep(n);
            }}
            arg={step - 1}
          />
          {premiumRoles.includes(name[0]) ? (
            <LogButton
              text={JsonActivityPages.JsonActivityManagement.JsonContinueButton}
              width={150}
              backgroundColor={"#59c09b"}
              func={(n) => {
                setErrorMessage("");
                setStep(n);
              }}
              arg={step + 1}
            />
          ) : (
            <LogButton
              text={JsonActivityPages.JsonActivityManagement.JsonPublishButton}
              width={150}
              backgroundColor={"#59c09b"}
              func={() => createAnActivity()}
              disabled={disableButton || !description || !howToFind} // Ajoutez cette condition
              // arg={null}
            />
          )}
        </View>
      </ScrollView>
  );
};

export default CreateActivityScreenStepThree;
