import React from "react";
import { Text, View, Image, Pressable, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components
import styles from "./StylesWeb";

// import des images
import { images } from "../../../constantes";



const VipBoardExplanation = (props) => {
  const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
  const JsonAchievementsPages = scr.JsonAchievementsPages || {};
  const Separator = () => {
    return <View style={styles.separator} />;
  };
  const SeparatorPlat = () => {
    return <View style={styles.separatorPlat} />;
  };
  const SeparatorNoir = () => {
    return <View style={styles.SeparatorNoir} />;
  };
  const navigation = useNavigation();


  return (
    <ScrollView>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>
          
        </View>
        {/**notre tablau */}
        <View style={styles.table}>
          <View style={styles.rowTab}>
            <View style={styles.cell}>
              <Text style={styles.cellTextTitle}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonPrivileges}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Image source={images.GoldStar} style={styles.imgsmall} />
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonGold}
              </Text>
            </View>
            
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Image source={images.RubyStar} style={styles.imgsmall} />
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonRuby}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Image source={images.EmeraldStar} style={styles.imgsmall} />
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonEmerald}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Image source={images.DiamandStar} style={styles.imgsmall} />
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDiamond}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonStarLevel}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>1</Text>
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>2</Text>
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>3</Text>
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>4</Text>
            </View>
          </View>

          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                 Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            
            <SeparatorNoir />
            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          
          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                "Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
               <Image source={images.SignRed} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>

          <Separator />

          

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

            <View style={styles.cell}>
            <Image source={images.CheckSign} style={styles.img} />
            </View>
            
            <SeparatorNoir />

          <View style={styles.cell}>
          <Image source={images.CheckSign} style={styles.img} />
            </View>
          </View>
          <Separator />
          
          {/* Les autres lignes du tableau vont ici */}
        </View>


    {/* gérer le bouton golden star*/}
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cellButton}>
            <Pressable style={styles.buttonGrey}   
                 onPress={() => navigation.navigate('Rewards')}
            >
                <Image source={images.GoldStar} style={styles.buttonimg} />
                <Text style={styles.buttonText}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonGoldLevel}
                </Text>
            </Pressable>
            </View>
            
            {/* gérer le bouton ruby star*/}
            <View style={styles.cellButton}>
            <Pressable style={styles.boxRuby}   
                       onPress={() => navigation.navigate('Rewards')}
                  >
                       <Image source={images.RubyStar} style={styles.buttonimg} />
                       <Text style={styles.buttonText}>
                            {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonRubyLevel}
                       </Text>
                  </Pressable>
            </View>
          </View>

       
         {/* gérer le bouton emerald star*/}
          <View style={styles.row}>
            <View style={styles.cellButton}>
            <Pressable style={styles.buttonGrey}   
                 onPress={() => navigation.navigate('Rewards')}
            >
                <Image source={images.EmeraldStar} style={styles.buttonimg} />
                <Text style={styles.buttonText}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonEmeraldLevel}
                </Text>
            </Pressable>
            </View>
            
             {/* gérer le bouton diamond star*/}
            <View style={styles.cellButton}>
            <Pressable style={styles.boxDiamond}   
                 onPress={() => navigation.navigate('Rewards')}
             >
                <Image source={images.DiamandStar} style={styles.buttonimgdiamond} />
                <Text style={styles.buttontextdiamond}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDiamondLevel}
                </Text>
            </Pressable>
            </View>

            
          </View>

          




        </View>  
      
    </ScrollView>
  );
};

export default VipBoardExplanation;
