import React from 'react';
import { Pressable, ScrollView, Text, View } from 'react-native';
import { ActivityTypesGrid_SeveralTopics } from "../../../../components/ActivityTypesGrids/ActivityTypesGrids.js";
import { CountriesGrid_SeveralFlags } from "../../../../components/CountriesGrids/CountriesGrids.js";
import MultilineFields from "../../../../components/MultilineFields/MultilineFields.js";
import { OptionButtonProfile } from "../../../../components/SelectionElements/SelectionElements.js";
import styles from "./EditProfileStepThreeCss.js";

const EditProfileStepThree = ({
    about,
    setAbout,
    topics,
    setTopics,
    languagesSpoken,
    setLanguagesSpoken,
    titleAge,
    setTitleAge,
    age,
    setAge,
    titleChildren,
    setTitleChildren,
    children,
    setChildren,
    titleTobacco,
    setTitleTobacco,
    tobacco,
    setTobacco,
    titleAlcohol,
    setTitleAlcohol,
    alcohol,
    setAlcohol,
    scr,
    sendInfoEditProfile,
    bigBtn1,
  }) => {

    const { JsonProfilePages } = scr;

    return (
        <ScrollView>
        <View id="container 3 " style={{ flex: 1, backgroundColor: "white" }}>
          {/* ----------------Titles---------------- */}
          <View id="title-1" style={styles.titleOneView}>
            <Text style={styles.title}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.public dans fr.json permet d'afficher "Informations Publiques"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.public displays "Public informations"*/}
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep3
                  .JsonPublicInformations
              }
            </Text>
          </View>
          <View id="title-2 about you" style={styles.titleTwo}>
            <Text style={[styles.bold, { fontSize: 16 }]}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.more dans fr.json permet d'afficher "Un petit message pour te présenter :*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.more displays "More about you"*/}
              {JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonMoreOnYou}
            </Text>
            {/* "more": "More about you" */}
          </View>
          {/* ----------------Fields---------------- */}
          <View id="view white square" style={{ backgroundColor: "white" }}>
            <View style={{ marginHorizontal: "2%" }}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.about dans fr.json permet d'afficher "Ma présentation"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.about displays "About you"*/}
              {/*🇫🇷 Le setter pour ce champ n'est pas défini.*/}
              {/*🇬🇧 The setter for this field is undefined*/}
              <MultilineFields
                lines={10}
                title={
                  JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAboutYou
                }
                state={about}
                setState={setAbout}
              />
            </View>
          </View>
          {/* ----------------Selects---------------- */}
          <View style={styles.editProfileStepThree}>
            <Text style={[styles.bold, { fontSize: 16 }]}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.what dans fr.json permet d'afficher "Mes activités préférées :"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.what displays "What I love to do:"*/}
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep3
                  .JsonWhatILoveToDo
              }
            </Text>
            {/*🇫🇷 Composant pour choisir ses activités preferées.*/}
            {/*🇬🇧 Component for select favorite activities*/}
            <ActivityTypesGrid_SeveralTopics
              selections={topics}
              setSelections={setTopics}
            />
          </View>
          <View id="selection language" style={{ backgroundColor: "white" }}>
            <View style={{ backgroundColor: "white", paddingHorizontal: "2%" }}>
              <Text style={[styles.bold, { fontSize: 16 }]}>
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.spoken dans fr.json permet d'afficher "Langues parlées:"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.spoken displays "Spoken languages:"*/}
                {
                  JsonProfilePages.JsonEditProfileScreen.JsonStep3
                    .JsonSpokenLanguages
                }
              </Text>
              {/*🇫🇷 Composant pour choisir les langues parlés*/}
              {/*🇬🇧 Component for select spoken languages*/}
              <CountriesGrid_SeveralFlags
                selections={languagesSpoken}
                setSelections={setLanguagesSpoken}
              />
            </View>
          </View>

          <View id="Container Age">
            <View id="View title age" style={styles.titleAgeView}>
              <Text style={[styles.bold, { fontSize: 16 }]}>
                {/* <Text id="title Age" style={styles.bold}> */}
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.age dans fr.json permet d'afficher "Age"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.age displays "Age"*/}
                {JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAge}
              </Text>
            </View>
            <View style={[styles.checkBoxContainer, { flexDirection: "row" }]}>
              {/*🇫🇷 On peut selectioner juste un button, information dans useState age*/}
              {/*🇬🇧 we can choose just one button, information in useState age*/}
              <View style={[styles.checkContainer_sub, { marginRight: 10 }]}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret
                  }
                  buttonSelected={titleAge}
                  setButtonSelected={setTitleAge}
                  index={age}
                  setIndex={setAge}
                  scr={scr}
                />
              </View>
              <View style={[styles.checkContainer_sub, { marginLeft: 10 }]}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonShow
                  }
                  buttonSelected={titleAge}
                  setButtonSelected={setTitleAge}
                  index={age}
                  setIndex={setAge}
                  scr={scr}
                />
              </View>
            </View>
          </View>

          <View id="Container Chilren" style={{ backgroundColor: "white" }}>
            <View id="View title children" style={styles.titleAgeView}>
              <Text style={[styles.bold, { fontSize: 16 }]}>
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.children dans fr.json permet d'afficher "Enfants"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.children displays "Children"*/}
                {JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonChildren}
              </Text>
            </View>
            <View
              style={[
                styles.checkBoxContainer,
                { justifyContent: "space-between" },
              ]}
            >
              {/*🇫🇷 On peut selectioner just un button, information dans useState children*/}
              {/*🇬🇧 we can choose just one button, information in useState children*/}
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret
                  }
                  buttonSelected={titleChildren}
                  setButtonSelected={setTitleChildren}
                  index={children}
                  setIndex={setChildren}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes
                  }
                  buttonSelected={titleChildren}
                  setButtonSelected={setTitleChildren}
                  index={children}
                  setIndex={setChildren}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
                  }
                  buttonSelected={titleChildren}
                  setButtonSelected={setTitleChildren}
                  index={children}
                  setIndex={setChildren}
                  scr={scr}
                />
              </View>
            </View>
          </View>
          <View id="Container Tobacco ">
            <View id="View title Tobacco" style={styles.titleAgeView}>
              <Text id="title Tobacco" style={styles.bold}>
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.tobacco dans fr.json permet d'afficher "Tabac"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.tobacco displays "Tobacco"*/}
                {JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonTobacco}
              </Text>
            </View>
            <View
              style={[
                styles.checkBoxContainer,
                { justifyContent: "space-between" },
              ]}
            >
              {/*🇫🇷 On peut selectioner just un button, information dans useState tobacco*/}
              {/*🇬🇧 we can choose just one button, information in useState tobacco*/}
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret
                  }
                  buttonSelected={titleTobacco}
                  setButtonSelected={setTitleTobacco}
                  index={tobacco}
                  setIndex={setTobacco}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3
                      .JsonSometimes
                  }
                  buttonSelected={titleTobacco}
                  setButtonSelected={setTitleTobacco}
                  index={tobacco}
                  setIndex={setTobacco}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
                  }
                  buttonSelected={titleTobacco}
                  setButtonSelected={setTitleTobacco}
                  index={tobacco}
                  setIndex={setTobacco}
                  scr={scr}
                />
              </View>
            </View>
          </View>
          <View id="Container Alcohol">
            <View style={styles.titleAgeView}>
              <Text style={styles.bold}>
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.alcohol dans fr.json permet d'afficher "Alcool"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.alcohol displays "Alcohol"*/}
                {JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAlcohol}
              </Text>
            </View>
            <View
              style={[
                styles.checkBoxContainer,
                { justifyContent: "space-between" },
              ]}
            >
              {/*🇫🇷 On peut selectioner just un button, information dans useState Alcool*/}
              {/*🇬🇧 we can choose just one button, information in useState Alcohol*/}
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret
                  }
                  buttonSelected={titleAlcohol}
                  setButtonSelected={setTitleAlcohol}
                  index={alcohol}
                  setIndex={setAlcohol}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3
                      .JsonSometimes
                  }
                  buttonSelected={titleAlcohol}
                  setButtonSelected={setTitleAlcohol}
                  index={alcohol}
                  setIndex={setAlcohol}
                  scr={scr}
                />
              </View>
              <View style={styles.checkContainer_sub}>
                <OptionButtonProfile
                  title={
                    JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
                  }
                  buttonSelected={titleAlcohol}
                  setButtonSelected={setTitleAlcohol}
                  index={alcohol}
                  setIndex={setAlcohol}
                  scr={scr}
                />
              </View>
            </View>
          </View>
          {/* ----------------ValidationButtons---------------- */}
          <View id="View submit button" style={styles.Valide}>
            <Pressable
              style={bigBtn1.button}
              onPress={() => sendInfoEditProfile()}
            >
              <Text style={styles.btnText}>
                {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.submit dans fr.json permet d'afficher "Sauvegarder"*/}
                {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep3.submit displays "Submit"*/}
                {JsonProfilePages.JsonEditProfileScreen.JsonSubmitButton}
              </Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    )
}

export default EditProfileStepThree