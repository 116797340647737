//🇫🇷 Ce fichier contient le composant pour afficher les membres sur une carte (FrameFigma22A)
//🇬🇧 This file contains the component to display members in a card (FrameFigma22A)

import React, { useState } from "react";
import { View, Text, Pressable, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";

// Components
import styles from "./MembersSquareCss";

// Assets
// import MapLocation from "../assets/images/map-location.svg";
// import LikeButton from "../assets/images/like-on.svg";
// import LoveActivated from "../assets/images/heartRed.svg";
// import LoveDeactivated from "../assets/images/heartGrey.svg";

export default function MembersSquare({ name, city, avatar, member, scr }) {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const navigation = useNavigation();

  const [liked, setLiked] = useState(false);
  function calculateAge(birthday) {
    if (!birthday) {
      return null;
    }

    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();

    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
  const age = calculateAge(member.birthday);

  return (
    <Pressable
      style={styles.container}
      onPress={() => {
        navigation.navigate("Profile", {
          user: member,
          scr: scr,
        });
      }}
    >
      <ImageBackground source={{ uri: avatar }} style={{ flex: 1 }}>
        <LinearGradient
          colors={[
            "rgba(255, 255, 255,0.05)",
            "rgba(255, 255, 255,0.2)",
            "#0C1D15",
          ]}
          style={styles.gradient}
        />
        <View style={styles.infosContainer}>
          <View style={styles.mainInfos}>
            <Text style={styles.name}>{name}</Text>
            <View style={styles.cityContainer}>
              {age === null || isNaN(age) || age === 0 ? null : (
                <Text style={[styles.secondaryInfos, { marginRight: 15 }]}>
                  {age} years
                </Text>
              )}
              <Text style={styles.secondaryInfos}>
                {city ? city.split(",")[0] : " "}{" "}
                {/* en cas de ville non renseignée, on affiche "City not available"*/}
              </Text>
            </View>
          </View>
          {/* les boutons de like */}
          {/* <Pressable
            onPress={() => setLiked(!liked)}
            style={styles.heartButton}>
            {liked ? (
              <Image source={LoveActivated} style={styles.image} />
            ) : (
              <Image source={LoveDeactivated} style={styles.image} />
            )}
          </Pressable> */}
        </View>
      </ImageBackground>
    </Pressable>
  );
}
