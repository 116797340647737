/*
🇫🇷 Import des langues a partir du dossier assets :
Ordre alphabetique des langues 
*/
/*
🇬🇧 Languages import from the assets folder:
Alphabetical order of languages
*/

import ar from "../../../../front-mobile/src/assets/json/Ar.json";
import bg from "../../../../front-mobile/src/assets/json/Bg.json";
import bs from "../../../../front-mobile/src/assets/json/Bs.json";
import cs from "../../../../front-mobile/src/assets/json/Cs.json";
import da from "../../../../front-mobile/src/assets/json/Da.json";
import de from "../../../../front-mobile/src/assets/json/De.json";
import el from "../../../../front-mobile/src/assets/json/El.json";
import en from "../../../../front-mobile/src/assets/json/en.json";
import es from "../../../../front-mobile/src/assets/json/Es.json";
import et from "../../../../front-mobile/src/assets/json/Et.json";
import fi from "../../../../front-mobile/src/assets/json/Fi.json";
import fr from "../../../../front-mobile/src/assets/json/Fr.json";
import ga from "../../../../front-mobile/src/assets/json/Ga.json";
import gd from "../../../../front-mobile/src/assets/json/Gd.json";
import he from "../../../../front-mobile/src/assets/json/He.json";
import hi from "../../../../front-mobile/src/assets/json/Hi.json";
import hr from "../../../../front-mobile/src/assets/json/Hr.json";
import hu from "../../../../front-mobile/src/assets/json/Hu.json";
import is from "../../../../front-mobile/src/assets/json/Is.json";
import it from "../../../../front-mobile/src/assets/json/It.json";
import ja from "../../../../front-mobile/src/assets/json/Ja.json";
import lt from "../../../../front-mobile/src/assets/json/Lt.json";
import lv from "../../../../front-mobile/src/assets/json/Lv.json";
import mg from "../../../../front-mobile/src/assets/json/Mg.json";
import nl from "../../../../front-mobile/src/assets/json/Nl.json";
import no from "../../../../front-mobile/src/assets/json/No.json";
import pl from "../../../../front-mobile/src/assets/json/Pl.json";
import pt from "../../../../front-mobile/src/assets/json/Pt.json";
import ro from "../../../../front-mobile/src/assets/json/Ro.json";
import ru from "../../../../front-mobile/src/assets/json/Ru.json";
import sk from "../../../../front-mobile/src/assets/json/Sk.json";
import sl from "../../../../front-mobile/src/assets/json/Sl.json";
import sr from "../../../../front-mobile/src/assets/json/Sr.json";
import sv from "../../../../front-mobile/src/assets/json/Sv.json";
import uk from "../../../../front-mobile/src/assets/json/Uk.json";
import zh from "../../../../front-mobile/src/assets/json/Zh.json";

export const getLangue = (state = en, action) =>{
    //🇫🇷 Gestion de la langue par défaut en anglais
    //🇬🇧 Default language management in English
    switch (action.type) {
        case "English": 
            return (state = en);
        case "French": 
            return (state = fr);
        // Gestion des autres langues :
        case "Arabic": 
            return (state = ar);
        case "Bulgarian": 
            return (state = bg);
        case  "Bosnia":
            return (state = bs);
        case "Czech":
            return (state = cs);
        case "Danish":
            return (state = da);
        case "German":
            return (state =de);
        case "Spanish":
            return (state =es);
        case "Estonian":
            return (state= et);
        case "Finnish":
            return (state = fi);
        case "Ireland":
            return (state = ga);
        case "Scotland":
            return (state = gd);
        case "Greek":
            return (state = el); // anciennement appelé grk / before called grk
        case "Israeli": //mod Hebrew
            return (state = he);
        case "Hindi":
            return (state = hi);
        case "Croatian":
            return (state = hr);
        case "Hungarian":
            return (state = hu);
        case "Iceland":
            return (state =is);
        case "Italian":
            return (state =it);
        case "Japanese":
            return (state =ja);
        case "Lithuanian":
            return (state =lt);
        case "Latvian":
            return (state =lv);
        case "Madagascar":
            return (state = mg);
        case "Dutch" :
            return (state = nl);
        case "Norwegian":
            return (state = no);
        case "Polish":
            return (state =pl);
        case "Portuguese" :
            return (state =pt);
        case "Romanian" :
            return (state =ro);
        case "Russian":
            return (state = ru);
        case "Slovakian":
            return (state = sk);
        case "Slovenia" :
            return (state =sl);
        case "Serbian" :
            return (state =sr);
        case "Swedish" :
            return (state =sv);
        case "Ukrainian" :
                return (state =uk);
        case "Chinese" :
            return (state = zh);

        //Gestion de la langue des dates
        case "UPDATE_LANGuE":
            return action.payload;
        default :
            return state;
    }
};
