import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";

// Components
import LikedScreen from "./LikedScreen/LikedScreen";
import OrganizedScreen from "./OrganizedScreen/OrganizedScreen";
import PastScreen from "./PastScreen/PastScreen";
import UpcomingScreen from "./UpcomingScreen/UpcomingScreen";

const Tab = createMaterialTopTabNavigator();

const CustomTabBarButton = ({ label, accessibilityState, onPress }) => {
  const isSelected = accessibilityState.selected;
  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.button,
        isSelected ? styles.buttonSelected : styles.buttonUnselected,
      ]}
    >
      <Text style={isSelected ? styles.activeLabel : styles.inactiveLabel}>
        {label}
      </Text>
    </Pressable>
  );
};

const MyActivitiesScreen = ({ user, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  // console.log("scr:", scr);
  const { JsonTopNav } = scr;

  return (
    <Tab.Navigator
      tabBar={(props) => (
        <View style={styles.tabBar}>
          {props.state.routes.map((route, index) => (
            <View key={route.key} style={styles.tabBarButtonContainer}>
              <View style={styles.separator} />
              <React.Fragment key={route.key}>
                {index > 0 && <View style={styles.separator} />}{" "}
                {/* Séparateur */}
                <CustomTabBarButton
                  label={route.name}
                  accessibilityState={
                    props.state.routes[props.state.index].key === route.key
                      ? { selected: true }
                      : { selected: false }
                  }
                  onPress={() => props.navigation.navigate(route.name)}
                />
              </React.Fragment>
            </View>
          ))}
        </View>
      )}
    >
      <Tab.Screen
        name={JsonTopNav.JsonMyActivitiesThirdLineUpcoming}
        children={() => <UpcomingScreen user={user} scr={scr} />}
      />
      <Tab.Screen
        name={JsonTopNav.JsonMyActivitiesThirdLinePast}
        children={() => <PastScreen user={user} scr={scr} />}
      />
      <Tab.Screen
        name={JsonTopNav.JsonMyActivitiesThirdLineLiked}
        children={() => <LikedScreen user={user} scr={scr} />}
      />
      <Tab.Screen
        name={JsonTopNav.JsonMyActivitiesThirdLineOrganized}
        children={() => <OrganizedScreen user={user} scr={scr} />}
      />
    </Tab.Navigator>
  );
};

const styles = StyleSheet.create({
  tabBar: {
    flexDirection: "row",
    backgroundColor: "#59C09B",
    marginTop: 20,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#59C09B",
  },
  tabBarButtonContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    flex: 1, // Ensure that buttons take equal width
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRightWidth: 1,
    borderRightColor: "white",
  },
  buttonSelected: {
    backgroundColor: "#59C09B",
  },
  buttonUnselected: {
    backgroundColor: "white",
  },
  activeLabel: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
  inactiveLabel: {
    color: "#59C09B",
    fontSize: 12,
    fontWeight: "bold",
  },
  separator: {
    width: 1, // Largeur du séparateur
    backgroundColor: "#59C09B", // Couleur du séparateur
  },
});

export default MyActivitiesScreen;
