// ENG - Here is the array that fills the lists of round flags. Fell free to commentarize flags if they're unnecessary or add some (the pattern is at the end of the array).  - ENG
// FR - Voilà Le tableau qui remplit les listes de drapeaux arrcountriesListCircleFlagsondis. n4hésitez pas à commentariser les drapeaux non nécessaires ou en ajouter (le patron est à la fin du tableau) - FR

import Arabic from "./flags-svg-round/arab-league.svg";
import Bosnia from "./flags-svg-round/bosnia.svg";
import Bulgarian from "./flags-svg-round/bulgaria.svg";
import Chinese from "./flags-svg-round/china.svg";
import Croatian from "./flags-svg-round/croatia.svg";
import Czech from "./flags-svg-round/czech-republic.svg";
import Danish from "./flags-svg-round/denmark.svg";
import English from "./flags-svg-round/england.svg";
import Estonian from "./flags-svg-round/estonia.svg";
import Finnish from "./flags-svg-round/finland.svg";
import France from "./flags-svg-round/france.svg";
import German from "./flags-svg-round/germany.svg";
import Greek from "./flags-svg-round/greece.svg";
import Hungarian from "./flags-svg-round/hungary.svg";
import Iceland from "./flags-svg-round/iceland.svg";
import Hindi from "./flags-svg-round/india.svg";
import Ireland from "./flags-svg-round/ireland.svg";
import Israeli from "./flags-svg-round/israel.svg";
import Italian from "./flags-svg-round/italy.svg";
import Japanese from "./flags-svg-round/japan.svg";
import Latvian from "./flags-svg-round/latvia.svg";
import Lithuanian from "./flags-svg-round/lithuania.svg";
import Madagascar from "./flags-svg-round/madagascar.svg";
import Dutch from "./flags-svg-round/netherlands.svg";
import Norwegian from "./flags-svg-round/norway.svg";
import Polish from "./flags-svg-round/poland.svg";
import Portuguese from "./flags-svg-round/portugal.svg";
import Romanian from "./flags-svg-round/romania.svg";
import Russian from "./flags-svg-round/russia.svg";
import Scotland from "./flags-svg-round/scotland.svg";
import Serbian from "./flags-svg-round/serbia.svg";
import Slovakian from "./flags-svg-round/slovakia.svg";
import Slovenia from "./flags-svg-round/slovenia.svg";
import Spanish from "./flags-svg-round/spain.svg";
import Swedish from "./flags-svg-round/sweden.svg";
import Ukrainian from "./flags-svg-round/ukraine.svg";


import Json from "./../../../front-mobile/src/assets/json/en.json";
const { JsonLanguageIso639_1 } = Json;

export const countriesListCircleFlags = [
  {
    language: JsonLanguageIso639_1.JsonFr,
    flag: <img src={France}/>,
    bigram: "FR",
 },
  {
    language: JsonLanguageIso639_1.JsonEn,
    flag: <img src={English}/>,
    bigram: "EN",
 },
  {
    language: JsonLanguageIso639_1.JsonEs,
    flag: <img src={Spanish}/>,
    bigram: "ES",
 },
  {
    language: JsonLanguageIso639_1.JsonPt,
    flag: <img src={Portuguese}/>,
    bigram: "PT",
 },
  {
    language: JsonLanguageIso639_1.JsonDe,
    flag: <img src={German}/>,
    bigram: "DE",
 },
  {
    language: JsonLanguageIso639_1.JsonIt,
    flag: <img src={Italian}/>,
    bigram: "IT",
 },
  {
    language: JsonLanguageIso639_1.JsonRu,
    flag: <img src={Russian}/>,
    bigram: "RU",
 },
  {
    language: JsonLanguageIso639_1.JsonZh,
    flag: <img src={Chinese}/>,
    bigram: "ZH",
 },
  {
    language: JsonLanguageIso639_1.JsonBg,
    flag: <img src={Bulgarian}/>,
    bigram: "BG",
 },
  {
    language: JsonLanguageIso639_1.JsonHi,
    flag: <img src={Hindi}/>,
    bigram: "IN",
 },
  {
    language: JsonLanguageIso639_1.JsonJa,
    flag: <img src={Japanese}/>,
    bigram: "JA",
 },
  {
    language: JsonLanguageIso639_1.JsonHe,
    flag: <img src={Israeli}/>,
    bigram: "HE",
 },
  {
    language: JsonLanguageIso639_1.JsonAr,
    flag: <img src={Arabic}/>,
    bigram: "AR",
 },
  {
    language: JsonLanguageIso639_1.JsonHu,
    flag: <img src={Hungarian}/>,
    bigram: "HU",
 },
  {
    language: JsonLanguageIso639_1.JsonPl,
    flag: <img src={Polish}/>,
    bigram: "PL",
 },
  {
    language: JsonLanguageIso639_1.JsonRo,
    flag: <img src={Romanian}/>,
    bigram: "RO",
 },
  {
    language: JsonLanguageIso639_1.JsonEl,
    flag: <img src={Greek}/>,
    bigram: "EL",
 },
  {
    language: JsonLanguageIso639_1.JsonHr,
    flag: <img src={Croatian}/>,
    bigram: "HR",
 },
  {
    language: JsonLanguageIso639_1.JsonCs,
    flag: <img src={Czech}/>,
    bigram: "CS",
 },
  {
    language: JsonLanguageIso639_1.JsonDa,
    flag: <img src={Danish}/>,
    bigram: "DA",
 },
  {
    language: JsonLanguageIso639_1.JsonEt,
    flag: <img src={Estonian}/>,
    bigram: "ET",
 },
  {
    language: JsonLanguageIso639_1.JsonFi,
    flag: <img src={Finnish}/>,
    bigram: "FI",
 },
  {
    language: JsonLanguageIso639_1.JsonLv,
    flag: <img src={Latvian}/>,
    bigram: "LV",
 },
  {
    language: JsonLanguageIso639_1.JsonLt,
    flag: <img src={Lithuanian}/>,
    bigram: "LT",
 },
  {
    language: JsonLanguageIso639_1.JsonNl,
    flag: <img src={Dutch}/>,
    bigram: "NL",
 },
  {
    language: JsonLanguageIso639_1.JsonNo,
    flag: <img src={Norwegian}/>,
    bigram: "NO",
 },
  {
    language: JsonLanguageIso639_1.JsonSr,
    flag: <img src={Serbian}/>,
    bigram: "SR",
 },
  {
    language: JsonLanguageIso639_1.JsonSk,
    flag: <img src={Slovakian}/>,
    bigram: "SK",
 },
  {
    language: JsonLanguageIso639_1.JsonSv,
    flag: <img src={Swedish}/>,
    bigram: "SV",
 },
  {
    language: JsonLanguageIso639_1.JsonUk,
    flag: <img src={Ukrainian}/>,
    bigram: "UK",
 },
 {
    language: JsonLanguageIso639_1.JsonMg,
    flag: <img src={Madagascar}/>,
    bigram: "MG",
 },
  {
    language: JsonLanguageIso639_1.JsonGd,
    flag: <img src={Scotland}/>,
    bigram: "GD",
  },
  {
    language: JsonLanguageIso639_1.JsonIs,
    flag: <img src={Iceland}/>,
    bigram:"IS",
  },
  {
    language: JsonLanguageIso639_1.JsonGa,
    flag: <img src={Ireland}/>,
    bigram: "GA",
  },
  {
    language: JsonLanguageIso639_1.JsonBs,
    flag: <img src={Bosnia}/>,
    bigram: "BS",
  },
  {
    language: JsonLanguageIso639_1.JsonSl,
    flag: <img src={Slovenia}/>,
    bigram:"SL",
  },

  //   {
  //     language: "",
  //     flag: <img src={Flag}/>,
  //  },
];