import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        backgroundColor: "white",
        flex: 1,
      },
      firstRow: {
        alignItems: "center",
      },
    centeredErrorMessage: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    proOrPerso: {
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 15,
    },
    titleFields: {
        justifyContent: "flex-start",
        top: 10,
        left: 15,
      },
    titleFields_text: {
        fontSize: 16,
        fontStyle: "italic",
        color: "#C4C4C4",
    },
    fields: {
        alignItems: "center",
        marginVertical: 2,
      },
    testEcarteur: {
        height: 30,
    },
    savOrConButtons: {
        marginVertical: 30,
        flexDirection: "row",
        //justifyContent: "space-around",
        justifyContent: "space-between",
        // shadowColor: "grey",
        // shadowOffset: { width: 0, height: 3 },
        // shadowOpacity: 1,
    },  
    bigBtn: {
        backgroundColor: "#59c09b",
        height: 50,
        width: "40%",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        marginHorizontal: 10,
      },
      btnText: {
        color: "white",
        fontSize: 22,
        lineHeight: 33,
        fontWeight: "700",
      },
      disabledButton: {
        backgroundColor: "grey",
        borderColor: "#ccc",
      },
});

export default styles;