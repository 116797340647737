import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text, View } from "react-native";

//Styles
import styles from "./ProfileActivitiesCss"

// Backend
import { hostname } from "../../../../../../front-mobile/backendconnect/hostname";

// Import des images
import { images } from "../../../../constantes";

function ProfileActivities({ userId }) {
  const navigation = useNavigation();
  const [activities, setActivities] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");

  // Fonction pour récupérer les activités depuis l'API
  const fetchActivities = async () => {
    try {
      const userToken = await AsyncStorage.getItem("userToken");
      if (!userToken || !userId) {
        setError("Vous devez vous connecter pour voir la liste des activités.");
        return;
      }

      // Effectuer une requête à l'API pour récupérer les activités de l'utilisateur
      const response = await axios.get(
        `${hostname}/api/v1/user/${userId}/activities`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const allActivities = response.data;

      if (!allActivities || allActivities.length === 0) {
        setError("Aucune activité trouvée pour cet utilisateur.");
        return;
      }

      // Trier les activités par date
      const sortedActivities = allActivities.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      // Filtrer les activités futures
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const futureActivities = sortedActivities.filter((activity) => {
        const activityDate = new Date(activity.date);
        return activityDate >= currentDate;
      });

      // Mettre à jour l'état avec les 5 prochaines activités futures
      setActivities(futureActivities.slice(0, 5));
    } catch (err) {
      setError("Erreur lors de la récupération des activités.");
      console.error(err);
    } finally {
      setLoadingActivities(false);
    }
  };

  // Fonction pour récupérer les informations de l'utilisateur depuis l'API
  const fetchUser = async () => {
    try {
      // Récupérer le jeton d'utilisateur depuis le stockage local
      const userToken = await AsyncStorage.getItem("userToken");
      if (!userToken || !userId) {
        setError(
          "Vous devez vous connecter pour voir les informations de l'utilisateur."
        );
        return;
      }

      // Effectuer une requête à l'API pour récupérer les informations de l'utilisateur
      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const userData = response.data.user;
      if (!userData) {
        setError("Aucune information d'utilisateur trouvée.");
        return;
      }

      // Mettre à jour l'état avec le prénom de l'utilisateur
      setFirstName(userData.firstName);
    } catch (err) {
      setError(
        "Erreur lors de la récupération des informations de l'utilisateur."
      );
      console.error("Erreur lors de la récupération de l'utilisateur:", err);
    }
  };

  // Utiliser useEffect pour exécuter fetchActivities et fetchUser lorsqu'il y a un changement dans userId
  useEffect(() => {
    fetchActivities();
    fetchUser();
  }, [userId]);

  // Fonction pour naviguer vers les détails de l'activité
  const navigateToActivityDetail = (activity) => {
    if (
      activity &&
      activity._id &&
      JsonActivityPages.JsonActivityScreen.JsonAddress
    ) {
      navigation.navigate("Activity", {
        activity: activity,
      });
    } else {
      console.error(
        "Les données de l'activité sont incomplètes ou manquantes."
      );
    }
  };

  // Si les activités sont en cours de chargement, afficher un indicateur de chargement
  if (loadingActivities) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  // Afficher la liste des activités
  return (
    <View style={{ flex: 1, paddingTop: 20, paddingHorizontal: 10 }}>
      <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 20 }}>
        Les 5 prochaines activités de {firstName ? firstName : userId}
      </Text>

      <View
        style={{
          flexDirection: "column",
          borderWidth: 1,
          borderColor: "#000",
          marginBottom: 10,
        }}
      >
        {/* En-tête du tableau */}
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            backgroundColor: "#f2f2f2",
          }}
        >
          <Text
            style={{
              ... styles.column,
              width: 100,
              paddingTop: 10,
              background: "#505050",
            }}
          >
            <img src={images.calendar} />
          </Text>
          <View
            style={{
              ...styles.column,
              flex: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#797B7A",
            }}
          >
            <Text style={{ marginRight: 5, fontWeight: "bold", color: "#fff" }}>
              Activité
            </Text>
            <img src={images.calendarOn} />
          </View>
          <Text style={{ ...styles.column, width: 100, background: "#505050" }}>
            <img src={images.members} />
          </Text>
          <View
            style={{
              ...styles.column,
              width: 100,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 5,
              paddingRight: 5,
              background: "#505050",
              color: "#fff",
            }}
          >
            Orga
          </View>
        </View>

        {/* Liste des activités */}
        {activities.map((activity, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderColor: "#000",
              backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
            }}
          >
            {/* Afficher la date de l'activité */}
            <Text style={{ ...styles.column, width: 100 }}>
              {new Date(activity.date).toLocaleDateString()}
            </Text>
            {/* Afficher le nom de l'activité */}
            <View
              style={{
                ...styles.column,
                flex: 2,
                flexDirection: "row",
                alignItems: "flex-end",
                color: "#59C19C",
                textWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              <Text>
                {activity.title.length > 17
                  ? `${activity.title}`
                  : activity.title}
              </Text>
            </View>
            {/* Afficher le nombre de participants */}
            <Text style={{ ...styles.column, width: 100 }}>
              {activity.attendees.length}
            </Text>
            {/* Afficher le nom de l'organisateur */}
            <Text
              style={{
                ...styles.column,
                width: 100,
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {activity.author && activity.author.firstName
  ? (activity.author.firstName.length > 10
      ? `${activity.author.firstName.substring(0, 10)}...`
      : activity.author.firstName)
  : "Aucun organisateur"}

            </Text>
          </View>
        ))}
      </View>
    </View>
  );
}

export default ProfileActivities;
