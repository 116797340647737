//🇫🇷 Ce fichier contient le bouton pour passer à la page suivante pour les pages de connexion et de création de compte (FrameFigma3-FrameFigma4)
//🇬🇧 This file contains the button to move to the next page for login and account creation pages (FrameFigma3-FrameFigma4)

import React from "react";
import { Pressable, Text } from "react-native";

// Components
import styles from "./LogButtonsCss";

export default function LogButton({
  text,
  width,
  navigate,
  func,
  path,
  setPressed,
  arg,
  disabled,
  backgroundColor,
  dataSave,
  token,
}) {
  //console.log(path);
  return (
    <Pressable
      onPress={() => {
        if (!disabled) {
          if (setPressed) {
            setPressed(true);
          }
          if (func) {
            func(arg);
          }
          if (path && !token) {
            navigate(path);
          }
          if (path && token) {
            navigate(path, { token: token });
          }
          if (dataSave) {
            dataSave(); //(fr) Fonction permettant de mettre à jour les données des différents étapes de la création du profil
          }
        }
      }}
      style={[
        styles.btn,
        { width: width, backgroundColor: disabled ? "grey" : backgroundColor },
      ]}
      disabled={disabled}
    >
      <Text style={styles.btnText}>{text}</Text>
    </Pressable>
  );
}
