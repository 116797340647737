/*
🇫🇷 Ce composant est la mise en page que tous les slides de aboutUsScreen partagent.
Pour afficher les bonnes données, le composant s'aide de l'id reçu en prop.
Ensuite, avec la méthode find, il compare tous les id des objets de l'array avec l'id reçu et initialise la variable conceptContent.
Pour toute réorganisation du swiper, il suffit soit de réorganiser le json, soit de changer l'ordre des AboutUsSlides dans la page AboutUsScreen.
Faîtes attention aux nom des images, le chiffre qu'il y a dedans doit être le meme que l'id de l'objet contenant les données correspondantes (FrameFigma80A-FrameFigma80F)
*/
/*
🇬🇧 This component is the layout that all the aboutUs slides share.
In order to display the right data, the component uses the "id" received as a prop.
Then, with the find method, it compares all the objects ids of the array with the received id and initializes the conceptContent variable.
For any swiper reorganization, you need either to reoganize the json, or change the AboutUsSlides order in the AboutUsScreen page.
Pay attention to the images names, the number in it has to be the same as id of the object that contains the corresponding data. (FrameFigma80A-FrameFigma80F)
*/

import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  Pressable,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// Components
import styles from "./ConceptSlideCss";

//Images
import { images } from "../../constantes";

// Assets

//Change the id value and set the name of the activity type instead of a number, and change it in the images name as well
const ConceptSlide = (props, user) => {
  // console.log("Props for ConceptSlide:", props);

  const { JsonConceptScreen } = props.scr;

  const [localUser, setLocalUser] = useState({});
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const hideHeader = () => {
    navigation.setOptions({
      headerShown: true,
      drawerLabel: () => null,
    });
  };
  const navigation = useNavigation();

  const screenHeight = Dimensions.get("screen").height;
  // console.log("ConceptSlide:");

  useEffect(() => {
    // console.log("ConceptSlide::useEffect!! user pt");
    AsyncStorage.getItem("user").then((suser) => {
      if (suser != "undefined") {
        setLocalUser(JSON.parse(suser));
        // console.log(
        //   "ConceptSlide::useEffect!! user pt",
        //   user,
        //   JSON.parse(suser)
        // );
        //console.log(suser);
      } else {
      }
    });
  }, []);

  const handleNavigationFromRole = () => {
    const authorized = ["admin", "moderator", "user"];
    navigation.navigate("Create Activity", {
      user: localUser,
    });
  };

  const SetConceptTitle = () => {
    {
      /*🇫🇷 Affichage du titre de la première page de concept */
    }
    {
      /*🇬🇧 Display of the title of the first concept page */
    }
    if (props.id === 1) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageOneTitle}
          </Text>
        </View>
      );
    }

    {
      /*🇫🇷 Affichage du titre de la deuxième page de concept */
    }
    {
      /*🇬🇧 Display of the title of the second concept page */
    }
    if (props.id === 2) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageTwoTitle}
          </Text>
        </View>
      );
    }

    {
      /*🇫🇷 Affichage du titre de la troisième page de concept */
    }
    {
      /*🇬🇧 Display of the title of the third concept page */
    }
    if (props.id === 3) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageThreeTitle}
          </Text>
        </View>
      );
    }

    {
      /*🇫🇷 Affichage du titre de la quatrième page de concept */
    }
    {
      /*🇬🇧 Display of the title of the fourth concept page */
    }
    if (props.id === 4) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageFourTitle}
          </Text>
        </View>
      );
    }

    {
      /*🇫🇷 Affichage du titre de la cinquième page de concept */
    }
    {
      /*🇬🇧 Display of the title of the fifth concept page */
    }
    if (props.id === 5) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageFiveTitle}
          </Text>
        </View>
      );
    }

    {
      /*🇫🇷 Affichage du titre de la sixième page de concept */
    }
    {
      /*🇬🇧 Display of the title of the sixth concept page */
    }
    if (props.id === 6) {
      return (
        <View style={styles.titleContainer}>
          <Text style={[styles.title]}>
            {JsonConceptScreen.JsonConceptPageSixTitle}
          </Text>
        </View>
      );
    }
  };

  const SetConceptDescription = () => {
    {
      /*🇫🇷 Affichage de la description de la première page de concept */
    }
    {
      /*🇬🇧 Display of the description of the first concept page */
    }
    if (props.id === 1) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageOneDescription}
        </Text>
      );
    }

    {
      /*🇫🇷 Affichage de la description de la deuxième page de concept */
    }
    {
      /*🇬🇧 Display of the description of the second concept page */
    }
    if (props.id === 2) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageTwoDescription}
        </Text>
      );
    }

    {
      /*🇫🇷 Affichage de la description de la troisième page de concept */
    }
    {
      /*🇬🇧 Display of the description of the third concept page */
    }
    if (props.id === 3) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageThreeDescription}
        </Text>
      );
    }

    {
      /*🇫🇷 Affichage de la description de la quatrième page de concept */
    }
    {
      /*🇬🇧 Display of the description of the fourth concept page */
    }
    if (props.id === 4) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageFourDescription}
        </Text>
      );
    }

    {
      /*🇫🇷 Affichage de la description de la cinquième page de concept */
    }
    {
      /*🇬🇧 Display of the description of the fifth concept page */
    }
    if (props.id === 5) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageFiveDescription}
        </Text>
      );
    }

    {
      /*🇫🇷 Affichage de la description de la sixième page de concept */
    }
    {
      /*🇬🇧 Display of the description of the sixth concept page */
    }
    if (props.id === 6) {
      return (
        <Text
          style={[
            styles.txt,
            {
              fontSize: screenHeight <= 750 ? 15 : 17,
              lineHeight: screenHeight <= 700 ? 15 : 30,
              marginBottom: screenHeight <= 700 ? 10 : "2%",
            },
          ]}
        >
          {JsonConceptScreen.JsonConceptPageSixDescription}
        </Text>
      );
    }
  };

  return (
    <ScrollView style={styles.scrollView}>
      <View
        style={{
          position: "relative",
          height: screenHeight <= 750 ? "30%" : "30%",
          maxHeight: 450,
        }}
      >
        {/*🇫🇷 Affichage du logo de Socializus */}
        {/*🇬🇧 Display of the socializus logo */}
        <Image source={images.LogoCS} style={styles.logo} />

        {/*🇫🇷 Affichage de l'image de la page de concept en fonction de l'id */}
        {/*🇬🇧 Display of the image of the concept page based on id */}
        <Image
          source={require(`../../assets/images/concept_images/AboutUs${props.id}.jpg`)}
          resizeMode={"cover"}
          style={styles.conceptImage}
        />
      </View>

      <View
        style={[
          styles.navigationContainer,
          { marginTop: screenHeight <= 700 ? 105 : 100 },
        ]}
      >
        {/*🇫🇷 Affichage du bouton de gauche pour naviguer vers la page de concept précédente */}
        {/*🇬🇧 Display of the left button to navigate to the previous page of concept */}
        <Pressable onPress={props.prevSlide} style={styles.iconButton}>
          <MaterialCommunityIcons
            name="chevron-left-circle"
            size={40}
            color="#59b09c"
          />
        </Pressable>

        {/*🇫🇷 Affichage du titre des pages de concept */}
        {/*🇬🇧 Display of title of all concept page */}
        <SetConceptTitle />

        {/*🇫🇷 Affichage du bouton de droite pour naviguer vers la page de concept suivante */}
        {/*🇬🇧 Display of the left button to navigate to the next page of concept */}
        <Pressable onPress={props.nextSlide} style={styles.iconButton}>
          <MaterialCommunityIcons
            name="chevron-right-circle"
            size={40}
            color="#59b09c"
          />
        </Pressable>
      </View>

      {/*🇫🇷 Affichage de la description des pages de concept */}
      {/*🇬🇧 Display of description of all concept page */}
      <SetConceptDescription />
      {/* <Text
        style={[
          styles.txt,
          {
            fontSize: screenHeight <= 750 ? 15 : 17,
            lineHeight: screenHeight <= 700 ? 15 : 30,
            marginBottom: screenHeight <= 700 ? 10 : "2%",
          },
        ]}
      >
        {conceptContent?.aboutDescription}
      </Text> */}

      <View style={styles.buttonContainer}>
        {/*🇫🇷 Affichage du bouton pour naviguer vers la page d'activité */}
        {/*🇬🇧 Display of the button to navigate to the activity page */}
        <Pressable
          style={styles.button}
          onPress={() => navigation.navigate("Activities")}
        >
          <Text style={styles.buttonText}>
            {JsonConceptScreen.JsonParticipateButton}
          </Text>
        </Pressable>

        {/*🇫🇷 Affichage du bouton pour naviguer vers la page de creation d'activité */}
        {/*🇬🇧 Display of the button to navigate to the activity creation page */}
        <Pressable
          style={styles.button}
          onPress={() => handleNavigationFromRole()}
        >
          <Text style={styles.buttonText}>
            {JsonConceptScreen.JsonShareButton}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};
export default ConceptSlide;
