//🇫🇷 Ce fichier gère la page où on rentre notre mot de passe lors du login/register si l'email rentré est un email qui existe dans la base de donnée (FrameFigma3)
//🇬🇧 This file manages the page where we enter our password during login/register if the email entered is an email that exists in the database (FrameFigma3)

import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  Linking,
  ScrollView,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import axios from "axios"; //🇫🇷 Importation de la librairie axios pour les requêtes API/ (gb) importing axios library for API requests
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./PasswordScreenExistingEmailCss.js";
import Fields from "../../../components/Fields/Fields.js";

const PasswordScreenExistingEmail = ({ route, setToken, setUser, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const navigation = useNavigation();
  const routeEmail = route.params.email;
  const email = routeEmail; //🇫🇷 Récupération de l'email de la page précédente/ (gb) Retrieving the email from the previous page
  const { JsonLoginRegisterPages } = scr;
  const { width } = useWindowDimensions();

  //🇫🇷 Déclaration des variables d'état
  //🇬🇧 Declaration of state variables
  const [password, setPassword] = useState(""); //🇫🇷 Récupération du mot de passe / 🇬🇧 Retrieving the password
  const [errorMsg, setErrorMsg] = useState(""); //🇫🇷 Message d'erreur si le format de l'email ou du mot de passe est incorrect / 🇬🇧 Error message if email or password format is incorrect
  const [pressed, setPressed] = useState(false); //🇫🇷 Gestion de l'état du bouton de connexion / 🇬🇧 managing login button state
  const [errorTimer, setErrorTimer] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("");

  //🇫🇷 Vérifie si le mot de passe saisi fait au moins 8 caractères
  //🇬🇧 Checks if the password entered is at least 8 characters
  const isValidPassword = (password) => {
    return password && password.length >= 8;
  };

  //🇫🇷 Gestion de l'état du bouton de connexion
  //🇬🇧 Managing login button state
  useEffect(() => {
    if (!isValidPassword(password)) {
      setBtnDisable(false);
      setBackgroundColor("grey");
    } else {
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }
  });

  //🇫🇷 Gestion du temps d'apparition du message d'erreur
  //🇬🇧 Time management of the error message
  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg(null);
      }, 5000); // 5000 milliseconds = 5 seconds
      //🇫🇷 Sauvegarde le timer de référence pour l'effacer si besoin
      //🇬🇧 Save the timer reference so we can clear it if needed
      setErrorTimer(timer);
    }

    //🇫🇷 Efface le timer si le composant est démonté ou si errorMsg change
    //🇬🇧 Clear the timer if the component is unmounted or if errorMsg changes
    return () => {
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [errorMsg]);

  //🇫🇷 Gestion de la requête API de connexion
  //🇬🇧 Managing API request for login
  const requestLogin = async () => {
    setPressed(true);
    setErrorMsg(null);

    if (!password) {
      setErrorMsg(
        JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty
      );
      return;
    }
    if (!isValidPassword(password)) {
      setErrorMsg(
        JsonLoginRegisterPages.JsonPasswordScreen
          .JsonPasswordErrorMessageTooShort
      );
      return;
    }
    if (password) {
      try {
        //🇫🇷Gestion de la requête API de connexion
        //🇬🇧Managing API request for login
        const response = await axios.post(`${hostname}/api/v1/login`, {
          email: email,
          password: password,
        });
        setToken(response.data.user.token);
        setUser(response.data.user);
        await AsyncStorage.setItem("user", JSON.stringify(response.data.user)); //🇫🇷 Stockage des données de l'utilisateur dans le local storage/ (gb) storing user data in local storage
      } catch (error) {
        //🇫🇷 Messages d'erreur en fonction de la réponse du serveur
        //🇬🇧 Error messages based on server response

        axios
          .post(`${hostname}/api/v1/sendcode`, {
            //🇫🇷 Envoi du code de vérification d'identité
            //🇬🇧 Sending identity verification code
            email: email,
            subject: "Code de vérification d'identité",
            message: "Entrer ce code pour confirmer votre identité : ######",
          })
          .then((response) => {
            navigation.navigate("VerificationIdentity", {
              email: email,
              password: password,
            });
          });
      }
    }
  };

  return (
    <ScrollView style={styles.LogIn}>
      <View style={styles.password}>
        {/*🇫🇷 Affichage des messages d'erreur */}
        {/*🇬🇧 Display of error messages */}
        {errorMsg ? (
          <View style={styles.center}>
            <Text style={styles.error}>{errorMsg}</Text>
          </View>
        ) : null}

        {/*🇫🇷 Affichage du champs pour saisir le mot de passe */}
        {/*🇬🇧 Display of the field to enter the password */}
        <Fields
          text={
            JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder
          }
          icon={"locker"}
          state={password}
          setState={setPassword}
          pressed={pressed}
          setPressed={setPressed}
          scr={scr}
        />
      </View>

      <View style={[styles.btn, { width: (width <= 450 ? width : 400) * 0.8 }]}>
        {/*🇫🇷 Affichage du bouton Suivant */}
        {/*🇬🇧 Display of the next button */}
        <Pressable
          style={[styles.verificationBtn, { backgroundColor: backgroundColor }]}
          disabled={btnDisable}
          onPress={() => {
            setPressed(true);
            requestLogin();
          }}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
          </Text>
        </Pressable>
      </View>

      <View style={styles.centerTerms}>
        {/*🇫🇷 Affichage des conditions d'utilisation et de politique de confidentialité */}
        {/*🇬🇧 Display of the terms and conditions and privacy policy */}
        <View style={styles.termsContainer}>
          <Text style={styles.terms}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}
          </Text>
          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}
            </Text>
          </Pressable>

          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}
            </Text>
          </Pressable>
        </View>
      </View>

      <View style={styles.centerBot}>
        <View style={styles.bottomBoxTop}>
          {/*🇫🇷 Affichage de la partie dédié au réinitialisation de mot de passe */}
          {/*🇬🇧 Display of the reset password section */}
          <Text style={styles.bottomText}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonForgotPassword}
          </Text>
          <Pressable
            //🇫🇷 Navigation vers la page EmailScreen avec la transmission d'une données
            //🇬🇧 Navigate to the page EmailScreen with the transmission of an data
            onPress={() => {
              navigation.navigate("LogIn", {
                MsgE: JsonLoginRegisterPages.JsonEmailScreen
                  .JsonHeaderTitleResetPassword,
              });
            }}
            style={[styles.smallBtn]}
          >
            <Text style={styles.smallBtnText}>
              {
                JsonLoginRegisterPages.JsonPasswordScreen
                  .JsonResetPasswordButton
              }
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

export default PasswordScreenExistingEmail;
