//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier assets/json/en.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the assets/json/en.json folder

/* 🇫🇷 Page de détails d'une activité(FrameFigma41-FrameFigma42-FrameFigma43-FrameFigma44 MVP)
A faire:
- Traduction automatique de la description de l'activité (via countriesListRectFlags et CountriesGrid)
- Remplacement des valeurs dans Interested et Followers par des valeurs dynamiques
- Programmation des boutons (il manque la programmation du boutton de suppression d'un participant, en tant qu'admin ou organisateur)
- Amélioration du code indiquant l'adresse, à côté de l'icône de position
🇫🇷 */

/* 🇬🇧 Activity details page (FrameFigma41-FrameFigma42-FrameFigma43-FrameFigma44 MVP)
To do: 
- Automatic activity description translation (via countriesListRectFlags and CountriesGrid)
- Replacement of the Interested and Followers values by dynamic ones
- Program the buttons (we need to prorm the button that deletes an attendee, as an admin or the organizer)
- Improvment of the code that displays the address, at the right of the position icon
🇬🇧 */

//🇫🇷 Detail d'un évenement (FrameFigma41)
//🇬🇧 Details of one event (FrameFigma41)

import AsyncStorage from "@react-native-async-storage/async-storage";
import Geolocation from "@react-native-community/geolocation";
import { useNavigation, useRoute } from "@react-navigation/native";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Pressable,
  SafeAreaView,
  ScrollView,
  Text,
  View
} from "react-native";
import MapView from "react-native-web-maps";
import Marker from "react-native-web-maps/dist/Marker";
import io from "socket.io-client";

//import des méthodes
import * as ActivityUtils from "../../../../utils/ActivityUtils.js";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import DeleteActivityDialog, {
  AddressAlertDialog,
  CancelParticipationDialog,
  DeleteRepeatEventDialog,
  FriendsInfos,
} from "../../../components/Dialogs/Dialogs.js";
import MiddleNav from "../../../navigation/MiddleNav.js";
import ActivityAddress from "./ActivityAddress/ActivityAddress.js";
import ActivityAttendees from "./ActivityAttendees/ActivityAttendees.js";
import ActivityComments from "./ActivityComments/ActivityComments.js";
import ActivityDescription from "./ActivityDescription/ActivityDescription.js";
import styles from "./ActivityScreenCss.js";

// import images
import { images } from "../../../constantes/index.js";
import { useActivityContext } from "./ActivityContext.js";

// import LoveActivated from "../assets/images/heartRed.svg";
const socket = io(`${hostname}`); // Remplacez l'URL par celle du serveur

const ActivityScreen = ({ user, scr }) => {
  const {
    currentLongitude,
    currentLatitude,
    sendLocation,
    setSendLocation,
    setCurrentLatitude,
    setCurrentLongitude,
  } = useActivityContext();
  // Effet pour recalculer la valeur de "sendLocation"
  useEffect(() => {
    if (currentLatitude !== null && currentLongitude !== null) {
      const points = [
        { latitude: currentLatitude, longitude: currentLongitude },
      ];
      const sendLocation = ActivityUtils.getRegionForCoordinates(points);
      setSendLocation(sendLocation);
    }
  }, [currentLatitude, currentLongitude]);
  // Effet pour gérer la permission de localisation
  useEffect(() => {
    ActivityUtils.requestLocationPermission();
    return () => Geolocation.clearWatch();
  }, []);

  const [chosenEmoji, setChosenEmoji] = useState(false);
  const [selectOption, setSelectOption] = useState(false);
  const ref = useRef(null);

  //🇫🇷 Déstructurer les accessoires nécessaires de l'objet scr 🇬🇧 Destructure the necessary props from the scr object
  const { JsonActivityPages } = scr; //🇫🇷 Passsage de langue depuis app.js🇫🇷//🇬🇧 Language passed from app.js 🇬🇧
  const previewCanvasRef = useRef(null);
  const [disableButton, setDisbaleButton] = useState(true);
  const [activityImage, setActivityImage] = useState(null);
  const [topic, setTopic] = useState(2);
  const route = useRoute();
  const [event, setEvent] = useState(route.params.event); //🇫🇷 Passsage d'info activité🇫🇷//🇬🇧 info activity passed 🇬🇧

  //🇫🇷 Récupérer le rôle de l'utilisateur connecté 🇬🇧 Retrieve the connected user's role
  const connectedUserRole = user?.role?.name?.[0];
  const [address, setAddress] = useState(event?.address); //🇫🇷 Passsage d'adresse🇫🇷//🇬🇧 addresse passed 🇬🇧
  const [locationLatitude, setLocationLatitude] = useState(event?.locationLatitude);
  const [locationLongitude, setLocationLongitude] = useState(event?.locationLongitude);
  const [locationLatitudeDelta, setLocationLatitudeDelta] = useState(0.0922); // Change this to your desired value
  const [locationLongitudeDelta, setLocationLongitudeDelta] = useState(0.0421); // Change this to your desired value
  

  //🇫🇷 Code pour passer le nom d'activité sur le title du header de page, les valeurs sont transmis depuis le composant ActivityCard par react router, ensuite on utilise useLayoutEffect pour la modification de title dans le header🇫🇷 🇬🇧 Code to pass the activity name on the title of the page header, the values ​​are transmitted from the ActivityCard component by react router, then we use useLayoutEffect for the modification of title in the header🇬🇧
  const { pageTitle } = route.params;
  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: pageTitle,
    });
  }, [navigation, pageTitle]);

  //console.log("Connected user role ActivityScreen: " + connectedUserRole);
  const premiumRoles = ["admin", "moderator"];
  const navigation = useNavigation();
  const [display, setDisplay] = useState(1);
  const [avatarList, setAvatarList] = useState([]);
  const [nbPeople, setNbPeople] = useState();
  const [loading, setLoading] = useState(true);

  //🇫🇷 Options des particiapants 🇬🇧 Attendees options
  const [isOrganizer, setIsOrganizer] = useState(false);

  /*🇫🇷 false = participant / true = organisateur 🇬🇧 false = attendee / true = organizer*/
  const [liked, setLiked] = useState(false);
  const [message, setMessage] = useState("");
  if (event && !event.isOnline && event.address.includes(",")) {
    var splitAddress = event.address.split(", ");
    var zipCode = splitAddress[splitAddress.length - 2].slice(0, 5);
  }

  const [dialogResult, setDialogResult] = useState(false);
  const [deleteActivityDialogVisible, setDeleteActivityDialogVisible] =
    useState(false);
  const [
    cancelParticipationDialogVisible,
    setCancelParticipationDialogVisible,
  ] = useState(false);
  const [addressAlertDialogVisible, setAddressAlertDialogVisible] =
    useState(false);
  const [deleteRepeatEventDialogVisible, setDeleteRepeatEventDialogVisible] =
    useState(false);
  const [friendsInfosShow, setFriendsInfosShow] = useState(false);
  const [isParticipating, setIsParticipating] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [fullAttendeeListModalVisible, isFullAttendeeListModalVisible] = useState(false);
  const [waitingListModalVisible, isWaitingListModalVisible] = useState(false);
  const [eventHoster, setEventHoster] = useState(null);
  const [connectedUser, setConnectedUser] = useState();
  const [comments, setComments] = useState([]);
  const [cmtText, setCmtText] = useState("");
  const [reload, setReload] = useState(false);

  //🇫🇷 Récupérer les commentaires de l'activité 🇬🇧 Fetch comments for the activity
  useEffect(() => {
    axios.get(`${hostname}/api/v1/comments/${event?._id}`).then((res) => {
      setComments(res.data);
    });
  }, [reload]);

  //🇫🇷 Gérer le changement de texte dans la saisie de commentaire 🇬🇧 Handle text change in comment input
  const handleTextChange = (text) => setCmtText(text);
  useEffect(() => {
    // Connexion au serveur socket.io
    socket.connect();
    // Écoute des événements "receive_message" pour recevoir de nouveaux messages
    socket.on("receive_message", async (message) => {
      // console.log("receive");
      await axios
        .get(`${hostname}/api/v1/comments/${event._id}`)
        .then((res) => {
          setComments(res.data);
        });
    });
    // Écoute des événements "new_notification" pour recevoir de nouvelles notifications
    socket.on("new_notification", (notification) => {
      // Traitez la notification comme vous le souhaitez (ex: affichage d'une alerte)
    });
    // Nettoyage des écouteurs d'événements lors du démontage du composant
    return () => {
      socket.disconnect();
      socket.off("new_notification");
    };
  }, []);

  //🇬🇧 Send a comment for the activity
  const sendComment = async () => {
    if (activityImage !== null && connectedUser) {
      const resp = await axios.post(`${hostname}/api/v1/comments/create`, {
        image: activityImage,
        comment: cmtText,
        user: connectedUser._id,
        activity_id: event._id,
      });
      setCmtText("");
      setSendLocation(null);
      setActivityImage(null);
      setCurrentLatitude(null);
      setCurrentLongitude(null);
      setSelectOption(false);
      // switch reload to trigger comments reload
      setReload(!reload);
      // alert socket for
      socket.emit("send_message", {
        author: cmtText,
        message: cmtText,
      });
    }
    if (
      sendLocation !== null &&
      currentLatitude !== null &&
      currentLatitude !== null &&
      connectedUser
    ) {
      await axios
        .post(`${hostname}/api/v1/comments/create`, {
          location: sendLocation,
          comment: cmtText,
          user: connectedUser._id,
          activity_id: event._id,
        })
        .then(() => {
          setCmtText("");
          setSendLocation(null);
          setActivityImage(null);
          setCurrentLatitude(null);
          setCurrentLongitude(null);
          setSelectOption(false);
          // switch reload to trigger comments reload
          setReload(!reload);
          // alert socket for
          socket.emit("send_message", {
            author: cmtText,
            message: cmtText,
          });
        })
        .catch((err) => {
          // console.log(err.message);
        });
    }
    if (
      cmtText &&
      cmtText.length > 0 &&
      sendLocation === null &&
      currentLatitude === null &&
      currentLongitude === null &&
      activityImage === null &&
      connectedUser
    ) {
      await axios
        .post(`${hostname}/api/v1/comments/create`, {
          comment: cmtText,
          user: connectedUser._id,
          activity_id: event._id,
        })
        .then(() => {
          // alert socket for
          setReload(!reload);
          setCmtText("");
          setSendLocation(null);
          setCurrentLatitude(null);
          setCurrentLongitude(null);
          setSelectOption(false);
          setActivityImage(null);
          socket.emit("send_message", {
            author: cmtText,
            message: cmtText,
          });
        })
        .catch((err) => {
          // console.log(err.message);
        });
    }
  };

  //🇫🇷 Hook useEffect pour récupérer les données lorsque le composant est monté 🇬🇧 useEffect hook to fetch data when the component mounts
  useEffect(() => {
    const getIfUserParticipate = async () => {
      const token = await AsyncStorage.getItem("userToken");
       console.log("token", token);
      const response1 = await axios.post(
        `${hostname}/api/v1/user/get-user-by-token`,
        { token }
      );
      const { user } = response1.data;
      setConnectedUser(user); //🇫🇷 Mettre à jour l'état de l'utilisateur connecté avec l'utilisateur récupéré //🇬🇧 Update the connectedUser state with the retrieved user
      if (event.attendees && event.attendees.includes(user._id)) {
        //🇫🇷 Définit l'état isParticipating sur true si l'utilisateur est inclus dans la liste des participants 🇬🇧 Set the isParticipating state to true if the user is included in the attendees list
        setIsParticipating(true);
      } else {
        //🇫🇷 Définissez l'état isParticipating sur false si l'utilisateur n'est pas inclus dans la liste des participants 🇬🇧 Set the isParticipating state to false if the user is not included in the attendees list
        setIsParticipating(false);
      }
    };
    getIfUserParticipate();
  }, []);

  //🇫🇷 Fonction pour souscrire à l'activité 🇬🇧 Function to subscribe to the activity
  const subscribe = async () => {
    // console.log("Subscribe");
    const token = await AsyncStorage.getItem("userToken");
    const response = await axios.post(
      `${hostname}/api/v1/activities/join`,
      { id: event._id },
      { headers: { Authorization: "Bearer " + token } }
    );

    // console.log(response.data);
    if (response && response.data && response.data.result === "OK") {
      var tmpEvent = event;
      if (response.data.addedToWaitingList) {
        // console.log("Added to waiting list");
      } else if (event.attendees) {
        // console.log("response Data userid: " + response.data.userId);
        tmpEvent.attendees.splice(1, 0, response.data.userId);
      } else {
        tmpEvent.attendees = [response.data.userId];
      }
      // console.log("Attendee list after participating: " + tmpEvent.attendees);
      //🇫🇷 Mettre à jour l'état de l'événement avec la liste des participants mise à jour 🇬🇧 Update the event state with the updated attendee list
      setEvent(tmpEvent);
    }
    setIsParticipating(true);
    setDisplay(2);
  };

  //🇫🇷 Fonction pour se désinscrire de l'activité 🇬🇧 Function to unsubscribe from the activity
  const unsubscribe = async () => {
    // console.log("unsubscribe");
    const token = await AsyncStorage.getItem("userToken");
    const response = await axios.post(
      `${hostname}/api/v1/activities/leave`,
      { id: event._id },
      { headers: { Authorization: "Bearer " + token } }
    );
    if (response && response.data && response.data.result === "OK") {
      // console.log(response.data);
      var tmpEvent = event;
      if (event.attendees && response.data.index !== -1) {
        let userIndex = event.attendees.findIndex(
          (attendee) => attendee === connectedUser._id
        );
        // console.log(userIndex);
        //🇫🇷  J'ai créé la variable userIndex (ligne 106) et l'ai mise à la place de response.data.index parce-que le response.data renvoyait toujours 2 pour je ne sais quelle raison, du coup, ça n'enlevait pas le bon avatar gb I created the userIndex variable (line 106) and put it instead of response.data.index because the response.data always returned 2 for some reason, so it doesn't was not removing the correct avatar
        tmpEvent.attendees.splice(userIndex, 1);
      }
      // console.log("Attendee list after unsubscribing: " + tmpEvent.attendees);
      setEvent(tmpEvent);
    }
    setIsParticipating(false);
  };
  //🇫🇷 suprimer une activiter après avoir fini de se conecter a notre firebase de hostname.js "api/v1/activities/removeone/${event._id} " il se trouve dans le backendconnect/hostname.js 🇬🇧 remove an activity from this page /api/v1/activities/removeone/${event._id}
  const deleteActivity = async () => {
    // console.log("deleteActivity called");
    const token = await AsyncStorage.getItem("userToken");
    const response = await axios.delete(
      `${hostname}/api/v1/activities/removeone/${event._id}`,
      { headers: { Authorization: "Bearer " + token } }
    );
    // console.log(response.data);
    //🇫🇷 si ma reponnse qui me redirige sur la page /api/v1/activities/removeone/${event._id}`  et que ce meme reponse.data  et la meme reponse.data.result n'est pas ok dit qur ce fichier est supprimer et nnavigue sur activities 🇬🇧 if my response which redirects me to the page /api/v1/activities/removeone/${event._id}` and this same response.data is the same answer.data.result is not ok says that this file is deleted and nnavigates to activities
    if (response && response.data && response.data.result === "OK") {
      // console.log("activity deleted");
      //🇫🇷 Accédez à la page "Activités" si l'activité est supprimée avec succès 🇬🇧 Navigate to the "Activities" page if the activity is deleted successfully
      navigation.navigate("Activities");
    } else {
      // console.log("error", response.data);
    }
  };
  //🇫🇷 Hook useEffect pour récupérer les données d'avatar et de participant lorsque le composant est monté ou lorsque l'état isParticipating change 🇬🇧 useEffect hook to fetch avatar and attendee data when the component mounts or when the isParticipating state changes
  useEffect(() => {
    const fetchAvatar = async () => {
      const token = await AsyncStorage.getItem("userToken");
      // console.log(event.attendees, "attttttttttttttt");
      const { data } = await axios.post(
        `${hostname}/api/v1/user/getavatarlistfromids`,
        { indexs: event.attendees },
        { headers: { authorization: "Bearer " + token } }
      );
      setNbPeople(data.result.length);
      let list = data.result;
      // 🇫🇷 4 avatars sont affichés 🇬🇧 4 avatars are displayed
      setAvatarList(list);
      setAttendees(list);
      // console.log("AvatarList: " + avatarList);
      // console.log("Attendees", list);
      const evthoster = list.filter((e) => e._id === event.author);
      // console.log("hoster", evthoster, event.author);
      if (evthoster && evthoster.length > 0) {
        setEventHoster(evthoster[0]);
      }
      setLoading(false);
    };
    try {
      fetchAvatar();
    } catch (error) {
      console.log("Error: " + error);
    }
  }, [event, isParticipating]);

  useEffect(() => {
    event?.author === user._id ? setIsOrganizer(true) : setIsOrganizer(false);
  }, []);
  // console.log("Is organizer: " + isOrganizer);
  if (loading) return;

  //Méthode permettant de supprimer une activité
  const displayDeleteActivityDialog = () => {
    setDeleteActivityDialogVisible(!deleteActivityDialogVisible);
  };
  //Méthode permettant d'annuler la participation d'une activité
  const displayCancelParticipationDialog = () => {
    setCancelParticipationDialogVisible(!cancelParticipationDialogVisible);
  };
  //Méthode permettant de donner une alerte à une adresse
  const displayAddressAlertDialog = () => {
    setAddressAlertDialogVisible(!addressAlertDialogVisible);
  };
  const displayDeleteRepeatEventDialog = () => {
    setDeleteRepeatEventDialogVisible(!deleteRepeatEventDialogVisible);
  };
  if (
    (display === 2 && !isParticipating) ||
    (display === 2 && !premiumRoles.includes(connectedUserRole))
  ) {
    () => displayAddressAlertDialog();
  }

  // console.log(event.activityImage)
  const isFromUnsplash = (uri) => uri.includes("unsplash.com");
  const canModifyActivity = () => {
    // Si l'utilisateur est l'organisateur de l'activité
    if (connectedUser._id === event.author) return true;
    // Si l'utilisateur est un admin ou un modérateur
    if (premiumRoles.includes(connectedUserRole)) return true;
    return false;
  };
  return (
    <SafeAreaView
      style={styles.container}
      key={Math.floor(Math.random() * 1000)}
    >
      <ScrollView contentContainerStyle={styles.center}>
        {/*Fenêtre popup pour la suppression de l'activité. langue passé pour tous les elements avec la propieté scr 🇫🇷 */}
        {/* 🇬🇧 Confirmation pop-up before deleting the event language passed for all components with scr propiety 🇬🇧 */}
        <DeleteActivityDialog
          dialogVisible={deleteActivityDialogVisible}
          displayModal={displayDeleteActivityDialog}
          deleteActivity={deleteActivity}
          scr={scr}
        />
        <CancelParticipationDialog
          dialogVisible={cancelParticipationDialogVisible}
          displayModal={displayCancelParticipationDialog}
          unsubscribe={unsubscribe}
          scr={scr}
        />
        <AddressAlertDialog
          dialogVisible={addressAlertDialogVisible}
          displayModal={displayAddressAlertDialog}
          subscribe={subscribe}
          scr={scr}
        />
        <DeleteRepeatEventDialog
          dialogVisible={deleteRepeatEventDialogVisible}
          displayModal={displayDeleteRepeatEventDialog}
          state={dialogResult}
          setState={setDialogResult}
          scr={scr}
        />
        <FriendsInfos
          isVisible={friendsInfosShow}
          setIsVisible={setFriendsInfosShow}
          scr={scr}
        />
        {/* 🇫🇷 Enlever le || display === 3 quand il faudra rajouter les options de chat 🇫🇷 🇬🇧 Remove the -- display === 3 when you will need to ad the chat options 🇬🇧 */}
        <View style={styles.mapViewStyle}>
          <View style={styles.mapSubView}>
            {/* 🇫🇷 Code pour faire aficher la location quand on appui sur adresse sur midNav, il reste à faire le cas si l'evenement est online 🇫🇷 🇬🇧 Code to display the location when you press on address on midNav, it remains to do the case if the event is online 🇬🇧 */}
            {display === 2 ? (
              <MapView
  style={styles.map}
  region={{
    latitude: locationLatitude,
    longitude: locationLongitude,
    latitudeDelta: locationLatitudeDelta,
    longitudeDelta: locationLongitudeDelta,
  }}
>
  <Marker
    coordinate={{
      latitude: locationLatitude,
      longitude: locationLongitude,
    }}
    pinColor="#3A8569"
    title={address}
  />
</MapView>
            ) : (
              <Image
                style={styles.header}
                source={{ uri: event.activityImage }}
                resizeMode={
                  isFromUnsplash(event.activityImage) ? "cover" : "contain"
                }
              />
            )}
            {/* 🇫🇷 Pour afficher dollar sur image de l'activité avec prix 🇫🇷 🇬🇧for show dollar over image activity with price  🇬🇧 */}
            {event.hasPrice && !(display === 2) ? (
              <Image source={images.Dollar} style={styles.dollarImgStyle} />
            ) : null}
            {isParticipating || premiumRoles.includes(connectedUserRole) ? (
              <View style={styles.eventAddressStyle}>
                <Text style={styles.eventAddressTxt}>{event.address}</Text>
                <Text style={styles.swipeDownTxt}>
                  {
                    JsonActivityPages.JsonActivityScreen
                      .JsonSwipeDownForMoreInfos
                  }
                </Text>
              </View>
            ) : null}
          </View>
        </View>
        <View style={styles.legend}>
          <Text style={styles.titleTxtStyle}>{event.title}</Text>
          <View style={styles.legendSubViewStyle}>
            <Text style={styles.startTimeTxt}>{event.startTime}</Text>
            <Text style={styles.dateTxtStyle}>
              {moment(event.date).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View style={styles.eventHosterView}>
            {eventHoster && (
              <Pressable
                onPress={() =>
                  navigation.navigate("Profile", { user: eventHoster })
                }
              >
                <View style={styles.eventHosterSubView}>
                  <Image
                    source={eventHoster.avatar}
                    style={styles.eventHosterImg}
                  />
                  <View style={{ width: 7 }} />
                  <View>
                    <Text
                      style={[
                        styles.userInfos,
                        { color: "blue", fontSize: 15 },
                      ]}
                    >
                      {" "}
                      {eventHoster.firstName}{" "}
                    </Text>
                  </View>
                </View>
              </Pressable>
            )}
            <View style={styles.googlePictoView}>
              <Image
                source={images.GooglePicto}
                style={styles.googlePictoImg}
              />
              {!event.isOnline && event.address.includes(",") ? (
                <Text style={styles.zipCodeTxt}>
                  {" "}
                  {zipCode}{" "}
                  {/* ENG - I split the address to extract the zip code - ENG //FR - J'ai découpé l'adresse pour extraire le code postal -FR*/}{" "}
                </Text>
              ) : (
                <Text></Text>
              )}
            </View>
          </View>
        </View>
        {/*🇫🇷 Composant de navigation MiddleNav propre à l'activité. Il contient 4 onglets et le props display sert à changer l'affichage sous l'onglet séléctionné. 🇬🇧 MiddleNav is a navigation component specific to the current event. It has 4 tabs and the props called "display" changes the display below the selected tab */}
        <MiddleNav
          comments={comments}
          display={display}
          setDisplay={setDisplay}
          isParticipating={isParticipating}
          connectedUserRole={connectedUserRole}
          addressAlertDialogVisible={addressAlertDialogVisible}
          setAddressAlertDialogVisible={setAddressAlertDialogVisible}
          scr={scr}
        />
        {/* 🇫🇷 scr pour Passsage de langue à middleNAv depuis ActivityScreen.js Onglet 1: Description (Figma Frame 41 MVP)🇫🇷 🇬🇧 scr for Language passed from ActivityScreen.js Tab 1: Description (Figma Frame 41 MVP) 🇬🇧 */}
        {display === 1 && (
          <ActivityDescription
            route={route}
            avatarList={avatarList}
            isParticipating={isParticipating}
            subscribe={subscribe}
            cancelParticipationDialogVisible={cancelParticipationDialogVisible}
            setCancelParticipationDialogVisible={
              setCancelParticipationDialogVisible
            }
            scr={scr}
            connectedUser={connectedUser}
            connectedUserRole={connectedUserRole}
            navigation={navigation}
            setFriendsInfosShow={setFriendsInfosShow}
            displayDeleteActivityDialog = {displayDeleteActivityDialog}
            deleteActivityDialogVisible = {deleteActivityDialogVisible}
            setDeleteActivityDialogVisible = {setDeleteActivityDialogVisible}
          />
        )}

        {/*🇫🇷 Onglet 2: Adresse "Où nous trouver" (Figma Frame 42 MVP) 🇬🇧 Tab 2: Description "How to find us" (Figma Frame 42 MVP)*/}
        {display === 2 && (
          <ActivityAddress route={route} navigation={navigation} scr={scr} />
        )}

        {/*🇫🇷 Onglet 3: Commentaires participants/organisateurs (Figma Frame 43 MVP) 🇬🇧 Tab 3: Hosts and attendees comments (Figma Frame 43 MVP)*/}
        {display === 3 && (
          <ActivityComments
            connectedUser={connectedUser}
            scr={scr}
            isParticipating={isParticipating}
            comments={comments}
            selectOption={selectOption}
            cmtText={cmtText}
            activityImage={activityImage}
            currentLatitude={currentLatitude}
            currentLongitude={currentLongitude}
            sendComment={sendComment}
            setCmtText={setCmtText}
            setSelectOption={setSelectOption}
            address={address}
            connectedUserRole={connectedUserRole}
            isOrganizer={isOrganizer}
            deleteComment={ActivityUtils.deleteComment}
            premiumRoles={premiumRoles}
            images={images}
            setActivityImage={setActivityImage}
            setDisbaleButton={setDisbaleButton}
          />
        )}
        {/* ---------------- 🇫🇷 Style CSS brut des participants via le display(4) (MiddleNav) 🇫🇷 🇬🇧 Raw CSS style of participants through the display(4) (MiddleNav)🇬🇧-------------------*/}
        {/*🇫🇷 Onglet 4: Participants (Figma Frame 44 MVP) 🇬🇧 Tab 4: Attendees (Figma Frame 44 MVP)*/}
        {display === 4 && (
          <ActivityAttendees
            connectedUserRole={connectedUserRole}
            isOrganizer={isOrganizer}
            waitingListModalVisible={waitingListModalVisible}
            isWaitingListModalVisible={isWaitingListModalVisible}
            scr={scr}
            attendees = {attendees}
            setAttendees = {setAttendees}
            fullAttendeeListModalVisible = {fullAttendeeListModalVisible}
            isFullAttendeeListModalVisible = {isFullAttendeeListModalVisible}
            event={event}
            setEvent={setEvent}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
};
export default ActivityScreen;
