import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        backgroundColor: "white",
        flex: 1,
    },
    important: {
        color: "red",
        marginBottom: 15,
        fontWeight: "bold",
        textAlign: "center",
    },
    membership: {
        flexDirection: "row",
    },
    membershipNumber: {
        color: "#3A8569",
        marginLeft: 10,
        marginBottom: 15,
        marginTop: 10,
        fontWeight: "bold",
    },
    bold: {
        fontWeight: "bold",
        fontSize: 14,
        marginTop: 10,
    },
    titleFields: {
        justifyContent: "flex-start",
        top: 10,
        left: 15,
      },
    titleFields_text: {
        fontSize: 16,
        fontStyle: "italic",
        color: "#C4C4C4",
    },
    fields: {
        alignItems: "center",
        marginVertical: 2,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    friendsNote: {
        marginTop: 30,
        marginBottom: 10,
        alignSelf: "center",
    },
    rectangleContainer: {
        borderWidth: 2,
        borderColor: "red",
        justifyContent: "center",
        alignItems: "center",
    },
    comingSoonText: {
        color: "red",
        fontSize: 16,
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
    },
    friendInfo: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        // shadowColor: "grey",
        // shadowOffset: { width: 0, height: 3 },
        // shadowOpacity: 1,
    },
    wrapped: {
        marginVertical: 20,
    },
    testEcarteur: {
        height: 30,
    },
    savOrConButtons: {
        marginVertical: 30,
        flexDirection: "row",
        //justifyContent: "space-around",
        justifyContent: "space-between",
        // shadowColor: "grey",
        // shadowOffset: { width: 0, height: 3 },
        // shadowOpacity: 1,
    },  
    bigBtn: {
        backgroundColor: "#59c09b",
        height: 50,
        width: "40%",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        marginHorizontal: 10,
    },
    btnText: {
        color: "white",
        fontSize: 22,
        lineHeight: 33,
        fontWeight: "700",
    },
    disabledButton: {
        backgroundColor: "grey",
        borderColor: "#ccc",
    },
})

export default styles;