import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    titleOneView: {
        backgroundColor: "white",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      },
      titleTwo: {
        backgroundColor: "white",
        paddingTop: 15,
        paddingHorizontal: "2%",
        marginBottom: 20,
      },
      editProfileStepThree: {
        backgroundColor: "white",
        paddingTop: 10,
        paddingHorizontal: "2%",
      },
      title: {
        color: "#3A8569",
        //marginTop: 15,
        fontWeight: "bold",
        textAlign: "center",
      },
      bold: {
        fontWeight: "bold",
        fontSize: 14,
        marginTop: 10,
      },
      titleAgeView: {
        backgroundColor: "white",
        paddingHorizontal: "2%",
        marginTop: 20,
      },
      checkBoxContainer: {
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: "3%",
        //justifyContent:"center",
        marginTop: 10,
        flexWrap: "wrap",
      },
      checkContainer: {
        width: "25%",
      },
});

export default styles;