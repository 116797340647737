//🇫🇷 Ce fichier gère tous les pages de modification d'activité (FrameFigma31-FrameFigma32-FrameFigma33-FrameFigma34)
//🇬🇧 This file manage all modification activity pages (FrameFigma31-FrameFigma32-FrameFigma33-FrameFigma34)

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { LogBox } from "react-native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import { activitiesList } from "../../../assets/activityList/activityListWithIcons.js";
import UpdateActivityScreenStepFour from "./UpdateActivityScreenStepFour/UpdateActivityScreenStepFour.js";
import UpdateActivityScreenStepOne from "./UpdateActivityScreenStepOne/UpdateActivityScreenStepOne.js";
import UpdateActivityScreenStepThree from "./UpdateActivityScreenStepThree/UpdateActivityScreenStepThree.js";
import UpdateActivityScreenStepTwo from "./UpdateActivityScreenStepTwo/UpdateActivityScreenTwo.js";

LogBox.ignoreLogs([
  "VirtualizedLists should never be nested inside plain ScrollViews with the same orientation because it can break windowing and other functionality - use another VirtualizedList-backed container instead.",
]);
// Des variables json de en.json permettant la traduction dans les différentes langues

const UpdateActivityScreen = ({ scr, user }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  const premiumRoles = ["admin", "moderator", "Diamond star"];

  // console.log("USER modify", user);

  const { name } = user?.role;

  const navigation = useNavigation();
  const route = useRoute();
  const event = route.params.event; //🇫🇷 Récupération de l'événement à modifier //🇬🇧 Get the event to modify
  // console.log("EVENT INFO", event); //🇫🇷 Affichage des informations de l'événement //🇬🇧 Display event informations
  const typeAction = route.params.type;
  const [buttonSelected, setButtonSelected] = useState(
    JsonActivityPages.JsonActivityManagement
      .JsonStandardActivityChooseAddressScreen.JsonAddress //🇫🇷 Valeur par défaut pour le bouton "En ligne" //🇬🇧 Default value for the "Online" button
  );
  // const [buttonSelected, setButtonSelected] = useState(onlinecreateActivity.step1.address);

  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  //FR ETAPE 1-variables relatives
  //GB STEP 1-related variables
  const [author, setAuthor] = useState(event.author);
  // console.log(event.author);
  //FR Récupérer l'ID et le nom de l'utilisateur, il serait mieux de le faire avec useContext
  //GB Get the id and the name of the user; could be nice to do it with useContext, I think
  const [title, setTitle] = useState(event.title); //ENLEVER PLUS TARD
  const [address, setAddress] = useState(event.address);
  const [online, setOnline] = useState(event.online);
  const [location, setLocation] = useState({
    latitude: 48.85714319815175,
    longitude: 2.347655098840397,
    latitudeDelta: 0.012,
    longitudeDelta: 0.012,
  });

  const [locationEdit, setLocationEdit] = useState(
    !online ? JSON.stringify(location) : null
  );

  const [date, setDate] = useState(event.date);
  const [startTime, setStartTime] = useState(event.startTime);
  //FR Nombre de participants
  //GB How many attendants
  // const [unlimited, setUnlimited] = useState(false);
  const [isAttendeeLimited, setIsAttendeeLimited] = useState(
    event.isAttendeeLimited ?? null
  );
  const [attendeeLimit, setAttendeeLimit] = useState(event.attendeeLimit);

  const [hasPrice, setHasPrice] = useState(event.hasPrice ?? false);
  //const [priceValue, setPriceValue] = useState(event.priceValue); // non utilisé
  const [ticketLink, setTicketLink] = useState(event.ticketLink);

  const [nbFriends, setNbFriends] = useState(event.nbFriends);

  const [helpForOrganizers, setHelpForOrganizers] = useState(
    event.helpForOrganizers
  );

  const [hasReminderName, setHasReminderName] = useState(
    event.hasReminderName ?? false
  );
  const [reminderName, setReminderName] = useState(event.reminderName);

  const [requestCoOrganizers, setRequestCoOrganizers] = useState(
    event.requestCoOrganizers ?? false
  );
  const [coOrganizerRequests, setCoOrganizerRequests] = useState(
    event.coOrganizerRequests ?? []
  );
  const [coOrganizerOffers, setCoOrganizerOffers] = useState(
    event.coOrganizerOffers ?? []
  );
  const [coOrganizerGift, setCoOrganizerGift] = useState(event.coOrganizerGift);

  const [optionInArray, setOptionInArray] = useState(
    event.optionInArray ?? false
  );

  //🇫🇷 Fonction de gestion des demandes des co-organisateurs
  //🇬🇧 Function to manage co-organizer requests
  const manageCoOrganizerRequests = (request) => {
    if (!coOrganizerRequests.includes(request)) {
      setOptionInArray(true); //🇫🇷 Si l'utilisateur active les options, les premières options sont ajoutées au tableau
      setCoOrganizerRequests((previousArray) => {
        return [...previousArray, request];
      });
    } else {
      setOptionInArray(false); //🇫🇷 Si l'utilisateur désactive les options, les premières options sont supprimées du tableau
      setCoOrganizerRequests((previousArray) => {
        return previousArray.filter((item, index) => {
          return item !== request;
        });
      });
    }
  };

  //🇫🇷 Fonction de gestion des offres des co-organisateurs
  //🇬🇧 Function to manage co-organizer offers
  const manageCoOrganizerOffers = (offer) => {
    if (!coOrganizerOffers.includes(offer)) {
      setOptionInArray(true); //🇫🇷 Si l'utilisateur active les options, les premières options sont ajoutées au tableau
      setCoOrganizerOffers((previousArray) => {
        return [...previousArray, offer];
      });
    } else {
      setOptionInArray(false); //🇫🇷 Si l'utilisateur désactive les options, les premières options sont supprimées du tableau
      setCoOrganizerOffers((previousArray) => {
        return previousArray.filter((item, index) => {
          //🇫🇷 Suppression des premières options du tableau
          return item !== offer;
        });
      });
    }
  };
  //FR Lorsque l'utilisateur active les premières options sont ajoutées ou supprimées du tableau
  //GB If the user switches Request Co-organizers on, the first options are added or removed from the array
  useEffect(() => {
    if (requestCoOrganizers) {
      setCoOrganizerRequests([
        JsonActivityPages.JsonCoOrganizerReceivePrivateMessage,
      ]); //🇫🇷 Ajout des premières options au tableau
      setCoOrganizerOffers([JsonActivityPages.JsonCoOrganizerOfferDrink]); //🇬🇧 Add the first options to the array
    } else {
      setCoOrganizerRequests([]); //🇫🇷 Suppression des premières options du tableau
      setCoOrganizerOffers([]); //🇬🇧 Remove the first options from the array
    }
  }, [requestCoOrganizers]);


  //STEP 2-related variables
  // console.log("topic", event.topic);
  const [topic, setTopic] = useState(event.topic);

  //STEP 3-related variables
  const [disableButton, setDisbaleButton] = useState(true);
  const [activityImage, setActivityImage] = useState(event.activityImage);

  const [description, setDescription] = useState(event.description);
  const [howToFind, setHowToFind] = useState(event.howToFind);

  //STEP 4-related variables
  const [whatsappLink, setWhatsappLink] = useState(event.whatsappLink);
  const [fbPageLink, setFbPageLink] = useState(event.fbPageLink);
  const [fbGroupLink, setFbGroupLink] = useState(event.fbGroupLink);
  const [meetupLink, setMeetupLink] = useState(event.meetupLink);
  const [telegramLink, setTelegramLink] = useState(event.telegramLink);
  const [otherLink, setOtherLink] = useState(event.otherLink);

  const [friendsOnly, setFriendsOnly] = useState(event.friendInfo ?? false);
  const [selectPeople, setSelectPeople] = useState(event.selectPeople ?? false);
  const [allowPhoneNumberDisplay, setAllowPhoneNumberDisplay] = useState(
    event.allowPhoneNumberDisplay ?? false
  );
  const [allowCoOrg, setAllowCoOrg] = useState(event.allowCoOrg ?? false);
  //Go fetch the list of the user's friends if true
  const [infoLine, setInfoLine] = useState(event.infoLine);

  const [repeatEvent, setRepeatEvent] = useState(event.repeatEvent ?? false);
  const [repeatEventFrequency, setRepeatEventFrequency] = useState(
    event.repeatEventFrequency
  );
  const [repeatEventDays, setRepeatEventDays] = useState(
    event.repeatEventDays ?? []
  );
  //STEP 4 event repeat manager code
  const [dayInArray, setDayInArray] = useState(event.dayInArray ?? false);
  const [repeatEventEndDate, setRepeatEventEndDate] = useState(
    event.repeatEventEndDate
  );

  const manageFrequencyDays = (day) => {
    //(fr)Gère les jours de répétition de l'événement(fr)/ (gb)
    if (!repeatEventDays.includes(day)) {
      setDayInArray(true); //🇫🇷 Si l'utilisateur active les options, les premières options sont ajoutées au tableau
      setRepeatEventDays((previousArray) => {
        return [...previousArray, day];
      });
    } else {
      setDayInArray(false); //🇫🇷 Si l'utilisateur désactive les options, les premières options sont supprimées du tableau
      setRepeatEventDays((previousArray) => {
        return previousArray.filter((item, index) => {
          return item !== day;
        });
      });
    }
  };
  // console.log(repeatEventDays);
  const [parity, setParity] = useState(event.parity ?? false);
  // const [parityValues, setParityValues] = useState(
  //   JSON.parse(event.parityValues)
  // );
  const [parityValuesMale, setParityValuesMale] = useState(
    event.parityValuesMale || 0
  );
  const [parityValuesFemale, setParityValuesFemale] = useState(
    event.parityValuesFemale || 0
  );

  const [allowGuests, setAllowGuests] = useState(event.allowGuests ?? false);
  const [howManyGuests, setHowManyGuests] = useState(event.howManyGuests);

  const [ageRestriction, setAgeRestriction] = useState(
    event.ageRestriction ?? false
  );
  // const [ages, setAges] = useState(event.ages ?? [20, 40]);
  const [ages, setAges] = useState(
    event.ages ?? [event.ages[0], event.ages[1]]
  );

  const showError = (text) => {
    //🇫🇷 Affiche un message d'erreur et retourne à l'étape précédente //🇬🇧 Display an error message and go back to the previous step
    setErrorMessage(text);
    setStep(step - 1);
  };
  const connectedUser = JSON.parse(localStorage.getItem("user"));
  // console.log("connectedUser", user);
  const isPremiumRole = (user) => {
    return (
      user &&
      user.role &&
      (user.role.name.includes("admin") || user.role.name.includes("moderator"))
    );
  };
  //🇫🇷 Gestion de la validation des éléments entre les étapes de création d'activité
  //🇬🇧  The useEffect manages validation between the event creation steps
  useEffect(() => {
    if (step === 2) {
      //FR quand on passe de l'étape 1 à l'étape 2
      //GB from step 1 to step 2
      if (title === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
            .JsonTitleErrorMessage
        );
      //🇫🇷 Si le titre est vide, on affiche un message d'erreur //🇬🇧 If the title is empty, we display an error message
      else if (address === "")
        return showError(
          JsonActivityPages.JsonActivityManagement
            .JsonStandardActivityChooseAddressScreen.JsonAddressErrorMessage
        );
      //🇫🇷 Si l'adresse est vide, on affiche un message d'erreur //🇬🇧 If the address is empty, we display an error message
      else if (date === null || startTime === null || date === "Invalid date")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
            .JsonDateTimeErrorMessage
        );
      //🇫🇷 Si la date ou l'heure sont vides, on affiche un message d'erreur //🇬🇧 If the date or the time are empty, we display an error message
      else if (ticketLink.length > 4) {
        if (
          !(
            ticketLink.startsWith("https://") ||
            ticketLink.startsWith("http://")
          )
        ) {
          return showError(
            JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
              .JsonPriceLinkErrorMessage
          ); //🇫🇷 Si le lien de billetterie n'est pas valide, on affiche un message d'erreur //🇬🇧 If the ticket link is not valid, we display an error message
        }
      }
    }

    if (step === 3) {
      //🇫🇷 quand on passe de l'étape 2 à l'étape 3
      //🇬🇧 From step 2 to step 3
      if (topic === -1)
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
            .JsonTopicErrorMessage
        ); //🇫🇷 Si le thème n'est pas sélectionné, on affiche un message d'erreur //🇬🇧 If the topic is not selected, we display an error message
    }

    if (step === 4) {
      //🇫🇷  quand on passe de l'étape 3 à l'étape 4
      //🇬🇧 From step 3 to step 4
      if (activityImage === null)
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonImageErrorMessage
        );
      if (description === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonDescriptionErrorMessage
        );
      if (howToFind === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonHowToFindMeErrorMessage
        ); //🇫🇷 Si la description ou les indications pour trouver l'activité sont vides, on affiche un message d'erreur //🇬🇧 If the description or the indications to find the activity are empty, we display an error message
      if (step === 4 && !isPremiumRole(connectedUser)) {
        // Si l'utilisateur n'a pas un rôle premium, affichez une erreur ou redirigez-le
        console.error("Accès refusé à l'étape 4. Rôle insuffisant.");
        // Redirection ou affichage d'une erreur, selon votre choix
      }
    }
  }, [step, address]);


  //🇫🇷 requete pour envoyer le données avec method PUT , chaque state et envoyé dans
  //chaque clé valeur ,token utilisateur envoyé pour authentifier la modification
  //🇬🇧 request to send the data with method PUT , each state
  //and sent in each key value , user token sent to authenticate the modification
  const sendModify = async () => {
    const token = await AsyncStorage.getItem("userToken");
    let ValAttendeeLimit = isAttendeeLimited ? attendeeLimit : 1000000;
    const ActivityBody = {
      title: title,
      author: author,
      isOnline: online,
      address: address,
      location: locationEdit, //object json
      date: date,
      startTime: startTime,
      isAttendeeLimited: isAttendeeLimited,
      attendeeLimit: ValAttendeeLimit,
      hasPrice: hasPrice,
      ticketLink: ticketLink,
      helpForOrganizers: helpForOrganizers,
      hasReminderName: hasReminderName,
      reminderName: reminderName,
      requestCoOrganizers: requestCoOrganizers,
      allowCoOrg: allowCoOrg,
      coOrganizerGift: coOrganizerGift,
      nbFriends: nbFriends,
      ///// step 2
      topic: topic,
      ///////step 3
      activityImage: activityImage,
      description: description,
      howToFind: howToFind,
      //// step 4 //// premium roles
      whatsappLink: whatsappLink,
      fbPageLink: fbPageLink,
      fbGroupLink: fbGroupLink,
      meetupLink: meetupLink,
      telegramLink: telegramLink,
      otherLink: otherLink,
      friendsOnly: friendsOnly,
      selectPeople: selectPeople,
      allowGuests: allowGuests,
      allowPhoneNumberDisplay: allowPhoneNumberDisplay,
      infoLine: infoLine,
      parity: parity,
      parityValuesMale: parityValuesMale,
      parityValuesFemale: parityValuesFemale,
      repeatEvent: repeatEvent,
      repeatEventDays: repeatEventDays,
      repeatEventFrequency: repeatEventFrequency,
      repeatEventEndDate: repeatEventEndDate,
      howManyGuests: howManyGuests,
      ageRestriction: ageRestriction,
      ages: ages, //object json
    };

    // console.log(event._id); //Affichage de l'id de l'evenement
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(ActivityBody),
      };
      const response = await fetch(
        `${hostname}/api/v1/activities/update/${event._id}`,
        requestOptions
      );
      const data = await response.json();
      // console.log("Activity data = ", data);
      //🇫🇷 reemplacement de données dans event avec les données modifies depuis ActivityBody
      //🇬🇧 replacing data in event with data changed from ActivityBody
      Object.assign(event, ActivityBody);
      setStep(1);
      navigation.navigate("Activity", {
        event: data.activity,
        pageTitle: activitiesList[event.topic].activityTypeTitle,
      }); /*🇫🇷 navigation to Activity*/ /*🇬🇧 navegation vers Activity */
    } catch (error) {
      // console.log("Activity save failed: " + error);
    }
  };

  if (step === 1) {
    return (
     <UpdateActivityScreenStepOne 
     title={title}
     setTitle={setTitle}
     scr={scr}
     buttonSelected={buttonSelected}
     setButtonSelected={setButtonSelected}
     location={location}
     setLocation={setLocation}
     date={date}
     setDate={setDate}
     startTime={startTime}
     setStartTime={setStartTime}
     name={name}
     isAttendeeLimited={isAttendeeLimited}
     setIsAttendeeLimited={setIsAttendeeLimited}
     attendeeLimit={attendeeLimit}
     setAttendeeLimit={setAttendeeLimit}
     hasPrice={hasPrice}
     setHasPrice={setHasPrice}
     ticketLink={ticketLink}
     setTicketLink={setTicketLink}
     nbFriends={nbFriends}
     setNbFriends={setNbFriends}
     premiumRoles={premiumRoles}
     step={step}
     setStep={setStep}
     errorMessage={errorMessage}
     setAddress={setAddress}
     setErrorMessage={setErrorMessage}
     />
    );
  }

  //FR Création d'activité - Choix du thème (Frame 33 MVP du Figma )
  //GB Event creation - Theme choice (Frame 33 MVP Figma)
  if (step === 2) {
    return (
      <UpdateActivityScreenStepTwo 
        topic={topic}
        setTopic={setTopic}
        step={step}
        setStep={setStep}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        scr={scr}
      />
    );
  }

  //FR Création d'activité - Photo et description (Frame 34 MVP du Figma )
  //GB Event creation - Photo and description (Frame 34 MVP Figma)
  if (step === 3) {
    return (
      <UpdateActivityScreenStepThree 
        topic={topic}
        activityImage={activityImage}
        setActivityImage={setActivityImage}
        disableButton={disableButton}
        setDisbaleButton={setDisbaleButton}
        scr={scr}
        description={description}
        setDescription={setDescription}
        howToFind={howToFind}
        setHowToFind={setHowToFind}
        step={step}
        setStep={setStep}
        errorMessage={errorMessage}
        premiumRoles={premiumRoles}
        name={name}
        setErrorMessage={setErrorMessage}
        sendModify={sendModify}
      />
    );
  }

  //FR Création d'activité - Infos complémentaires (Frame 35 MVP du Figma )
  //GB Event creation - Additional info (Frame 35 MVP Figma)
  if (step === 4) {
    return (
      <UpdateActivityScreenStepFour 
      scr={scr}
      setStep={setStep}
      step={step}
      sendModify={sendModify}
      whatsappLink={whatsappLink}
      setWhatsappLink={setWhatsappLink}
      fbPageLink={fbPageLink}
      setFbPageLink={setFbPageLink}
      fbGroupLink={fbGroupLink}
      setFbGroupLink={setFbGroupLink}
      meetupLink={meetupLink}
      setMeetupLink={setMeetupLink}
      telegramLink={telegramLink}
      setTelegramLink={setTelegramLink}
      otherLink={otherLink}
      setOtherLink={setOtherLink}
      />
    );
  }
};

export default UpdateActivityScreen;