import React from 'react';
import { Image, Modal, Pressable, Text, View } from 'react-native';
import { images } from "../../../constantes";
import styles from "../MyProfileScreenCss";

const ConfirmationModal = ({
  modalVisible,
  setModalVisible,
  confirmPressed,
  setConfirmPressed,
  cancelPressed,
  setCancelPressed,
  scr
}) => {

    const {
        JsonConfirmFriendshipPopup,
        JsonActivityPages
      } = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.modalContainer}>
        <View style={styles.popupBox}>
          <Image source={images.warning} style={styles.modalImage} />
          <Text style={styles.modalText}>
            If you continue, this user will not be able to see you as an attendee or to see your activities.
          </Text>
          <View style={styles.buttonContainer}>
            <Pressable
              style={[
                styles.confirmButton,
                confirmPressed && styles.buttonPressed,
              ]}
              onPressIn={() => setConfirmPressed(true)}
              onPressOut={() => {
                setConfirmPressed(false);
                // Ajoutez ici la logique pour confirmer l'action
                setModalVisible(false);
              }}
            >
              <Text
                style={[
                  styles.buttonText,
                  confirmPressed && styles.buttonTextPressed,
                ]}
              >
                {JsonConfirmFriendshipPopup.JsonButtonConfirm}
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.cancelButton,
                cancelPressed && styles.buttonPressed,
              ]}
              onPressIn={() => setCancelPressed(true)}
              onPressOut={() => {
                setCancelPressed(false);
                setModalVisible(false);
              }}
            >
              <Text
                style={[
                  styles.buttonText,
                  cancelPressed && styles.buttonTextPressed,
                ]}
              >
                {JsonActivityPages.JsonActivityScreen.JsonCancel}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmationModal;
