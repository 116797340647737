// 🇫🇷 cette page affiche les informations sur le premium badges gold star FrameFigma051A
// 🇫🇷 this page displays information about the premium gold star badges FrameFigma051A
import React from "react";
import { Text, View, Image, Pressable, ScrollView, ImageBackground } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components 
import styles from "./StylesWeb"

// import des images
import { images } from "../../../../constantes";

const VipBadgesGold = (props) => {
    const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
    const JsonAchievementsPages = scr.JsonAchievementsPages || {};
  
    const navigation = useNavigation();

// 🇫🇷 Trait permettant d'afficher la separation entre les étoiles(Golden, Emurald, Ruby ,Diamond) et les parrainages 
  // 🇬🇧 Feature for displaying the separation between the stars (Golden, Emerald, Ruby, Diamond) and sponsorships
  const SeparatorG = () => {
    return <View style={styles.separatorG} />;
  };
  const SeparatorR = () => {
    return <View style={styles.separatorR} />;
  };const SeparatorE = () => {
    return <View style={styles.separatorE} />;
  };const SeparatorD = () => {
    return <View style={styles.separatorD} />;
  };

  
  return (
    <ScrollView style={styles.GrandBox}>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>
        
        {/*Gold*/}

        <View style={styles.button}>
           {/* Utilisez ImageBackground pour définir l'image comme arrière-plan */}
        
          <Image source={images.RubyStar} style={styles.img} />
            <Text style={styles.rubytitle} > {/*title*/}
                {JsonAchievementsPages.JsonRubyStar}
            </Text>

            <SeparatorR />
            {/* <Text style={styles.ruby} >
                  4.90€/month 
            </Text> */}
            <View style={styles.container}>
            {/*texte random*/}
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

             
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>
                
             
            </View>
            <Pressable style={styles.buttonGrey}   
            onPress={() => navigation.navigate('VipBoardExplanation')}
            >
                <Image source={images.RubyStar} style={styles.imgsmall} />
                <Text style={styles.textbutton}>
                {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonMoreInformations}
                </Text>

           </Pressable>

          
      
            
        </View>  
       
        <Text style={styles.Text}>
           {JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDonatorToGetAStar}
          </Text>
      </View>
    </ScrollView>

    );
};
  
 
export default VipBadgesGold;
