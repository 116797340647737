//🇫🇷 Page du profil utilisateur (FrameFigma70 et FrameFigma22 voir ligne 569)
//🇬🇧 User profile screen (FrameFigma70 et FrameFigma22 check line 569)

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/native";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Image,
  Pressable,
  SafeAreaView,
  ScrollView,
  Text,
  View,
  useWindowDimensions
} from "react-native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import AddFriendModal from "../../../components/FriendsModal/AddFriendModal/AddFriendModal.js";
import ConfirmBirthdayModal from "../../../components/FriendsModal/ConfirmBirthdayModal/ConfirmBirthdayModal.js";
import ConfirmEmailModal from "../../../components/FriendsModal/ConfirmEmailModal/ConfirmEmailModal.js";
import ConfirmLastNameModal from "../../../components/FriendsModal/ConfirmLastNameModal/ConfirmLastNameModal.js";
import ConfirmMemberNumberModal from "../../../components/FriendsModal/ConfirmMemberNumberModal/ConfirmMemberNumberModal.js";
import ConfirmPhoneModal from "../../../components/FriendsModal/ConfirmPhoneModal/ConfirmPhoneModal.js";
import ConfirmationModal from "../../../components/FriendsModal/ConfirmationModal/ConfirmationModal.js";
import TopNavigation from "../../../navigation/TopNavigation.js";
import PopupDialog from "../PopupDialog.js";
import ProfileActivities from "../ProfileInfoActNetwork/ProfileActivities/ProfileActivities.js";
import ProfileInfo from "../ProfileInfoActNetwork/ProfileInfo/ProfileInfo.js";
import ProfileNetwork from "../ProfileInfoActNetwork/ProfileNetwork/ProfileNetwork.js";
import styles from "./MyProfileScreenCss.js";

// import des images
import { images } from "../../../constantes";
// Assets
// import BlockFriend from "../../assets/profile/block-friend.svg";
// import ComingSoon from "../../assets/images/coming-soon.svg";
// import Chat from "../../assets/profile/chat.svg";

const MyProfileScreen = ({ user, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const [age, setAge] = useState(""); //🇫🇷 Etat pour gerer l'age de l'utilisateur
  // const { friendDetails, addFriend, loading, error } = useAddFriend(
  //   visitedUserToken,
  //   hostname
  // );

useEffect(() => {
  if (user?.birthday) {
    const timestamp = user.birthday;
    const birthday = moment(timestamp);
    setAge(moment().diff(birthday, "years"));
  }
}, [user]);

  //Page du profil utilisateur (Frame Figma 70)
  const { height, width } = useWindowDimensions(); //🇫🇷 Etat pour gerer la taille de l'image de profil
  // Etat pour gerer le droit de éditer le profil, le token local est comparé avec le token des autres utilsateurs pour afficher le bouton edit profile
  const [userToken, setUserToken] = useState(null);

  //Variable de json permettant de rendre dynamique le texte front-end
  const {
    JsonProfilePages,
    JsonConfirmFriendshipPopup,
    JsonCreateProfilePages,
    JsonActivityPages,
  } = scr;

  const navigation = useNavigation();

  const route = useRoute(); //🇫🇷 la recuperation de données utilisateur depuis middleNav pour afficher profil d'utilisateur dans une activité
  user = route.params.user; //🇬🇧 Retrieving user data from middleNav to display user profile in an activity

  const [imageSize, setImageSize] = useState(0);
  const [imageGenre, setImageGenre] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmPressed, setConfirmPressed] = useState(false);
  const [cancelPressed, setCancelPressed] = useState(false);
  const [addFriendModalVisible, setAddFriendModalVisible] = useState(false);
  const [emailPressed, setEmailPressed] = useState(false);
  const [lastNamePressed, setLastNamePressed] = useState(false);
  const [phonePressed, setPhonePressed] = useState(false);
  const [birthdayPressed, setBirthdayPressed] = useState(false);
  const [memberNumberPressed, setMemberNumberPressed] = useState(false);
  const [continuePressed, setContinuePressed] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [confirmEmailModalVisible, setConfirmEmailModalVisible] =useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  const [lastNameModalVisible, setLastNameModalVisible] = useState(false);
  const [birthdayModalVisible, setBirthdayModalVisible] = useState(false);
  const [memberNumberModalVisible, setMemberNumberModalVisible] =useState(false);
  const [selectedCountry, setSelectedCountry] = useState("FR");
  const [countryCode, setCountryCode] = useState("+33");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onCountryChange = (countryObj) => {
    setSelectedCountry(countryObj.bigram);
    // add the code for country dial code
    setCountryCode(countryObj.dialCode);
  };
  const [date, setDate] = useState(null);

  const [isPhonePublic, setIsPhonePublic] = useState(false);
  const [isLastNamePublic, setIsLastNamePublic] = useState(false);
  const [isBirthdayPublic, setIsBirthdayPublic] = useState(false);

  const [alreadyFriendsModalVisible, setAlreadyFriendsModalVisible] =
    useState(false);

  // Supposons que vous avez déclaré ces états ailleurs
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  const [isPhoneAvailable, setIsPhoneAvailable] = useState(false);
  const [isLastNameAvailable, setIsLastNameAvailable] = useState(false);
  const [isBirthdayAvailable, setIsBirthdayAvailable] = useState(false);
  const [isMemberNumberAvailable, setIsMemberNumberAvailable] = useState(false);
  const [popupDialogVisible, setPopupDialogVisible] = useState(false);
  const [popupDialogContent, setPopupDialogContent] = useState("");

  const [isPhoneActive, setPhoneActive] = useState(false);
  const [isInputEmpty, setInputEmpty] = useState(true);

  //llogique pour griser le outton addfreind

  const [alreadyFriend, setAlreadyFriend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchMethod, setSearchMethod] = useState(null);
  const [dateInput, setDateInput] = useState("");

  useEffect(() => {
    //🇫🇷 useEffect pour obtenir token utilisateur et faire verification pour button edit profile
    //🇬🇧 useEffect to get user token and do verification for button edit profile
    Image.getSize(user.avatar, (width, height) => {
      setImageSize(450);
      if (user.sexe === "male") {
        setImageGenre(images.userguy); //🇫🇷 Condition pour afficher image homme ou femme
      } else {
        setImageGenre(images.usergirl);
      }
    });
    const fetchToken = async () => {
      //🇫🇷 Récupération des données utilisateur dans le storage
      const receivedUsertoken = await AsyncStorage.getItem("userToken");
      setUserToken(receivedUsertoken); //🇫🇷 Stockage du token utilisateur dans le state
    };
    fetchToken();
  }, []);

  // console.log("user data", user);
  const [city, setCity] = useState(" "); //🇫🇷 Etat pour gerer la ville de l'utilisateur
  useEffect(() => {
    if (user.city && user.city != "null") {
      let userCity = user.city.split(", ");
      setCity(
        userCity[0].charAt(0).toUpperCase() + userCity[0].slice(1).toLowerCase()
      );
    }
  }, []);

  //const canEdit = user.token === userToken;
  var canEdit;
  if (user.token) {
    if (user.token === userToken) {
      //🇫🇷 Condition pour afficher le bouton edit profile
      canEdit = true;
    } else {
      canEdit = false;
    }
  } else {
    canEdit = true;
  }
  useEffect(() => {
    // console.log("isPhonePublic a été mis à jour:", isPhonePublic);
  }, [isPhonePublic]);

  useEffect(() => {
    // console.log("isLastNamePublic a été mis à jour:", isLastNamePublic);
  }, [isLastNamePublic]);

  useEffect(() => {
    // console.log("isBirthdayPublic a été mis à jour:", isBirthdayPublic);
  }, [isBirthdayPublic]);

  const fetchUserDetails = async (userId) => {
    if (!userId) {
      console.error("Invalid userId:", userId);
      return;
    }

    // console.log("fetchUserDetails executed with userId:", userId);

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      // console.log("Sending request to server with options:", requestOptions);

      const response = await fetch(
        `${hostname}/api/v1/user/getuserinfo/${userId}`,
        requestOptions
      );

      if (!response.ok) {
        console.error("Server responded with an error:", response.status);
      }

      const data = await response.json();
      // console.log("Raw data received from the server:", data);
      if (!data || !data.user) {
        console.error("Invalid data from the server:", data);
        return;
      }

      const user = data.user;
      // console.log("Processed user data from the server:", user);

      if (user) {
        setIsPhonePublic(!!user.isPhonePublic);
        setIsLastNamePublic(!!user.isLastNamePublic);
        setIsBirthdayPublic(!!user.isBirthdayPublic);
        setIsEmailAvailable(!!user.email);
        setIsPhoneAvailable(!!user.phone);
        setIsLastNameAvailable(!!user.lastName);
        setIsBirthdayAvailable(!!user.birthday);
        setIsMemberNumberAvailable(!!user.memberId);
      }
      return user;
    } catch (error) {
      console.error("Error retrieving user details:", error);
    }
    // console.log("user connecter:", user);
  };

  // console.log("user de profile visiter:", user);

  const [profilData, setProfilData] = useState(user);
  const [currentUserId, setCurrentUserId] = useState(user);

  useEffect(() => {
    // console.log("useEffect exécuté");
    if (profilData && profilData._id) {
      const userId = profilData._id;
      setCurrentUserId(userId);
      fetchUserDetails(userId);
    }
  }, [profilData]);
  // console.log("profilData:", profilData);

  const [loggedInUser, setLoggedInUser] = useState(null);
  //recupuration du user connecter depuis le local storage
  useEffect(() => {
    const userFromLocalStorage = localStorage.getItem("user");
    if (userFromLocalStorage) {
      const parsedUser = JSON.parse(userFromLocalStorage);
      setLoggedInUser(parsedUser._id);
    }
  }, []);

  // console.log("user connecter:", loggedInUser);
  // console.log("user de profile visiter:", user._id);

  const [friendEmail, setFriendEmail] = useState("");
  const [friendPhone, setFriendPhone] = useState("");
  const [friendLastName, setFriendLastName] = useState("");
  const [friendBirthday, setFriendBirthday] = useState("");
  const [friendMemberNumber, setFriendMemberNumber] = useState("");
  // console.log(friendPhone);

  const fetchUrlMap = {
    memberId: (memberId) =>
      `${hostname}/api/v1/user/getuserbymemberid/${memberId}`,
    lastName: (lastName) =>
      `${hostname}/api/v1/user/getuserbylastname/${lastName}`,
    phoneNumber: (phoneNumber) =>
      `${hostname}/api/v1/user/getuserbyphonenumber/${phoneNumber}`,
    birthday: (birthdayString) =>
      `${hostname}/api/v1/user/getuserbybirthday/${dateToTimestamp(
        birthdayString
      )}`,
    email: (email) => `${hostname}/api/v1/user/getuserbyemail/${email}`,
  };

  const errorMessageMap = {
    memberId: JsonConfirmFriendshipPopup.JsonPopupMemberIdErrorNotARealFriend,
    lastName: JsonConfirmFriendshipPopup.JsonPopupLastNameErrorNotARealFriend,
    phoneNumber:
      JsonConfirmFriendshipPopup.JsonPopupPhoneNumberErrorNotARealFriend,
    birthday: JsonConfirmFriendshipPopup.JsonPopupBirthdayErrorNotARealFriend,
    email: JsonConfirmFriendshipPopup.JsonPopupEmailErrorNotARealFriend,
  };

  const handleAddFriend = async (input, type) => {
    if (!fetchUrlMap[type]) {
      console.error(`Invalid type provided: ${type}`);
      return;
    }

    try {
      const fetchUrl = fetchUrlMap[type](input);
      const visitedUserResponse = await fetch(fetchUrl);
      if (!visitedUserResponse.ok) {
        if (visitedUserResponse.status === 404) {
          throw new Error(errorMessageMap[type]);
        }
        throw new Error(`HTTP error! Status: ${visitedUserResponse.status}`);
      }
      const visitedUser = await visitedUserResponse.json();
      if (!visitedUser._id) {
        throw new Error(errorMessageMap[type]);
      }

      const userInfoResponse = await fetch(
        `${hostname}/api/v1/user/getuserinfo/${visitedUser._id}`
      );
      if (!userInfoResponse.ok) {
        throw new Error(`HTTP error! Status: ${userInfoResponse.status}`);
      }
      const userInfo = await userInfoResponse.json();

      setFriendEmail(userInfo.user.email || "");
      setFriendPhone(userInfo.user.phone || "");
      setFriendLastName(userInfo.user.lastName || "");
      setFriendBirthday(dateToTimestamp(userInfo.user.birthday) || "");
      setFriendMemberNumber(userInfo.user.memberNumber || "");

      const addFriendResponse = await fetch(
        `${hostname}/api/v1/user/friends/${visitedUser._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "logged-in-user-id": loggedInUser,
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!addFriendResponse.ok) {
        switch (addFriendResponse.status) {
          case 409:
            setPopupDialogContent(
              JsonConfirmFriendshipPopup.JsonPopupAlreadyFriend
            );
            break;
          case 400:
            throw new Error(
              JsonConfirmFriendshipPopup.JsonPopupErrorImpossibleToAddFriendBadRequest
            );
            break;
          default:
            throw new Error(
              JsonConfirmFriendshipPopup.JsonPopupErrorImpossibleToAddFriend
            );
        }
      } else {
        const addFriendResult = await addFriendResponse.json();
        if (addFriendResult && addFriendResult.updatedFriendsList) {
          const currentUserData = JSON.parse(localStorage.getItem("user"));
          const updatedUserData = {
            ...currentUserData,
            friends: addFriendResult.updatedFriendsList,
          };
          localStorage.setItem("user", JSON.stringify(updatedUserData));
        }
        setPopupDialogContent(
          JsonConfirmFriendshipPopup.JsonPopupCongratulationsYouAreNowFriend
        );
        setAlreadyFriend(true);
      }
    } catch (error) {
      console.log(error);
      setPopupDialogContent(
        error.message || JsonConfirmFriendshipPopup.JsonPopupError
      );
    } finally {
      setPopupDialogVisible(true);
    }
  };

  function dateToTimestamp(dateString) {
    const [day, month, year] = dateString.split("/");
    
    // Ajout de vérifications pour s'assurer que day, month et year sont définis
    const formattedMonth = month ? month.padStart(2, '0') : '';
    const formattedDay = day ? day.padStart(2, '0') : '';
  
    const date = new Date(year, formattedMonth - 1, formattedDay);
    
    // Formatage de la date en "AAAA-MM-JJ"
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    
    return formattedDate;
  }
  
  
  function timestampToDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  // console.log(dateToTimestamp("27/03/1981"));

  //console.log(convertToTimestamp("27/03/1981"));

  const checkIfAlreadyFriend = () => {
    //const user = JSON.parse(localStorage.getItem("user"));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    const isFriend = loggedInUser?.friends?.includes(currentUserId);

    setAlreadyFriend(isFriend);
  };

  //console.log("alreadyFriend:", alreadyFriend);
  const handlePress = async () => {
    setIsLoading(true);

    try {
      if (!alreadyFriend) {
        setAddFriendModalVisible(true);
        fetchUserDetails(currentUserId);
        await handleAddFriend();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'ami:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    checkIfAlreadyFriend();
  }, [currentUserId, loggedInUser]);

  const disabledButtonStyle = {
    opacity: 0.5,
  };

  return (
    <ScrollView style={styles.container}>
      <SafeAreaView>
        <View style={{ height: imageSize, position: "relative" }}>
          <Image
            source={
              user.avatar ===
              "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                ? imageGenre
                : user.avatar
            }
            style={styles.profilePhoto}
          />
          {canEdit && (
            <Pressable
              onPress={() => {
                navigation.navigate("Edit Profile");
              }}
              style={styles.editBtn}
            >
              <Image source={images.Edit} style={styles.editImg} />
              <Text style={styles.editBtnText}>
                {JsonProfilePages.JsonEditProfileScreen.JsonTitleEditProfile}
              </Text>
            </Pressable>
          )}
        </View>

        <View style={styles.actionProfile}>
          {/* 🇫🇷  Ci-dessous , element pour voir si l'utilisateur a été organisateur et sa fiabilité (reliability)*/}
          {/* 🇬🇧 Below, element to see if the user was an organizer and its reliability (reliability)*/}
          {/* <View style={styles.headerRow}>
            <View>
              <Text style={styles.text}>Organizer : never</Text>
            </View>
            <View style={styles.hRow2}>
              <Text style={styles.text}>Reliability : </Text>
              <View style={{ height: 10 }}>
                <Bar
                  style={{ marginTop: 6 }}
                  progress={0.8}
                  color="green"
                  height={12}
                  unfilledColor="red"
                  borderWidth={0}
                />
              </View>
            </View>
          </View> */}
          {/*🇫🇷 Points Container , pour mettre en place system de points*/}
          {/*🇬🇧 Points Container , pour put in on system points*/}
          {/* <View style={styles.pointsContainer}>
            <View>
              <View style={styles.medalCtn}>insert image</View>
              <Text style={styles.userPoint}>9000 {JsonProfilePages.JsonMyProfileScreen.JsonPoints}</Text>
            </View>
          </View> */}

          <View style={styles.infoText}>
            <Text style={styles.userPseudo}>{user.firstName}</Text>
            {/*🇫🇷 La variable JsonProfilePages.JsonMyProfileScreen.JsonYears dans fr.json permet d'afficher "ans", il manque envoyer depuis backend l'age de l'utilisateur*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonMyProfileScreen.JsonYearsl displays "years", it misses send from backend the age of the user*/}
            {/* {age && (
              <Text style={styles.userAge}>
                {age} {JsonProfilePages.JsonMyProfileScreen.JsonYears}
              </Text>
            )}
            {age === null || isNaN(age) || age === 0 ? null : (
              <Text style={styles.userAge}>
                {age} {JsonProfilePages.JsonMyProfileScreen.JsonYears}
              </Text>
            )} */}
            {/*🇫🇷 La variable profile.usercity dans fr.json permet d'afficher la ville de residence utilisateur,elle est modifie avec de methodes pour le mettre en bon format*/}
            {/*🇬🇧 The variable profile.usercity in en.json allows to display the user's city of residence, it is modified with methods to put it in the right format*/}
            <View style={{ flexDirection: "row" }}>
              <Image
                source={images.Placeholder2}
                style={styles.placeHolderImg}
              />
              <Text style={styles.userPoint}>{city}</Text>
              {/*🇫🇷 La variable JsonProfilePages.JsonMyProfileScreen.JsonPoints dans fr.json permet d'afficher "points", pour le sisteme de qualification de l'aplication*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonMyProfileScreen.JsonPoints displays "points" , for the system qualification app*/}
            </View>
          </View>
          {/*🇫🇷 IMAGE COMMING SOONr*/}
          {/* <View style={{ flex: 1, marginTop: 25, marginHorizontal: 15 }}>
          <img src={ComingSoon} alt="" width="90%" height="auto" />
          </View>
          {/*🇫🇷 BARRE D'OUTILS POUR AJOUTER-BLOQUER-CHAT: A voir si on l'utilise pour version webview*/}
          {!canEdit && (
            <View style={styles.btnContainer}>
              {/*---------------------------🇫🇷 Bouton pour ajouter un utilisateur en ami ------------------------------*/}
              {!alreadyFriend && (
                <Pressable
                  style={[
                    styles.addFriend,
                    isLoading ? disabledButtonStyle : null,
                  ]}
                  onPress={handlePress}
                  disabled={isLoading}
                >
                  <img src={images.AddFriend} />
                  <Text
                    style={{
                      paddingLeft: 5,
                      display: width > 350 ? "flex" : "none",
                    }}
                  >
                    {JsonProfilePages.JsonMyProfileScreen.JsonAddFriend}
                  </Text>
                </Pressable>
              )}
              {/* le modal principale avec les options pour ajouter un ami (Frame22) */}
              <AddFriendModal
                addFriendModalVisible={addFriendModalVisible}
                setAddFriendModalVisible={setAddFriendModalVisible}
                emailPressed={emailPressed}
                setEmailPressed={setEmailPressed}
                lastNamePressed={lastNamePressed}
                setLastNamePressed={setLastNamePressed}
                phonePressed={phonePressed}
                setPhonePressed={setPhonePressed}
                birthdayPressed={birthdayPressed}
                setBirthdayPressed={setBirthdayPressed}
                setMemberNumberPressed={setMemberNumberPressed}
                buttonPressed={buttonPressed}
                setButtonPressed={setButtonPressed}
                scr={scr}
                isPhonePublic={isPhonePublic}
                isLastNamePublic={isLastNamePublic}
                isBirthdayPublic={isBirthdayPublic}
                isPhoneAvailable={isPhoneAvailable}
                memberNumberPressed={memberNumberPressed}
                continuePressed={continuePressed}
                setContinuePressed={setContinuePressed}
                setConfirmEmailModalVisible={setConfirmEmailModalVisible}
                setMemberNumberModalVisible={setMemberNumberModalVisible}
                setLastNameModalVisible = {setLastNameModalVisible}
                setBirthdayModalVisible={setBirthdayModalVisible}
              />
              {/* modal ajout d'ami par email */}
              <ConfirmEmailModal
                  confirmEmailModalVisible={confirmEmailModalVisible}
                  setConfirmEmailModalVisible={setConfirmEmailModalVisible}
                  setAddFriendModalVisible={setAddFriendModalVisible}
                  friendEmail={friendEmail}
                  setFriendEmail={setFriendEmail}
                  handleAddFriend={handleAddFriend}
                  setModalVisible={setModalVisible}
                  scr={scr}
              />
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
                scr={scr}
              />
              <Pressable onPress={() => setPhoneModalVisible(true)}></Pressable>
              {/* modal ajout d'ami par téléphone */}
              <ConfirmPhoneModal
                  phoneModalVisible={phoneModalVisible}
                  setPhoneModalVisible={setPhoneModalVisible}
                  setAddFriendModalVisible={setAddFriendModalVisible}
                  friendPhone={friendPhone}
                  setFriendPhone={setFriendPhone}
                  handleAddFriend={handleAddFriend}
                  setModalVisible={setModalVisible}
                  scr={scr}
                  isInputEmpty={isInputEmpty}
              />
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
                scr={scr}
              />
              {/* modal ajout d'ami par nom de famille */}
              <ConfirmLastNameModal
                  lastNameModalVisible={lastNameModalVisible}
                  setLastNameModalVisible={setLastNameModalVisible}
                  setAddFriendModalVisible={setAddFriendModalVisible}
                  friendLastName={friendLastName}
                  setFriendLastName={setFriendLastName}
                  handleAddFriend={handleAddFriend}
                  setModalVisible={setModalVisible}
                  scr={scr}
                />
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
                scr={scr}
              />
              {/* modal ajout d'ami par date de naissance */}
              <ConfirmBirthdayModal
                  birthdayModalVisible={birthdayModalVisible}
                  setBirthdayModalVisible={setBirthdayModalVisible}
                  setAddFriendModalVisible={setAddFriendModalVisible}
                  friendBirthday={friendBirthday}
                  setFriendBirthday={setFriendBirthday}
                  handleAddFriend={handleAddFriend}
                  setModalVisible={setModalVisible}
                  scr={scr}
              />
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
                scr={scr}
              />
              {/* modal ajout d'ami par numéro de membre */}
              <ConfirmMemberNumberModal
                memberNumberModalVisible={memberNumberModalVisible}
                setMemberNumberModalVisible={setMemberNumberModalVisible}
                setAddFriendModalVisible={setAddFriendModalVisible}
                friendMemberNumber={friendMemberNumber}
                setFriendMemberNumber={setFriendMemberNumber}
                handleAddFriend={handleAddFriend}
                setModalVisible={setModalVisible}
                scr={scr}
              />
              <PopupDialog
                isShown={popupDialogVisible}
                dialogMessage={popupDialogContent}
                onDismiss={() => setPopupDialogVisible(false)}
                scr={scr}
              />
              {/*---------------------------🇫🇷 Bouton pour tchat------------------------- ------------------------------*/}
              {/*🇫🇷 La variable JsonProfilePages.JsonMyProfileScreen.JsonChatdans fr.json permet d'afficher "Chat"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonMyProfileScreen.JsonChat displays "Chat"*/}
              {/* <Pressable style={styles.chat}>
                <img src={Chat} />
                <Text
                  style={{
                    paddingLeft: 5,
                    display: width > 350 ? "flex" : "none",
                  }}
                >
                  {JsonProfilePages.JsonMyProfileScreen.JsonChat}
                </Text>
              </Pressable> */}
              {/*🇫🇷 La variable JsonProfilePages.JsonMyProfileScreen.JsonBlock}dans fr.json permet d'afficher "Bloquer"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonMyProfileScreen.JsonBlock} displays "Block"*/}

              {/*---------------------------🇫🇷 Bouton pour Bloquer ------------------------------*/}
              {/* <Pressable
                style={styles.block}
                onPress={() => setModalVisible(true)}
              >
                <img src={BlockFriend} />
                <Text
                  style={{
                    paddingLeft: 5,
                    display: width > 350 ? "flex" : "none",
                  }}
                >
                  {JsonProfilePages.JsonMyProfileScreen.JsonBlock}
                </Text>
              </Pressable> */}

              <ConfirmationModal
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                confirmPressed={confirmPressed}
                setConfirmPressed={setConfirmPressed}
                cancelPressed={cancelPressed}
                setCancelPressed={setCancelPressed}
                scr={scr}
              />
            </View>
          )}
        </View>

        {/*🇫🇷 menu de navigation crée pour switcher entre les pages information, activités et friends de l'utilisateur*/}
        {/*🇬🇧 navigation menu created to switch between the user's information, activities and friends pages*/}
        {/*🇫🇷 les functionalités activites et reseau sont en off pour l'instant*/}
        {/*🇬🇧 the activities and network function are off for now*/}

        <TopNavigation
          arg={[
            //🇫🇷 c'est le menu de navigation qui permet de switcher entre les pages information, activités et friends de l'utilisateur*/}
            {
              link: JsonProfilePages.JsonMyProfileScreen.JsonInformations,
              to: (props) => (
                <ProfileInfo {...props} user={user} scr={scr} city={city} />
              ),
            },
            //c'est le menu de navigation qui permet de switcher entre les pages information, activités et Network de l'utilisateur*/}
            {
              link: JsonProfilePages.JsonMyProfileScreen.JsonActivities,
              to: (props) => <ProfileActivities {...props} userId={user._id} />,
            },
            // //c'est le menu de navigation qui permet de switcher entre les pages information, activités et Network de l'utilisateur*/}
            {
              link: JsonProfilePages.JsonMyProfileScreen.JsonNetwork,
              to: (props) => <ProfileNetwork {...props} userId={user._id} />,
            },
          ]}
        />
      </SafeAreaView>
    </ScrollView>
  );
};

export default MyProfileScreen;