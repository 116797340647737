//🇫🇷 Ce fichier gère toutes les pages d'édition de profil (FrameFigma15-FrameFigma16-FrameFigma17)
//🇬🇧 This file manage all edit profile pages (FrameFigma15-FrameFigma16-FrameFigma17)

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import moment from "moment"; // biblioteque javasCript pour travailler avec la date
import React, { useEffect, useState } from "react";
import {
  StyleSheet
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Composants
import { countriesListRectFlags as countriesList } from "../../../assets/countriesListRectFlags.js";

// import des images

import EditProfileStepOne from "./EditProfileStepOne/EditProfileStepOne.js";
import EditProfileStepThree from "./EditProfileStepThree/EditProfileStepThree.js";
import EditProfileStepTwo from "./EditProfileStepTwo/EditProfileStepTwo.js";

//UPDATE: Import the {CountriesGrid_SeveralFlags} from the CountriesGrids.js component
// FR Variable json se trouvant dans en.json permet de faire la traduction des differentes langues
// GB Json variable located in en.json allows the translation of different languages

const EditProfileScreen = ({
  token,
  flags,
  scr,
  user,
  setUser,
  onClick,
  payscountry,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const dispatch = useDispatch(); // passage de la langue selectionnée in countryListDropdown,
  useEffect(() => {
    // à l'APP, onClick(langue) est le messanger dell"APP
    dispatch({ type: language });
  }, []);
  const langue = useSelector((state) => state.langue);
  // console.log(langue);
  onClick(langue);

  const { JsonProfilePages } = scr;

  const navigation = useNavigation();

  //Demande users-list pour check nickname
  const [users, setUsers] = useState([]);
  const [nickNameIsValid, setNickNameIsValid] = useState(false);

  // logique de page/buttons
  const [step, setStep] = useState(1);
  const [leftActive, setLeftActive] = useState(false); //🇫🇷 valeur para accountType dans BD //🇬🇧 accounType value in BD
  const [rightActive, setRightActive] = useState(false);
  const [pressed, setPressed] = useState(false); //🇫🇷 condition pour rendre rouge bord fields //🇬🇧 condition for put borderline red in fields
  const [selected, setSelected] = useState(false);
  const [selectAccount, setSelectedAccount] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);

  //messages d'erreur // error messages
  const [errorMsgFirstName, setErrorMsgFirstName] = useState(null);
  const [errorMsgNickName, setErrorMsgNickName] = useState(null);
  const [errorMsgCity, setErrorMsgCity] = useState(null);
  const [errorMsgLastName, setErrorMsgLastName] = useState(null);
  const [errorMsgPhone, setErrorMsgPhone] = useState(null);
  const [errorMsgBirthday, setErrorMsgBirthday] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [errorMsgAvatarImage, setErrorMsgAvatarImage] = useState(null);

  //states pour enregitrement de données

  // step 1
  const [profilData, setProfilData] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [language, setLanguage] = useState(payscountry);
  //const [nativeLanguage, setNativeLanguage] = useState(null);
  const [appLanguage, setAppLanguage] = useState(language); // useState pour passer langue à l'aplication
  // step 2
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [birthdayDate, setBirthdayDate] = useState(null); //🇫🇷 state pour convertir avec biblioteque moment de string à nombre //🇬🇧 state to convert with library moment from string to number
  const [birthdayNumber, setBirthdayNumber] = useState(0); //🇫🇷 state pour convertir avec biblioteque moment de nombre à string //🇬🇧 state to convert with library moment from number to string
  const [email, setEmail] = useState(null);
  const [city, setCity] = useState(null);
  const [about, setAbout] = useState(null);
  const [topics, setTopics] = useState([]); // hobbies utilisateur in NUMBER
  //const [topic, setTopic] = useState(-1); //voir le code de createActivity car c'est la meme logique qui est appliquée
  //const [activityList, setActivityList] = useState(null);
  const [languagesSpoken, setLanguagesSpoken] = useState([]); // les langues parlées pour l'utilisateur NUMBER
  //States pour selection de children, tobacco, age et alcool
  const [children, setChildren] = useState(null); // ATTENTION dans le model du backend , il figure comme NUMBER
  const [titleChildren, setTitleChildren] = useState(""); // ATTENTION en string
  const [tobacco, setTobacco] = useState(null); // ATTENTION dans le model du backend , il figure comme NUMBER
  const [titleTobacco, setTitleTobacco] = useState(""); // ATTENTION en string
  const [alcohol, setAlcohol] = useState(null); // ATTENTION dans le model du backend , il figure comme NUMBER
  const [titleAlcohol, setTitleAlcohol] = useState(""); // ATTENTION en string
  const [age, setAge] = useState(null);
  const [titleAge, setTitleAge] = useState("");

  // pour enregistrer avatar
  const [profileImage, setProfileImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);

  //states pour studies/university (à voir si c'est implementé dans editprofile)
  const [studies, setStudies] = useState("secret");
  const [university, setUniversity] = useState("secret");
  const [test, setTest] = useState(false);
  // données utilisateur non changeables
  const [accountType, setAccountType] = useState(null); // personal account or pro (not boolean ==> see leftActive)
  const [memberId, setMemberId] = useState("");
  const [role, setRole] = useState(user?.role);
  const [msgErrorProfile, setMsgErrorProfile] = useState("");

  // console.log(profilData);

  const profileState = {
    //🇫🇷 variable qui contient les states pour les passer dans les composants
    accountType,
    setAccountType,
    memberId,
    setMemberId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    nickName,
    setNickName,
    city,
    setCity,
    language,
    setLanguage,
    role,
    setRole,
    avatarImage,
    setAvatarImage,
    isPhoneActive,
    setPhoneActive,
    isLastNameActive,
    setLastNameActive,
    isBirthdayActive,
    setBirthdayActive,
  };

  const initScreen = () => {
    //🇫🇷 fonction pour initialiser les states avec les données de l'utilisateur
    if (profilData.birthday > 0) {
      setBirthdayDate(moment(profilData.birthday).format("DD/MM/YYYY")); //🇫🇷moment passe la date en format DD/MM/YYYY depuis BD //🇬🇧 moment put the date in format DD/MM/YYYY depuis DB
    }
    if (profilData.isPersonalAccount) {
      setLeftActive(true);
      setRightActive(false);
    } else {
      setLeftActive(false);
      setRightActive(true);
    }
    setAvatarImage(profilData.avatar);
    setMemberId(profilData.memberId);
    setFirstName(profilData.firstName === "null" ? null : profilData.firstName);
    setNickName(profilData.userName === "null" ? null : profilData.userName);
    setCity(profilData.city === "null" ? null : profilData.city);
    // console.log(profilData.nativeLanguage);
    setLanguage(
      profilData.nativeLanguage === "undefined" ||
        profilData.nativeLanguage === "null" ||
        !profilData.nativeLanguage
        ? payscountry
        : profilData.nativeLanguage
    ); //pour probleme d'enregistrement de langue aprés verification user, enlever condition si c'est ok
    setLastName(profilData.lastName === "null" ? null : profilData.lastName);
    setPhoneNumber(profilData.phone);
    setEmail(profilData.email);
    setAccountType(
      profilData.isPersonalAccount === null
        ? null
        : profilData.isPersonalAccount
    );
    setPhoneActive(profilData.isPhonePublic ?? false);
    setLastNameActive(profilData.isLastNamePublic ?? false);
    setBirthdayActive(profilData.isBirthdayPublic ?? false);
    setAbout(profilData.about);
    setTopics(profilData.hobbies ?? []); /// tableau vide pour bug dutilisateur non verifies
    setLanguagesSpoken(profilData.spokenLanguage ?? []); // tableau vide pour bug
    setChildren(profilData.children ?? null);
    setTobacco(profilData.tobacco ?? null);
    setAlcohol(profilData.alcohol ?? null);
    setAge(profilData.age ?? null);
    if (
      profilData.firstName === "null" ||
      profilData.userName === "null" ||
      profilData.city === "null" ||
      profilData.lastName === "null" ||
      profilData.isPersonalAccount === null
    ) {
      setTest(true);
    }
  };
  const [modalVisible, setModalVisible] = useState(false);

  const handleDateSelection = (selectedDate) => {

     // Formatage de la date au format "AAAA-MM-JJ"
      const formattedDate = `${selectedDate.getFullYear()}-${
        String(selectedDate.getMonth() + 1).padStart(2, "0")
      }-${String(selectedDate.getDate()).padStart(2, "0")}`;


    // console.log("handleDateSelection called");
    setBirthdayDate(formattedDate);
    setModalVisible(false);
  };
  //Import d'information utilisateur

  /*🇫🇷 Les informations proviens du user.storage(avant les info etaient cherchais sur "Profile" en storage) qui est enregistré dans le store dans user , cet store est enregistré quand on fait log-in dans la aplication  , à voir plus dans le fichier passwordscreen.js*/
  /*🇬🇧 The information comes from user.storage ( before , the infos was getted from "Profile" en storage), which is registered in the user store, the store is registered when you log-in in the application, see more in the passwordscreen.js file"*/

  useEffect(() => {
    const getProfileData = async () => {
      // console.log("getProfilData");
      let data = JSON.parse(await AsyncStorage.getItem("user"));

      if (!data) {
        // Récupérez les données de l'utilisateur depuis la base de données
        data = await fetchUserDataFromDatabase();

        // Stockez les données récupérées dans AsyncStorage
        await AsyncStorage.setItem("user", JSON.stringify(data));
      }
      setProfilData(data);
      setisLoading(false);
    };

    const fetchUserDataFromDatabase = async () => {
      try {
        const response = await axios.get(
          `${hostname}/api/v1/user/getuserinfo/${user._id}`
        );

        if (response.status !== 200) {
          throw new Error(
            "Erreur lors de la récupération des données de l'utilisateur."
          );
        }

        return response.data.user;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    if (!profilData)
      getProfileData(); //🇫🇷 Si les données de profil ne sont pas disponibles, récupérez-les depuis AsyncStorage
    //🇫🇷 Si les données de profil ne sont pas disponibles, récupérez-les depuis AsyncStorage
    //🇬🇧 If profile data is not available, fetch it from AsyncStorage
    else {
      // console.log("profile data chargé", profilData);
      initScreen();
      setisLoading(false);
    }
  }, [profilData]);

  /////////////////🇫🇷  Validations de données avant etre envoyés/*🇬🇧 data validations before send///////////////////////////////////
  const [skip, setSkip] = useState(0);
  const limit = 1;
  //🇫🇷 User effect pour demande de liste users/*🇬🇧 UseEffect for check nickname
  useEffect(() => {
    axios
      .get(`${hostname}/api/v1/user/getuserlist?limit=${limit}&skip=${skip}`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    //🇫🇷 Si le nickname existe dans la BD, le formulaire n'est pas valide
    if (users.length > 0 && nickName) {
      const userNameExists = users.some((user) => user.userName === nickName);
      setNickNameIsValid(userNameExists);
    }
    setSkip(skip + limit);
  }, [nickName]);

  const HandleValidate = () => {
    //🇫🇷 Fonction pour valider les données avant de les envoyer au serveur
    setPressed(true);
    setIsFormValid(true);

    /*🇫🇷 Validation pour firstName,avec regex, pour accepter des lettres et des espaces
        /*🇬🇧 Validation for firstName,with regex for acceptings just letters and spaces*/
    const NameRegex = /^[A-Za-z]+(?:[- ][A-Za-z]+)*$/;
    if (!avatarImage) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName dans fr.json permet d'afficher "Prénom manquant"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName displays "Missing first name"*/
      setErrorMsgAvatarImage(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonAvatarImageMissing
      ); // Assurez-vous que ce message d'erreur est défini dans votre fichier JSON.
      setIsFormValid(false);
    }
    if (!firstName) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName dans fr.json permet d'afficher "Prénom manquant"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName displays "Missing first name"*/
      setErrorMsgFirstName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonFirstNameMissing
      );
      setIsFormValid(false);
    } else if (!NameRegex.test(firstName)) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName dans fr.json permet d'afficher "Prénom ne peut pas contenir de caratère spéciaux ou des chiffres"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrFirstName displays "First name can't contain numbers or special characters"*/
      setErrorMsgFirstName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonFirstNameCannotContainSpecialCharacter
      );
      setIsFormValid(false);
    } else {
      setErrorMsgFirstName("");
    }

    /*🇫🇷 Validation pour nickName, s'il existe dans la BD , le formulaire n'est pas valide
     /*🇬🇧 Validation for nickName, if it exists in the DB, the form is invalid */

    const NickNameRegex = /^[^(){}\[\]=]+$/;
    if (!nickName) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickname dans fr.json permet d'afficher "Surnom manquant"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickname displays "Missing nickname"*/
      setErrorMsgNickName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonNicknameMissing
      );
      setIsFormValid(false);
    } else if (nickName.length > 15) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickAnomaly dans fr.json permet d'afficher "Surnom max 15 lettres"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickAnomaly displays "Nickname max 15 lettres"*/
      setErrorMsgNickName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonNickname15LetterMax
      );
      setIsFormValid(false);
    } else if (nickNameIsValid) {
      /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickAnomaly2 dans fr.json permet d'afficher "Surnom existe déjà"*/
      /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrNickAnomaly2 displays "Nickname already exist"*/
      setErrorMsgNickName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonNicknameAlreadyExist
      );
      setIsFormValid(false);
    } else if (!NickNameRegex.test(nickName)) {
      setIsFormValid(false);
      setErrorMsgNickName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonNicknameCannotContainSpecialCharacter
      );
    } else {
      setErrorMsgNickName("");
    }

    if (!city) {
      setErrorMsgCity(
        /*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrCity dans fr.json permet d'afficher "Ville manquante"*/
        /*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.t2022_ErrCity displays "Missing City"*/
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonCityMissing
      );
    }

    /*🇫🇷 Validation pour Nom,avec regex, pour accepter des lettres et des espaces
       /*🇬🇧 Validation for lastName,with regex for acceptings just letters and spaces*/
    const LastNameRegex = /^[A-Za-z]+(?:[- ][A-Za-z]+)*$/;
    if (!lastName) {
      setErrorMsgLastName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonLastNameMissing
      );
      setIsFormValid(false);
    } else if (!LastNameRegex.test(lastName)) {
      setErrorMsgLastName(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonLastNameCannotContainSpecialCharacter
      );
      setIsFormValid(false);
    } else {
      setErrorMsgLastName("");
    }

    /*🇫🇷 Validation pour numero de telephone,avec regex, pour accepter juste de nombres
    /*🇬🇧 Validation for phoneNumber,with regex for acceptings just numbers*/
    if (phoneNumber && !/^\d+$/.test(phoneNumber)) {
      setErrorMsgPhone(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonPhoneNotValid
      );
      setIsFormValid(false);
    } else {
      setErrorMsgPhone("");
    }
    /*🇫🇷 Validation pour date d'anniversaire,avec biblioteque moment , pour accepter un format de date valid DD/MM/YYYY
     /*🇬🇧 Validation for birthday date, with moment library, to accept a valid date format DD/MM/YYYY*/
    if (birthdayDate) {
      const isValidDate = moment(birthdayDate, "DD/MM/YYYY", true).isValid();
      if (!isValidDate) {
        setIsFormValid(false);
        setErrorMsgBirthday(
          JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
            .JsonBirthFormat
        );
      } else {
        const numberValue = moment(birthdayDate, "DD/MM/YYYY").valueOf();
        const timestamp = moment(numberValue);
        const age = moment().diff(timestamp, "years");
        // console.log(age);
        setAge(age); // Ajoutez cette ligne pour définir l'âge dans l'état

        if (age < 18) {
          setIsFormValid(false);
          setErrorMsgBirthday(
            JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
              .JsonBirthMustBeMajor
          );
        } else {
          setIsFormValid(true);
          setErrorMsgBirthday("");
        }
        setBirthdayNumber(numberValue);
      }
    }

    /*🇫🇷 Validation pour email,avec regex, pour accepter un format d'adresse mail
       /*🇬🇧 Validation for email,with regex for acceptings email format valid*/
    if (
      !email &&
      !JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonEmailMissing
    ) {
      setEmailError(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonEmailMissing
      );
      setIsFormValid(false);
    } else if (email && !/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError(
        JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage
          .JsonEmailNotValid
      );
      setIsFormValid(false);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    HandleValidate();
  }, [
    firstName,
    nickName,
    lastName,
    birthdayDate,
    phoneNumber,
    email,
    avatarImage,
  ]);

  useEffect(() => {
    if (
      avatarImage &&
      avatarImage !==
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
    ) {
      setErrorMsgAvatarImage(null);
    }
  }, [avatarImage]);

  const UpdateData = async () => {
    try {
      let res = JSON.parse(await AsyncStorage.getItem("user"));
      setProfilData(res);
      // console.log(res);
      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${res._id}`
      );
      // console.log(response.data.user);
      const resultat = JSON.stringify(response.data.user);
      // console.log(resultat);
      await AsyncStorage.setItem("Profile", resultat);
      await AsyncStorage.setItem("user", resultat);

      // 🇫🇷 Mettre à jour l'état avec les informations les plus récentes
      // 🇬🇧 Update the state with the latest information
      setProfilData(response.data.user);
    } catch (error) {
      console.error(error);
    }
  };

  /*🇫🇷 Ci-dessous, function pour mettre à jours les données dans la BD*/
  /*🇬🇧 Function for update BD */
  const sendInfoEditProfile = async () => {
    // console.log("is FORM VALID", isFormValid);
    let res = JSON.parse(await AsyncStorage.getItem("user"));
    let image;
    if (avatarImage === null) {
      //🇫🇷 Si l'utilisateur n'a pas choisi une image, on envoie l'image qui est dans la BD
      // console.log(res.avatar);
      image = res.avatar;
    }
    if (isFormValid) {
      //🇫🇷 Si le formulaire est valide, on envoie les données à la BD
      const editProfilBody = {
        avatar: avatarImage === null ? image : avatarImage,
        image: [avatarImage],
        isPersonalAccount: leftActive === false ? false : true,
        firstName: firstName,
        userName: nickName,
        city: city,
        nativeLanguage: language, // bug envoi de json à la basse de données , il faut envoyer string de nom language
        lastName: lastName,
        phone: phoneNumber,
        birthday: birthdayNumber,
        about: about,
        hobbies: topics,
        spokenLanguage: languagesSpoken,
        children: children,
        age: age,
        tobacco: tobacco,
        alcohol: alcohol,
        isPhonePublic: isPhoneActive,
        isLastNamePublic: isLastNameActive,
        isBirthdayPublic: isBirthdayActive,
      };
      // console.log("sendInfoEditProfile", editProfilBody);
      // console.log("sendInfoEditProfile", editProfilBody);
      // console.log("ID utilisateur", profilData._id);

      // console.log("TOKEN", token);
      // console.log(editProfilBody);
      try {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editProfilBody),
        };
        const response = await fetch(
          `${hostname}/api/v1/user/info/${profilData._id}`,
          requestOptions
        );
        const data = await response.json();
        // console.log("data", data);
        //🇫🇷 reemplacement de données sur user , et apres sur le JSON user et Profile  dans le storage
        //🇬🇧 replacement of data on user , and after on the JSON user and Profile in the storage
        Object.assign(user, editProfilBody);
        setUser(user);
        UpdateData();
        if (test === true) {
          navigation.replace("Profile", {
            user: user,
          }); /*🇫🇷 navegation vers Profile */ /*🇬🇧 navigation to Profile*/
        } else {
          navigation.navigate("Profile", { user: user });
        }
      } catch (error) {
        console.log("CATCH :", error);
      }
    }
  };

  // console.log("sendInfoEditProfile");
  const [isPhoneActive, setPhoneActive] = useState(null);
  const [isLastNameActive, setLastNameActive] = useState(null);
  const [isBirthdayActive, setBirthdayActive] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [phone, lastName, birthday] = await Promise.all([
          AsyncStorage.getItem("isPhoneActive"),
          AsyncStorage.getItem("isLastNameActive"),
          AsyncStorage.getItem("isBirthdayActive"),
        ]);

        if (phone !== null) {
          setPhoneActive(JSON.parse(phone));
        } else {
          setPhoneActive(false); // initialise à false si non présent dans AsyncStorage
        }

        if (lastName !== null) {
          setLastNameActive(JSON.parse(lastName));
        } else {
          setLastNameActive(false); // initialise à false si non présent dans AsyncStorage
        }

        if (birthday !== null) {
          setBirthdayActive(JSON.parse(birthday));
        } else {
          setBirthdayActive(false); // initialise à false si non présent dans AsyncStorage
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updateStorage = async () => {
      try {
        await Promise.all([
          AsyncStorage.setItem("isPhoneActive", JSON.stringify(isPhoneActive)),
          AsyncStorage.setItem(
            "isLastNameActive",
            JSON.stringify(isLastNameActive)
          ),
          AsyncStorage.setItem(
            "isBirthdayActive",
            JSON.stringify(isBirthdayActive)
          ),
        ]);
      } catch (error) {
        console.error("Error updating storage:", error);
      }
    };

    updateStorage();
  }, [isPhoneActive, isLastNameActive, isBirthdayActive]);

  //🇫🇷 style de button pour enregistrer données dans la BD, si données ne sont pas bonnes, le button est desactivé
  //🇬🇧 style for button register in BD , if data is not good, button is off
  const bigBtn1 = StyleSheet.create({
    button: {
      backgroundColor: isFormValid ? "#59c09b" : "grey",
      height: 50,
      width: "40%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      marginHorizontal: 10,
    },
  });

  if (isLoading) return;

  const allFieldsFilled = () => {
    return (
      avatarImage &&
      avatarImage !==
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" &&
      firstName &&
      nickName &&
      city &&
      language &&
      appLanguage
    );
  };

  // console.log("Avatar Image:", avatarImage);
  // console.log("All fields filled:", allFieldsFilled());

  if (step === 1) {
    return (
      <EditProfileStepOne 
        avatarImage = {avatarImage}
        setAvatarImage = {setAvatarImage}
        profileImage = {profileImage}
        setProfileImage = {setProfileImage}
        scr = {scr}
        profileState = {profileState}
        setSelected = {setSelected}
        leftActive = {leftActive}
        setLeftActive = {setLeftActive}
        rightActive = {rightActive}
        setRightActive = {setRightActive}
        pressed = {pressed}
        firstName = {firstName}
        setFirstName = {setFirstName}
        city = {city}
        setCity = {setCity}
        language = {language}
        setLanguage = {setLanguage}
        countriesList = {countriesList}
        errorMsgAvatarImage = {errorMsgAvatarImage}
        errorMsgFirstName = {errorMsgFirstName}
        errorMsgCity = {errorMsgCity}
        errorMsgNickName = {errorMsgNickName}
        nickName={nickName}
        setNickName={setNickName}
        appLanguage={appLanguage}
        setAppLanguage={setAppLanguage}
        flags= {flags}
        bigBtn1={bigBtn1}
        allFieldsFilled={allFieldsFilled}
        step={step}
        setStep={setStep}
        sendInfoEditProfile = {sendInfoEditProfile}
      />
    );
  }
  if (step === 2) {
    return (
      <EditProfileStepTwo 
          memberId={memberId}
          lastName={lastName}
          setLastName={setLastName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          birthdayDate={birthdayDate}
          setBirthdayDate={setBirthdayDate}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          isPhoneActive={isPhoneActive}
          setPhoneActive={setPhoneActive}
          isLastNameActive={isLastNameActive}
          setLastNameActive={setLastNameActive}
          isBirthdayActive={isBirthdayActive}
          setBirthdayActive={setBirthdayActive}
          scr={scr}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          errorMsgLastName={errorMsgLastName}
          errorMsgPhone={errorMsgPhone}
          errorMsgBirthday={errorMsgBirthday}
          sendInfoEditProfile={sendInfoEditProfile}
          step={step}
          setStep={setStep}
          bigBtn1={bigBtn1}
      />
    );
  }
  if (step === 3) {
    ///////////////////////////public informations /////////////////////////////
    return (
      <EditProfileStepThree 
        about={about}
        setAbout={setAbout}
        topics={topics}
        setTopics={setTopics}
        languagesSpoken={languagesSpoken}
        setLanguagesSpoken={setLanguagesSpoken}
        titleAge={titleAge}
        setTitleAge={setTitleAge}
        age={age}
        setAge={setAge}
        titleChildren={titleChildren}
        setTitleChildren={setTitleChildren}
        children={children}
        setChildren={setChildren}
        titleTobacco={titleTobacco}
        setTitleTobacco={setTitleTobacco}
        tobacco={tobacco}
        setTobacco={setTobacco}
        titleAlcohol={titleAlcohol}
        setTitleAlcohol={setTitleAlcohol}
        alcohol={alcohol}
        setAlcohol={setAlcohol}
        scr={scr}
        sendInfoEditProfile={sendInfoEditProfile}
        bigBtn1={bigBtn1}
      />
    );
  }
};

export default EditProfileScreen;
