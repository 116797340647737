//🇫🇷 Affichage des champs de texte multiligne (FrameFigma17-FrameFigma34)
//🇬🇧 Display of multiline Input text fields (FrameFigma17-FrameFigma34)

import React from "react";
import { TextInput, View, Text } from "react-native";

// Components
import styles from "./MultilineFieldsCss";

export default function MultilineFields({ lines, title, setState, state }) {
  return (
    <View>
      <TextInput
        value={state}
        onChangeText={(text) => {
          setState(text);
        }}
        multiline={lines ? true : false}
        numberOfLines={lines ? lines : null}
        style={styles.multiline}
      />
      <View style={[styles.textContainer, { width: title.length * 9 }]}>
        <Text>{title}</Text>
      </View>
    </View>
  );
}
