//*************** 🇫🇷 Composant MiddleNav Utilisé sur la page ActivityScreen 🇫🇷**********************//
//******************/ 🇬🇧 MiddleNav Composant Used On the ActivityScreen page 🇬🇧 -------------------//

import React from "react";
import { StyleSheet, Pressable, View, Text } from "react-native";

// images
import { images } from "../constantes";

const MiddleNav = ({
  comments,
  display,
  setDisplay,
  isParticipating,
  connectedUserRole,
  addressAlertDialogVisible,
  setAddressAlertDialogVisible,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  const premiumRoles = ["admin", "moderator"];

  const handleMapReveal = () => {
    if (
      isParticipating ||
      (!isParticipating && premiumRoles.includes(connectedUserRole))
    ) {
      setDisplay(2);
    } else {
      setAddressAlertDialogVisible(!addressAlertDialogVisible);
      setDisplay(1);
    }
  };
  const buttons = [
    //🇫🇷 Tableau de boutons pour la navigation 🇫🇷
    {
      onPress: () => setDisplay(1),
      displayCondition: display === 1,
      source: images.EditMV,
      text: JsonActivityPages.JsonActivityScreen.JsonDescription,
      key: "button1",
    },
    {
      onPress: () => handleMapReveal(),
      displayCondition: display === 2,
      source: images.Position,
      text: JsonActivityPages.JsonActivityScreen.JsonAddress,
      key: "button2",
    },
    {
      onPress: () => setDisplay(3),
      displayCondition: display === 3,
      source: images.BubbleChat,
      text: JsonActivityPages.JsonActivityScreen.JsonChat,
      extraComponent: comments.length > 0 && (
        <View style={styles.notificationBadge}>
          <Text style={styles.notificationBadgeText}>{comments.length}</Text>
        </View>
      ),
      key: "button3",
    },
    {
      onPress: () => setDisplay(4),
      displayCondition: display === 4,
      source: images.Users,
      text: JsonActivityPages.JsonActivityScreen.JsonParticipants,
      key: "button4",
    },
  ];

  return (
    // <View style={styles.container}>
    //   {/********************************CHANGEMENT DE COULEUR POUR LA MIDDLE NAV AU TOUCH***************************************************/}
    //   <Pressable
    //     onPress={() => setDisplay(1)}
    //     style={[
    //       styles.nav,
    //       { backgroundColor: display === 1 ? "#FFA113" : "#59C09B" },
    //     ]}>
    //     <img src={Edit} />

    //     <Text style={styles.navText}>{JsonActivityPages.JsonActivityScreen.JsonDescription}</Text>
    //   </Pressable>

    //   <Pressable
    //     onPress={() => handleMapReveal()}
    //     style={[
    //       styles.nav,
    //       {
    //         borderLeftWidth: 1,
    //         borderLeftColor: "white",
    //         backgroundColor: display === 2 ? "#FFA113" : "#59C09B",
    //       },
    //     ]}>
    //     <img src={Position} height={28} width={28} fill="white" />
    //     <Text style={styles.navText}>{JsonActivityPages.JsonActivityScreen.JsonAddress}</Text>
    //   </Pressable>

    //   <Pressable
    //     onPress={() => setDisplay(3)}
    //     display={display}
    //     backgroundColor="black"
    //     style={[
    //       styles.nav,
    //       {
    //         borderLeftWidth: 1,
    //         borderLeftColor: "white",
    //         backgroundColor: display === 3 ? "#FFA113" : "#59C09B",
    //       },
    //     ]}>
    //     {" "}
    //     <View style={styles.notificationBadge}>
    //       <Text style={styles.notificationBadgeText}>
    //         {comments.length}
    //         {/*  met ta logique pour le nombre de commentaire*/}
    //       </Text>
    //     </View>
    //     <img src={BubbleChat} height={28} width={28} fill="white" />
    //     <Text style={styles.navText}>{JsonActivityPages.JsonActivityScreen.JsonChat}</Text>
    //   </Pressable>

    //   <Pressable
    //     onPress={() => setDisplay(4)}
    //     style={[
    //       styles.nav,
    //       {
    //         borderLeftWidth: 1,
    //         borderLeftColor: "white",
    //         backgroundColor: display === 4 ? "#FFA113" : "#59C09B",
    //       },
    //     ]}>
    //     <img src={Users} />
    //     <Text style={styles.navText}>{JsonActivityPages.JsonActivityScreen.JsonParticipants}</Text>
    //   </Pressable>
    // </View>

    <View style={styles.container}>
      {buttons.map(
        (
          button //🇫🇷 Boucle sur le tableau de boutons 🇫🇷
        ) => (
          <Pressable
            key={button.key}
            onPress={button.onPress}
            style={[
              styles.nav,
              {
                backgroundColor: button.displayCondition
                  ? "#FFA113"
                  : "#59C09B",
              },
              button.additionalStyle,
            ]}
          >
            {button.extraComponent}
            <img src={button.source} height={28} width={28} fill="white" />
            <Text style={styles.navText}>{button.text}</Text>
          </Pressable>
        )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: "5%",
    flexDirection: "row",
    width: "95%",
    height: 70,
    overflow: "hidden",
    borderRadius: 50,
    backgroundColor: "#FFA113",
    justifyContent: "space-evenly",
  },

  nav: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 5,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "white",
  },

  navText: {
    fontWeight: "bold",
    fontSize: 11,
    color: "white",
  },
  notificationBadge: {
    position: "absolute",
    marginLeft: 25,
    marginBottom: 10,
    backgroundColor: "red",
    width: 20,
    height: 20,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  notificationBadgeText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});

export default MiddleNav;
