import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import Fields from '../../../../components/Fields/Fields.js';
import LogButton from '../../../../components/LogButtons/LogButtons.js';

import styles from '../UpdateActivityScreenCss.js';

const UpdateActivityScreenStepFour = ({
  scr,
  setStep,
  step,
  sendModify,
  whatsappLink,
  setWhatsappLink,
  fbPageLink,
  setFbPageLink,
  fbGroupLink,
  setFbGroupLink,
  meetupLink,
  setMeetupLink,
  telegramLink,
  setTelegramLink,
  otherLink,
  setOtherLink,
}) => {

    const {JsonActivityPages} = scr;
    
  return (
    <ScrollView style={styles.container}>
        {/* ----------------Titles---------------- */}
        <Text
          style={[
            styles.boldTitle,
            { alignSelf: "center", marginVertical: 10 },
          ]}
        >
          {/* FR Afficher ce texte dans une nouvelle variable dans en.json si nécessaire*/}
          {/* GB The text should be stored in a new variable inside en.json */}
          {
            JsonActivityPages.JsonActivityManagement
              .JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures
          }
        </Text>

        <Fields
          text="WhatsApp group link"
          state={whatsappLink}
          setState={setWhatsappLink}
          scr={scr}
        />
        <Fields
          text="Page"
          state={fbPageLink}
          setState={setFbPageLink}
          scr={scr}
        />
        <Fields
          text="Group"
          state={fbGroupLink}
          setState={setFbGroupLink}
          scr={scr}
        />
        <Fields
          text="Meetup"
          state={meetupLink}
          setState={setMeetupLink}
          scr={scr}
        />
        <Fields
          text="Telegram"
          state={telegramLink}
          setState={setTelegramLink}
          scr={scr}
        />
        <Fields
          text="Autre Link"
          state={otherLink}
          setState={setOtherLink}
          scr={scr}
        />

        {/*🇬🇧 The rest of the code has been saved in the "help_codes" folder */}
        {/*🇫🇷 Le reste du code a été enregistré dans le dossier "help_codes" */}

        {/* ------------Save or Continue------------ */}
        <View style={[styles.savOrConButtons, { marginTop: 40 }]}>
          <LogButton
            text={JsonActivityPages.JsonActivityManagement.JsonPreviousButton}
            width={150}
            backgroundColor={"#59c09b"}
            func={setStep}
            arg={step - 1}
          />
          {/*FR Envoi des données du formulaire au back-end*/}
          {/*GB Sending form data to the back-end*/}
          <LogButton
            text={JsonActivityPages.JsonActivityManagement.JsonPublishButton}
            width={150}
            backgroundColor={"#59c09b"}
            func={() => sendModify()}
            // arg={null}
          />
        </View>
      </ScrollView>
  )

}

export default UpdateActivityScreenStepFour;