import React from 'react';
import { Pressable, ScrollView, Text, View } from 'react-native';
import CountryListDropdown from "../../../../components/CountryListDropdown/CountryListDropdown.js";
import Fields, { CityField } from "../../../../components/Fields/Fields.js";
import ProfilePictureEdit from "../../../../components/ProfilePictureEdit/ProfilePictureEdit.js";
import TwinSelectButton from "../../../../components/TwinSelectButton/TwinSelectButton.js";
import styles from "./EditProfileStepOneCss.js";

const EditProfileStepOne = ({
  avatarImage,
  setAvatarImage,
  profileImage,
  setProfileImage,
  scr,
  profileState,
  setSelected,
  leftActive,
  setLeftActive,
  rightActive,
  setRightActive,
  pressed,
  firstName,
  setFirstName,
  city,
  setCity,
  language,
  setLanguage,
  countriesList,
  errorMsgAvatarImage,
  errorMsgFirstName,
  errorMsgCity,
  errorMsgNickName,
  nickName,
  setNickName,
  appLanguage,
  setAppLanguage,
  flags,
  bigBtn1,
  allFieldsFilled,
  step,
  setStep,
  sendInfoEditProfile,
}) => {

    const { JsonProfilePages } = scr;

  return (
    <ScrollView style={styles.container}>
        {/* ----------------Titles---------------- */}
        <View style={styles.center}>
          <View style={styles.firstRow}>
            {/*🇫🇷 Les informations proviens de  profilData.avatar, qui est enregistré dans le store dans user , cet store est enregistré quand on fait log-in dans la aplication  , a voir plus dans le fichier passwordscreen.js*/}
            {/*🇬🇧 The information comes from profilData.avatar, which is registered in the user store, the store is registered when you log-in in the application, see more in the passwordscreen.js file"*/}
            <ProfilePictureEdit
              avatarImage={avatarImage}
              setAvatarImage={setAvatarImage}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              scr={scr}
            />
          </View>
          {/* {console.log(avatarImage)} */}
          <View style={styles.centeredErrorMessage}>
            {errorMsgAvatarImage && !avatarImage ? (
              <Text style={{ color: "red", marginBottom: 10 }}>
                {errorMsgAvatarImage}
              </Text>
            ) : (
              errorMsgAvatarImage && (
                <Text style={{ color: "red" }}>{errorMsgAvatarImage}</Text>
              )
            )}
          </View>

          <View style={styles.proOrPerso}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.personal dans fr.json permet d'afficher "Compte personnel"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.personal displays "Personal account"*/}

            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.pro dans fr.json permet d'afficher "Compte Pro"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.pro displays "Pro account"*/}
            <TwinSelectButton
              firstTitle={
                JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPersonal
              } // Personal Account
              secondTitle={
                JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPro
              } // Pro Account
              profileState={profileState}
              setIsSelect={setSelected}
              active={leftActive}
              setActive={setLeftActive}
              secondActive={rightActive}
              setSecondActive={setRightActive}
              scr={scr}
            />
          </View>
          {/* ----------------Fields---------------- */}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName}
            </Text>
          </View>
          <View style={styles.fields}>
            <Fields
              icon={"textFrame"}
              pressed={pressed}
              state={firstName}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName
              }
              setState={setFirstName}
              scr={scr}
            />
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.firstName dans fr.json permet d'afficher "Prénom""*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.firstName displays "First name""*/}
          </View>
          {errorMsgFirstName && !firstName ? (
            <Text style={{ color: "red", marginBottom: 10 }}>
              {errorMsgFirstName}
            </Text>
          ) : (
            errorMsgFirstName && (
              <Text style={{ color: "red" }}>{errorMsgFirstName}</Text>
            )
          )}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname}
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.nickname dans fr.json permet d'afficher "Pseudo ou nom d'utilisateur"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.nickname displays "Nickname, brand or user name""*/}
            <Fields
              icon={"textFrame"}
              pressed={pressed}
              state={nickName}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname
              }
              setState={setNickName}
              scr={scr}
            />
          </View>
          {errorMsgNickName && !nickName ? (
            <Text style={{ color: "red", marginBottom: 10 }}>
              {errorMsgNickName}
            </Text>
          ) : (
            errorMsgNickName && (
              <Text style={{ color: "red" }}>{errorMsgNickName}</Text>
            )
          )}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep1
                  .JsonActivityAroundYourCity
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.city dans fr.json permet d'afficher "Ville"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.city displays "City"*/}
            <CityField
              icon={"textFrame"}
              text={
                city ??
                JsonProfilePages.JsonEditProfileScreen.JsonStep1
                  .JsonCityPlaceholder
              }
              state={city}
              setState={setCity}
            />
          </View>
          {errorMsgCity && !city ? (
            <Text style={{ color: "red" }}>{errorMsgCity}</Text>
          ) : null}
          <View style={styles.titleFields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.title_language dans fr.json permet d'afficher "visible sur ton profil"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.title_language displays "visibile in your profile*/}
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep1
                  .JsonLanguageVisibleOnYourProfile
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 Composant CountryListDropdown , qui reçoit/enregistre la langue native enregistré pour l'utilisateur dans la BD*/}
            {/*🇬🇧 CountryListDropdown component, which receives/saves the native language registered for the user in the DB*/}
            {
              <CountryListDropdown
                placeholder={
                  JsonProfilePages.JsonEditProfileScreen.JsonStep1
                    .JsonNativeLanguagePlaceholder
                }
                setSelected={setSelected}
                data={countriesList}
                language={language}
                setLanguage={setLanguage}
              />
            }
          </View>
          {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.app_Language dans fr.json permet d'afficher "langue d'affichage de l'aplication"*/}
          {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep1.app_nativeLanguage displays "App language"*/}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep1
                  .JsonAppLanguageDisplay
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 Composant CountryListDropdown (avant SelectLanguage) , qui reçoit/enregistre la langue d'affichage de l'aplication dans le storage*/}
            {/*🇬🇧 CountryListDropdown (before SelectLanguage) component, which receives/stores the display language of the application in storage*/}
            {
              <CountryListDropdown
                placeholder={
                  JsonProfilePages.JsonEditProfileScreen.JsonStep1
                    .JsonAppDisplayPlaceholder
                }
                pressed={pressed}
                setSelected={setSelected}
                data={countriesList}
                language={appLanguage}
                setLanguage={setAppLanguage} // function pour gerer le changement de la langue
                flags={flags}
              />
            }
          </View>
        </View>

        {/* ----------------ValidationButtons---------------- */}
        <View style={styles.testEcarteur}></View>
        <View style={styles.savOrConButtons}>
          <Pressable
            style={bigBtn1.button}
            onPress={() => sendInfoEditProfile()}
          >
            <Text style={styles.btnText}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.save dans fr.json permet d'afficher "Enregistrer"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.save displays "Save"*/}
              {JsonProfilePages.JsonEditProfileScreen.JsonSaveButton}
            </Text>
          </Pressable>
          <Pressable
            style={[
              styles.bigBtn,
              !allFieldsFilled() ? styles.disabledButton : {},
            ]}
            onPress={() => setStep(step + 1)}
            disabled={!allFieldsFilled()}
          >
            <Text style={styles.btnText}>
              {JsonProfilePages.JsonEditProfileScreen.JsonContinueButton}
            </Text>
          </Pressable>
        </View>
      </ScrollView>
  )

}

export default EditProfileStepOne;