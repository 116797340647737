//🇫🇷 Ce fichier gère la page de vérification pour le mail après la création d'un compte si on ne l'a toujours pas vérifié, on doit rentrer un code à 6 chiffres (FrameFigma6)
//🇬🇧 This file manages the verification page for the email after creating an account if we didn't verify it, we must enter a 6-digit code (FrameFigma6)

import React, { useRef, useState, useEffect } from "react";
import { Text, View, Pressable, ScrollView, TextInput } from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./VerificationMailScreenRoleUpdateCss.js";
import { ReLoginDialog } from "../../../components/Dialogs/Dialogs.js";

const VerificationMailScreenRoleUpdate = ({ scr, user, rolesList }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const navigation = useNavigation();
  const [reLoginDialogVisible, setReLoginDialogVisible] = useState(false);
  const { JsonLoginRegisterPages } = scr;

  const verifiedUser =
    rolesList && rolesList.find((role) => role.name === "user");

  //🇫🇷 Chiffres du code de vérification
  //🇬🇧 The numbers of verification code
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);
  const pin5Ref = useRef(null);
  const pin6Ref = useRef(null);

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [error, setError] = useState(null);

  //🇫🇷 Fonction pour basculer la visibilité du modal de re-connexion
  //🇬🇧 Function to toggle the visibility of the re-login modal
  const displayReLoginModal = () => {
    setReLoginDialogVisible(!reLoginDialogVisible);
  };

  //🇫🇷 Fonction pour revenir à l'écran des activités
  //🇬🇧 Function to navigate back to the activities screen
  const backToActivitiesScreen = () => {
    navigation.pop();
  };

  //🇫🇷 Cette requête API envoie le mail à l'utilisateur avec le code de vérification à 6 chiffres.
  //🇬🇧 This API request sends a message to the user's email with the 6-digit verification code.
  const requestCode = async () => {
    await axios.post(`${hostname}/api/v1/sendcode`, {
      email: user.email,
      subject: JsonLoginRegisterPages.JsonVerificationCode.JsonEmailSubject,
      message:
        JsonLoginRegisterPages.JsonVerificationCode.JsonRoleChangeMessage,
    });
  };

  //🇫🇷 Envoie un code de vérification à l'email de l'utilisateur si le rôle de l'utilisateur est "utilisateur sans confirmation"
  //🇬🇧 Send a verification code to the user's email if the user role is "user without confirmation"
  useEffect(() => {
    user?.role.name[0] === "user without confirmation" && requestCode();
  }, []);

  //🇫🇷 Cette requête API traite le code de vérification envoyé par l'utilisateur.
  //🇬🇧 This API request checks the 6-digit verification code sent by the user.
  const checkMailForRole = async () => {
    let code = `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`;

    setError(null);
    try {
      const checkCodeResponse = await axios.post(
        `${hostname}/api/v1/checkcode`,
        {
          email: user.email,
          code: code,
        }
      );

      if (checkCodeResponse.data.result === "OK") {
        //🇫🇷 Si le code est correct, le rôle de l'utilisateur est changé en "utilisateur"
        //🇬🇧 If the code is correct, the user role is changed to "user"
        // console.log("Check ok, now changing the role");
        await axios.patch(`${hostname}/api/v1/user/role`, {
          emails: user.email,
          roleId: verifiedUser._id,
        });
      }
      displayReLoginModal();
    } catch (error) {
      //🇫🇷 Si le code est incorrect, un message d'erreur est affiché
      //🇬🇧 If the code is incorrect, an error message is displayed
      if (error.response.status === 402) {
        setError(JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
      } else if (error.response.status === 404) {
        setError(JsonLoginRegisterPages.JsonVerificationCode.JsonEmailError);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <ScrollView style={styles.forgotPage}>
      {/*🇫🇷 L'affichage du popup pour se reconnecter une fois la vérification faite */}
      {/*🇬🇧 The display of the popup to reconnect once the verification is done */}
      <ReLoginDialog
        dialogVisible={reLoginDialogVisible}
        displayModal={displayReLoginModal}
        backToActivitiesScreen={backToActivitiesScreen}
        scr={scr}
      />

      <Text style={styles.title}>
        {JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification}
      </Text>

      {/*🇫🇷 Les champs pour saisir le code de vérification envoyé par mail */}
      {/*🇬🇧 The fields to enter the verification code sent by email */}
      <View style={styles.textInputView}>
        <TextInput
          ref={pin1Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin1) => {
            setPin1(pin1);
            if (pin1) {
              pin2Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin2Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin2) => {
            setPin2(pin2);
            if (pin2) {
              pin3Ref.current.focus();
            } else {
              pin1Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin3Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin3) => {
            setPin3(pin3);
            if (pin3) {
              pin4Ref.current.focus();
            } else {
              pin2Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin4Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin4) => {
            setPin4(pin4);
            if (pin4) {
              pin5Ref.current.focus();
            } else {
              pin3Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin5Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin5) => {
            setPin5(pin5);
            if (pin5) {
              pin6Ref.current.focus();
            } else {
              pin4Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin6Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin6) => {
            setPin6(pin6);
            if (!pin6) {
              pin5Ref.current.focus();
            }
          }}
        />
      </View>

      <View style={styles.btnContainer}>
        {/*🇫🇷 Affichage du message d'erreur */}
        {/*🇬🇧 Display of the error message */}
        {error && <Text style={{ color: "red", marginTop: 10 }}>{error}</Text>}

        {/*🇫🇷 Affichage du bouton "Vérifier" */}
        {/*🇬🇧 Display of the "Verify" button */}
        <Pressable
          onPress={() => {
            checkMailForRole();
          }}
          style={styles.bigBtn}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonVerifyButton}
          </Text>
        </Pressable>

        {/*🇫🇷 Affichage de la partie pour renvoyer le code de vérification */}
        {/*🇬🇧 Display of the resend of the verification code part */}
        <View style={styles.verificationCodeView}>
          <Text
            style={[
              styles.text,
              { width: 250, textAlign: "center", marginBottom: 0 },
            ]}
          >
            {JsonLoginRegisterPages.JsonVerificationCode.JsonCodeNotReceved}
          </Text>
        </View>
        <Pressable
          onPress={() => {
            requestCode();
          }}
          style={styles.smallBtn}
        >
          <Text style={styles.smallBtnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonResendButton}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default VerificationMailScreenRoleUpdate;
