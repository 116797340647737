import Json from "../../../../front-mobile/src/assets/json/en.json";
import AfterworkOff from "./afterwork-off.svg";
import AfterworkOn from "./afterwork.svg";
import AperoOff from "./apero-off.svg";
import AperoOn from "./apero.svg";
import BusinessOff from "./business-off.svg";
import BusinessOn from "./business.svg";
import CultureOff from "./culture-off.svg";
import CultureOn from "./culture.svg";
import DancingOff from "./dancing-off.svg";
import DancingOn from "./dancing.svg";
import GamesOff from "./games-off.svg";
import GamesOn from "./games.svg";
import LinguisticOff from "./linguistic-off.svg";
import LinguisticOn from "./linguistic.svg";
import MealOff from "./meal-off.svg";
import MealOn from "./meal.svg";
import MovieOff from "./movie-off.svg";
import MovieOn from "./movie.svg";
import MusicOff from "./music-off.svg";
import MusicOn from "./music.svg";
import MutualHelpOff from "./mutual-help-off.svg";
import MutualHelpOn from "./mutual-help.svg";
import PartyOff from "./party-off.svg";
import PartyOn from "./party.svg";
import PicnicOff from "./picnic-off.svg";
import PicnicOn from "./picnic.svg";
import PrivatePartyOff from "./private-off.svg";
import PrivatePartyOn from "./private.svg";
import SportOff from "./sports-off.svg";
import SportOn from "./sports.svg";
import TravelOff from "./travel-off.svg";
import TravelOn from "./travel.svg";

const { JsonAllTopics } = Json;

export const activitiesList = [
  {
    id: 1,
    activityTypeIcon_On: <img src={AfterworkOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={AfterworkOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonAfterwork,
  },
  {
    id: 2,
    activityTypeIcon_On: <img src={AperoOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={AperoOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonApero,
  },
  {
    id: 3,
    activityTypeIcon_On: <img src={BusinessOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={BusinessOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonBusiness,
  },
  {
    id: 4,
    activityTypeIcon_On: <img src={CultureOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={CultureOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonCulture,
  },
  {
    id: 5,
    activityTypeIcon_On: <img src={DancingOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={DancingOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonDancing,
  },
  {
    id: 6,
    activityTypeIcon_On: <img src={GamesOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={GamesOff} width={50} height={50} />,
    activityTypeTitle:JsonAllTopics.JsonGames,
  },
  {
    id: 7,
    activityTypeIcon_On: <img src={LinguisticOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={LinguisticOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonLinguistic,
  },
  {
    id: 8,
    activityTypeIcon_On: <img src={MealOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={MealOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonMeal,
  },
  {
    id: 9,
    activityTypeIcon_On: <img src={MovieOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={MovieOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonMovies,
  },
  {
    id: 10,
    activityTypeIcon_On: <img src={MusicOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={MusicOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonMusic,
  },
  {
    id: 11,
    activityTypeIcon_On: <img src={MutualHelpOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={MutualHelpOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonMutualHelp,
  },
  {
    id: 12,
    activityTypeIcon_On: <img src={PartyOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={PartyOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonParty,
  },
  {
    id: 13,
    activityTypeIcon_On: <img src={PicnicOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={PicnicOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonPicnic,
  },
  {
    id: 14,
    activityTypeIcon_On: <img src={PrivatePartyOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={PrivatePartyOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonPrivateParty,
  },
  {
    id: 15,
    activityTypeIcon_On: <img src={SportOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={SportOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonSport,
  },
  {
    id: 16,
    activityTypeIcon_On: <img src={TravelOn} width={50} height={50} />,
    activityTypeIcon_Off: <img src={TravelOff} width={50} height={50} />,
    activityTypeTitle: JsonAllTopics.JsonTravel,
  },
];