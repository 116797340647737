import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, StyleSheet, Text, Pressable, View } from "react-native";

// Backend
import { hostname } from "../../../../../../front-mobile/backendconnect/hostname";

// Components
import ActivityCard_small from "../../../../components/ActivityCards/ActivityCards";

const LikedScreen = ({ scr }) => {
  const { JsonTopNav, JsonActivityPages } = scr;

  // État pour stocker les activités aimées et leurs détails
  const [likedActivities, setLikedActivities] = useState([]);
  const [likedActivitiesDetails, setLikedActivitiesDetails] = useState([]);
  const [connectedUser, setConnectedUser] = useState({});
  const navigation = useNavigation();

  // Fonction pour récupérer les données de l'utilisateur depuis le stockage
  const fetchUserData = useCallback(async () => {
    try {
      const jsonValue = await AsyncStorage.getItem("user");
      return jsonValue != null ? JSON.parse(jsonValue) : {};
    } catch (e) {
      console.error(
        "Erreur lors de la récupération des données de l'utilisateur :",
        e
      );
      return {};
    }
  }, []);

  // Fonction pour récupérer les activités aimées de l'utilisateur
  const fetchLikedActivities = useCallback(async (userId) => {
    try {
      const url = `${hostname}/api/v1/user/${userId}/likedActivities`;
      const response = await axios.get(url);

      if (response.status === 200) {
        setLikedActivities(response.data);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des activités aimées :",
        error
      );
    }
  }, []);

  // Fonction pour récupérer les détails des activités aimées
  const fetchLikedActivitiesDetails = useCallback(async (activityIds) => {
    // Créer une instance axios avec un intercepteur pour gérer les erreurs 404
    const axiosInstance = axios.create();
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 404) {
          return null;
        }
        return Promise.reject(error);
      }
    );

    try {
      const detailsPromises = activityIds.map(async (id) => {
        try {
          const response = await axiosInstance.get(
            `${hostname}/api/v1/activities/list/${id}`
          );
          return response ? response.data : null;
        } catch (error) {
          // Journaliser les erreurs uniquement si ce ne sont pas des erreurs 404
          if (error) {
            console.error(
              `Erreur lors de la récupération des détails de l'activité avec l'ID ${id} :`,
              error
            );
          }
          return null;
        }
      });

      const detailsResponses = await Promise.all(detailsPromises);
      const filteredDetails = detailsResponses.filter(
        (detail) => detail !== null
      );
      setLikedActivitiesDetails(filteredDetails);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails des activités aimées :",
        error
      );
    }
  }, []);

  // Récupérer les données de l'utilisateur lors du montage du composant
  useEffect(() => {
    fetchUserData().then((userData) => {
      setConnectedUser(userData);
    });
  }, [fetchUserData]);

  // Récupérer les activités aimées lorsque l'utilisateur est connecté
  useEffect(() => {
    if (connectedUser?._id) {
      fetchLikedActivities(connectedUser._id);
    }
  }, [connectedUser?._id, fetchLikedActivities]);

  // Récupérer les détails des activités aimées lorsque les activités aimées changent
  useEffect(() => {
    if (likedActivities.length > 0) {
      fetchLikedActivitiesDetails(likedActivities);
    }
  }, [likedActivities, fetchLikedActivitiesDetails]);

  // Utiliser le hook useFocusEffect pour récupérer les activités aimées lorsque l'écran est au premier plan
  useFocusEffect(
    useCallback(() => {
      fetchLikedActivities(connectedUser?._id);
    }, [connectedUser?._id, fetchLikedActivities])
  );

  // Rendre chaque activité aimée dans un composant Pressable
  const renderItem = ({ item }) => (
    <Pressable
      style={styles.itemContainer}
      onPress={() => navigation.navigate("ActivityDetails", { activity: item })}
    >
      <ActivityCard_small
        event={item}
        connectedUser={connectedUser}
        scr={scr}
      />
    </Pressable>
  );

  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        {JsonTopNav.JsonMyActivitiesExplanationLiked}
      </Text>
      {likedActivitiesDetails.length > 0 ? (
        <FlatList
          data={likedActivitiesDetails}
          renderItem={renderItem}
          keyExtractor={(item) => item._id}
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text style={{ textAlign: "center", fontSize: 20 }}>
            {JsonActivityPages.JsonMyActivitiesScreen.JsonLikedActivityEmpty}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  itemContainer: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
});

export default LikedScreen;
