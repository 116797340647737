import React from 'react';
import { Image, Modal, Pressable, Text, TextInput, View } from 'react-native';
import { images } from "../../../constantes";
import styles from '../MyProfileScreenCss';

const ConfirmEmailModal = ({
  confirmEmailModalVisible,
  setConfirmEmailModalVisible,
  setAddFriendModalVisible,
  friendEmail,
  setFriendEmail,
  handleAddFriend,
  setModalVisible,
  scr
}) => {

  const JsonConfirmFriendshipPopup = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={confirmEmailModalVisible}
      onRequestClose={() => {
        setConfirmEmailModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.confirmEmailModalContainer}>
          <Pressable
            style={styles.backButtonWrapper}
            onPress={() => {
              setConfirmEmailModalVisible(false);
              setAddFriendModalVisible(true);
            }}
          >
            <Image source={images.leftArrow} style={styles.leftArrowIcon} />
          </Pressable>
          <View style={styles.iconWrapper}>
            <Image source={images.EmailIcon} style={styles.addFriendButtonIcon} />
          </View>
          <Text style={styles.confirmEmailModalText}>
            {JsonConfirmFriendshipPopup.JsonPopupEnterEmail}
          </Text>
          <TextInput
            style={styles.emailInput}
            placeholder={JsonConfirmFriendshipPopup.JsonPlaceholderEmail}
            placeholderTextColor="gray"
            value={friendEmail}
            onChangeText={(text) => setFriendEmail(text)}
          />
          <Pressable
            style={styles.confirmEmailButton}
            onPress={async () => {
              await handleAddFriend(friendEmail, "email");
              setConfirmEmailModalVisible(false);
              setModalVisible(false);
            }}
          >
            <Text style={styles.confirmEmailButtonText}>
              {JsonConfirmFriendshipPopup.JsonButtonConfirm}
            </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmEmailModal;
