//🇫🇷 Page d'informations (à propos de nous) (FrameFigma91)
//🇬🇧 News page (FrameFigma91)

import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Image,
  ImageBackground,
  Linking,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
} from "react-native";

// Components
import styles from "./NewsCss";

// import des images
import { images } from "../../constantes";

//🇫🇷 L'image du haut et les icônes sont manquantes
//🇬🇧 The top picture and the three icons are missing

const News = (props) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonNewsScreen } = props.scr;
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: "white" }}>
        <View style={styles.backgroundImgStyle}>
          <ImageBackground
            source={require("../../assets/images/rectangle230.png")}
            resizeMode={"cover"}
            style={{ flex: 1, padding: 10 }}
          ></ImageBackground>
        </View>
        {/* TITRE DE LA PAGE */}
        <Text style={styles.title}>{JsonNewsScreen.JsonMainTitle}</Text>
        {/* Remplacer ce titre par "Socializus is ..." " */}
        {/* PARTIE AVEC LES LOGOS ET LES TEXTES */}

        {/* PREMIERE LIGNE */}
        <View style={styles.newslist}>
          <Image
            source={images.NonProfit}
            style={styles.nonProfitImg}
            resizeMode="contain"
          />
          {/*Insrer ici la premiere image*/}
          <Text style={styles.txt}>{JsonNewsScreen.JsonNonProfitMessage}</Text>
          {/*Remplacer ce texte par la pemiere description */}
        </View>

        {/* DEUXIEME LIGNE */}
        <View style={styles.newslist}>
          <Image
            source={images.StarUsers}
            style={styles.activeUsersImg}
            resizeMode="contain"
          />
          {/*Inserer ici la deuxieme image*/}
          <Text style={styles.txt}>
            {JsonNewsScreen.JsonActiveUsersMessage}
          </Text>
          {/*Remplacer ce texte par la deuxieme description */}
        </View>

        {/* TROISIEME LIGNE */}
        <View style={styles.newslist}>
          <Image
            source={images.FreeApp}
            style={styles.freeAppImg}
            resizeMode="contain"
          />
          {/*Inserer ici la troisieme image*/}
          <Text style={styles.txt}>{JsonNewsScreen.JsonFreeAppMessage}</Text>
          {/*En cas de difficulte pour vous connecter ... */}
        </View>

        <View style={styles.helpParagraph}>
          <Image source={images.Idea} style={{ width: 26, height: 26 }} />
          {/*Inserer ici la troisieme image*/}
          <Text style={styles.txt}>{JsonNewsScreen.JsonHelpTheAppMessage}</Text>
          {/*En cas de difficulte pour vous connecter ... */}
        </View>

        {/*LIEN VERS FACEBOOK*/}
        <Pressable
          style={styles.ButtonFB}
          onPress={() =>
            Linking.openURL(
              "https://www.facebook.com/groups/socializus"
              //Inserer le lien facebook a la place
            )
          }
        >
          <Image source={images.Facebook} style={{ width: 50, height: 51 }} />
          <View style={styles.row}>
            <Text style={styles.buttonTextFB}>
              {JsonNewsScreen.JsonJoinFaceBookButton}
            </Text>
          </View>
        </Pressable>

        {/*LIEN VERS MY ACTIVITY*/}
        <Pressable
          style={styles.eventRedirectButton}
          onPress={() => navigation.goBack()}
        >
          <View>
            <Text style={styles.buttonText}>
              {JsonNewsScreen.JsonFindEventButton}
            </Text>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  );
};

export default News;
