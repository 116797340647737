import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { FAB } from "@rneui/themed";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { Dimensions, FlatList, SafeAreaView, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";
import ActivityCard_small from "../../../components/ActivityCards/ActivityCards.js";
import { EmailCheckDialog } from "../../../components/Dialogs/Dialogs.js";
import TopNavigation from "../../../navigation/TopNavigation";
import MyActivitiesScreen from "../MyActivitiesScreen/MyActivitiesScreen";
import styles from "./ActivitiesScreenCss.js";

// import de la ActivityDateUtils
import { formatDate } from "../../../../utils/ActivityDateUtils";

const ActivitiesScreen = ({ user, scr, fromStack }) => {
  const [events, setEvents] = useState([]);
  const [splashLoad, setSplashLoading] = useState(true);
  const [emailCheckDialogVisible, setEmailCheckDialogVisible] = useState(false);
  const navigation = useNavigation();
  const screenWidth = Dimensions.get("screen").width;

  const langue = useSelector((state) => state.langue);
  // console.log("langue: ", langue);
  // 🇫🇷 Fonction pour regrouper les activités par date
  // 🇬🇧 Function to group activities by date
  const groupActivitiesByDate = useCallback((activities) => {
    const groupedActivities = {};
    activities.forEach((activity) => {
      const dateStr = activity.date.split("T")[0];
      if (!groupedActivities[dateStr]) {
        groupedActivities[dateStr] = [];
      }
      groupedActivities[dateStr].push(activity);
    });
    return groupedActivities;
  }, []);

  // 🇫🇷 Fonction pour récupérer les données depuis le serveur de production
  // 🇬🇧 Function to fetch data from the production server

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${hostname}/api/v1/activities/list`);
      const allEvents = response.data.data;
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const newEvents = allEvents.filter((event) => {
        const eventDate = new Date(event.date);
        return eventDate >= today;
      });

      const groupedEvents = groupActivitiesByDate(newEvents);
      const sortedDates = Object.keys(groupedEvents).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
      const sortedGroupedEvents = {};
      sortedDates.forEach((date) => {
        sortedGroupedEvents[date] = groupedEvents[date];
      });

      setEvents(sortedGroupedEvents);
    } catch (error) {
      console.error("Erreur lors de la récupération des activités:", error);
    } finally {
      setSplashLoading(false);
    }
  }, [groupActivitiesByDate]);
  // 🇫🇷 Utilisation de useFocusEffect pour récupérer les données quand l'écran est en focus
  // 🇬🇧 Use of useFocusEffect to fetch data when the screen is in focus
  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [fetchData])
  );
  // 🇫🇷 Fonction pour basculer la visibilité de la boîte de dialogue de vérification par e-mail
  // 🇬🇧 Function to toggle the visibility of the email check dialog
  const displayEmailCheckDialog = useCallback(() => {
    setEmailCheckDialogVisible(!emailCheckDialogVisible);
  }, [emailCheckDialogVisible]);

  // 🇫🇷 Fonction pour rendre chaque élément dans la FlatList
  // 🇬🇧 Function to render each item in the FlatList
  const renderItem = useCallback(
    ({ item: dateStr }) => {
      console.log('Item', dateStr)
      const formattedDate = formatDate(dateStr, langue);

      return (
        <View key={dateStr}>
          <Text style={styles.dateHeader}>{formattedDate}</Text>
          {events[dateStr].map((activity) => (
            <ActivityCard_small
              key={activity._id}
              event={activity}
              connectedUser={user}
              scr={scr}
            />
          ))}
        </View>
      );
    },
    [events, user, scr]
  );

  return (
    <SafeAreaView
      style={[styles.container, { width: screenWidth, maxWidth: 450 }]}
    >
      {!splashLoad && (
        <TopNavigation
          arg={[
            {
              link: scr.JsonTopNav.JsonSecondLineLeft,
              to: () => (
                <FlatList
                  data={Object.keys(events)}
                  keyExtractor={(date) => date}
                  renderItem={renderItem}
                />
              ),
            },
            {
              link: scr.JsonTopNav.JsonSecondLineRight,
              to: () => <MyActivitiesScreen user={user} scr={scr} />,
            },
          ]}
        />
      )}
      <FAB
        onPress={() => navigation.navigate("Create Activity", { user })}
        style={{ margin: 30 }}
        icon={
          <Text
            style={{
              fontSize: 70,
              color: "#59C09B",
              textAlign: "center",
              lineHeight: 56,
              paddingBottom: 15,
            }}
          >
            +
          </Text>
        }
        color="white"
        placement="left"
      />
      {/* 🇫🇷 Boîte de dialogue de vérification par e-mail */}
      {/* 🇬🇧 Email check dialog */}
      <EmailCheckDialog
        dialogVisible={emailCheckDialogVisible}
        displayModal={displayEmailCheckDialog}
        goToMailCheckScreen={() => navigation.navigate("VerificationMailRole")}
        user={user}
        scr={scr}
      />
    </SafeAreaView>
  );
};

export default ActivitiesScreen;
