//🇫🇷 Ce fichier gère le troisième page de création de profil sur la sélection de la ville (FrameFigma11)
//🇬🇧 This file manages the third page of profile creation on the selection of the city (FrameFigma11)

import React, { useState, useEffect } from "react";
import { View, ScrollView, Text, Pressable } from "react-native";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./CreateProfileScreenStepThreeCss.js";
import Fields, { CityField } from "../../../components/Fields/Fields.js";
import CountryListDropdown from "../../../components/CountryListDropdown/CountryListDropdown.js";

const CreateProfileScreenStepThree = ({
  profileState,
  navigation,
  scr,
  userToken,
  user,
  setUser,
}) => {
  //🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.
  //🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language

  const { JsonCreateProfilePages } = scr;

  const [errorMsg, setErrorMsg] = useState(null);
  const [selected, setSelected] = useState(false);
  const [profilData, setProfilData] = useState(null);
  const [pressed, setPressed] = useState(false);
  const [index, setIndex] = useState(null);
  const [flags, setFlags] = useState(null);
  const [enter, setEnter] = useState(false);
  const [enterText, setEnterText] = useState(false);
  const [change, setChange] = useState(false);
  const [errorMessageCity, setErrorMessageCity] = useState("");
  const [errorMessageNickName, setErrorMessageNickname] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [skip, setSkip] = useState(0);
  const limit = 1;
  const {
    nickName,
    city,
    setCity,
    setNickName,
    setLanguage,
    nativeLanguage,
    accountType,
  } = profileState;

  useEffect(() => {
    if (!city) {
      //🇫🇷 Si la ville n'est pas remplie, Paris est définie par défaut //🇬🇧 If the city is not filled, Paris is set by default
      setCity("Paris");
    }

    //🇫🇷 Vérifie si le champ ville ou pseudo est vide ou nul
    //🇬🇧 Check if city or nickname field is empty or null
    if (city == null || city === "" || nickName === "" || nickName === null) {
      setErrorMessageCity(JsonCreateProfilePages.JsonCityMessage);
      setBtnDisable(true);
      setBackgroundColor("grey");
    } else {
      setErrorMessageCity("");
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }
  }, [city, nickName]);

  //🇫🇷 Mise à jour des données utilisateur
  //🇬🇧 Updating user data
  useEffect(() => {
    UpdateData();
  }, []);

  const UpdateData = async () => {
    //🇫🇷 Récupération des données utilisateur dans le storage
    //🇬🇧 Recovery of user data in storage
    try {
      let res = JSON.parse(await AsyncStorage.getItem("id"));
      setProfilData(res);
      // console.log(res);
      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${res._id}`
      );
      // console.log(response.data.user);
      //🇫🇷 Enregistrement des données utilisateur dans le storage
      //🇬🇧 Saving user data in storage
      const resultat = JSON.stringify(response.data.user);
      // console.log(resultat);
      await AsyncStorage.setItem("Profile", resultat);
      await AsyncStorage.setItem("user", resultat);
    } catch (error) {
      console.error(error);
    }
  };

  //🇫🇷 Cette fonction envoie les données utilisateur à la base de donnée
  //🇬🇧 This function sends user data to the database
  const sendInfoEditProfile = async () => {
    const editProfilBody = {
      city: city,
    };

    try {
      const requestOptions = {
        //🇫🇷 Requête pour mettre a jour les données dans la base de données
        //🇬🇧 Request to update data in the database
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(editProfilBody),
      };
      const response = await fetch(
        `${hostname}/api/v1/user/info/${profilData._id}`,
        requestOptions
      );
      const data = await response.json();
      // console.log("data", data);

      Object.assign(user, editProfilBody);
      UpdateData();
      setUser(user);
    } catch (error) {
      console.log("CATCH :", error);
    }
  };

  //🇫🇷 Ce useEffect sert à activer le bouton "Suivant" quand le champs de la ville à été remplie
  //🇬🇧 This useEffect is used to activate the "Next" button when the city field has been filled
  useEffect(() => {
    if (city) {
      setChange(true);
    } else {
      setChange(false);
    }
  }, [city]);

  const component = (typeOfComponent) => {
    const data = [
      { key: "0", value: "French" },
      { key: "1", value: "English" },
      { key: "2", value: "Spanish" },
      { key: "3", value: "Portugese" },
      { key: "4", value: "German" },
      { key: "5", value: "Italian" },
      { key: "6", value: "Russian" },
      { key: "7", value: "Chinese" },
      { key: "8", value: "Indian" },
      { key: "9", value: "Japanese" },
      { key: "10", value: "Hebrew" },
      { key: "11", value: "Hungarian" },
      { key: "12", value: "Polish" },
      { key: "13", value: "Romanian" },
      { key: "14", value: "Greek" },
      { key: "15", value: "Arabic" },
    ];
    const obj = {
      flags,
      data,
      setErrorMsg,
      navigate: navigation.navigate,
      path: "Step4",
      city,
      setCity,
      nickName,
      setNickName,
      nativeLanguage,
      setLanguage,
      currentPage: "Step3",
      selected,
      setSelected,
      pressed,
      setPressed,
      index,
      setIndex,
      text: JsonCreateProfilePages[typeOfComponent],
    };

    if (typeOfComponent === "language") {
      return (
        <>
          <View style={[styles[typeOfComponent]]}>
            <CountryListDropdown {...obj} {...props} />
          </View>
          {!selected && errorMsg && (
            <Text style={{ color: "red" }}>{errorMsg}</Text>
          )}
        </>
      );
    } else if (typeOfComponent === "titleOfSelectComponent") {
      return (
        <View
          style={[
            styles.textContainer,
            {
              width: JsonCreateProfilePages.JsonNativeLanguage.length * 9,
              top:
                !nickName && !city && errorMsg
                  ? 403
                  : (!city || !nickName) && errorMsg
                  ? 384
                  : 364,
            },
          ]}
        >
          <Text
            style={[
              styles.inputText,
              { color: pressed && !selected ? "red" : "black" },
            ]}
          >
            {/* {JsonCreateProfilePages.JsonNativeLanguage} */}
          </Text>
        </View>
      );
    } else {
      return (
        <>
          <View style={[styles[typeOfComponent], { marginBottom: 10 }]}>
            <Fields {...obj} scr={scr} />
          </View>
          //🇫🇷 Affiche un message d'erreur si le champ est vide //🇬🇧 Display an error message if the field is empty
          {typeOfComponent === "city" ? !city : !nickName && errorMsg}
        </>
      );
    }
  };

  return (
    <ScrollView style={styles.profil}>
      {/*🇫🇷 Affichage du message d'erreur si la ville n'est pas saisi */}
      {/*🇬🇧 Display error message if city is not entered */}
      {!city && (
        <Text style={{ padding: 10, textAlign: "center" }}>
          {errorMessageCity}
        </Text>
      )}

      {/*🇫🇷 Affichage du champs pour saisir la ville */}
      {/*🇬🇧 Display of the field to enter a city */}
      <CityField
        text={JsonCreateProfilePages.JsonCityPlaceholder}
        state={city}
        setState={setCity}
      />

      <View style={{ height: 15 }}></View>

      {/*🇫🇷 Affichage de la partie du pseudo si le compte est un compte pro */}
      {/*🇬🇧 Display the nickname part if the account is a pro account */}
      {accountType === JsonCreateProfilePages.JsonProfessional && (
        <>
          <Text style={styles.nickNameMessage}>
            {JsonCreateProfilePages.JsonNickNameMessage}
          </Text>
          <Fields
            icon={"textFrame"}
            text={JsonCreateProfilePages.JsonNickName}
            state={nickName}
            setState={setNickName}
            scr={scr}
          />
        </>
      )}

      {component("titleOfSelectComponent")}

      {/*🇫🇷 Affichage du bouton "Suivant" pour continuer vers la page 4 de création de profil */}
      {/*🇬🇧 Displaying the "Next" button to continue to page 4 of profile creation */}
      <Pressable
        onPress={() => {
          if (change === true) {
            sendInfoEditProfile();
            navigation.navigate("Step4");
          }
        }}
        style={[styles.bigBtn, { backgroundColor: backgroundColor }]}
        disabled={btnDisable}
      >
        <Text style={styles.btnText}>
          {JsonCreateProfilePages.JsonContinueButton}
        </Text>
      </Pressable>
    </ScrollView>
  );
};

export default CreateProfileScreenStepThree;
