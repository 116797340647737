//🇫🇷 Ce fichier gère le drawer navigation
//🇬🇧 This file manage the drawer navigation

import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import CustomDrawerContent from "./CustomDrawerContent";
import BottomNav from "./BottomNav";
import BurgerButton from "../components/BurgerButton/BurgerButton.js";
import HomeScreen from "../pages/HomeScreen/HomeScreen.js";

const Drawer = createDrawerNavigator();

const DrawerNavigation = ({
  setToken,
  userToken,
  user,
  setUser,
  scr,
  gender,
  setGender,
  accountType,
  setAccountType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  nickName,
  setNickName,
  city,
  setCity,
  nativeLanguage,
  setNativeLanguage,
  role,
  setRole,
  setUserNativeLanguage,
  shouldShowContactScreen,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const navigation = useNavigation();

  const isLoggedIn = userToken !== null;

  return (
    <Drawer.Navigator
      drawerContent={(props) => (
        <CustomDrawerContent
          {...props}
          setToken={setToken}
          user={user}
          setUser={setUser}
          scr={scr}
          navigation={navigation}
          gender={gender}
          setGender={setGender}
          accountType={accountType}
          setAccountType={setAccountType}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nickName={nickName}
          setNickName={setNickName}
          city={city}
          setCity={setCity}
          nativeLanguage={nativeLanguage}
          setNativeLanguage={setNativeLanguage}
          role={role}
          setRole={setRole}
        />
      )}
      screenOptions={{
        drawerStyle: {
          backgroundColor: "#59C09B",
        },
        title: "Socializus",
      }}
    >
      <Drawer.Screen
        name="Socializus"
        options={{
          tabBarLabel: "Activities",
          headerShown: false,
          headerStyle: { backgroundColor: "#59C09B" },
          headerTitleStyle: {
            color: "white",
            fontWeight: "bold",
            fontSize: 30,
          },
          headerTitleAlign: "center",
          headerLeft: () => <BurgerButton />,
        }}
      >
        {(props) => (
          <BottomNav
            user={user}
            scr={scr}
            shouldShowContactScreen={shouldShowContactScreen}
          />
        )}
      </Drawer.Screen>

      {isLoggedIn && (
        <Drawer.Screen
          name="Home"
          children={(props) => (
            <HomeScreen
              {...props}
              setUserNativeLanguage={setUserNativeLanguage}
            />
          )}
        />
      )}
    </Drawer.Navigator>
  );
};

export default DrawerNavigation;
