import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FlatList, Text, View } from "react-native";
import { useSelector } from "react-redux";

// Backend
import { hostname } from "../../../../../../front-mobile/backendconnect/hostname";

// Components
import { formatDate } from "../../../../../utils/ActivityDateUtils";
import ActivityCard_small from "../../../../components/ActivityCards/ActivityCards";

const PastActivitiesScreen = ({ scr }) => {
  const { JsonTopNav, JsonActivityPages } = scr;

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connectedUser, setConnectedUser] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const langue = useSelector((state) => state.langue);

  const fetchActivities = async (userId) => {
    setLoading(true);
    setRefreshing(true);
    try {
      const response = await axios.get(`${hostname}/api/v1/activities/list`);
      if (Array.isArray(response.data.data)) {
        const myPastActivities = response.data.data.filter(
          (activity) => activity.attendees.includes(userId) && new Date(activity.date) < new Date()
        ).sort((a, b) => new Date(a.date) - new Date(b.date));
        setActivities(myPastActivities);
      } else {
        console.error("Data received is not an array:", response.data.data);
      }
    } catch (error) {
      console.error(
        "Error fetching activities:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const jsonValue = await AsyncStorage.getItem("user");
        const userData = jsonValue != null ? JSON.parse(jsonValue) : null;
        setConnectedUser(userData);
        if (userData?._id) {
          await fetchActivities(userData._id);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchInitialData();
  }, []);

  const onRefresh = useCallback(() => {
    if (connectedUser?._id) {
      fetchActivities(connectedUser._id);
    }
  }, [connectedUser]);

  const renderActivity = ({ item, index }) => {
    const formattedDate = formatDate(item.date, langue);

      // Vérifier si c'est la première activité ou si la date est différente de la précédente
    const showDate = index === 0 || formattedDate !== formatDate(activities[index - 1].date);
    return (
      <View>
           {showDate && (
          <Text style={{ fontSize: 16, paddingHorizontal: 15, paddingVertical: 10, backgroundColor: "white" }}>
            {formattedDate}
          </Text>
        )}
          <ActivityCard_small
              event={item}
              connectedUser={connectedUser}
              scr={scr}
            />
      </View>
    );
  };
  
  return (
    <View style={{ padding: 10, flex: 1 }}>
      <Text
        style={{
          fontSize: 18,
          fontWeight: "bold",
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        {JsonTopNav.JsonMyActivitiesExplanationPast}
      </Text>
      {activities.length > 0 ? (
        <FlatList
          data={activities}
          keyExtractor={(item) => item._id}
          renderItem={renderActivity}
          contentContainerStyle={{ flexGrow: 1 }}
          refreshing={refreshing}
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text style={{ textAlign: "center", fontSize: 20 }}>
            {JsonActivityPages.JsonMyActivitiesScreen.JsonPastActivityEmpty}
          </Text>
        </View>
      )}
    </View>
  );
};

export default PastActivitiesScreen;
