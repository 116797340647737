import { Icon } from "@rneui/themed";
import React, { useState } from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";

//components
import EventButton from "../../../../components/EventButton/EventButton.js";
import SubButton from "../../../../components/SubUnsubButton/SubUnsubButton.js";
import { images } from "../../../../constantes/index.js";

import Men from "../../../../assets/images/men.svg";

import styles from "./ActivityDescriptionCss.js";

const ActivityDescription = ({
  route,
  navigation,
  avatarList,
  isParticipating,
  subscribe,
  cancelParticipationDialogVisible,
  setCancelParticipationDialogVisible,
  scr,
  connectedUser,
  connectedUserRole,
  setFriendsInfosShow,
  displayDeleteActivityDialog,
}) => {
  const { JsonActivityPages } = scr;
  const [event, setEvent] = useState(route.params.event); //🇫🇷 Passsage d'info activité🇫🇷//🇬🇧 info activity passed 🇬🇧
  const [descReadMoreBtn, setDescReadMoreBtn] = useState(false);
  // const displayDeleteActivityDialog = () => {
  //   setDeleteActivityDialogVisible(!deleteActivityDialogVisible);
  // };

  // const [deleteActivityDialogVisible, setDeleteActivityDialogVisible] =
  //   useState(false);

  return (
    <>
      <View style={{ flexDirection: "row" }}>
        <ScrollView
          horizontal={true} // Set horizontal to true to make it scrollable horizontally
          showsHorizontalScrollIndicator={false} // Optional: Hide the horizontal scroll bar.
          //contentContainerStyle={{flexDirection:'row-reverse'}}
        >
          <View style={styles.scrollViewStyle}>
            <View style={styles.scrollSubViewStyle}>
              {avatarList.map((o, index) => {
                return (
                  <Pressable
                    key={`touchable_${index}`}
                    onPress={() => navigation.navigate("Profile", { user: o })}
                  >
                    {/* Commentaire */}
                    <Image
                      source={{ uri: o.avatar }}
                      style={styles.scrollViewImg}
                    />
                  </Pressable>
                );
              })}
            </View>
          </View>
        </ScrollView>

        <View style={styles.nbrFriendsStyle}>
        {event && event.nbFriends !== undefined && event.nbFriends > 0 && (
          <Pressable onPress={() => setFriendsInfosShow(true)}>
            <View style={{ marginRight: 15 }}>
              <Image
                source={images.Info}
                style={{ width: 42, height: 42 }}
                resizeMode="contain"
              />

              <View style={styles.nbrFriendsView}>
                <Text style={styles.nbrFriendsTxt}>+{event.nbFriends}</Text>
              </View>
            </View>
          </Pressable>
        )}

        {event && !event.isAttendeeLimited && (
          <View>
            <Image
              source={images.Men}
              style={{ width: 42, height: 42, marginRight: 100 }}
              resizeMode="contain"
            />
            {event.attendees && (
              <Text style={{ textAlign: "center", marginRight: 100 }}>
                {event.nbFriends !== undefined
                  ? event.attendees.length + event.nbFriends
                  : event.attendees.length}
                / {event.attendeeLimit}
              </Text>
            )}

            {!event.attendees && (
              <Text style={styles.attendeeTxt}>
                {event.nbFriends !== undefined ? event.nbFriends : 0}/{event.attendeeLimit}
              </Text>
            )}
          </View>
        )}

        {event && event.isAttendeeLimited && (
          <View style={{ marginRight: 30 }}>
            <Image
              source={Men}
              style={{ width: 42, height: 42, marginRight: 100 }}
              resizeMode="contain"
            />
            {event.attendees && (
              <Text style={{ textAlign: "center", marginRight: 100 }}>
                {event.nbFriends !== undefined
                  ? event.attendees.length + event.nbFriends
                  : event.attendees.length}
              </Text>
            )}

            {!event.attendees && (
              <Text style={styles.attendeeTxt}>
                {event.nbFriends !== undefined ? event.nbFriends : 0}
              </Text>
            )}
          </View>
        )}
      </View>

      </View>

      <View style={styles.inviteOrParticipate}>
        {/*🇫🇷 Définir la logique pour l'invitation en cliquant sur Invite*/}
        {/*🇬🇧 The code managing invitations is missing*/}
        <SubButton
          isParticipating={isParticipating}
          subscribe={subscribe}
          cancelParticipationDialogVisible={cancelParticipationDialogVisible}
          setCancelParticipationDialogVisible={
            setCancelParticipationDialogVisible
          }
          scr={scr}
        />
      </View>

      <View style={{ width: "100%" }}>
        <View style={styles.activityAboutView}>
          <Text style={styles.about}>
            {
              JsonActivityPages.JsonActivityScreen.JsonActivityDescriptionScreen
                .JsonAboutEvent
            }
          </Text>
          {/*🇫🇷 La variable JsonActivityPages.JsonActivityScreen.JsonActivityDescriptionScreen.JsonAboutEvent dans en.json permet d'afficher "A propos de l'activité"*/}
          {/*🇬🇧 The en.json variable JsonActivityPages.JsonActivityScreen.JsonActivityDescriptionScreen.JsonAboutEvent displays "About the event"*/}
          {/*Mise en commentaire de la traduction des langue au niveau de la description de l'activité (a decommenter par la suite)*/}
          {/*🇫🇷 Bouton pour choisir la langue (liste de drapeaux carrés par langue).*/}
          {/*🇬🇧 Language selection button (list of rectangular flags)*/}
        </View>
        {/*🇫🇷 Impossible de modifier la description de l'activité pour l'instant*/}
        {/*🇬🇧 Can't edit event description so far*/}
        <View style={[styles.longText]}>
          {/*
                    🇫🇷Pour modifier ou supprimer, in faut etre soit le propritaire de l'event ou avoir l'addresse email admin
                    🇬🇧To edit or delete you have to be the owner or an adamin
                  */}
          {connectedUser && event &&
            (connectedUser._id === event.author ||
              connectedUser.role.name.includes("admin") ||
              connectedUser.role.name.includes("moderator")) && (
              <View style={styles.editActivityView}>
                {/*🇫🇷 Button pour édition d'activité, les info sont passées par route.params.event à UpdateActivityScreen, le type c'est pour le backend*/}
                {/*🇬🇧 Button for edit activity, the info is passed by route.params.event to UpdateActivityScreen, le type c'est pour le backend*/}
                <Pressable
                  onPress={() =>
                    navigation.navigate("Modify Activity", {
                      event,
                      type: "edit",
                    })
                  }
                >
                  <Image
                    source={images.Edit}
                    style={{ width: 21, height: 21 }}
                  />
                </Pressable>
                {/*🇫🇷 Impossible de supprimer la description de l'activité pour l'instant, ce boutton marche pour suprimer l'activité complete*/}
                {/*🇬🇧 Can't delete event description so far, this button delete all the activity*/}
                <Pressable onPress={() => displayDeleteActivityDialog()}>
                  <Image
                    source={images.Trash}
                    style={{ marginLeft: 20, width: 20, height: 20 }}
                    resizeMode="contain"
                  />
                </Pressable>
              </View>
            )}
          <Text
            style={[
              styles.eventDescriptionTxt,
              {
                height: descReadMoreBtn ? null : 200,
                overflow: descReadMoreBtn ? null : "hidden",
              },
            ]}
          >
            {event && event.description}
          </Text>
          {/*🇫🇷 Ci-dessous button read more/ read less si la description a plus de 100 caracteres / 🇬🇧 below, button read more/read less if the description has more than 150 characters  */}
          {event && event.description.length > 100 && (
            <Pressable
              onPress={() => setDescReadMoreBtn(!descReadMoreBtn)}
              style={styles.descReamMoreBtnStyle}
            >
              <Text style={styles.readMoreButton}>
                {descReadMoreBtn
                  ? JsonActivityPages.JsonActivityScreen
                      .JsonActivityDescriptionScreen.JsonReadLess
                  : JsonActivityPages.JsonActivityScreen
                      .JsonActivityDescriptionScreen.JsonReadMore}
                {/*"Read Less" : "Read More"*/}
              </Text>
            </Pressable>
          )}

          <View style={styles.socialMediaBtns}>
            {event && event.fbPageLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.fbPageLink);
                }}
              >
                <Image
                  style={{ width: 35, height: 35 }}
                  source={images.fbPage}
                  resizeMode="contain"
                />
              </Pressable>
            )}

            {event && event.fbGroupLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.fbGroupLink);
                }}
              >
                <Image
                  style={{ width: 35, height: 35 }}
                  source={images.fbGroup}
                  resizeMode="contain"
                />
              </Pressable>
            )}

            {event && event.otherLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.otherLink);
                }}
              >
                <Image
                  style={{ width: 35, height: 35 }}
                  source={images.otherLink}
                  resizeMode="contain"
                />
              </Pressable>
            )}

            {event && event.whatsappLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.whatsappLink);
                }}
              >
                <Image
                  style={{ width: 35, height: 35 }}
                  source={images.whatsappLink}
                  resizeMode="contain"
                />
              </Pressable>
            )}

            {event && event.telegramLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.telegramLink);
                }}
              >
                <Image
                  style={{ width: 43, height: 43, bottom: 3.5 }}
                  source={images.telegramLink}
                  resizeMode="contain"
                />
              </Pressable>
            )}

            {event && event.meetupLink !== "" && (
              <Pressable
                onPress={() => {
                  openLink(event.meetupLink);
                }}
              >
                <Image
                  style={{ width: 35, height: 35 }}
                  source={images.meetupLink}
                  resizeMode="contain"
                />
              </Pressable>
            )}
          </View>
        </View>

        <View style={styles.inviteOrParticipate}>
          {connectedUserRole !== "user without confirmation" && (
            <EventButton
              title={JsonActivityPages.JsonActivityScreen.JsonCopyPasteActivity}
              type="Copy & Paste event"
              bgColor={"lightgray"}
              onPress={() =>
                navigation.navigate("Copy Activity", {
                  event,
                })
              }
            />
          )}
          {/*🇫🇷 avant s'affichait le type de buton, maintent c'est le title, pour permetre la traduction et pas deranger le composant EventButton */}
          {/*🇬🇧 before the button type was displayed, now it's the title, to allow translation and not disturb the EventButton component*/}
        </View>
        {event && event.hasPrice && (
          <View style={{ margin: 5 }}>
            {/*🇫🇷 La logique permettant d'accéder à la billeterie en ligne est manquante*/}
            {/*🇬🇧 The link to buy tickets online is missing*/}
            <Pressable
              onPress={() => openLink(event.ticketLink)}
              style={styles.buyTicketButton}
            >
              <Text style={styles.ticketLinkTxt}>
                {/*🇫🇷 La variable JsonActivityPages.JsonActivityScreen.JsonActivityAddressScreen.JsonBuyTicket dans fr.json permet d'afficher "Acheter un ticket"*/}
                {/*🇬🇧 The en.json variable JsonActivityPages.JsonActivityScreen.JsonActivityAddressScreen.JsonBuyTicket displays "Buy ticket"*/}
                {
                  JsonActivityPages.JsonActivityScreen.JsonActivityAddressScreen
                    .JsonBuyTicket
                }
              </Text>
              <Icon
                name="ticket"
                type="font-awesome"
                size={20}
                color="dodgerblue"
              />
            </Pressable>
          </View>
        )}
      </View>
    </>
  );
};

export default ActivityDescription;
