import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { ActivityTypesGrid_OneTopic } from '../../../../components/ActivityTypesGrids/ActivityTypesGrids.js';
import LogButton from '../../../../components/LogButtons/LogButtons.js';

import styles from '../UpdateActivityScreenCss.js';

const UpdateActivityScreenStepTwo = ({
  topic,
  setTopic,
  step,
  setStep,
  errorMessage,
  setErrorMessage,
  scr
}) => {

    const {JsonActivityPages} = scr;
    return(
        <ScrollView>
        {/* ----------------Titles---------------- */}
        <Text style={[styles.boldTitle, { paddingHorizontal: 20 }]}>
          {
            JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
              .JsonChooseTopic
          }
        </Text>
        {/* "choose": "Choose a Topic :" */}
        <Text style={{ marginVertical: 10, paddingHorizontal: 20 }}>
          {
            JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
              .JsonIncreaseYourActivityInterestMessage
          }
        </Text>
        {/* "inOrder": "Your event will look more exciting with a nice picture!" */}
        {/*FR Grille de la liste des thèmes d'activités. Le props selectionMode limite
          le choix à un seul.*/}
        {/*GB Activity topic grid. The props selectionMode limits our choice to one.*/}
        <ActivityTypesGrid_OneTopic
          topic={topic}
          setTopic={setTopic}
          selectionMode={"one"}
        />

        {/* ------------Save or Continue------------ */}
        <Text style={styles.errorMsgTxt}>{errorMessage}</Text>

        <View style={[styles.savOrConButtons, { paddingHorizontal: 15 }]}>
          <LogButton
            text={JsonActivityPages.JsonActivityManagement.JsonPreviousButton} // Previous
            width={150}
            backgroundColor={"#59c09b"}
            func={(n) => {
              setErrorMessage("");
              setStep(n);
            }}
            arg={step - 1}
          />
          {/*🇫🇷 Ci-dessous le code fait une diference de bouton pour les utilisateurs avec premium roles, les utilisateurs avec roles passent pour étape suivante avec liens sur reseaux sociaux
                        🇬🇧 Below the code makes a button difference for users with premium roles, users with roles go to next step with links on social networks*/}

          <LogButton
            text={JsonActivityPages.JsonActivityManagement.JsonContinueButton}
            width={150}
            backgroundColor={"#59c09b"}
            func={(n) => {
              // console.log("Passing to step 3...");
              setErrorMessage("");
              setStep(n);
            }}
            arg={step + 1}
          />
        </View>
      </ScrollView>
    )
}

export default UpdateActivityScreenStepTwo;