import { Platform } from 'react-native';
import { useActivityContext } from "../src/pages/ActivityPages/ActivityScreen/ActivityContext";


// Fonction pour calculer la région en fonction des coordonnées
export const  getRegionForCoordinates = (points) => {
    const { latitude, longitude } = points.reduce(
      (acc, point) => ({
        latitude: (acc.latitude + point.latitude) / 2,
        longitude: (acc.longitude + point.longitude) / 2,
      }),
      points[0]
    );

    const deltas = points.reduce(
        (acc, point) => ({
          latitudeDelta: Math.max(acc.latitudeDelta, point.latitude - latitude),
          longitudeDelta: Math.max(acc.longitudeDelta, point.longitude - longitude),
        }),
        { latitudeDelta: 0, longitudeDelta: 0 }
      );
  
      return { latitude, longitude, ...deltas };
};

    // Fonction pour gérer le clic sur un emoji
export const onEmojiClick = (emojiObject) => {
        const cursor = ref.current.selectionStart;
        const text =
          cmtText.slice(0, cursor) + emojiObject.emoji + cmtText.slice(cursor);
        setCmtText(text);
        const newCursor = cursor + emojiObject.emoji.length;
        setTimeout(() => ref.current.setSelectionRange(newCursor, newCursor), 10);
};

export   const deleteComment = async (commentId) => {
    await axios.delete(`${hostname}/api/v1/comments/${commentId}`);
    // switch reload to trigger comments reload
    setReload(!reload);
};

export const requestLocationPermission = async () => {
  if (Platform.OS === 'android') {
      const result = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
          { title: 'Location Access Required', message: 'This App needs to Access your location' },
      );
      if (result === PermissionsAndroid.RESULTS.GRANTED) {
          getOneTimeLocation();
          subscribeLocationLocation();
      }
    }
};

    // Fonction pour obtenir la localisation ponctuelle
export const getOneTimeLocation = () => {
  const { setCurrentLatitude, setCurrentLongitude } = useActivityContext();
        Geolocation.getCurrentPosition(
          (position) => {
            const { longitude, latitude } = position.coords;
            setCurrentLongitude(longitude);
            setCurrentLatitude(latitude);
          },
          (error) => console.log(error.message),
          { enableHighAccuracy: false, timeout: 30000, maximumAge: 1000 }
        );
};
  
    // Fonction pour s'abonner aux mises à jour de localisation
export const subscribeLocationLocation = () => {
  const { setCurrentLatitude, setCurrentLongitude } = useActivityContext();
        watchID = Geolocation.watchPosition(
          (position) => {
            const { longitude, latitude } = position.coords;
            setCurrentLongitude(longitude);
            setCurrentLatitude(latitude);
          },
          (error) => console.log(error.message),
          { enableHighAccuracy: false, maximumAge: 1000 }
        );
};

