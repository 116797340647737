// 🇫🇷 cette page affiche les informations sur différentes offres de badges premium pour une adhésion FrameFigma050B
// 🇬🇧 This page displays information about various premium badge offers for a membership FrameFigma050B
import React from "react";
import { Text, View, Image, Pressable, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components 
import styles from "./StylesWeb"

// import des images
import { images } from "../../../../constantes";



const Test1 = (props) => {
  const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
  const JsonAchievementsPages = scr.JsonAchievementsPages || {};

  const navigation = useNavigation();

  // 🇫🇷 Trait permettant d'afficher la separation entre les étoiles(Golden, Emurald, Ruby ,Diamond) et les parrainages 
  // 🇬🇧 Feature for displaying the separation between the stars (Golden, Emerald, Ruby, Diamond) and sponsorships
  const SeparatorG = () => {
    return <View style={styles.separatorG} />;
  };
  const SeparatorR = () => {
    return <View style={styles.separatorR} />;
  };const SeparatorE = () => {
    return <View style={styles.separatorE} />;
  };const SeparatorD = () => {
    return <View style={styles.separatorD} />;
  };

  return (
  <ScrollView>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>
      
      
      {/*GoldButton*/}
      <View style={styles.cardButton}>
       <Pressable style={styles.button}
            onPress={() => navigation.navigate('VipBadgeGold')}
        >
      
         <Text style={styles.goldtitle} > {/*title*/}
             {JsonAchievementsPages.JsonGoldStar}
          </Text>

           <SeparatorG />
           <Text style={styles.gold} >
             {JsonAchievementsPages.JsonSponsorshipScreen.Json10Sponsorship}
           </Text>
           <Text style={styles.gold} >
             {/* 1.90€/month */}
           </Text>
           <Image source={images.Gold} style={styles.img} />
           <Image source={images.GoldSmallBorder} style={styles.imgbord} />
        </Pressable>
      </View>

{/*RubyButton*/}
      <View style={styles.cardButton}>
        <Pressable style={styles.button}
            onPress={() => navigation.navigate('VipBadgeRuby')}
        >
      
      <Text style={styles.rubytitle} >{/*title*/}
        {JsonAchievementsPages.JsonRubyStar}
       </Text>

       <SeparatorR />
       <Text style={styles.ruby} >
        {JsonAchievementsPages.JsonSponsorshipScreen.Json20Sponsorship}
        </Text>
       <Text style={styles.ruby} >
         {/* 4.90€/month */}
        </Text>
        <Image source={images.Ruby} style={styles.img} />
        <Image source={images.RubySmallBorder} style={styles.imgbord} />
        </Pressable>
      </View>


{/*EmeraldButton*/}
      <View style={styles.cardButton}>
        <Pressable style={styles.button}
            onPress={() => navigation.navigate('VipBadgeEmerald')}
        >
        
        <Text style={styles.emeraldtitle} > {/*title*/}
         {JsonAchievementsPages.JsonEmeraldStar}
        </Text>

        <SeparatorE />
        <Text style={styles.emerald} >
          {JsonAchievementsPages.JsonSponsorshipScreen.Json50Sponsorship}   
        </Text>
        <Text style={styles.emerald} >
          {/* 9.90€/month */}
        </Text>
        <Image source={images.Emerald} style={styles.img} />
        <Image source={images.EmeraldSmallBorder} style={styles.imgbord} />
        </Pressable>
      </View>

   {/*DiamandButton*/}
      <View style={styles.cardButton}>
        <Pressable style={styles.button}
            onPress={() => navigation.navigate('VipBadgeDiamand')}
        >
      
        <Text style={styles.diamandtitle} >  {/*title*/}
         {JsonAchievementsPages.JsonDiamondStar}
        </Text>

        <SeparatorD />
        <Text style={styles.diamand} >
         {JsonAchievementsPages.JsonSponsorshipScreen.Json100Sponsorship}
        </Text>
        <Text style={styles.diamand} >
         {/* 14.90€ */}
        </Text>
        <Image source={images.Diamand} style={styles.img} />
        <Image source={images.DiamandSmallBorder} style={styles.imgbord} />
        </Pressable>
      </View>


      </View>
    
      </ScrollView>
  );
};
  
 
export default Test1;
