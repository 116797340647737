/*
🇫🇷 Composant Activité dans ActivitiesScreen (Login)
Les cartes sont censées changer quand l'utilisateur appuie sur la loupe, dans le header.
Je n'ai par contre pas réussi à faire fonctionner ça...
*/
/*
🇬🇧 Activity Component in ActivitiesScreen (login)
The cards are supposed to change when the user presses the magnifying glass in the header.
I didn't get to make it work, though...
*/

//🇫🇷 Composant Activité dans ActivitiesScreen (FrameFigma21)
//🇬🇧 Activity Component in ActivitiesScreen (FrameFigma21)

import React, { useEffect, useState } from "react";
import { Text, View, Pressable, Image, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";

// Backend
import { hostname } from "../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./ActivityCardsCss.js";
import { activitiesList } from "../../assets/activityList/activityListWithIcons.js";

//Images
import { images } from "../../constantes/index.js";

const ActivityCard_small = ({ event, connectedUser, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  //Il faut trouver un truc pour la waiting list; comment voir si l'utilisateur veut participer, mais la liste des utilisateur est déjà remplie...
  const navigation = useNavigation();
  const screenWidth = Dimensions.get("screen").width;

  const [author, setAuthor] = useState();

  //🇫🇷 Ce useEffect sert à récupérer les informations de l'auteur de l'évènement à chaque fois que la valeur de "event" change
  //🇬🇧 This useEffect is used to fetch the author of event informations every time that the value "event" change
  useEffect(() => {
    const getUser = async () => {
      //🇫🇷 Les logs de tous les évènements et l'ID des auteurs
      //🇬🇧 Log the whole event object and the author ID
      // console.log("Event:", event);
      // console.log("Author:", event.author);

      //🇫🇷 Ignorer ces ID utilisateurs spécifiques
      //🇬🇧 Ignore these specific user IDs
      const ignoredUserIds = [
        "647f2d2a6080595ba0822471",
        "647857aafc63f4b426a7ede7",
      ];

      //🇫🇷 Vérifie si event.author existe et que ce n'est pas dans la liste d'ID ignoré
      //🇬🇧 Check if event.author exists and is not in the ignored ID list
      if (
        event.author &&
        typeof event.author === "string" &&
        !ignoredUserIds.includes(event.author)
      ) {
        try {
          const response = await axios.get(
            `${hostname}/api/v1/user/getuserinfo/${event.author}`
          );

          if (response && response.data && response.data.user) {
            setAuthor(response.data.user);
          } else {
            // console.log(
            //   "Réponse invalide du serveur ou utilisateur non trouvé."
            // );
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log("L'activité n'existe plus");
          } else {
            console.error("Erreur de requête:", error);
          }
        }
      }
    };

    getUser();
  }, [event]);

  /* For a future update, the following commented data will be for when a user is in the waiting list.
  When the time to add this comes, just add a condition that states if the user is in the attendee list but the quota of prticipants for the activity has been reached */

  // {status: "Waiting list",
  //  backgroundColor: "#FFC107",
  //  cardBackgroundColor: "#FFFCF3"}

  //🇫🇷 Titre de l'évènement géré pour toujours s'adapter à la carte (une meilleure adaptation)
  //🇬🇧 event title trim for it always fits the card (better responsiveness)
  let title = "";
  if (screenWidth < 250) {
    if (event.title.length > 30) {
      title = event.title.substr(0, 30) + "...";
    } else {
      title = event.title;
    }
  } else {
    if (event.title.length > 60) {
      title = event.title.substr(0, 59) + "...";
    } else {
      title = event.title;
    }
  }

  //🇫🇷 Gestion et extraction du code postal de l'évènement si l'évènement n'est pas un évènement en ligne
  //🇬🇧 Handling and extract zipcode of the event if the event is not an online event
  if (!event.isOnline) {
    if (typeof event.address === "string") {
      var splitAddress = event.address.split(", ");
      if (splitAddress.length >= 2) {
        var zipCode = splitAddress[splitAddress.length - 2].slice(0, 5);
        // utilisez 'zipCode' ici
      } else {
        // gérer le cas où 'splitAddress' n'a pas au moins deux éléments
      }
    } else {
      // gérer le cas où 'event.address' n'est pas une chaîne de caractères
    }
  }

  const [resizeMode, setResizeMode] = useState("cover");

  //🇫🇷 Ce useEffect gère dynamiquement le redimensionnement de l'image de l'activité
  //🇬🇧 This useEffect dynamically manage the scaling of tha activity image
  useEffect(() => {
    Image.getSize(
      event.activityImage,
      (width, height) => {
        const imageRatio = width / height;
        setResizeMode(imageRatio > 1 ? "cover" : "contain"); // Modifiez la condition ici
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error}`);
      }
    );
  }, [event.activityImage]);

  //🇫🇷 Etat des couleurs de fond des cartes
  //🇬🇧 State of the card background color
  const [userStatus, setUserStatus] = useState({
    status: "",
    backgroundColor: "transparent",
    cardBackgroundColor: "#FDFDFD",
  });
  // useEffect(() => {
  //   if (connectedUser) {
  //     let userS = event.attendees.includes(connectedUser._id)
  //       ? {
  //           status: JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonAttendeeCard, //  pour afficher "Participant" dans ActivityCard
  //           backgroundColor: "#59c09b",
  //           cardBackgroundColor: "#D8EDE6",
  //         }
  //       : {
  //           status: "",
  //           backgroundColor: "transparent",
  //           cardBackgroundColor: "#FDFDFD",
  //         };
  //     if (event.waitingList && event.waitingList.includes(connectedUser._id)) {
  //       userS = {
  //         status: JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonWaitingList, //  pour afficher "Liste d'attente" dans ActivityCard
  //         backgroundColor: "#FFC107",
  //         cardBackgroundColor: "#FFFCF3",
  //       };
  //     }
  //     setUserStatus(userS);
  //   }
  // }, [event, connectedUser]);

  //etat du like
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  const [likedActivityIds, setLikedActivityIds] = useState([]);

  const fetchLikedActivityIds = async () => {
    if (!event || isActivityExpired(event)) {
      // Si l'événement est expiré ou inexistant, arrête la requête et retourne
      return;
    }

    try {
      const url = `${hostname}/api/v1/user/${connectedUser._id}/likedActivities`;
      const response = await axios.get(url);

      if (response.status === 200) {
        setLikedActivityIds(response.data); // Mettez à jour l'état avec les nouvelles données
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des activités aimées:",
        error
      );
    }
  };

  // Vérifie si l'activité est expirée en comparant les dates
  const isActivityExpired = (activity) => {
    // Obtient la date actuelle
    const currentDate = new Date();

    // Obtient la date de l'événement de l'activité
    const eventDate = new Date(activity.eventDate);

    // Comparaison des dates : renvoie vrai si l'événement est expiré, sinon faux
    return currentDate > eventDate;
  };

  // Gérer les clics des likes d'une activité
  // Manage of the click of of liking an activity
  const handleLike = async () => {
    try {
      const action = isLiked ? "dislike" : "like";
      const url = `${hostname}/api/v1/activity/${action}/${event._id}`;
      const response = await axios.post(url, { userId: connectedUser._id });
      if (response.status === 200) {
        setIsLiked(!isLiked);
        setIsDisliked(false);
        fetchLikedActivityIds();

        // Au lieu de définir les couleurs ici, appelez simplement la fonction pour vérifier le statut de l'utilisateur et les "likes"
        checkUserStatusAndLikes();
      } else {
        // Gérez ici les autres codes de statut HTTP si nécessaire
        console.error("Erreur de l'API avec le statut:", response.status);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("L'activité n'existe plus");
      } else {
        console.error("Erreur de requête:", error);
      }
    }
    checkUserStatusAndLikes();
  };

  const handleDislike = async () => {
    try {
      const action = isDisliked ? "like" : "dislike"; // Si déjà "disliké", alors "like", sinon "dislike"
      const url = `${hostname}/api/v1/activity/${action}/${event._id}`;

      const response = await axios.post(url, { userId: connectedUser._id });

      if (response.status === 200) {
        setIsDisliked(!isDisliked);
        setIsLiked(false);

        // Au lieu de définir les couleurs ici, appelez simplement la fonction pour vérifier le statut de l'utilisateur et les "likes"
        checkUserStatusAndLikes();
      } else {
        console.error("Erreur de l'API avec le statut:", response.status);
      }
    } catch (error) {
      // condition et vérification  si l'activicté 'liké' existe encore ou pas
      if (error.response && error.response.status === 404) {
        console.log("L'activité n'existe plus");
      } else {
        console.error("Erreur de requête:", error);
      }
    }
    checkUserStatusAndLikes();
  };

  const checkUserStatusAndLikes = async () => {
    if (!connectedUser || !event._id) return;

    let newStatus = {
      status: "",
      backgroundColor: "transparent",
      cardBackgroundColor: "#FDFDFD",
    };

    const isAttendee = event.attendees.includes(connectedUser._id);
    const isOnWaitingList = event.waitingList?.includes(connectedUser._id);

    if (isAttendee) {
      newStatus = {
        status: "Attendee", // Ajustez en fonction de vos besoins
        backgroundColor: "#59c09b",
        cardBackgroundColor: "#D8EDE6",
      };
    } else if (isOnWaitingList) {
      newStatus = {
        status: "Waiting List", // Ajustez en fonction de vos besoins
        backgroundColor: "#FFC107",
        cardBackgroundColor: "#FFFCF3",
      };
    }

    try {
      // Vérifier les "likes"
      const responseLike = await axios.get(
        `${hostname}/api/v1/activity/${event._id}/likes`
      );
      const usersWhoLiked = responseLike.data || [];
      const hasLiked = usersWhoLiked.some(
        (user) => user._id === connectedUser._id
      );

      setIsLiked(hasLiked);

      if (hasLiked && !isAttendee && !isOnWaitingList) {
        newStatus = {
          ...newStatus,
          cardBackgroundColor: "#FEEBEF",
        };
      }

      // Vérifier les "dislikes"
      const responseDislike = await axios.get(
        `${hostname}/api/v1/activity/${event._id}/dislikes`
      );
      const usersWhoDisliked = responseDislike.data || [];
      const hasDisliked = usersWhoDisliked.some(
        (user) => user._id === connectedUser._id
      );

      setIsDisliked(hasDisliked);

      if (hasDisliked) {
        newStatus = {
          ...newStatus,
          backgroundColor: "#ff4500",
          cardBackgroundColor: "#FFEFEB",
        };
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // console.log(
        //   "L'activité n'existe plus ou erreur de récupération des likes/dislikes"
        // );
      } else {
        console.error(
          "Erreur lors de la récupération des likes/dislikes:",
          error
        );
      }
    }

    setUserStatus(newStatus);
  };

  useEffect(() => {
    checkUserStatusAndLikes(); // Cette fonction sera appelée à chaque fois que les dépendances changent
  }, [
    connectedUser,
    event,
    JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen
      .JsonAttendeeCard,
    JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen
      .JsonWaitingList,
    isLiked,
    isDisliked, // Ajouté en tant que dépendance si vous voulez réexécuter la logique lorsque isDisliked change
  ]);

  return (
    <Pressable
      onPress={() => {
        navigation.navigate("Activity", {
          event: event,
          pageTitle: activitiesList[event.topic].activityTypeTitle,
        });
      }}
      style={[
        styles.container_small,
        {
          backgroundColor: userStatus.cardBackgroundColor,
        },
      ]}
    >
      <View style={styles.leftSide}>
        <View style={styles.imgContainer}>
          <Image
            source={{ uri: event.activityImage }}
            style={[styles.activityImg_small, { resizeMode: resizeMode }]}
          />
        </View>
        {/* icône liker */}
        <Pressable
          onPress={handleLike}
          style={{ position: "absolute", top: 6, left: 6 }}
        >
          <Image
            source={isLiked ? images.LoveActivated : images.LoveDeactivated}
            style={{ width: 20, height: 20 }}
          />
        </Pressable>
        {/* tarif de participation à l'activité */}
        {event.hasPrice && (
          <Image
            source={require("../../assets/images/dollar.svg")}
            style={styles.dollarImgStyle}
          />
        )}

        {/* Put a conditional rendering here for this Text to display Attendee, Waiting or any other status */}
        <Text
          style={[
            styles.userStatusText,
            { backgroundColor: userStatus.backgroundColor },
          ]}
        >
          {userStatus.status}
        </Text>
      </View>

      <View style={styles.rightSide}>
        <View style={styles.basicStyle}>
          <View style={{ flex: 1 }}>
            <Text style={styles.titleTxt}>
              {/* titre de l'activité */}
              {title}
            </Text>
            <Text style={styles.startTimeTxt}>
              {/* horaire de l'activité */}
              {event.startTime}
            </Text>
          </View>
          <View style={styles.activityTypeContainer}>
            {/* image montre le type de l'activité qui aura lieu */}
            {!author && (
              <Image
                source={{ uri: event.activityImage }}
                style={styles.activityTypeImg}
              />
            )}
            {author && (
              <Image
                source={{ uri: author.avatar }}
                style={styles.activityTypeImgTwo}
              />
            )}
          </View>
        </View>

        <View style={styles.basicStyle}>
          <View style={styles.flexContainer}>
            <View style={styles.rowCenter}>
              {/* le nombre de participant à l'activité */}
              <Image
                source={images.Participants}
                style={styles.participantsImg}
              />
              <Text style={styles.textWithIcon}>
                {event.attendeeLimit === 1000000
                  ? event.attendees.length + event.nbFriends
                  : event.attendees.length +
                    event.nbFriends +
                    "/" +
                    event.attendeeLimit}
              </Text>
            </View>

            <View style={[styles.rowCenter, { marginLeft: 15 }]}>
              {/* localisation du lieu de l'activité */}
              <Image
                source={images.Placeholder2}
                style={styles.placeHolderImg}
              />
              {!event.isOnline ? (
                <Text style={{ fontWeight: "bold" }}>{zipCode}</Text>
              ) : (
                <Text></Text>
              )}
            </View>
          </View>

          <View>
            <Text style={styles.eventTopicTxt}>
              {event.topic === undefined
                ? "Party"
                : activitiesList[event.topic].activityTypeTitle}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default ActivityCard_small;
