import React from 'react';
import { Image, Modal, Pressable, Text, TextInput, View } from 'react-native';
import { images } from "../../../constantes";
import styles from "../MyProfileScreenCss";

const ConfirmBirthdayModal = ({
  birthdayModalVisible,
  setBirthdayModalVisible,
  setAddFriendModalVisible,
  friendBirthday,
  setFriendBirthday,
  handleAddFriend,
  setModalVisible,
  scr
}) => {

  const JsonConfirmFriendshipPopup = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={birthdayModalVisible}
      onRequestClose={() => {
        setBirthdayModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.confirmEmailModalContainer}>
          <Pressable
            style={styles.backButtonWrapper}
            onPress={() => {
              setBirthdayModalVisible(false);
              setAddFriendModalVisible(true);
            }}
          >
            <Image source={images.leftArrow} style={styles.leftArrowIcon} />
          </Pressable>
          <View style={styles.iconWrapper}>
            <Image source={images.BirthdayIcon} style={styles.addFriendButtonIcon} />
          </View>
          <Text style={styles.confirmEmailModalText}>
            {JsonConfirmFriendshipPopup.JsonPopupEnterBirthday}
          </Text>

          {/* Utilisation du TextInput pour une saisie manuelle */}
          <View style={styles.centeredContainer}>
            <TextInput
              style={styles.dateInput} // Vous devez définir ce style dans votre fichier styles
              placeholder="DD/MM/YYYY"
              value={friendBirthday}
              onChangeText={setFriendBirthday}
            />
          </View>

          <Pressable
            style={styles.confirmBirthdayButton}
            onPress={async () => {
              await handleAddFriend(friendBirthday, "birthday");
              setBirthdayModalVisible(false);
              setModalVisible(false);
            }}
          >
            <Text style={styles.confirmBirthdayButtonText}>
              {JsonConfirmFriendshipPopup.JsonButtonConfirm}
            </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmBirthdayModal;
