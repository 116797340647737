//🇫🇷 Ce composant permet de générer notre propre input (FrameFigma15-FrameFigma16)
//🇬🇧 This component permit to generate our own input (FrameFigma15-FrameFigma16)

import React from "react";
import { View, TextInput, Text } from "react-native";

// Component
import styles from "./InputFieldCss";

const InputField = ({ title, specialHeight, state, setState }) => {
  return (
    <View style={[styles.container, { height: specialHeight || 45 }]}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.btnContainer}>
        <TextInput
          style={styles.input}
          value={state}
          onChangeText={(text) => setState(text)}
        />
      </View>
    </View>
  );
};
export default InputField;
