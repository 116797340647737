//🇫🇷 Filtrer l'entrée de l'utilisateur avant de déclencher l'action (FrameFigma12)
//🇬🇧 Filtering the user input before triggering the action (FrameFigma12)

import { useEffect } from 'react';

export function useDebounceEffect(
  fn,
  waitTime,
  deps,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  },[deps])
}
