//🇫🇷 Composants de bouton et checkbox pour sélectionner une option (FrameFigma17)
//🇬🇧 Button and checkbox components to select an option (FrameFigma17)

import { useState, useEffect } from "react";
import { Text, View, Pressable } from "react-native";
import { CheckBox } from "@rneui/themed";

// Components
import styles from "./SelectionElementsCss";

const OptionButtonProfile = ({
  title,
  buttonSelected,
  setButtonSelected,
  index,
  setIndex,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonProfilePages } = scr;

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (index === 0) {
      setButtonSelected(
        JsonProfilePages.JsonEditProfileScreen.JsonStep3.Jsonsecret
      );
      setSelected(true);
    }
    if (index === 1) {
      // console.log("title pour button", title);
      if (JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes === title) {
        setButtonSelected(
          JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes
        );
        setSelected(true);
      }
      setButtonSelected(
        JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes
      );
      setSelected(true);
    }
    if (index === 2) {
      setButtonSelected(
        JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
      );
      setSelected(true);
    }
  }, []);

  useEffect(() => {
    if (
      buttonSelected ===
      JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret
    ) {
      setIndex(0);
    }
    if (
      buttonSelected ===
        JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes ||
      buttonSelected ===
        JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes
    ) {
      setIndex(1);
    }

    if (
      buttonSelected === JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
    ) {
      setIndex(2);
    }
  }, [buttonSelected]);

  const handlePress = () => {
    setSelected(true);
    setButtonSelected(title);
  };

  return (
    <Pressable
      onPress={handlePress}
      style={[
        { flex: 1 },
        selected && buttonSelected === title
          ? styles.buttonSelected
          : styles.button,
      ]}
    >
      <Text
        style={
          selected && buttonSelected === title
            ? styles.textButtonSelected
            : styles.textButton
        }
      >
        {title}
      </Text>
    </Pressable>
  );
};

const OptionButton = ({
  title,
  buttonSelected,
  setButtonSelected,
  setOption,
  buttonselect,
  scr,
}) => {
  const { JsonActivityPages } = scr;

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (
      buttonSelected ===
        JsonActivityPages.JsonActivityManagement
          .JsonStandardActivityChooseAddressScreen.JsonAddress &&
      buttonselect === true
    ) {
      setSelected(true);
      setOption(false);
    }
    if (
      buttonSelected ===
        JsonActivityPages.JsonActivityManagement
          .JsonStandardActivityChooseAddressScreen.JsonOnline &&
      buttonselect === true
    ) {
      setOption(true);
    }
  }, [buttonSelected]);

  const handlePress = () => {
    setSelected(true);
    setButtonSelected(title);
  };

  return (
    <Pressable
      onPress={handlePress}
      style={[
        { flex: 1 },
        selected && buttonSelected === title
          ? styles.buttonSelected
          : styles.button,
      ]}
    >
      <Text
        style={
          selected && buttonSelected === title
            ? styles.textButtonSelected
            : styles.textButton
        }
      >
        {title}
      </Text>
    </Pressable>
  );
};

const CheckboxSquare = ({ title, state, setState }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
    setState(checked ? true : false);
  };

  return (
    <View style={styles.checkBoxInput}>
      <CheckBox
        title={title}
        checked={checked}
        onPress={handleChange}
        containerStyle={{ padding: 5 }}
        checkedColor={"#59c09b"}
        uncheckedColor={"#59c09b"}
      />
    </View>
  );
};

export { OptionButtonProfile };
export { OptionButton };
export { CheckboxSquare };
