import React from "react";
import { Modal, Text, View, Pressable } from "react-native";

//🇫🇷 Tous les textes sont en dur, à changer et à traduire, et il faut aussi externaliser le css
//🇬🇧 All texts are hardcoded, to change and to translate, and it is also necessary to outsource the css

const PopupDialog = ({ isShown, onDismiss, dialogMessage, scr }) => {
  const { JsonConfirmFriendshipPopup } = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isShown}
      onRequestClose={onDismiss}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View
          style={{
            width: 300,
            padding: 20,
            backgroundColor: "white",
            borderRadius: 10,
            alignItems: "center",
          }}
        >
          {dialogMessage ===
          JsonConfirmFriendshipPopup.JsonPopupCongratulationsYouAreNowFriend ? (
            <>
              <Text
                style={{
                  fontWeight: "bold",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {JsonConfirmFriendshipPopup.JsonPopupCongratulations}
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 22,
                  textAlign: "center",
                }}
              >
                {JsonConfirmFriendshipPopup.JsonPopupYouAreNowFriend}
              </Text>
            </>
          ) : (
            <Text style={{ textAlign: "center" }}>{dialogMessage}</Text>
          )}

          <Pressable
            onPress={onDismiss}
            style={{
              marginTop: 20,
              backgroundColor: "#59C09B",
              borderRadius: 5,
              paddingHorizontal: 15,
              paddingVertical: 8,
            }}
          >
            <Text style={{ color: "white" }}>
              {JsonConfirmFriendshipPopup.JsonButtonClose}
            </Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default PopupDialog;
