//🇫🇷 Ce fichier gère la page de vérification pour le changement d'un mot de passe, on doit rentrer un code à 6 chiffres (FrameFigma6)
//🇬🇧 This file manages the verification page for the reset of an password, we must enter a 6-digit code (FrameFigma6)

import React, { useRef, useState } from "react";
import { Text, View, Pressable, ScrollView, TextInput } from "react-native";
import axios from "axios";
import { useNavigation, useRoute } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./VerificationPasswordUpdateScreenCss.js";

const VerificationPasswordUpdateScreen = ({ setUser, setToken, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const navigation = useNavigation();
  const { JsonLoginRegisterPages } = scr;
  const { params } = useRoute();

  //🇫🇷 Chiffres du code de vérification
  //🇬🇧 The numbers of verification code
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);
  const pin5Ref = useRef(null);
  const pin6Ref = useRef(null);

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [error, setError] = useState(null);

  //🇫🇷 Cette requête API envoie le mail à l'utilisateur avec le code de vérification à 6 chiffres.
  //🇬🇧 This API request sends a message to the user's email with the 6-digit verification code.
  const requestCode = async () => {
    await axios.post(`${hostname}/api/v1/sendcode`, {
      email: params.email,
      subject: "Code de vérification d'identité",
      message: "Entrer ce code pour confirmer votre identité : ######",
      // "message": "You received this message because you try to change your password on socializus. Please put this code ###### on the app in order to change to your password"
    });
  };

  //🇫🇷 Cette requête API traite le code de vérification envoyé par l'utilisateur.
  //🇬🇧 This API request checks the 6-digit verification code sent by the user.
  const requestPassword = async () => {
    let code = `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`;

    setError(null);
    try {
      const response = await axios.post(`${hostname}/api/v1/change-password`, {
        email: params.email,
        password: params.password,
        code: code,
      });
      if (response.data.result === "OK") {
        // console.log("Ok token", response.data.user.token);
        setToken(response.data.user.token);
        setUser(response.data.user);
        await AsyncStorage.setItem("user", JSON.stringify(response.data.user));
        navigation.navigate("Activities List");
      }
    } catch (error) {
      //🇫🇷 Si le code est incorrect, un message d'erreur est affiché
      //🇬🇧 If the code is incorrect, an error message is displayed
      setError(JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
    }
  };

  return (
    <ScrollView style={styles.forgotPage}>
      <Text style={styles.title}>
        {JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification}
      </Text>
      <View style={styles.container}>
        <Text style={styles.text}>
          {
            JsonLoginRegisterPages.JsonVerificationCode
              .JsonEnterVerificationCode
          }
        </Text>
      </View>

      {/*🇫🇷 Les champs pour saisir le code de vérification envoyé par mail */}
      {/*🇬🇧 The fields to enter the verification code sent by email */}
      <View style={{ flexDirection: "row", alignSelf: "center" }}>
        <TextInput
          ref={pin1Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin1) => {
            setPin1(pin1);
            if (pin1) {
              pin2Ref.current.focus();
            }
          }}
        />

        <TextInput
          ref={pin2Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin2) => {
            setPin2(pin2);
            if (pin2) {
              pin3Ref.current.focus();
            } else {
              pin1Ref.current.focus();
            }
          }}
        />

        <TextInput
          ref={pin3Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin3) => {
            setPin3(pin3);
            if (pin3) {
              pin4Ref.current.focus();
            } else {
              pin2Ref.current.focus();
            }
          }}
        />

        <TextInput
          ref={pin4Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin4) => {
            setPin4(pin4);
            if (pin4) {
              pin5Ref.current.focus();
            } else {
              pin3Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin5Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin5) => {
            setPin5(pin5);
            if (pin5) {
              pin6Ref.current.focus();
            } else {
              pin4Ref.current.focus();
            }
          }}
        />
        <TextInput
          ref={pin6Ref}
          style={styles.opt}
          keyboardType={"number-pad"}
          maxLength={1}
          onChangeText={(pin6) => {
            setPin6(pin6);
            if (!pin6) {
              pin5Ref.current.focus();
            }
          }}
        />
      </View>

      <View style={styles.btnContainer}>
        {/*🇫🇷 Affichage du message d'erreur */}
        {/*🇬🇧 Display of the error message */}
        {error && (
          <View style={styles.errorCard}>
            <Text style={styles.error}>{error}</Text>
          </View>
        )}

        {/*🇫🇷 Affichage du bouton "Vérifier" */}
        {/*🇬🇧 Display of the "Verify" button */}
        <Pressable
          onPress={() => {
            requestPassword();
          }}
          style={styles.bigBtn}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonVerifyButton}
          </Text>
        </Pressable>

        {/*🇫🇷 Affichage de la partie pour renvoyer le code de vérification */}
        {/*🇬🇧 Display of the resend of the verification code part */}
        <View style={styles.verificationCodeView}>
          <Text
            style={[
              styles.text,
              { width: 250, textAlign: "center", marginBottom: 0 },
            ]}
          >
            {JsonLoginRegisterPages.JsonVerificationCode.JsonCodeNotReceved}
          </Text>
        </View>
        <Pressable
          onPress={() => {
            requestCode();
          }}
          style={styles.smallBtn}
        >
          <Text style={styles.smallBtnText}>
            {JsonLoginRegisterPages.JsonVerificationCode.JsonResendButton}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default VerificationPasswordUpdateScreen;
