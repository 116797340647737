import React from "react";
import { Image, Modal, Pressable, Text, View } from "react-native";
import { images } from "../../../constantes";
import styles from "../MyProfileScreenCss";

const AddFriendModal = ({
  addFriendModalVisible,
  setAddFriendModalVisible,
  setEmailPressed,
  lastNamePressed,
  setLastNamePressed,
  phonePressed,
  setPhonePressed,
  birthdayPressed,
  setBirthdayPressed,
  setMemberNumberPressed,
  buttonPressed,
  setButtonPressed,
  scr,
  emailPressed,
  isPhonePublic,
  isLastNamePublic,
  isBirthdayPublic,
  isPhoneAvailable,
  memberNumberPressed,
  continuePressed,
  setContinuePressed,
  setConfirmEmailModalVisible,
  setMemberNumberModalVisible,
  setLastNameModalVisible,
  setBirthdayModalVisible,
}) => {
  const {
    JsonProfilePages,
    JsonConfirmFriendshipPopup,
    JsonCreateProfilePages,
  } = scr;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={addFriendModalVisible}
      onRequestClose={() => {
        setAddFriendModalVisible(!addFriendModalVisible);
      }}
    >
      <View style={styles.modalContainerAddFreind}>
        <View style={[styles.addFriendModalBox, styles.modalAddUser]}>
          <Pressable
            style={styles.closeButton}
            onPress={() => setAddFriendModalVisible(false)}
          >
            <Image source={images.close} style={styles.closeIcon} />
          </Pressable>
          <Text style={[styles.addFriendModalText, { textAlign: "center" }]}>
            {JsonConfirmFriendshipPopup.JsonPopupConfirmFriendship}
          </Text>
          <View
            style={[styles.addFriendButtonContainer, { alignItems: "center" }]}
          >
            <View style={styles.buttonRow}>
              <Pressable
                style={[
                  styles.addFriendButton,
                  emailPressed && styles.buttonPressed,
                ]}
                onPress={() => {
                  setEmailPressed(true);
                  setLastNamePressed(false);
                  setPhonePressed(false);
                  setBirthdayPressed(false);
                  setMemberNumberPressed(false);
                  setButtonPressed(true);
                  //setConfirmEmailModalVisible(true);
                }}
              >
                <View style={styles.iconWrapper}>
                  <Image
                    source={images.EmailIcon}
                    style={styles.addFriendButtonIcon}
                  />
                </View>
                {/* Email */}
                <Text
                  style={[
                    styles.addFriendButtonText,
                    emailPressed && styles.buttonTextPressed,
                  ]}
                >
                  {JsonConfirmFriendshipPopup.JsonButtonEmail}
                </Text>
              </Pressable>
              {isPhonePublic && (
                <Pressable
                  style={[
                    styles.addFriendButton,
                    phonePressed && styles.buttonPressed,
                    !isPhoneAvailable && styles.buttonDisabled,
                  ]}
                  onPress={() => {
                    setPhonePressed(true);
                    setEmailPressed(false);
                    setLastNamePressed(false);
                    setBirthdayPressed(false);
                    setMemberNumberPressed(false);
                    setButtonPressed(true);
                  }}
                  disabled={!isPhoneAvailable}
                >
                  <View style={styles.iconWrapper}>
                    <Image
                      source={images.PhoneIcon}
                      style={styles.addFriendButtonIcon}
                    />
                  </View>
                  {/* Phone */}
                  <Text
                    style={[
                      styles.addFriendButtonText,
                      phonePressed && styles.buttonTextPressed,
                      !isPhoneAvailable && styles.buttonDisabledText,
                    ]}
                  >
                    {JsonConfirmFriendshipPopup.JsonButtonPhone}
                  </Text>
                </Pressable>
              )}
              {isLastNamePublic && (
                <Pressable
                  style={[
                    styles.addFriendButton,
                    lastNamePressed && styles.buttonPressed,
                  ]}
                  onPress={() => {
                    setLastNamePressed(true);
                    setEmailPressed(false);
                    setPhonePressed(false);
                    setBirthdayPressed(false);
                    setMemberNumberPressed(false);
                    setButtonPressed(true);
                  }}
                >
                  <View style={styles.iconWrapper}>
                    <Image
                      source={images.lastName}
                      style={styles.addFriendButtonIcon}
                    />
                  </View>
                  {/* Last name */}
                  <Text
                    style={[
                      styles.addFriendButtonText,
                      lastNamePressed && styles.buttonTextPressed,
                    ]}
                  >
                    {JsonCreateProfilePages.JsonLastNamePlaceholder}
                  </Text>
                </Pressable>
              )}
            </View>
            <View style={styles.buttonRow}>
              {isBirthdayPublic && (
                <Pressable
                  style={[
                    styles.addFriendButton,
                    birthdayPressed && styles.buttonPressed,
                  ]}
                  onPress={() => {
                    setBirthdayPressed(true);
                    setEmailPressed(false);
                    setLastNamePressed(false);
                    setPhonePressed(false);
                    setMemberNumberPressed(false);
                    setButtonPressed(true);
                  }}
                >
                  <View style={styles.iconWrapper}>
                    <Image
                      source={images.BirthdayIcon}
                      style={styles.addFriendButtonIcon}
                    />
                  </View>
                  {/* Birthday */}
                  <Text
                    style={[
                      styles.addFriendButtonText,
                      birthdayPressed && styles.buttonTextPressed,
                    ]}
                  >
                    {JsonConfirmFriendshipPopup.JsonButtonBirthday}
                  </Text>
                </Pressable>
              )}
              <Pressable
                style={[
                  styles.addFriendButton,
                  memberNumberPressed && styles.buttonPressed,
                ]}
                onPress={() => {
                  setMemberNumberPressed(true);
                  setLastNamePressed(false);
                  setEmailPressed(false);
                  setPhonePressed(false);
                  setBirthdayPressed(false);
                  setButtonPressed(true);
                }}
              >
                <View style={styles.iconWrapper}>
                  <Image
                    source={images.users}
                    style={styles.addFriendMemberButtonIcon}
                  />
                </View>
                {/* Member number */}
                <Text
                  style={[
                    styles.addFriendMemberText,
                    memberNumberPressed && styles.buttonTextPressed,
                  ]}
                >
                  {JsonConfirmFriendshipPopup.JsonButtonMemberNumber}
                </Text>
              </Pressable>
            </View>
          </View>
          <View style={{ margin: 10 }}></View>
          <View style={styles.continueBtnView}>
            {/*--------------------------- Boutton Continuer ------------------------ */}
            <Pressable
              style={[
                styles.continueButton,
                continuePressed && styles.buttonPressed,
                !buttonPressed && styles.buttonDisabled,
              ]}
              onPressIn={() => {
                if (buttonPressed) {
                  setContinuePressed(true);
                }
              }}
              onPressOut={() => {
                if (buttonPressed) {
                  setContinuePressed(false);
                  setEmailPressed(false);
                  setLastNamePressed(false);
                  setPhonePressed(false);
                  setBirthdayPressed(false);
                  setMemberNumberPressed(false);
                  setButtonPressed(false);
                  if (emailPressed) {
                    setConfirmEmailModalVisible(true); // Ouvrir le deuxième modal
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  } else if (phonePressed) {
                    setPhoneModalVisible(true); // Ouvrir le modal du téléphone
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  } else if (lastNamePressed) {
                    setLastNameModalVisible(true); // Ouvrir le modal du nom de famille
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  } else if (birthdayPressed) {
                    setBirthdayModalVisible(true); // Ouvrir le modal de la date de naissance
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  } else if (memberNumberPressed) {
                    setMemberNumberModalVisible(true); // Ouvrir le modal du numéro de membre
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  } else {
                    setAddFriendModalVisible(false); // Fermer le premier modal
                  }
                }
              }}
            >
              <View style={styles.addFriendView}>
                <Text
                  style={[
                    styles.addFriendButtonText,
                    continuePressed && styles.buttonTextPressed,
                  ]}
                >
                  {JsonProfilePages.JsonEditProfileScreen.JsonContinueButton}
                </Text>
                <Image
                  source={images.ArrayRight}
                  style={styles.addFriendButtonIcon}
                />
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AddFriendModal;
