//🇫🇷 Ce fichier gère la Top Navigation
//🇬🇧 This file manage the Top navigation

import React from "react";
import { Pressable, Text, View } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

const TopTab = createMaterialTopTabNavigator();

const CustomTabBar = (props) => {
  const { state, descriptors, navigation } = props;

  return (
    <View style={{ flexDirection: "row" }}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label = options.tabBarLabel || route.name;
        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        return (
          <Pressable
            key={index}
            role="button"
            onPress={onPress}
            style={{
              flex: 1,
              backgroundColor: isFocused ? "#59C09B" : "gray",
              borderRightWidth: index === 0 ? 2 : 0,
              borderRightColor: "white",
              justifyContent: "center",
              height: 50,
            }}
          >
            <Text
              style={{
                color: isFocused ? "gray" : "white",
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {label}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};

const TopNavigation = ({ arg }) => {
  return (
    <TopTab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarScrollEnabled: false,
      }}
      tabBar={(props) => <CustomTabBar {...props} />}
    >
      {arg.map((screen) => {
        return (
          <TopTab.Screen
            key={screen.link}
            name={screen.link}
            options={{
              tabBarLabel: screen.link,
            }}
          >
            {(props) => screen.to(props)}
          </TopTab.Screen>
        );
      })}
    </TopTab.Navigator>
  );
};

export default TopNavigation;
