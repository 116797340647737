// import of images
//Images pour le ficher AcitivitesScreen.js
import ScheduleIcon from "../assets/images/schedule.svg";
import SplashS from "../assets/splash.png";

//Images pour le ficher AcitiviyScreen.js
import GooglePicto from "../assets/images/googlemaps.svg";
import locationOff from "../assets/images/placeholder-off.svg";
import locationOn from "../assets/images/placeholder-on.svg";
import Info from "../assets/images/info.svg";
import Men from "../assets/images/men.svg";
import Dollar from "../assets/images/dollar.svg";
import Trash from "../assets/images/trashcan.svg";
import Edit from "../assets/images/Iconedit.svg";
import fbPage from "../assets/images/fbPage.svg";
import fbGroup from "../assets/images/fbGroup.svg";
import HappyOFF from "../assets/images/happyOff.svg";
import HappyON from "../assets/images/happyOn.svg";
import otherLink from "../assets/images/otherLink.svg";
import whatsappLink from "../assets/inviteFriends-icons/whatsappIcon.svg";
import meetupLink from "../assets/images/meetup.svg";
import telegramLink from "../assets/images/telegram.svg";
import locationPin from "../assets/images/location-pin.svg";
import France from "../assets/flags-svg/france.svg";

//Images pour le fichier ChatDetail.js
import joinFileIcon from "../assets/images/joinFile.png";
import emojiIcon from "../assets/images/messageEmoji.png";
import plusIcon from "../assets/images/plus.png";
import sendMessageIcon from "../assets/images/SendMessage.png";
import button from "../assets/images/ButtonMessage.svg";
import createbutton from "../assets/images/create-activity.png";

//Images pour le fichier ContactScreen.js
import Bug from "../assets/images/ContactBug.svg";
import Orga from "../assets/images/ContactOrga.svg";
import Phone from "../assets/images/ContactPhone.svg";
import Discord from "../assets/images/Discord.svg";
import Facebook from "../assets/images/Facebook.svg";
import Telegram from "../assets/images/telegram.svg";

//Images pour le fichier HomeSceen.js
import Logo from "../assets/images/logo-Socializus.svg";
import LogoErasmusEvent from "../assets/images/ErasmusEventsLogo.svg"
import ButtonWorldStart from "../../assets/ButtonWorldStart.png"

//Images pour le fichier InviteFriendsScreen.js
import Friendship from "../assets/images/friends.svg";
import Arrow from "../assets/images/right-arrow.svg";
import Email from "../assets/inviteFriends-icons/emailIcon.svg";
import Instagram from "../assets/inviteFriends-icons/instagramIcon.svg";
import Link from "../assets/inviteFriends-icons/linkChain.svg";
import PhoneMsg from "../assets/inviteFriends-icons/messages.svg";
import Messenger from "../assets/inviteFriends-icons/messengerIcon.svg";
import Snapchat from "../assets/inviteFriends-icons/snapchatIcon.svg";
import Tiktok from "../assets/inviteFriends-icons/tik-tokIcon.svg";
import Twitter from "../assets/inviteFriends-icons/twitterIcon.svg";
import Whatsapp from "../assets/inviteFriends-icons/whatsappIcon.svg";

//Images pour le fichier MemberShipScreen.js
import coupe1 from "../assets/Icons/Achievements/Cups/NoStar/Level1NoStar.svg";
import coupe2 from "../assets/Icons/Achievements/Cups/NoStar/Level2NoStar.svg";
import coupe3 from "../assets/Icons/Achievements/Cups/NoStar/Level3NoStar.svg";
import coupe4 from "../assets/Icons/Achievements/Cups/NoStar/Level4NoStar.svg";
import coupe5 from "../assets/Icons/Achievements/Cups/NoStar/Level5NoStar.svg";
import coupe6  from "../assets/Icons/Achievements/Cups/NoStar/Level6NoStar.svg";
import coupe7 from "../assets/Icons/Achievements/Cups/NoStar/Level7NoStar.svg";


//Images pour le fichier MembersScreen.js
import Settings from "../assets/images/searchOff.svg";

//Images pour le fichier FriendListScreen.js
import next2 from "../assets/images/next2.svg";

//Images pour le fichier News.js
import FreeApp from "../assets/images/free_app.svg";
import Idea from "../assets/images/idea_1.svg";
import NonProfit from "../assets/images/non-profit.svg";
import StarUsers from "../assets/images/starUsers.svg";

//Images pour le fichier Parrinage.js
import Coupe1 from "../assets/images/Coupe1.svg";
import Coupe2 from "../assets/images/Coupe3.svg";
import Coupe3 from "../assets/images/Coupe41.svg";
import Coupe41 from "../assets/images/Coupe2.svg";
import cadeau from "../assets/images/cadeau.svg";
import file_copy from "../assets/images/file_copy.svg";

//Images pour le fichier EditProfileScreen.js
import LastNameIcon from "../assets/images/image_edit_profile/textFrame.svg";
import MemberNumberIcon from "../assets/images/image_edit_profile/avatar.svg";
import EmailIcon from "../assets/images/image_edit_profile/envelope.svg";
import PhoneIcon from "../assets/images/phone.svg";
import BirthdayIcon from "../assets/images/birthday.svg";
import confidential from "../assets/images/confidential.svg";

//Images pour le ficher MyProfileScreen.js
import AddFriend from "../assets/profile/add-friend.svg";
import Placeholder2 from "../assets/images/placeholder2.svg";
import warning from "../assets/images/warning.svg";
import usergirl from "../assets/images/image_edit_profile/user-girl.svg";
import userguy from "../assets/images/image_edit_profile/user-guy.svg";
import users from "../assets/images/image_edit_profile/avatar.svg";
import close from "../assets/images/close.svg";
import ArrayRight from "../assets/images/right-arrow.svg";
import lastName from "../assets/images/image_edit_profile/textFrame.svg";
import leftArrow from "../assets/images/left-arrow.svg";

//Images pour le fichier ProfileFriends.js
import Previous from "../assets/images/previous-black.svg";

//Images pour le fichier ProfileAcitvites.js
import calendar from "../assets/images/calendar.svg";
import calendarOn from "../assets/images/calendar-on.svg";
import members from "../assets/images/MembersOn.svg";

//Images pour le fichier SettingsScreen.js
import settings from "../assets/images/settingsAnimated.gif";
import contactUs from "../assets/settings-icons/contactUs.svg";
import deleteAccount from "../assets/settings-icons/deleteAccount.svg";
import editProfile from "../assets/settings-icons/editProfile.svg";
import news from "../assets/settings-icons/news.svg";
import termsOfSales from "../assets/settings-icons/termsOfSales.svg";

//Image pour le fichier app.js
import SkipButton from "../assets/images/next-white.svg";

//Image pour le fichier ActivityCards.js
import LoveActivated from "../assets/images/heartRed.svg";
import LoveDeactivated from "../assets/images/heartGrey.svg";
import Participants from "../assets/images/participants.svg";

//Images pour le fichier ActivityPhoto.js
import PhotoSvg from "../assets/images/photo.svg";
import cadenaOff from "../assets/images/padlock.svg";
import cadenaOn from "../assets/images/unlock.svg";

//Images pour le fichier ActivityPhotoForChat.js
import CameraSvg from "../assets/images/camera.svg";

//Images pour le fichier ConceptSlide.js
import LogoCS from "../assets/images/concept_images/logo.svg";

//Images pour le fichier CountryListDropdown.js
import Language from "../assets/images/language.svg";

//Images pour le fichier InviteButton.js
import Share from "../assets/images/share.svg";

//Images pour le fichier MyActivityDetails.js
import Heart from "../assets/images/heart.svg";

//Images pour le fichier ProfilePicture.js
import DefaultPicture from "../assets/images/user-frame-missing.svg";

//Images pour le fichier SelectLanguage.js
import DownArrow from "../assets/images/downward-arrow-2.svg";

//Images pour le fichier BottomNav.js
import MembersOff from "../assets/images/MembersOff.svg";
import MembersOn from "../assets/images/MembersOn.svg";
import CalendarOff from "../assets/images/calendar-off.svg";
import CalendarOn from "../assets/images/calendar-on.svg";
import ConceptOff from "../assets/images/concept.svg";
import ConceptOn from "../assets/images/concept_lightbulb.svg";
import ContactOff from "../assets/images/contact-us.svg";
import ContactOn from "../assets/images/contact.svg";
import NewsOff from "../assets/images/infoOff.svg";
import NewsOn from "../assets/images/infoOn.svg";

//Images pour le fichier CustomDrawerContent.js
import InviteFriendsIcon from "../assets/drawer-icons/inviteFriends.svg";
import SettingsIcon from "../assets/drawer-icons/settings.svg";
import UpdateIcon from "../assets/drawer-icons/update.svg";
import NewsIcon from "../assets/drawer-icons/informations.svg";
import LogoutIcon from "../assets/drawer-icons/logout.svg";
import CreateActivityIcon from "../assets/drawer-icons/createActivity.svg";
import TermsOfSalesIcon from "../assets/settings-icons/termsOfSales.svg";

//Images pour le fichier MiddleNav.js
import EditMV from "../assets/images/edit-info.svg";
import Position from "../assets/images/placeholder.svg";
import BubbleChat from "../assets/images/bubbleChat.svg";
import Users from "../assets/images/users.svg";


import Ruby from "../assets/Icons/Achievements/Cups/Ruby/Level4RubyStar.svg";
import Gold from "../assets/Icons/Achievements/Cups/Gold/Level4GoldStar.svg";
import Emerald from "../assets/Icons/Achievements/Cups/Emerald/Level4EmeraldStar.svg";
import Diamand from "../assets/Icons/Achievements/Cups/Diamond/Level4DiamondStar.svg";
import RubyBorder from "../assets/Icons/Achievements/Border/RubyBorder.svg";
import GoldBorder from "../assets/Icons/Achievements/Border/GoldBorder.svg";
import EmeraldBorder from "../assets/Icons/Achievements/Border/EmeraldBorder.svg";
import DiamandBorder from "../assets/Icons/Achievements/Border/DiamondBorder.svg";

import GoldenStar from "../assets/drawer-icons/goldenStar.svg";
import GoldStar from "../assets/Icons/Achievements/Stars/Gold.svg";
import RubyStar from "../assets/Icons/Achievements/Stars/Ruby.svg";
import EmeraldStar from "../assets/Icons/Achievements/Stars/Emerald.svg";
import DiamandStar from "../assets/Icons/Achievements/Stars/Diamond.svg";

import GoldSmallBorder from "../assets/Icons/Achievements/Border/GoldSmallBorder.svg";
import RubySmallBorder from "../assets/Icons/Achievements/Border/RubySmallBorder.svg";
import EmeraldSmallBorder from "../assets/Icons/Achievements/Border/EmeraldSmallBorder.svg";
import DiamandSmallBorder from "../assets/Icons/Achievements/Border/DiamondSmallBorder.svg";

import CheckSign from "../assets/Icons/Achievements/Sign/CheckSign.svg";
import SignRed from "../assets/Icons/Achievements/Sign/CloseLargeSignRed.svg";

import FreeGift from "../assets/Icons/Achievements/Box/FreeGift.svg";
import GiftGoldStars from "../assets/Icons/Achievements/Box/GiftGoldStars.svg";
import GiftEmeraldStars from "../assets/Icons/Achievements/Box/GiftEmeraldStars.svg";
import GiftRubyStars from "../assets/Icons/Achievements/Box/GiftRubyStars.svg";
import GiftDiamondStars from "../assets/Icons/Achievements/Box/GiftDiamondStars.svg";


const images = {
  ButtonWorldStart,
  ScheduleIcon,
  SplashS,
  GooglePicto,
  locationOff,
  locationOn,
  Info,
  Men,
  Dollar,
  Trash,
  Edit,
  fbPage,
  fbGroup,
  HappyOFF,
  HappyON,
  otherLink,
  whatsappLink,
  meetupLink,
  telegramLink,
  locationPin,
  France,
  joinFileIcon,
  emojiIcon,
  plusIcon,
  sendMessageIcon,
  button,
  createbutton,
  Bug,
  Orga,
  Phone,
  Discord,
  Facebook,
  Telegram,
  Logo,
  LogoErasmusEvent,
  Friendship,
  Arrow,
  Email,
  Instagram,
  Link,
  PhoneMsg,
  Messenger,
  Snapchat,
  Tiktok,
  Twitter,
  Whatsapp,
  coupe1,
  coupe2,
  coupe3,
  coupe4,
  coupe5,
  coupe7,
  Friendship,
  Settings,
  next2,
  FreeApp,
  Idea,
  NonProfit,
  StarUsers,
  Coupe1,
  Coupe2,
  Coupe3,
  Coupe41,
  cadeau,
  file_copy,
  LastNameIcon,
  MemberNumberIcon,
  EmailIcon,
  PhoneIcon,
  BirthdayIcon,
  confidential,
  AddFriend,
  Placeholder2,
  warning,
  usergirl,
  userguy,
  users,
  close,
  ArrayRight,
  lastName,
  leftArrow,
  Previous,
  calendar,
  calendarOn,
  members,
  settings,
  contactUs,
  deleteAccount,
  editProfile,
  news,
  termsOfSales,
  SkipButton,
  LoveActivated,
  LoveDeactivated,
  Participants,
  PhotoSvg,
  cadenaOff,
  cadenaOn,
  CameraSvg,
  LogoCS,
  Language,
  Share,
  Heart,
  DefaultPicture,
  DownArrow,
  MembersOff,
  MembersOn,
  CalendarOff,
  CalendarOn,
  ConceptOff,
  ConceptOn,
  ContactOff,
  ContactOn,
  NewsOff,
  NewsOn,
  InviteFriendsIcon,
  SettingsIcon,
  UpdateIcon,
  NewsIcon,
  LogoutIcon,
  CreateActivityIcon,
  TermsOfSalesIcon,
  EditMV,
  Position,
  BubbleChat,
  coupe6,
  Ruby,
  Gold,
  Emerald,
  Diamand,
  GoldSmallBorder,
  RubySmallBorder,
  EmeraldSmallBorder,
  DiamandSmallBorder,
  Users,
  GoldenStar,
  GoldBorder,
  RubyBorder,
  EmeraldBorder,
  DiamandBorder,
  RubyStar,
  EmeraldStar,
  DiamandStar,
  CheckSign,
  SignRed ,
  GoldStar,
  FreeGift,
  GiftGoldStars,
  GiftEmeraldStars,
  GiftRubyStars,
  GiftDiamondStars
  
};

export default images;
