import React, { useState } from "react";
import { Text, View, Modal, Pressable, Image, TextInput } from "react-native";
import axios from "axios";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname";

// Components
import styles from "./deleteAccountScreenCss";

// import des images
import { images } from "../../../constantes";

const DeleteAccountScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstModalVisible, setFirstModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);
  const [specificIssues, setSpecificIssues] = useState([]);
  const [isYesPressed, setIsYesPressed] = useState(false); // Ajoutez cette variable d'état pour le bouton "Yes"
  const [isNoPressed, setIsNoPressed] = useState(false); // Ajoutez cette variable d'état pour le bouton "No"

  const navigation = useNavigation();
  const openFirstModal = () => {
    setFirstModalVisible(true);
  };
  const handleCancel = () => {
    navigation.navigate("Activities"); // Rediriger vers l'écran "Activities"
  };
  const closeFirstModal = () => {
    setFirstModalVisible(false);
  };

  const closeSecondModal = () => {
    setSecondModalVisible(false); // Fermez la deuxième modal
  };

  const handleYesClick = () => {
    setIsYesPressed(true);
    setIsNoPressed(false);
    // Ajoutez ici le code que vous souhaitez exécuter lorsque le bouton "Yes" est pressé
  };

  const handleNoClick = () => {
    setIsNoPressed(true);
    setIsYesPressed(false);
    // Ajoutez ici le code que vous souhaitez exécuter lorsque le bouton "No" est pressé
  };

  const [isIssueButtonPressed, setIsIssueButtonPressed] = useState(null);

  const [isButtonPressed, setIsButtonPressed] = useState([
    false,
    false,
    false,
    false,
  ]);

  const handleIssueButtonClick = (index) => {
    setIsButtonPressed((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const confirmAndDeleteAccount = async () => {
    try {
      // console.log("Attempting to delete account...");

      const storedData = await AsyncStorage.getItem("user");
      if (!storedData) {
        throw new Error("No user found in AsyncStorage");
      }
      const resultat = JSON.parse(storedData);
      const userToken = await AsyncStorage.getItem("userToken");

      if (!userToken) {
        throw new Error("No user token found in AsyncStorage");
      }

      // console.log("User data retrieved:", resultat);
      // console.log("User token retrieved:", userToken);

      const res = await axios.delete(
        `${hostname}/api/v1/user/delete/${resultat._id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // console.log("DELETE request response:", res);

      if (res.status === 200) {
        // Suppression des données du local storage et déconnexion
        await AsyncStorage.multiRemove([
          "userToken",
          "user",
          "gender",
          "accountType",
          "firstName",
          "lastName",
          "nickName",
          "city",
          "nativeLanguage",
          "role",
          "userProfile",
        ]);

        // console.log("Successfully deleted account");

        // Afficher un message de confirmation dans la première modal
        setModalMessage("Account deleted successfully");

        // Ouvrir la deuxième modal
        setSecondModalVisible(true);

        // Réinitialiser la première modal
        setFirstModalVisible(false);
      } else {
        throw new Error("Failed to delete account");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setModalMessage("Failed to delete account");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      {/* Question 1: Why do you want to delete your account? */}
      <Text style={styles.Title}>
        Why do you want {"\n"} to delete your account?
      </Text>

      {/* Question 2: Additional feedback about your experience */}
      <Text style={styles.questionText}>
        Can you provide us with additional feedback about your experience with
        our service?
      </Text>
      <TextInput
        style={styles.textInput}
        placeholder=""
        value={feedback}
        onChangeText={(text) => setFeedback(text)}
      />

      {/* Question 3: Receive newsletter */}
      <Text style={styles.questionText}>
        Would you like to receive our newsletter by email in the future?
      </Text>
      <View style={styles.buttonContainer1}>
        <Pressable
          style={[
            styles.buttonYes,
            isYesPressed ? styles.yesButtonPressed : null,
          ]}
          onPress={() => handleYesClick()}
        >
          <Text
            style={[
              styles.buttonText1,
              isYesPressed ? styles.textPressed : null,
            ]}
          >
            Yes
          </Text>
        </Pressable>
        <Pressable
          style={[styles.buttonNo, isNoPressed ? styles.noButtonPressed : null]}
          onPress={() => handleNoClick()}
        >
          <Text
            style={[
              styles.buttonText1,
              styles.noButtonText,
              isNoPressed ? styles.textPressed : null,
            ]}
          >
            No
          </Text>
        </Pressable>
      </View>

      {/* Question 4: Specific issues with our service */}
      <Text style={styles.questionText}>
        Can you tell us if you encountered any specific issues with our service?
      </Text>
      <View style={styles.buttonContainer2}>
        <View style={styles.leftButtons}>
          {["Not useful", "Complicated"].map((buttonText, index) => (
            <Pressable
              key={index}
              style={[
                styles.button2,
                isButtonPressed[index]
                  ? [styles.issueButtonPressed, styles.buttonPressed]
                  : null,
              ]}
              onPress={() => handleIssueButtonClick(index)}
            >
              <Text
                style={[
                  styles.buttonText2,
                  isButtonPressed[index] ? styles.textPressed : null,
                ]}
              >
                {buttonText}
              </Text>
            </Pressable>
          ))}
        </View>
        <View style={styles.rightButtons}>
          {["Too slow", "Better competitor"].map((buttonText, index) => (
            <Pressable
              key={index + 2} // Avoid key conflict with the first two buttons
              style={[
                styles.button2,
                isButtonPressed[index + 2]
                  ? [styles.issueButtonPressed, styles.buttonPressed]
                  : null,
              ]}
              onPress={() => handleIssueButtonClick(index + 2)}
            >
              <Text
                style={[
                  styles.buttonText2,
                  isButtonPressed[index + 2] ? styles.textPressed : null,
                ]}
              >
                {buttonText}
              </Text>
            </Pressable>
          ))}
        </View>
      </View>

      <Text style={styles.questionText}>
        Are you sure,{"\n"}you want to delete your account?
      </Text>
      <Modal
        animationType="slide"
        transparent={true}
        visible={firstModalVisible}
        onRequestClose={() => {
          closeFirstModal();
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalInnerContainer}>
            {/* Ajout du bouton avec la flèche */}
            <Pressable
              style={styles.backButtonWrapper}
              onPress={() => {
                closeFirstModal();
              }}
              // si non on peut ajouter handleCancel qui nous rediriger directement vers la page activities juste a la changer ici  onPress={() => {
              //closeFirstModal();
              //onPress={handleCancel}
            >
              <Image source={images.leftArrow} style={styles.leftArrowIcon} />
            </Pressable>

            <Text style={styles.modalText}>
              One more time ...{"\n"} {"\n"}Are ou sure,?
            </Text>

            <View style={styles.buttonRowContainer}>
              <Pressable
                style={(styles.buttonYesNo, styles.buttonCancel1)}
                onPress={() => closeFirstModal()}
              >
                <Text style={styles.buttonTextCancel}>CANCEL</Text>
              </Pressable>
              <Pressable
                style={(styles.buttonYesNo, styles.buttonDelete1)}
                onPress={confirmAndDeleteAccount}
              >
                <Text style={styles.buttonTextDelete}>DELETE</Text>
              </Pressable>
            </View>

            <Text style={styles.modalMessage}>{modalMessage}</Text>
          </View>
        </View>
      </Modal>

      {/* Second confirmation modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={secondModalVisible}
        onRequestClose={() => {
          closeSecondModal();
        }}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalInnerContainer}>
            <Text style={styles.modalTextOk}>
              Your account has been deleted {"\n"} successfully.
            </Text>
            <Pressable
              style={styles.buttonOk}
              onPress={() => {
                closeSecondModal();
                // Reload the entire page to reset the hamburger menu
                window.location.reload();
              }}
            >
              <Text style={styles.buttonTextOk}>OK</Text>
            </Pressable>
          </View>
        </View>
      </Modal>

      <View style={styles.buttonContainer}>
        <Pressable style={styles.buttonCancel} onPress={handleCancel}>
          <Text style={styles.buttonText3}>No,cancel</Text>
        </Pressable>
        <Pressable style={styles.button} onPress={openFirstModal}>
          <Text style={styles.buttonText3}>Yes, I'am sure</Text>
        </Pressable>
      </View>

      {isLoading && <Text style={styles.loadingText}>Loading...</Text>}
    </View>
  );
};

export default DeleteAccountScreen;
