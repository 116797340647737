import React from 'react';
import { Image, Modal, Pressable, ScrollView, Text, View } from 'react-native';
import EditBigSquare from "../../../../components/EditBigSquare/EditBigSquare.js";
import Fields from "../../../../components/Fields/Fields.js";
import SimpleDateField from "../../../../components/SimpleDateField.js";
import { images } from "../../../../constantes";
import styles from "./EditProfileStepTwoCss.js";

const EditProfileStepTwo = ({
  memberId,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  birthdayDate,
  setBirthdayDate,
  modalVisible,
  setModalVisible,
  isPhoneActive,
  setPhoneActive,
  isLastNameActive,
  setLastNameActive,
  isBirthdayActive,
  setBirthdayActive,
  email,
  setEmail,
  emailError,
  errorMsgLastName,
  errorMsgPhone,
  errorMsgBirthday,
  sendInfoEditProfile,
  step,
  setStep,
  pressed,
  handleDateSelection,
  scr, 
  bigBtn1
}) => {
    const { JsonProfilePages } = scr;
    return (
        <ScrollView style={styles.container}>
        {/* ----------------Titles---------------- */}
        <View style={styles.center}>
          <Image
            style={{ width: 100, height: 100, alignSelf: "center" }}
            source={images.confidential}
          />
          <Text style={styles.important}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.warning dans fr.json permet d'afficher "Pour pouvoir ajouter des amis, créer des évènements et envoyer des messages privés, tu dois fournir les informations ci-dessous"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.warning displays "To add friends, create events and send private messages we need some information"*/}
            {JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonWarning}
          </Text>
          {/* "warning": "In order to add friends, create events and send private message you need to let informations here" */}
        </View>
        <View style={styles.membership}>
          <Text style={styles.bold}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.membership dans fr.json permet d'afficher "Ton numéro d'utilisateur est :"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.membership displays "Membership number:"*/}
            {
              JsonProfilePages.JsonEditProfileScreen.JsonStep2
                .JsonYourMembershipIs
            }
          </Text>
          {/* "membership": "Your membership number is:" */}
          <Text style={styles.membershipNumber}>{memberId}</Text>
        </View>
        {/* ----------------Fields---------------- */}

        <View style={styles.center}>
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonLastnameDescription
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*Voir si on peut pas enlever cette partie #noworries*/}
            {/*🇫🇷 Aucun de ces champs n'est fonctionnel pour l'instant (tél, date de naissance, email)*/}
            {/*🇬🇧 None of these fields work right now. (phone, birthday, email) */}
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.lastName dans fr.json permet d'afficher "Nom de famille"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.lastName displays "Last name"*/}
            <Fields
              icon={"textFrame"}
              pressed={pressed}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastname
              }
              state={lastName}
              setState={setLastName}
              scr={scr}
            />
          </View>
          {errorMsgLastName && !lastName ? (
            <Text style={{ color: "red" }}>{errorMsgLastName}</Text>
          ) : (
            <Text style={{ color: "red" }}>{errorMsgLastName}</Text>
          )}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPhoneNumberDescription
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.phone dans fr.json permet d'afficher "Numéro de téléphone"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.phone displays "Phone number"*/}

            <Fields
              icon={"telephone"}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumber
              }
              state={phoneNumber}
              setState={setPhoneNumber}
              scr={scr}
            />
            {/* "phone": "Phone Number" */}
          </View>
          {errorMsgPhone && phoneNumber ? (
            <Text style={{ color: "red" }}>{errorMsgPhone}</Text>
          ) : null}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonBirthdayDescription
              }
            </Text>
          </View>

          <View style={styles.fields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.birthday dans fr.json permet d'afficher "Anniversaire"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.birthday displays "Birthday"*/}

            <Fields
              icon={"birthday"}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonBirthday
              }
              state={birthdayDate}
              setState={setBirthdayDate}
              onPress={() => {
                // console.log("Field pressed"); //🇫🇷 Aucune action pour l'instant
                setModalVisible(true);
              }}
              editable={false}
              scr={scr}
            />

            {/* Modal pour la sélection de la date d'anniversaire */}
            <Modal
              animationType="slide"
              transparent={true}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.centeredView}>
                <SimpleDateField setDate={handleDateSelection} />
              </View>
            </Modal>
          </View>

          {errorMsgBirthday ? (
            <Text style={{ color: "red" }}>{errorMsgBirthday}</Text>
          ) : null}
          <View style={styles.titleFields}>
            <Text style={styles.titleFields_text}>
              {
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonEmailDescription
              }
            </Text>
          </View>
          <View style={styles.fields}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.email dans fr.json permet d'afficher "Email"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.email displays "Email"*/}

            <Fields
              icon={"arobase"}
              pressed={pressed}
              text={JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail}
              state={email}
              setState={setEmail}
              disable={true}
              scr={scr}
            />
          </View>
        </View>
        {emailError && !email ? (
          <Text style={{ color: "red" }}>{emailError}</Text>
        ) : (
          <Text style={{ color: "red" }}>{emailError}</Text>
        )}
        <View style={styles.titleFields}></View>
        {/* ----------------FriendsInfo---------------- */}
        {/*Ajout des icons svg que l'on va prendre integer entant que chlidren dans le components EditSquare*/}
        <View style={styles.friendsNote}>
          {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.message_chat dans fr.json permet d'afficher "L'application n'est pas encore terminée mais vous pourrez bientôt avoir un chat privé avec vos vrais amis..."*/}
          {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.message_chat displays "The app is not finished yet but soon you will be able to get private chat with your true friends ..."*/}
          {/* <Text style={styles.bold}>{JsonProfilePages.JsonEditProfileScreen.JsonStep2.message_chat}</Text> */}

          <View style={styles.rectangleContainer}>
            <Text style={styles.comingSoonText}>
              {JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonComingSoon}
            </Text>
          </View>

          <Text style={styles.bold}>
            {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.label dans fr.json permet d'afficher "On pourra te rajouter en tant qu'ami pour t'envoyer des messages privés si l'on connaît ton :"*/}
            {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.label displays "People will be able to add you as a friend and send you private messages if they know your :"*/}

            {
              JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPeopleCanAddYouIfTheyKnowYour.split(
                "\n"
              )[0]
            }
          </Text>
          <Text style={[styles.bold, { textAlign: "center" }]}>
            {
              JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPeopleCanAddYouIfTheyKnowYour.split(
                "\n"
              )[1]
            }
          </Text>
          {/* "label": "People are able to add you as friend in order to send you private message if they know your :" */}
        </View>
        <View style={styles.friendInfo}>
          <View style={styles.wrapped}>
            <EditBigSquare
              title={JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail}
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPrivateForEveryone
              }
              fixedState={true}
            >
              <Image
                style={{ width: 24, height: 24 }}
                source={images.EmailIcon}
              />
            </EditBigSquare>
          </View>
          <View style={styles.wrapped}>
            {/*🇫🇷 Icon et titre iconde grand button carré pour habiliter affichage d'information*/}
            {/*🇬🇧 Icon and title of big square button for make public information*/}
            <EditBigSquare
              title={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumber
              }
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPrivateForEveryone
              }
              isActive={isPhoneActive}
              setActive={setPhoneActive}
            >
              <Image
                style={{ width: 24, height: 25 }}
                source={images.PhoneIcon}
              />
            </EditBigSquare>
          </View>
          <View style={styles.wrapped}>
            <EditBigSquare
              style={styles.wrapped}
              title={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastname
              }
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPrivateForEveryone
              }
              isActive={isLastNameActive}
              setActive={setLastNameActive}
            >
              <Image
                style={{ width: 28, height: 28 }}
                source={images.LastNameIcon}
              />
            </EditBigSquare>
          </View>
          <View style={styles.wrapped}>
            <EditBigSquare
              style={styles.wrapped}
              title={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonBirthday
              }
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPrivateForEveryone
              }
              isActive={isBirthdayActive}
              setActive={setBirthdayActive}
            >
              <Image
                style={{ width: 28, height: 28 }}
                source={images.BirthdayIcon}
              />
            </EditBigSquare>
          </View>
          <View style={styles.wrapped}>
            <EditBigSquare
              style={styles.wrapped}
              title={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonMemberShip
              }
              text={
                JsonProfilePages.JsonEditProfileScreen.JsonStep2
                  .JsonPrivateForEveryone
              }
              fixedState={true}
            >
              <Image
                style={{ width: 48, height: 49 }}
                source={images.MemberNumberIcon}
              />
            </EditBigSquare>
          </View>
        </View>
        {/* ----------------ValidationButtons---------------- */}
        {/*modif pour le saveButton*/}
        <View style={styles.savOrConButtons}>
          <Pressable
            style={bigBtn1.button}
            onPress={() => sendInfoEditProfile()}
          >
            <Text style={styles.btnText}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.save dans fr.json permet d'afficher "Enregistrer"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.save displays "Save"*/}
              {JsonProfilePages.JsonEditProfileScreen.JsonSaveButton}
            </Text>
          </Pressable>
          <Pressable style={styles.bigBtn} onPress={() => setStep(step + 1)}>
            <Text style={styles.btnText}>
              {/*🇫🇷 La variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.continue dans fr.json permet d'afficher "Continuer"*/}
              {/*🇬🇧 The en.json variable JsonProfilePages.JsonEditProfileScreen.JsonStep2.continue displays "Continue"*/}
              {JsonProfilePages.JsonEditProfileScreen.JsonContinueButton}
            </Text>
          </Pressable>
        </View>
      </ScrollView>
    )

}

export default EditProfileStepTwo;