//🇫🇷 Page de mise à jour de l'application
//🇬🇧 Application Update Page

import React from "react";
import { Image, Linking, ScrollView, Text, Pressable } from "react-native";

// Components
import styles from "./UpdateCss";

// Assets
import AppStore from "../../assets/images/app-store.svg";
import GooglePlay from "../../assets/images/google-play.svg";
import Logo from "../../assets/images/logo-Socializus.svg";

const Update = ({ scr }) => {
  const { JsonUpdateScreen } = scr;

  return (
    <ScrollView style={styles.container}>
      <Image source={Logo} style={styles.img} resizeMode="contain" />

      <Text style={styles.txt}>{JsonUpdateScreen.JsonDontForgetUpdate}</Text>

      <Pressable
        style={styles.ButtonFB}
        onPress={() =>
          Linking.openURL(
            "https://apps.apple.com/fr/app/socializus/id1492352535"
          )
        }
      >
        <Image source={AppStore} style={styles.logo} resizeMode="contain" />
      </Pressable>

      <Pressable
        style={styles.ButtonFB}
        onPress={() =>
          Linking.openURL(
            "https://play.google.com/store/apps/details?id=com.social.firebase.example.socializus&gl=FR"
          )
        }
      >
        <Image source={GooglePlay} style={styles.logo} resizeMode="contain" />
      </Pressable>
    </ScrollView>
  );
};

export default Update;
