//🇫🇷 Ce fichier regroupe tous les éléments en rapport avec la navigation du menu (drawer) (FrameFigma100)
//🇬🇧 This file contains all the items related to menu navigation (drawer) (FrameFigma100)

import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Linking,
  Pressable,
} from "react-native";
import {
  DrawerContentScrollView,
  DrawerItem,
  useDrawerStatus,
} from "@react-navigation/drawer";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { Icon } from "@rneui/themed";
import { EmailCheckDialog } from "../components/Dialogs/Dialogs";

// images
import { images } from "../constantes";

//Assets images
//  import ExampleCup from "../assets/user-grades-and-sponsorship/GoldCup_Gold_noStar.svg";
 import GoldenStar from "../assets/drawer-icons/goldenStar.svg";
// import ConceptIcon from "../assets/images/concept_lightbulb.svg";

const CustomDrawerContent = (props) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonDrawerNavMenu } = props.scr;
  const isDrawerOpen = useDrawerStatus() === "open";
  const [emailCheckDialogVisible, setEmailCheckDialogVisible] = useState(false);

  const text = "Username";
  const [image, setImage] = useState();
  //🇫🇷  declaration d'une url d'image
  //🇬🇧 declaration of an image url

  useEffect(() => {
    if (props.user != null) {
      setImage(props.user.sexe === "male" ? images.userguy : images.usergirl);
    } else {
      setImage(images.MemberNumberIcon);
    }
  });

  //end text

  //Role-reated functions and dialog
  const displayEmailCheckDialog = () => {
    setEmailCheckDialogVisible(!emailCheckDialogVisible);
  };

  const goToMailCheckScreen = () => {
    props.navigation.navigate("VerificationMailRole");
    displayEmailCheckDialog(!emailCheckDialogVisible);
  };

  const handleNavigationFromRole = () => {
    props.navigation.dispatch(DrawerActions.closeDrawer());
    if (props.user?.role.name[0] === "user without confirmation") {
      displayEmailCheckDialog();
    } else {
      props.navigation.navigate("Create Activity", {
        user: props.user,
      });
    }
  };

  let avatar = image; //avatar de dafault sans registration
  let us = "user"; //user de default sans reigration
  if (props.user != null) {
    if (
      props.user.avatar &&
      props.user.avatar !==
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
    ) {
      avatar = props.user.avatar; //avatar avec registration complete
    }
  }
  if (props.user != null) {
    if (props.user.userName) {
      us = props.user.userName; //user avec registration complete
    }
  }
  const navigation = useNavigation();
  const clearAllData = () => {
    // Effacer le localStorage
    localStorage.clear();

    // Effacer les cookies
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  return (
    <>
      <EmailCheckDialog
        dialogVisible={emailCheckDialogVisible}
        displayModal={displayEmailCheckDialog}
        goToMailCheckScreen={goToMailCheckScreen}
        user={props.user}
        scr={props.scr}
      />
      <View
        //🇫🇷si le drawer est ouvert prend le flex 1 sinon flex: 1, et change la couleur en blanc
        //🇬🇧 if the drawer is open take flex 1 otherwise flex: 1, and change the color to white
        style={
          isDrawerOpen ? { flex: 1 } : { flex: 1, backgroundColor: "white" }
        }
      >
        <DrawerContentScrollView
          {...props}
          //🇫🇷notre scrool qui vas permettre d'afficher notre view de drawer de couleur blanc et de padding 0
          //🇬🇧our scroll which will allow us to display our drawer view in white color and padding 0
          style={{ backgroundColor: "white", padding: 0 }}
        >
          {/*Style the image */}
          {isDrawerOpen && (
            <>
              <View
                style={{
                  marginTop: -4,
                  paddingHorizontal: 15,
                  paddingVertical: 8,
                  backgroundColor: "#59C09B",
                }}
              >
                {/*Croix pour fermer le menu */}
                {/*Icon for closing menu */}
                <View
                  style={{
                    alignSelf: "flex-end",
                    borderRadius: 50,
                    borderWidth: 0,
                  }}
                >
                  <Icon
                    name="close"
                    type="font-awesome"
                    size={25}
                    color="black"
                    onPress={() =>
                      props.navigation.dispatch(DrawerActions.closeDrawer())
                    }
                  />
                </View>
                {/* Début du header du menu burger */}
                {/* The menu content header */}

                <View style={styles.drawerHeader}>
                  <Pressable
                    onPress={() =>
                      props.navigation.navigate("Profile", {
                        // action pour aller dans la page de profile, ensuite aller dans edit profile?
                        user: props.user,
                      })
                    }
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Image source={avatar} style={styles.headerProfilePic} />
                      <Text style={styles.drawerLabel}>{us}</Text>
                    </View>
                  </Pressable>
                  {/* Voir dans la BDD si le user détient un trophée ou non */}
                  {/* Check in back-end if the user has a trophy */}
                  {/* <img src={ExampleCup} style={{ marginLeft: 30, width: 70 }} /> */}
                </View>
              </View>

              {/* Début du corps du menu burger */}
              {/* Menu content body */}
              <View style={{ backgroundColor: "#fff", flex: 1 }}>
                {/* Concept */}
                {/* <DrawerItem
                  icon={() => <img src={ConceptIcon} width="38" height="38" />}
                  label={JsonDrawerNavMenu.JsonConceptScreen}
                  onPress={() => {
                    props.navigation.navigate("Concept");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                /> */}

                {/* Profil */}
                <DrawerItem
                  icon={() => <img src={image} width="38" height="38" />}
                  label={JsonDrawerNavMenu.JsonMyProfile}
                  onPress={() => {
                    props.navigation.navigate("Profile", {
                      // action pour aller dans la page de profile, ensuite aller dans edit profile?
                      user: props.user,
                    });
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />
                {/* Créer une activité */}
                <DrawerItem
                  icon={() => (
                    <img
                      src={images.CreateActivityIcon}
                      width="38"
                      height="38"
                    />
                  )}
                  label={JsonDrawerNavMenu.JsonCreateEvent}
                  onPress={() => handleNavigationFromRole()}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />
                {/* Inviter des amis */}
                <DrawerItem
                  icon={() => (
                    <img
                      src={images.InviteFriendsIcon}
                      width="38"
                      height="38"
                    />
                  )}
                  label={JsonDrawerNavMenu.JsonInviteFriends}
                  onPress={() => {
                    props.navigation.navigate("Invite Friends");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />

                {/* Contact us */}
                <DrawerItem
                  icon={() => (
                    <img src={images.contactUs} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonContactUs}
                  onPress={() => {
                    props.navigation.navigate("Contact");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />

                {/* Become a VIP */}
                <DrawerItem
                  icon={() => (
                    <img src={GoldenStar} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonBecomeVip}
                  onPress={() => {
                    props.navigation.navigate("CupsAndAwards");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />

                {/* Settings */}
                <DrawerItem
                  icon={() => (
                    <img src={images.SettingsIcon} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonSettings}
                  onPress={() => {
                    props.navigation.navigate("Settings");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />
                {/* News */}
                <DrawerItem
                  icon={() => (
                    <img src={images.NewsIcon} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonNews}
                  onPress={() => {
                    props.navigation.navigate("News");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />

                {/* Update */}
                <DrawerItem
                  icon={() => (
                    <img src={images.UpdateIcon} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonUpdate}
                  onPress={() => {
                    props.navigation.navigate("Update");
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />

                {/* Terms and conditions */}
                <DrawerItem
                  icon={() => (
                    <img src={images.TermsOfSalesIcon} width="34" height="34" />
                  )}
                  label={JsonDrawerNavMenu.JsonTerms}
                  onPress={() => {
                    Linking.openURL(
                      "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
                      //Inserer le lien de CGU
                    );
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                  }}
                  labelStyle={styles.drawerLabel}
                  style={styles.drawerItemStyle}
                />
                {/* Logout */}

                <DrawerItem
                  icon={() => (
                    <img src={images.LogoutIcon} width="38" height="38" />
                  )}
                  label={JsonDrawerNavMenu.JsonLogout}
                  onPress={async () => {
                    try {
                      // Call the function to clear all data
                      clearAllData();

                      // Resetting the states
                      await Promise.all([
                        props.setToken(null),
                        props.setUser("undefined"),
                        props.setGender(null),
                        props.setAccountType(null),
                        props.setFirstName(null),
                        props.setLastName(null),
                        props.setNickName(null),
                        props.setCity(null),
                        props.setNativeLanguage(null),
                        props.setRole(null),
                      ]);

                      // console.log("Successfully logged out");

                      // Recharger la page entière
                      window.location.reload();
                    } catch (e) {
                      console.error("Error logging out:", e);
                    }
                  }}
                  labelStyle={styles.drawerLabel}
                />
              </View>
            </>
          )}
        </DrawerContentScrollView>
      </View>
    </>
  );
};

export default CustomDrawerContent;

const styles = StyleSheet.create({
  drawerHeader: {
    marginVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerProfilePic: {
    marginRight: 10,
    width: 65,
    height: 65,
    borderRadius: 65 / 2,
  },
  drawerLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  drawerItemStyle: {
    marginVertical: 4.5,
  },
});
