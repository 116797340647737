//🇫🇷 Affichage de la Burger Button pour le header pour ouvrir le drawer navigation
//🇬🇧 Display of the burger button for the header to open the drawer navigation

import React from "react";
import { View, Text, Pressable } from "react-native";

// Components
import styles from "./BurgerButtonCss";
import { useNavigation, DrawerActions } from "@react-navigation/native";

const BurgerButton = () => {
  const navigation = useNavigation();
  return (
    <Pressable
      style={styles.drawerBtn}
      onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
    >
      <View>
        <View style={styles.line} />
        <View style={styles.line} />
        <View style={styles.line} />
      </View>
      <View style={styles.menuBtn}>
        <Text style={styles.menuText}>Menu</Text>
      </View>
    </Pressable>
  );
};

export default BurgerButton;
