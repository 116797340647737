import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useRef, useState } from "react";
import { SafeAreaView, View } from "react-native";
import Swiper from "react-native-web-swiper";

// Components
import ConceptSlide from "../../components/ConceptSlide/ConceptSlide";
import styles from "./ConceptScreenCss";

const ConceptScreen = (props) => {
  // console.log("Props for ConceptScreen:", props);

  const [swiperKey, setSwiperKey] = useState(Math.random());
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const ref = useRef(null);

  //🇫🇷 Si jamais, vous voulez changer le nombre de page de concept, c'est la constante "slides" qu'il faut changer
  //🇬🇧 If ever you want to change the number of concept pages, it is the constant "slides" that must be changed
  const slides = [1, 2, 3, 4, 5, 6];
  slides.sort(() => Math.random() - 0.5);

  //🇫🇷 Gestion de l'action pour passer au slide suivant
  //🇬🇧 Action management to move to the next slide
  const nextSlide = () => {
    setActiveSlideIndex((prevIndex) => {
      if (prevIndex === slides.length - 1) {
        ref.current.goTo(0);
        return 0;
      } else {
        ref.current.goTo(prevIndex + 1);
        return prevIndex + 1;
      }
    });
  };

  //🇫🇷 Gestion de l'action pour passer au slide précédent
  //🇬🇧 Action management to move to the previous slide
  const prevSlide = () => {
    setActiveSlideIndex((prevIndex) => {
      if (prevIndex === 0) {
        ref.current.goTo(slides.length - 1);
        return slides.length - 1;
      } else {
        ref.current.goTo(prevIndex - 1);
        return prevIndex - 1;
      }
    });
  };

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.slideContainer}>
        <Swiper
          key={swiperKey}
          ref={ref}
          index={activeSlideIndex}
          onChangeIndex={({ index }) => {
            setActiveSlideIndex(index);
            //setSwiperKey(Math.random());
          }}
          controlsProps={{
            prevTitle: ">",
            nextTitle: "<",
            dotsTouchable: true,
            dotsWrapperStyle: {
              display: "none",
              marginBottom: "10px",
            },
          }}
        >
          {slides.map((id) => (
            <ConceptSlide
              key={id}
              id={id}
              scr={props.scr}
              nextSlide={nextSlide}
              prevSlide={prevSlide}
            />
          ))}
        </Swiper>
      </SafeAreaView>
    </View>
  );
  
};

export default ConceptScreen;
