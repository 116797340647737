import React, { useState } from "react";
import {
  Image,
  Linking,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

import constStyle from "./InviteFriendsScreenCss";
import { images } from "../../constantes";

const InviteFriends = (props) => {
  const { JsonInviteFriendsScreen } = props.scr;
  const { JsonInvitationMessage, JsonInvite } = JsonInviteFriendsScreen;

  const navigation = useNavigation();
  const [invitationCount, setInvitationCount] = useState(0);

  const socialButtons = [
    {
      icon: images.Email,
      text: JsonInviteFriendsScreen.JsonInviteEmail,
      action: () => `mailto:?subject=Invitation&body=${JsonInvitationMessage}`,
    },
    {
      icon: images.Messenger,
      text: JsonInviteFriendsScreen.JsonInviteFacebook,
      action: () => `fb-messenger://user/?message=${JsonInvitationMessage}`,
    },
    {
      icon: images.Whatsapp,
      text: JsonInviteFriendsScreen.JsonInviteWhatsapp,
      action: () => `whatsapp://send?text=${JsonInvitationMessage}`,
    },
    {
      icon: images.PhoneMsg,
      text: JsonInviteFriendsScreen.JsonInviteTextMessage,
      action: () => `sms:?&body=${encodeURIComponent(JsonInvitationMessage)}`,
    },
    {
      icon: images.Twitter,
      text: JsonInviteFriendsScreen.JsonInviteTwitter,
      action: () => `twitter://post?message=${JsonInvitationMessage}`,
    },
    {
      icon: images.Instagram,
      text: JsonInviteFriendsScreen.JsonInviteInstagram,
      action: () => "instagram://app",
    },
    {
      icon: images.Tiktok,
      text: JsonInviteFriendsScreen.JsonInviteTiktok,
      action: () => "tiktok://",
    },
    {
      icon: images.Snapchat,
      text: JsonInviteFriendsScreen.JsonInviteSnapchat,
      action: () => "snapchat://",
    },
    {
      icon: images.Link,
      text: JsonInviteFriendsScreen.JsonInviteWithLink,
      action: () => "https://install.socializus.com/",
    },
  ];

  const inviteFriend = (action) => {
    Linking.openURL(action());
    const newCount = invitationCount + 1;
    setInvitationCount(newCount);
    //🇫🇷 Vous pouvez ajouter ici la logique pour sauvegarder 'newCount' dans votre MongoDB
    //🇬🇧 You can add the logic here to save 'newCount' in your MongoDB
  };

  return (
    <SafeAreaView style={constStyle.container}>
      <ScrollView style={{ backgroundColor: "white" }}>
        <View style={constStyle.inviteStyle}>
          <View style={constStyle.friendship}>
            <Image
              source={images.Friendship}
              style={constStyle.friendshipImg}
            />
          </View>
          <View style={constStyle.inviteFriendsView}>
            <Text style={constStyle.inviteFriendsStyle}>{JsonInvite}:</Text>
          </View>
          {socialButtons.map((button, index) => (
            <Pressable
              key={index}
              style={constStyle.social}
              onPress={() => inviteFriend(button.action)}
            >
              <Image source={button.icon} style={constStyle.imageStyle} />
              <Text style={constStyle.txt}>{button.text}</Text>
              <View style={constStyle.linkIcon}>
                <Image source={images.Arrow} style={constStyle.imageArrow} />
              </View>
            </Pressable>
          ))}
        </View>
        <Pressable
          onPress={() => {
            navigation.navigate("Invite Friends");
          }}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default InviteFriends;
