import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    column: {
        flex: 1,
        fontWeight: "bold",
        borderRightWidth: 1,
        borderColor: "#000",
        textAlign: "center",
      },
})

export default styles;