//🇫🇷 Ce fichier gère tous les pages de création d'activité (FrameFigma31-FrameFigma32-FrameFigma33-FrameFigma34)
//🇬🇧 This file manage all create activity pages (FrameFigma31-FrameFigma32-FrameFigma33-FrameFigma34)

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation, useRoute } from "@react-navigation/native";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LogBox } from "react-native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components

import CreateActivityScreenStepOne from "./CreateActivityScreenStepOne/CreateActivityScreenStepOne.js";
import CreateActivityScreenStepTwo from "./CreateActivityScreenStepTwo/CreateActivityScreenStepTwo.js";
// Assets
import { activitiesList } from "../../../assets/activityList/activityListWithIcons.js";
import CreateActivityScreenStepFour from "./CreateActivityScreenStepFour/CreateActivityScreenStepFour.js";
import CreateActivityScreenStepThree from "./CreateActivityScreenStepThree/CreateActivityScreenStepThree.js";

LogBox.ignoreLogs([
  "VirtualizedLists should never be nested inside plain ScrollViews with the same orientation because it can break windowing and other functionality - use another VirtualizedList-backed container instead.",
]);

const CreateActivityScreen = ({ user, scr }) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const route = useRoute();
  user = route.params.user;

  // console.log("user", user); //🇫🇷  Affiche les informations de l'utilisateur //🇬🇧  Display user information
  // console.log("info event", route.params.event); //🇫🇷  Affiche les informations de l'événement //🇬🇧  Display event information

  const { name } = user?.role;
  //role est un array, d'où le [0]

  // console.log("user.role", user?.role);

  const { JsonActivityPages } = scr;

  const premiumRoles = ["admin", "moderator", "Diamond star"];

  const navigation = useNavigation();
  const [buttonSelected, setButtonSelected] = useState(
    //🇫🇷  Variable pour le bouton de sélection de l'adresse //🇬🇧  Variable for the address selection button
    JsonActivityPages.JsonActivityManagement
      .JsonStandardActivityChooseAddressScreen.JsonAddress
  );

  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState(""); //🇫🇷  Variable pour afficher les erreurs //🇬🇧  Variable to display errors

  //FR ETAPE 1-variables relatives
  //GB STEP 1-related variables
  const [author, setAuthor] = useState();
  //FR Récupérer l'ID et le nom de l'utilisateur, il serait mieux de le faire avec useContext
  //GB Get the id and the name of the user; could be nice to do it with useContext, I think
  const [title, setTitle] = useState(""); //ENLEVER PLUS TARD
  const [address, setAddress] = useState();
  const [online, setOnline] = useState(false);
  const [location, setLocation] = useState({
    latitude: 48.85714319815175,
    longitude: 2.347655098840397,
    latitudeDelta: 0.012,
    longitudeDelta: 0.012,
  });

  const [dateTime, setDateTime] = useState(0);
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  //FR Nombre de participants
  //GB How many attendants
  const [isAttendeeLimited, setIsAttendeeLimited] = useState(false);
  const [attendeeLimit, setAttendeeLimit] = useState(2);
  const [disableButton, setDisbaleButton] = useState(true);
  const [hasPrice, setHasPrice] = useState(false);
  const [priceValue, setPriceValue] = useState(0);
  const [ticketLink, setTicketLink] = useState("");

  const [nbFriend, setNbFriend] = useState(0);

  const [helpForOrganizers, setHelpForOrganizers] = useState(false);

  const [hasReminderName, setHasReminderName] = useState(false);
  const [reminderName, setReminderName] = useState("");

  const [requestCoOrganizers, setRequestCoOrganizers] = useState(false);
  const [coOrganizerRequests, setCoOrganizerRequests] = useState([]);
  const [coOrganizerOffers, setCoOrganizerOffers] = useState([]);
  const [coOrganizerGift, setCoOrganizerGift] = useState("");

  const [optionInArray, setOptionInArray] = useState(false);

  //FR Lorsque l'utilisateur active les premières options sont ajoutées ou supprimées du tableau
  //GB If the user switches Request Co-organizers on, the first options are added or removed from the array
  useEffect(() => {
    if (requestCoOrganizers) {
      //🇫🇷 Si requestCoOrganizers est vrai, ajouter des options initiales aux demandes et offres des co-organisateurs
      //🇬🇧 If requestCoOrganizers is true, add initial options to co-organizer requests and offers
      setCoOrganizerRequests([
        JsonActivityPages.JsonCoOrganizerReceivePrivateMessage,
      ]); //🇫🇷 Ajouter des options initiales aux demandes des co-organisateurs
      setCoOrganizerOffers([JsonActivityPages.JsonCoOrganizerOfferDrink]); //🇫🇷 Ajouter des options initiales aux offres des co-organisateurs
    } else {
      setCoOrganizerRequests([]); //🇫🇷 Supprimer des options initiales aux demandes des co-organisateurs
      setCoOrganizerOffers([]); //🇫🇷 Supprimer des options initiales aux offres des co-organisateurs
    }
  }, [requestCoOrganizers]);

  // const [forbiddenPeople, setForbiddenPeople] = useState([]);
  // const [invitations, setInvitations] = useState([]);
  // const [type, setType] = useState("");
  // const [fake, setFake] = useState(true);
  // const [restriction, setRestriction] = useState(false);

  //STEP 2-related variables
  const [topic, setTopic] = useState(null);

  //STEP 3-related variables
  const [activityImage, setActivityImage] = useState(null);
  const [description, setDescription] = useState(""); //🇫🇷 Valeur pour default pour description //🇬🇧 default value for description
  const [howToFind, setHowToFind] = useState(""); //🇫🇷 Valeur pour default pour how to find ux //🇬🇧 default value for how to find us

  //STEP 4-related variables
  const [whatsappLink, setWhatsappLink] = useState("");
  const [fbPageLink, setFbPageLink] = useState("");
  const [fbGroupLink, setFbGroupLink] = useState("");
  const [meetupLink, setMeetupLink] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [otherLink, setOtherLink] = useState("");

  const [friendsOnly, setFriendsOnly] = useState(false);
  const [selectPeople, setSelectPeople] = useState(false);
  const [allowPhoneNumberDisplay, setAllowPhoneNumberDisplay] = useState(false);
  const [allowCoOrg, setAllowCoOrg] = useState(false);
  //Go fetch the list of the user's friends if true
  const [infoLine, setInfoLine] = useState("");

  const [repeatEvent, setRepeatEvent] = useState(false);
  const [repeatEventFrequency, setRepeatEventFrequency] = useState("");
  const [repeatEventDays, setRepeatEventDays] = useState([]);
  //🇬🇧STEP 4 event repeat manager code
  const [dayInArray, setDayInArray] = useState(false);
  const [repeatEventEndDate, setRepeatEventEndDate] = useState(null);

  const [parity, setParity] = useState(false);
  const [parityValuesMale, setParityValuesMale] = useState(  50 );
  const [parityValuesFemale, setParityValuesFemale] = useState( 50 );

  const [allowGuests, setAllowGuests] = useState(false);
  const [howManyGuests, setHowManyGuests] = useState(1);

  const [ageRestriction, setAgeRestriction] = useState(false);
  const [ages, setAges] = useState([20, 40]);

  const showError = (text) => {
    //🇫🇷 Affiche l'erreur si l'envoi des données de l'activité à la base de donnée a échoué
    setErrorMessage(text);
    setStep(step - 1);
  };

  //🇫🇷 Gestion de la validation des éléments entre les étapes de création d'activité
  //🇬🇧GB The useEffect manages validation between the event creation steps
  useEffect(() => {
    // console.log("CreateActivityScreen Topic", topic);
    if (step === 2) {
      //FR quand on passe de l'étape 1 à l'étape 2
      //🇬🇧GB from step 1 to step 2
      if (title === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
            .JsonTitleErrorMessage
        );
      //🇫🇷 Affiche error si le titre est vide 🇫🇷
      else if (address === undefined || address === "")
        //🇫🇷 Affiche error si l'adresse est vide 🇫🇷
        return showError(
          JsonActivityPages.JsonActivityManagement
            .JsonStandardActivityChooseAddressScreen.JsonAddressErrorMessage
        );
      else if (date === null || startTime === null || date === "Invalid date")
        //🇫🇷 Affiche error si la date ou l'heure est vide 🇫🇷
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
            .JsonDateTimeErrorMessage
        );
      /* 🇫🇷 Affiche error si le lien pour ticket n'est pas valid avec debut http.. 🇫🇷 */
      /* 🇬🇧Show error if ticket link is not valid with start http..  🇬🇧 */ else if (
        ticketLink.length > 4
      ) {
        //🇫🇷 Si la longueur du lien pour ticket est supérieure à 4
        if (
          !(
            (
              ticketLink.startsWith("https://") || //🇫🇷 Si le lien pour ticket commence par https://
              ticketLink.startsWith("http://")
            ) //🇫🇷 Si le lien pour ticket commence par http://
          )
        ) {
          return showError(
            JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
              .JsonPriceLinkErrorMessage
          );
        }
      }
      // else if (hasPrice && isNaN(priceValue))
      //   return showError(JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPriceErrorMessage);
      // 🇬🇧else if(attendee + nbFriend > attendeeLimit)
      // 🇬🇧return showError("There is more participants than the limit you set");
    }

    if (step === 3) {
      //🇫🇷 quand on passe de l'étape 2 à l'étape 3
      //🇬🇧 From step 2 to step 3
      if (topic === null)
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen
            .JsonTopicErrorMessage
        ); //🇫🇷 Affiche error si le thème est vide 🇫🇷
    }

    if (step === 4) {
      //🇫🇷  quand on passe de l'étape 3 à l'étape 4
      //🇬🇧 From step 3 to step 4
      if (activityImage === null)
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonImageErrorMessage
        ); //🇫🇷 Affiche error si l'image est vide 🇫🇷
      if (description === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonDescriptionErrorMessage
        ); //🇫🇷 Affiche error si la description est vide 🇫🇷
      if (howToFind === "")
        return showError(
          JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen
            .JsonHowToFindMeErrorMessage
        );
    }
  }, [step, address]);
  //🇬🇧 function called to create the activity in the database Used at the end of Step3 or 4 depending on the role of the user (lines 59 and 60)
  //🇫🇷 fonction appelée pour créer l'activité dans la base de donnée Utilisée à la fin du Step3 ou 4 en fonction du rôle de l'utilisateur (lignes 59 et 60)
  const createAnActivity = async () => {
    try {
      const token = await AsyncStorage.getItem("userToken");
      const formData = new FormData();
      const dateEvent = new Date(date);
  
      formData.append("date", date);
      formData.append("activityImage", activityImage);
  
      // Other data fields
      formData.append("title", title);
      formData.append("isOnline", online);
      formData.append("address", address);
      formData.append("locationLatitude", location.latitude);
      formData.append("locationLongitude", location.longitude);
      formData.append("locationLatitudeDelta", location.latitudeDelta);
      formData.append("locationLongitudeDelta", location.longitudeDelta);


      formData.append("startTime", startTime);
      formData.append("isAttendeeLimited", isAttendeeLimited);
      formData.append("attendeeLimit", isAttendeeLimited ? 1000000 : attendeeLimit);
      formData.append("hasPrice", hasPrice);
      formData.append("price", hasPrice ? priceValue : 0);
      formData.append("ticketLink", hasPrice ? ticketLink : null);
  
      formData.append("helpForOrganizers", helpForOrganizers);
      formData.append("hasReminderName", hasReminderName);
      formData.append("reminderName", reminderName);
      formData.append("requestCoOrganizers", requestCoOrganizers);
      formData.append("coOrganizerRequests", coOrganizerRequests);
      formData.append("coOrganizerOffers", coOrganizerOffers);
      formData.append("coOrganizerGift", coOrganizerGift);
  
      formData.append("topic", topic);
      formData.append("description", description);
      formData.append("howToFind", howToFind);
  
      formData.append("whatsappLink", whatsappLink);
      formData.append("fbPageLink", fbPageLink);
      formData.append("fbGroupLink", fbGroupLink);
      formData.append("meetupLink", meetupLink);
      formData.append("telegramLink", telegramLink);
      formData.append("otherLink", otherLink);
      formData.append("friendsOnly", friendsOnly);
      formData.append("nbFriends", nbFriend);
      formData.append("selectPeople", selectPeople);
      formData.append("allowPhoneNumberDisplay", allowPhoneNumberDisplay);
      formData.append("allowCoOrganiser", allowCoOrg);
      formData.append("infoLine", infoLine);
      formData.append("repeatEvent", repeatEvent);
      formData.append("repeatEventFrequency", repeatEvent ? repeatEventFrequency : null);
      formData.append("repeatEventDays", repeatEvent ? repeatEventDays : null);
      formData.append("repeatEventEndDate", repeatEvent ? repeatEventEndDate : null);
      formData.append("parity", parity);
      formData.append("parityValuesMale", parityValuesMale);
      formData.append("parityValuesFemale", parityValuesFemale);

      formData.append("allowGuests", allowGuests);
      formData.append("howManyGuests", allowGuests ? howManyGuests : 0);
      formData.append("ageRestriction", ageRestriction);
      formData.append("ages", ageRestriction ? JSON.stringify(ages) : JSON.stringify([0, 0]));
      
      const { data } = await axios.post(
        `${hostname}/api/v1/activities/createactivity`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    
      setStep(1);
      setTitle("");
      navigation.replace("Activity", {
        event: data.data,
        pageTitle: activitiesList[data.data.topic].activityTypeTitle,
      });
    } catch (error) {
      console.error("Error creating activity:", error.response.data);
      // Handle the error as needed
    }
  };
  

  if (step === 1) {
    return (
      <CreateActivityScreenStepOne
        title={title}
        setTitle={setTitle}
        scr={scr}
        buttonSelected={buttonSelected}
        setButtonSelected={setButtonSelected}
        setOnline={setOnline}
        location={location}
        setLocation={setLocation}
        date={date}
        setDate={setDate}
        startTime={startTime}
        setStartTime={setStartTime}
        name={name}
        isAttendeeLimited={isAttendeeLimited}
        setIsAttendeeLimited={setIsAttendeeLimited}
        attendeeLimit={attendeeLimit}
        setAttendeeLimit={setAttendeeLimit}
        hasPrice={hasPrice}
        setHasPrice={setHasPrice}
        priceValue={priceValue}
        setPriceValue={setPriceValue}
        ticketLink={ticketLink}
        setTicketLink={setTicketLink}
        nbFriend={nbFriend}
        setNbFriend={setNbFriend}
        premiumRoles={premiumRoles}
        step={step}
        setStep={setStep}
        errorMessage={errorMessage}
        setAddress={setAddress}
        setErrorMessage={setErrorMessage}
    />
    );
  }

  //FR Création d'activité - Choix du thème (Frame 33 MVP du Figma )
  //GB Event creation - Theme choice (Frame 33 MVP Figma)
  if (step === 2) {
    return (
      <CreateActivityScreenStepTwo
          topic={topic}
          setTopic={setTopic}
          step={step}
          setStep={setStep}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          scr={scr}
      />
    );
  }

  //FR Création d'activité - Photo et description (Frame 34 MVP du Figma )
  //GB Event creation - Photo and description (Frame 34 MVP Figma)
  if (step === 3) {
    return (
      <CreateActivityScreenStepThree 
      topic={topic}
      activityImage={activityImage}
      setActivityImage={setActivityImage}
      disableButton={disableButton}
      setDisbaleButton={setDisbaleButton}
      scr={scr}
      description={description}
      setDescription={setDescription}
      howToFind={howToFind}
      setHowToFind={setHowToFind}
      step={step}
      setStep={setStep}
      errorMessage={errorMessage}
      premiumRoles={premiumRoles}
      name={name}
      createAnActivity={createAnActivity}
      setErrorMessage={setErrorMessage}
      />
    );
  }

  //🇫🇷 Création d'activité - Infos complémentaires (Frame 35 MVP du Figma )
  //🇬🇧 Event creation - Additional info (Frame 35 MVP Figma)
  if (step === 4) {
    return (
      <CreateActivityScreenStepFour 
        scr={scr}
        setStep={setStep}
        step={step}
        createAnActivity={createAnActivity}
        whatsappLink={whatsappLink}
        setWhatsappLink={setWhatsappLink}
        fbPageLink={fbPageLink}
        setFbPageLink={setFbPageLink}
        fbGroupLink={fbGroupLink}
        setFbGroupLink={setFbGroupLink}
        meetupLink={meetupLink}
        setMeetupLink={setMeetupLink}
        telegramLink={telegramLink}
        setTelegramLink={setTelegramLink}
        otherLink={otherLink}
        setOtherLink={setOtherLink}
      />
    );
  }
};

export default CreateActivityScreen;