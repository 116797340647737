//🇫🇷 Ceci est le composant où l'adresse est enregistrée; si vous devez changer les infos de cette adresse, loggez les différentes données après avoir sélectionné une adresse (à partir de data et details) (FrameFigma31)
//🇬🇧 This is the component where the address is saved; if you ever have to change the address, just log the different data retreived after pressing an address and save the data you need  (from data and details) (FrameFigma31)

import React, { useState } from "react";
import { Text, View, Pressable } from "react-native";
import { Icon } from "@rneui/themed";
import MapView from "react-native-web-maps";
import Marker from "react-native-web-maps/dist/Marker";
import { usePlacesWidget } from "react-google-autocomplete";

// Components
import styles from "./AddressMapCss";
import InputField from "../InputField/InputField";

const AddressMap = ({
  upperTitle,
  address,
  setAddress,
  location,
  setLocation,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  const [isOnline, setIsOnline] = useState(false);

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setLocation({
        locationLatitude: place.geometry.location.lat(),
        locationLongitude: place.geometry.location.lng(),
        locationLatitudeDelta: 0.002,
        locationLongitudeDelta: 0.002,
      });
      setAddress(place.formatted_address);
      //console.log(place)
      // console.log(place.formatted_address);
    },
    options: {
      types: "addresses",
    },
  });
  //FR Méthode permettant de modifier la valeur de l'input
  //GB Method to modify the value of the input

  const emptyAddress = () => {
    setAddress("");
  };

  return (
    <>
      {upperTitle ===
      JsonActivityPages.JsonActivityManagement
        .JsonStandardActivityChooseAddressScreen.JsonAddress ? (
        <>
          <View style={styles.addressView}>
            <View style={styles.addressSubView}>
              <Text style={styles.title}>{upperTitle}</Text>

              <View style={styles.textInputContainer}>
                <input
                  ref={ref}
                  style={styles.textInput}
                  placeholder={address}
                />
                {/* <Fields ref={ref} text={JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonAddressPlaceholder}      //🇫🇷  Essaie de changement de input, mais les donnes sont pas reconnus pas google .js //🇬🇧 trying to change input , but google is no reconnising app.js
                  upperText={JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonAddressPlaceholder} placeholder={address} /> */}
              </View>
            </View>
            {/* "info": "Address / Google maps url" */}
          </View>
          <View style={styles.mapViewStyle}>
            <MapView
              style={styles.map}
              region={location}
              // onRegionChangeComplete={(v) => { console.log(v) }}
            >
              <Marker
                coordinate={location}
                pinColor="#3A8569"
                title={address}
              />
            </MapView>
          </View>
        </>
      ) : (
        <View style={styles.inputFieldView}>
          <InputField
            title={upperTitle}
            state={address}
            setState={setAddress}
            specialHeight={60}
          />
          <Pressable style={styles.closeIconBtn} onPress={() => emptyAddress()}>
            <Icon name="close" type="font-awesome" color="#ccc" size={22} />
          </Pressable>
        </View>
      )}
    </>
  );
};

export default AddressMap;
