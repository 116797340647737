// CreateActivityScreenStepOne.js

import React,{useState} from 'react';
import { ScrollView, Text, View } from 'react-native';
import AddressMap from '../../../../components/AddressMap/AddressMap.js';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker.js';
import Fields from '../../../../components/Fields/Fields.js';
import LogButton from '../../../../components/LogButtons/LogButtons.js';
import { OptionButton } from '../../../../components/SelectionElements/SelectionElements.js';
import { OneValueSlider } from '../../../../components/Sliders/Sliders.js';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn.js';

//Styles
import styles from '../CreateActivityScreenCss.js';

const CreateActivityScreenStepOne = ({
  title,
  setTitle,
  scr,
  buttonSelected,
  setButtonSelected,
  setOnline,
  location,
  setLocation,
  date,
  setDate,
  startTime,
  setStartTime,
  name,
  isAttendeeLimited,
  setIsAttendeeLimited,
  attendeeLimit,
  setAttendeeLimit,
  hasPrice,
  setHasPrice,
  ticketLink,
  setTicketLink,
  nbFriend,
  setNbFriend,
  premiumRoles,
  step,
  setStep,
  errorMessage,
  setErrorMessage,
  setAddress,
}) => {
    const [localLocation, setLocalLocation] = useState(location);
    const {JsonActivityPages} = scr;
  return (
    <ScrollView
            style={styles.container}
            keyboardShouldPersistTaps="handled"
            listViewDisplayed={false}
    >
            {/* ----------------Titles---------------- */}
            <Text style={styles.boldTitle}>
            {/*🇫🇷 La variable createActivity.step1.information définit l'affichage en "Les informations principales".
                🇬🇧 The variable createActivity.step1.information displays "Main information"*/}
            {
                JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                .JsonMainInformation
            }
            </Text>
            <View style={styles.fields}>
            <Fields
                //upperText={JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitle}
                text={
                JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonActivityTitle
                }
                state={title}
                setState={(newTitle) => {
                setTitle(newTitle.substring(0, 65));
                }}
                scr={scr}
            />
            </View>

            <View style={styles.localizationRow}>
            {/*🇫🇷 La variable createActivity.step1.address définit l'affichage en "Adresse.
                🇬🇧 The variable createActivity.step1.address displays "Address"*/}
            <OptionButton
                title={
                JsonActivityPages.JsonActivityManagement
                    .JsonStandardActivityChooseAddressScreen.JsonAddress
                }
                buttonSelected={buttonSelected}
                setButtonSelected={setButtonSelected}
                setOption={setOnline}
                buttonselect={true}
                scr={scr}
            />
            {/*🇫🇷 La variable createActivity.step1.online définit l'affichage en "En ligne.
                🇬🇧 The variable createActivity.step1.online displays "Online"*/}
            {/*FR le button optionButton est pour le moment désactivé car il provoque un bug (ce bouton ne doit pas être decommenté pour le moment)
                GB The button optionButton is disable for the moment because he provoke an bug (this button is disable for the moment, do not comment)*/}
            {/*<OptionButton
                title={JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonOnline}
                buttonSelected={buttonSelected}
                setButtonSelected={setButtonSelected}
                setOption={setOnline}
                buttonselect={true}
                />*/}
            {/*🇫🇷 La variable createActivity.step1.mapUrl définit l'affichage en "Url Google Map".
                🇬🇧 The variable createActivity.step1.mapUrl displays "Google Maps URL"*/}
            {/* <OptionButton
                title={JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonGoogleMapsUrl}
                buttonSelected={buttonSelected}
                setButtonSelected={setButtonSelected}
            /> */}
            </View>

            <View style={styles.AddressMapView}>
            <AddressMap
                scr={scr}
                upperTitle={buttonSelected}
                address={
                JsonActivityPages.JsonActivityManagement
                    .JsonStandardActivityChooseAddressScreen.JsonAddressPlaceholder
                }
                setAddress={setAddress}
                location={location}
                setLocation={setLocation}
            />
            </View>

            {/* 🇫🇷 La variable createActivity.step1.date définit l'affichage en
            "Date". 🇬🇧 The variable createActivity.step1.date displays "Date" */}

            <View style={styles.DateTimePickerView}>
            <DateTimePicker
                title={
                JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
                    .JsonDateTime
                }
                date={date}
                setDate={setDate}
                time={startTime}
                setTime={setStartTime}
            />
            </View>

            {premiumRoles.includes(name[0]) && (
            <View style={styles.switchRow}>
                <Text style={styles.boldTitle}>
                {/* 🇫🇷 La variable createActivity.step1.unlimited définit l'affichage en "Participants illimités".
                    🇬🇧 The variable createActivity.step1.unlimited displays "Unlimited attendees"*/}
                {
                    JsonActivityPages.JsonActivityManagement
                    .JsonGeneralOptionsScreen.JsonUnlimitedAttendees
                }
                </Text>
                <SwitchBtn
                state={isAttendeeLimited}
                setState={setIsAttendeeLimited}
                />
            </View>
            )}

            <Text style={styles.createActivityStep1Only}>
            {/*🇫🇷 La variable createActivity.step1.theOnly définit l'affichage en "(la seule application sans restrictions pour les organisateurs)".
                    🇬🇧 The variable createActivity.step1.theOnly displays "(The only map without restrictions for organizers)"*/}
            {
                JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                .JsonOnlyAppWithoutRestrictionForOrganizers
            }
            </Text>
            {/* "theOnly": "(The only map without restrictions for organizers)" */}
            {!isAttendeeLimited && (
            <View style={styles.fields}>
                <Text style={styles.boldTitle}>
                {
                    JsonActivityPages.JsonActivityManagement
                    .JsonGeneralOptionsScreen.JsonAttendeesLimitation
                }
                </Text>
                {/* "attendee": "Attendee limitation" */}
                <OneValueSlider
                minVal={2}
                maxVal={premiumRoles.includes(name[0]) ? 100 : 100}
                state={attendeeLimit}
                setState={setAttendeeLimit}
                />
            </View>
            )}

            {/*FR Bouton du prix */}
            {/*GB Price button */}
            <View style={styles.switchRow}>
            <Text style={styles.boldTitle}>
                {/*🇫🇷 La variable createActivity.step1.price définit l'affichage en "Prix".
                    🇬🇧 The variable createActivity.step1.price displays "Price"*/}
                {
                JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                    .JsonPrice
                }
            </Text>
            <SwitchBtn state={hasPrice} setState={setHasPrice} />
            </View>
            {/*FR Les champs ci-dessous s'affichent si le bouton est activé(si le state hasPrice a une valeur*/}
            {/*GB The fields below only display when the button is on (if the state hasPrice contains a value*/}
            <View style={styles.fields}>
            {hasPrice && (
                <>
                {/* <View style={styles.fields}> */}
                {/*🇫🇷 La variable createActivity.step1.price définit l'affichage en "Prix".
                    🇬🇧 The variable createActivity.step1.price displays "Price"*/}
                {/* <Fields
                    text={JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
                    upperText={JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
                    state={priceValue}
                    setState={setPriceValue}
                    />
                </View> */}
                <View style={styles.fields}>
                    {/*🇫🇷 La variable createActivity.step1.buyTicket définit l'affichage en "Url de la billeterie".
                    🇬🇧 The variable createActivity.step1.buyTicket displays "Buy ticket link"*/}
                    <Fields
                    text={
                        JsonActivityPages.JsonActivityManagement
                        .JsonGeneralOptionsScreen.JsonBuyTicket
                    }
                    state={ticketLink}
                    setState={setTicketLink}
                    scr={scr}
                    />
                </View>
                </>
            )}
            </View>
            <View style={styles.fields}>
            <Text style={styles.boldTitle}>
                {/*🇫🇷 La variable createActivity.step1.howMany définit l'affichage en "How many friends with me".
                    🇬🇧 The variable createActivity.step1.howMany displays "Combien d'amis avec moi"*/}
                {
                JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen
                    .JsonHowMuchFriends
                }
            </Text>
            <OneValueSlider
                minVal={0}
                maxVal={9}
                state={nbFriend}
                setState={setNbFriend}
            />
            </View>
            {/* The code about "Help for organizers" has been saved in the "help_codes" folder. It was too annoying to comment all the code because of the comments... */}
            {/* ------------Save or Continue------------ */}
            <Text style={styles.errorMessageText1}>{errorMessage}</Text>
            {/*FR Bouton pour passer à l'étape suivante*/}
            {/*GB Button to enter next step*/}
            <View style={styles.editProfileStep2Continue}>
            <LogButton
          text={
            JsonActivityPages.JsonActivityManagement.JsonContinueButton
          }
          width={150}
          backgroundColor={"#59c09b"}
          func={(n) => {
            // Utilisez la localisation locale lors de la mise à jour de l'étape
            setLocation(localLocation);
            setErrorMessage("");
            setStep(n);
          }}
          arg={step + 1}
        />
            </View>
        </ScrollView>
  );
};

export default CreateActivityScreenStepOne;