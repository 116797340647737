//🇫🇷 Ce fichier gère la page de membres principale (FrameFigma22A)
//🇬🇧 This file manages the main members page (FrameFigma22A)

import React, { useEffect, useState } from "react";
import {
  ScrollView,
  View,
  Text,
  Pressable,
  TextInput,
  Image,
} from "react-native";
import axios from "axios";
import { useNavigation, useFocusEffect } from "@react-navigation/native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname";

// Components
import styles from "./MembersScreenCss";
import MembersSquare from "../../../components/MembersSquare/MembersSquare";
import NetworkScreen from "../NetworkScreen";
import FriendListScreen from "../FriendListScreen";

// import des images
import { images } from "../../../constantes";

// Assets

// import Male from "../../assets/images/male.svg";
// import Female from "../../assets/images/female.svg";

const MembersScreen = (props) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const [membersList, setMembersList] = useState([]);
  const [activeButton, setActiveButton] = useState("Members");
  const [searchValue, setSearchValue] = useState("");
  const [skip, setSkip] = useState(0);
  const [chargement, setChargement] = useState(true); // pour arreter le chargement lorsqu'il n'y a pas plus de données
  const limit = 20;
  const [subFilter, setSubFilter] = useState("");

  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      if (!chargement) return;

      const fetchData = async () => {
        let apiUrl;
        if (activeButton === "Network") {
        } else if (activeButton === "FriendList") {
        } else {
          apiUrl = `${hostname}/api/v1/user/getuserlist?limit=${limit}&skip=${skip}`;
        }

        try {
          const response = await axios.get(apiUrl);
          const shuffledData = response.data;
          // console.log(shuffledData);

          if (shuffledData.length === 0) {
            setChargement(false);
          } else {
            if (activeButton === "Members") {
              setMembersList((prevEvents) => [...prevEvents, ...shuffledData]);
            } else {
              // Traitement pour d'autres boutons si nécessaire
            }
          }
        } catch (error) {
          // console.log(error.message);
        }
      };

      fetchData();

      if (activeButton === "Members") {
        // N'augmentez le 'skip' que si vous êtes dans l'onglet 'Members'
        const timer = setInterval(() => {
          setSkip((prevSkip) => prevSkip + limit);
        }, 1500);

        return () => {
          clearInterval(timer);
        };
      }
    }, [skip, chargement, activeButton])
  );
  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setSkip((prevSkip) => prevSkip + limit); // Reset skip to 0
    });
    // The cleanup function will run when the component is unmounted
    return unsubscribe;
  }, []);

  return (
    <>
      <View style={styles.menuContainer}>
        <Pressable
          style={[
            styles.button,
            activeButton === "Members" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => setActiveButton("Members")}
        >
          <Text style={styles.text}>
            {props.scr.JsonTopNav.JsonMembersSecondLineAll}
          </Text>
        </Pressable>

        <View style={styles.separator} />

        <Pressable
          style={[
            styles.button,
            activeButton === "Network" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => {
            setActiveButton("Network");
          }}
        >
          <Text style={styles.text}>
            {props.scr.JsonTopNav.JsonMembersSecondLineNetwork}
          </Text>
        </Pressable>

        <View style={styles.separator} />

        <Pressable
          style={[
            styles.button,
            activeButton === "FriendList" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => {
            setActiveButton("FriendList");
          }}
        >
          <Text style={styles.text}>
            {props.scr.JsonTopNav.JsonMembersSecondLineFriends}
          </Text>
        </Pressable>
      </View>
      {activeButton === "Members" && (
        <View style={styles.secondaryMenuContainer}>
          {/* <Pressable
            style={[
              styles.smallButton,
              subFilter === "Male" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Male")}
          >
            <Image source={Male} style={{ width: 21, height: 21 }} />
          </Pressable> */}

          {/* <View style={styles.smallSeparator} /> */}

          {/* <Pressable
            style={[
              styles.smallButton,
              subFilter === "Pros" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Pros")}
          >
            <Text style={styles.smallText}>Pros</Text>
          </Pressable> */}

          {/* <View style={styles.smallSeparator} /> */}

          {/* <Pressable
            style={[
              styles.smallButton,
              subFilter === "Female" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Female")}
          >
            <Image source={Female} style={{ width: 21, height: 21 }} />
          </Pressable> */}
        </View>
      )}
      {activeButton === "Members" && (
        <>
          <View style={styles.searchContainer}>
            <Image source={images.Settings} style={styles.searchIcon} />
            <TextInput
              style={styles.searchInput}
              // placeholder="Search here ..."
              placeholder={
                props.scr.JsonMembersScreen.JsonSearchMemberPlaceHolder
              }
              placeholderTextColor="#CCCCCC"
              onChangeText={(text) => setSearchValue(text)}
            />
          </View>

          {membersList.length > 0 && (
            <ScrollView>
              <View style={styles.container}>
                {membersList
                  .filter((member) => {
                    if (subFilter === "Male") {
                      return (
                        member.sexe === "male" &&
                        member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (subFilter === "Pros") {
                      return (
                        !member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (subFilter === "Female") {
                      return (
                        member.sexe === "female" &&
                        member.isPersonalAccount &&
                        (!searchValue ||
                          (member.firstName &&
                            member.firstName
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())))
                      );
                    }
                    if (searchValue !== "") {
                      return (
                        member.firstName &&
                        member.firstName
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      );
                    }
                    return true; // cette ligne affichera tous les membres par défaut
                  })
                  .map((member, index) => (
                    <View key={index} style={styles.memberCard}>
                      <MembersSquare
                        city={member.city}
                        name={member.firstName}
                        avatar={member.avatar}
                        member={member}
                        scr={props.scr}
                      />
                    </View>
                  ))}
              </View>
            </ScrollView>
          )}
        </>
      )}
      {activeButton === "Network" && <NetworkScreen scr={props.scr} />}

      {activeButton === "FriendList" && <FriendListScreen scr={props.scr} />}
    </>
  );
};

export default MembersScreen;
