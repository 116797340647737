//🇫🇷 Affichage du bouton TwinSelect pour la selection de genre et de type de compte (FrameFigma15-FrameFigma60)
//🇬🇧 Display of TwinSelect button for the selection of gender and account type (FrameFigma15-FrameFigma60)

import React from "react";
import { View, Pressable, Text, Image } from "react-native";

// Components
import styles from "./TwinSelectButtonCss";

const TwinSelectButton = ({
  firstTitle,
  secondTitle,
  profileState,
  setIsSelect,
  setActive,
  setSecondActive,
  active,
  secondActive,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const component = (positionBtn, title) => {
    return (
      <Pressable
        style={[
          styles[positionBtn],
          {
            backgroundColor:
              positionBtn === "firstBtn"
                ? active
                  ? "#ACE0CD"
                  : "white"
                : positionBtn === "secondBtn" && secondActive
                ? "#ACE0CD"
                : "white",
          },
          {
            justifyContent:
              title === "Male" || title === "Female" ? null : "center",
          },
        ]}
        onPress={() => {
          const { setGender, setAccountType } = profileState;
          setIsSelect(true);
          if (positionBtn === "firstBtn") {
            setSecondActive(false);
            setActive(true);
            if (title === scr.JsonCreateProfilePages.JsonMale) {
              setGender("male");
            } else {
              setAccountType(title);
            }
          } else {
            setSecondActive(true);
            setActive(false);
            // console.log(title);
            // console.log(scr);
            if (title === scr.JsonCreateProfilePages.JsonFemale) {
              setGender("female");
            } else {
              setAccountType(title);
            }
          }
        }}
      >
        {title === "Male" ? (
          <View style={styles.logo}>
            <Image
              source={require("../../assets/images/male.png")}
              style={{ width: 25, height: 25 }}
            />
          </View>
        ) : (
          title === "Female" && (
            <View style={styles.logo}>
              <Image
                source={require("../../assets/images/female.png")}
                style={{ width: 25, height: 25 }}
              />
            </View>
          )
        )}
        <Text style={styles.text}>{title}</Text>
      </Pressable>
    );
  };
  return (
    <View style={styles.btnContainer}>
      {component("firstBtn", firstTitle)}
      {component("secondBtn", secondTitle)}
    </View>
  );
};

export default TwinSelectButton;
