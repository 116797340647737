import { StyleSheet } from "react-native";


const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  //View titre texte
  row: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    margin: 10,
  },
  text: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
  },
  card: {
    flex: 1,
    alignItems: "center",
    padding: 15,
    // Add this line
  },
  img: {
    width: 96,
    height: 96,
    marginTop: "-40%",
    marginBottom: "-10%",
    position: 'relative',right: "-75%",
  },
  cardContent: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    padding: 10,
  },
  button: {
    borderWidth: "10%",
    borderColor: "#fff",
    borderRadius: "40px",
    marginTop: "10%",
    padding: "10%", // Augmenter la valeur du padding
    
    width: "70%",
    overflow : "hidden",
    shadowColor:'#000',
    shadowOffset: {width: 0, height:8},
    shadowOpacity:0.5,
    shadowRadius: 10,

    
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  textCard: {
    justifyContent: "center",
    alignItems: "center",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
  },
  cardButton: {
    width: "100%",
    alignItems: "center", // Aligner le contenu au centre
    backgroundColor: "#fff,"
  },
  separator: {
    borderTopWidth: 2,
    borderTopColor: 'blue', // Couleur du trait
    marginVertical: 10,
    width: '50%', // Largeur du trait
    
  },
  CarTabCentre: {
    backgroundColor: "white",
    alignItems: "center",
    width: "100%", // Ajuster la largeur à 100%
  },
  cardTab: {
    flexDirection: "row", // Add this line
    backgroundColor: "#888",
    borderRadius: 15,
    alignItems: "center",
    margin: 5,
  },
  cardTextTab: {
    flex: 1,
    alignItems: "center",
    padding: 25,
    marginLeft: 29,
  },
  cardTabStyle: {
    backgroundColor: "white",
    alignItems: "center",
    marginBottom: 0,
    flexDirection: "row",
  },
  separatorPlat: {
    width: "100%",
    height: 2,
    backgroundColor: "#888",
    marginTop: 0, // Modifié pour que le texte soit au-dessus du séparateur
  },
  table: {
    borderColor: "#000",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    padding: 10,
    width: "50%",
  },
  cellText: {
    textAlign: "center",
    color: "white",
  },
  cellTextCentre: {
    textAlign: "center",
    color: "black",
    fontSize: 11,
  },
  rowTab: {
    flexDirection: "row",
    backgroundColor: "#888",
    borderRadius: 10,
    paddingLeft: 27,
  },
  SeparatorNoir: {
    width: 2,
    height: "100%",
    backgroundColor: "#888",
    transform: "rotate(180deg)",
    marginLeft: 51,
  },
  rowText: {
    alignItems: "center",
    fontSize: 10,
    padding: 10,
  },
  gold: {
    fontWeight: "bold",
    alignItems: "top",
    padding: 10,
    color: "#FFD700" ,
   textAlign:'center',
   marginTop: '-5%',
   marginBottom: '-5%'
  
  },
 
  emerald: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#42DB71" ,
    textAlign:'center',
    marginTop: '-5%',
    marginBottom: '-5%'
  },
  ruby: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#E84857" ,
    textAlign:'center',
    marginTop: '-5%',
    marginBottom: '-5%'
  },
  diamand: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#7ACEFA" ,
    textAlign:'center',
    marginTop: '-5%',
    marginBottom: '-5%'
  }, 
   goldtitle: {
    fontWeight: "bold",
    alignItems: "top",
    padding: 10,
    color: "#FFD700" ,
   textAlign:'center',
   marginTop: '-10%',
   
  
  },
 
  emeraldtitle: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#42DB71" ,
    textAlign:'center',
    marginTop: '-10%',
    
  },
  rubytitle: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#E84857" ,
    textAlign:'center',
    marginTop: '-10%',
   
  },
  diamandtitle: {
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    color: "#7ACEFA" ,
    textAlign:'center',
    marginTop: '-10%',
    
  },

  separatorG: {
    borderTopWidth: 2,
    borderTopColor: '#FFD700', 
    width: '50%', 
    marginLeft: "25%",
  },
  separatorR: {
    borderTopWidth: 2,
    borderTopColor: '#E84857', 
    marginLeft: "25%",
    width: '50%', 
  },
  separatorE: {
    borderTopWidth: 2,
    borderTopColor: '#42DB71', 
    marginLeft: "25%",
    width: '50%', 
  },
  separatorD: {
    borderTopWidth: 2,
    borderTopColor: '#7ACEFA', 
    marginLeft: "25%",
    width: '50%', 
  },
  imgbord: {
    width: 125,
    height: 125,
    marginTop: "-40%",
    marginBottom: "-25%",
    position: 'relative',right: "25%",
  },
});

export default styles;
