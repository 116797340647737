// ConfirmLastNameModal.js
import React from 'react';
import { Image, Modal, Pressable, Text, TextInput, View } from 'react-native';
import { images } from "../../../constantes";
import styles from '../MyProfileScreenCss';

const ConfirmLastNameModal = ({
  lastNameModalVisible,
  setLastNameModalVisible,
  setAddFriendModalVisible,
  friendLastName,
  setFriendLastName,
  handleAddFriend,
  setModalVisible,
  scr
}) => {

  const JsonConfirmFriendshipPopup = scr;
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={lastNameModalVisible}
      onRequestClose={() => {
        setLastNameModalVisible(false);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.confirmEmailModalContainer}>
          <Pressable
            style={styles.backButtonWrapper}
            onPress={() => {
              setLastNameModalVisible(false);
              setAddFriendModalVisible(true);
            }}
          >
            <Image source={images.leftArrow} style={styles.leftArrowIcon} />
          </Pressable>
          <View style={styles.iconWrapper}>
            <Image source={images.lastName} style={styles.addFriendButtonIcon} />
          </View>
          <Text style={styles.confirmEmailModalText}>
            {JsonConfirmFriendshipPopup.JsonPopupEnterLastname}
          </Text>
          <TextInput
            style={styles.lastNameInput}
            placeholder={JsonConfirmFriendshipPopup.JsonPlaceholderLastname}
            placeholderTextColor="gray"
            value={friendLastName}
            onChangeText={(text) => setFriendLastName(text)}
            // Ajoutez ici les autres propriétés pour gérer la valeur du champ de saisie
          />
          <Pressable
            style={styles.confirmLastNameButton}
            onPress={async () => {
              await handleAddFriend(friendLastName, "lastName");
              setLastNameModalVisible(false);
              setModalVisible(false);
            }}
          >
            <Text style={styles.confirmLastNameButtonText}>
              {JsonConfirmFriendshipPopup.JsonButtonConfirm}
            </Text>
          </Pressable>
          {/* Contenu du modal du nom de famille */}
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmLastNameModal;
