import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  text: {
    flexDirection: "row",
    marginTop: 30,
    marginBottom: 15,
    justifyContent: "space-between",
  },
  container: {
    flex: 1,
  },
  map: {
    height: 260,
  },
  image: {
    flex: 1,
  },
  scrollViewStyle: {
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 15,
    flexDirection: "row",
    width: "350px",
    marginLeft: "20%",
    justifyContent: "center",
  },
  scrollSubViewStyle: {
    flexDirection: "row",
    marginStart: 30,
  },
  scrollViewImg: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  about: {
    marginRight: 15,
    paddingVertical: 10,
    paddingLeft: 10,
    fontSize: 22,
    fontWeight: "bold",
  },
  longText: {
    marginHorizontal: 15,
    padding: 10,
    backgroundColor: "white",
  },
  editActivityView: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  eventDescriptionTxt: {
    marginTop: 10,
    padding: 5,
    color: "gray",
    textAlign: "justify",
  },
  descReamMoreBtnStyle: {
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 10,
  },
  socialMediaBtns: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  readMoreButton: {
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderRadius: 20,
    backgroundColor: "#59c09b",
    color: "white",
    fontSize: 16,
  },
  ticketLinkTxt: {
    marginRight: 5,
    fontSize: 14,
    fontWeight: "bold",
  },
  buyTicketButton: {
    paddingVertical: 10,
    width: "50%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 30,
    backgroundColor: "gold",
  },
  image: {
    width: 26,
    height: 26,
  },
  aboutActView: {
    marginVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  findUsTxt: {
    marginTop: 10,
    padding: 5,
    color: "gray",
    textAlign: "justify",
  },
  findUsBtn: {
    justifyContent: "center",
    alignSelf: "center",
    marginVertical: 10,
  },
  inviteOrParticipate: {
    justifyContent: "center",
    marginHorizontal: 15,
    paddingVertical: 10,
  },
});

export default styles