import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexWrap: "wrap",
    marginTop: 15,
    marginHorizontal: "2.5%",
    marginBottom: "5%",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#F3F3F3",
    borderRadius: 15,
    boxShadow: "0px 4px 2.22px rgba(0, 0, 0, 0.42)",
  },
  activityCard: {
    marginHorizontal: 5,
    marginVertical: 8,
    borderRadius: 10,
    width: 80,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  shadow: {
    boxShadow: "0px 2px 2.22px rgba(0, 0, 0, 0.42)", /* décalage horizontal, décalage vertical, rayon, couleur et opacité */
    elevation: 3,
  },
  activityTitle: {
    marginBottom: 5,
    fontSize: 12,
  },
  activityTypeView: {
    flex: 1,
    justifyContent: "center",
  },
});

export default styles;
