//🇫🇷 Ce fichier gère la page de listes d'amis pour l'écran "Menbers" (FrameFigma22C)
//🇬🇧 This file manages the friend list page for the "Members" screen (FrameFigma22C)

import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  Image,
  Pressable,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";

// Components
import { fetchFriends as loadFriends } from "../../../utils/FetchFriendList";

// import des images
import { images } from "../../constantes";

// Assets

function FriendListScreen() {
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigation = useNavigation();
  const route = useRoute();

  useEffect(() => {
    const getFriends = async () => {
      const userToken = localStorage.getItem("userToken");
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user?._id;

      if (!userToken || !userId) {
        setError("Vous devez vous connecter pour voir la liste des amis.");
        setLoading(false);
        return;
      }

      try {
        const friendList = await loadFriends(userToken, userId);
        setFriends(friendList);
        // console.log("Liste des amis:", friendList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getFriends();
  }, []);

  // console.log("friends:", friends);

  if (loading) return <ActivityIndicator size="large" color="#0000ff" />;
  if (error) return <Text>Error: {error}</Text>;

  const friendId = route.params ? route.params.friendId : null;

  return (
    <View style={{ flex: 1, paddingTop: 20 }}>
      <FlatList
        data={friends}
        keyExtractor={(item) => item._id}
        renderItem={({ item }) => (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              {/* Image de profil */}
              {item.avatar ? (
                <Image
                  source={{ uri: item.avatar }}
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    marginLeft: 20,
                    marginRight: 50,
                  }}
                />
              ) : (
                <View
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: "gray",
                    marginRight: 10,
                  }}
                />
              )}

              {/* Nom, Prénom, Ville, et Code postal */}
              <View>
                <Text style={{ fontWeight: "bold" }}>
                  {item.firstName ? item.firstName.toUpperCase() : ""}
                </Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <MaterialIcons name="location-on" size={16} color="black" />
                  <Text>
                    {item.city}, {item.postalCode}
                  </Text>
                </View>
              </View>
            </View>

            {/* Ici, le Pressable pour le bouton 'Next' */}
            <Pressable
              onPress={() => {
                navigation.navigate("Profile", { user: item });
              }}
            >
              <Image
                source={images.next2}
                style={{
                  width: 21,
                  height: 21,
                  marginLeft: 10,
                  marginRight: 20,
                }}
              />
            </Pressable>
          </View>
        )}
        ItemSeparatorComponent={() => (
          <View
            style={{ height: 1, backgroundColor: "gray", marginVertical: 10 }}
          />
        )}
      />
    </View>
  );
}

export default FriendListScreen;
