//Ce fichier regroupe tous les useStates necessaires au fonctionnement de la page activityScreen. Ces useState sont utilisé dans toutes les pages de la page activityScreen.

import React, { createContext, useContext, useState } from 'react';

const ActivityContext = createContext();

export const ActivityProvider = ({ children }) => {
  const [currentLongitude, setCurrentLongitude] = useState(null);
  const [currentLatitude, setCurrentLatitude] = useState(null);
  const [sendLocation, setSendLocation] = useState(null);
  const [activityImage, setActivityImage] = useState(null);
  const [cmtText, setCmtText] = useState("");
  const [connectedUser, setConnectedUser] = useState();
  const [selectOption, setSelectOption] = useState(false);
  const [reload, setReload] = useState(false);

  const contextValue = {
    currentLongitude,
    setCurrentLongitude,
    currentLatitude,
    setCurrentLatitude,
    sendLocation,
    setSendLocation,
    activityImage,
    setActivityImage,
    cmtText,
    setCmtText,
    connectedUser,
    setConnectedUser, 
    selectOption,
    setSelectOption,
    reload, 
    setReload
  };

  return (
    <ActivityContext.Provider value={contextValue}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivityContext = () => useContext(ActivityContext);
