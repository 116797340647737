//🇫🇷 Ce fichier gère la page où on rentre notre mot de passe lors du login/register si l'email rentré est un email qui n'existe pas dans la base de donnée (FrameFigma4)
//🇬🇧 This file manages the page where we enter our password during login/register if the email entered is an email that doesn't exist in the database (FrameFigma4)

import React, { useState, useEffect } from "react";
import { View, Text, ScrollView, Pressable } from "react-native";
import axios from "axios";
import { useRoute } from "@react-navigation/native";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./PasswordScreenNoEmailCss.js";
import Fields from "../../../components/Fields/Fields.js";

const PasswordScreenNoEmail = ({
  route,
  navigation,
  setToken,
  setProfile,
  setNumber,
  setRegisterMailCall,
  scr,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const routeEmail = route.params.email;
  // console.log(useRoute().params);
  const email = routeEmail;
  const { JsonLoginRegisterPages } = scr;

  //🇫🇷 Déclaration des variables d'état
  //🇬🇧 Declaration of state variables
  const [password, setPassword] = useState("");
  const [pressed, setPressed] = useState(false); //🇫🇷 Variable permettant de vérifier si le bouton a été pressé
  const [errorMsg, setErrorMsg] = useState(null); //🇫🇷 Variable permettant d'afficher les messages d'erreur
  const [errorPassword, setErrorPassword] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true); //🇫🇷 Variable permettant de désactiver le bouton
  const [backgroundColor, setBackgroundColor] = useState("");
  const [errorTimer, setErrorTimer] = useState(null);

  //🇫🇷 Vérifie si le mot de passe saisi fait au moins 8 caractères
  //🇬🇧 Checks if the password entered is at least 8 characters
  const isValidPassword = (password) => {
    return password && password.length >= 8;
  };

  //🇫🇷 Gestion de l'état du bouton de connexion
  //🇬🇧 Managing login button state
  useEffect(() => {
    if (!isValidPassword(password)) {
      setBackgroundColor("grey");
      setBtnDisable(false);
    } else {
      setBtnDisable(false);
      setBackgroundColor("#59c09b");
    }
  }, [password]);

  //🇫🇷 Gestion du temps d'apparition du message d'erreur
  //🇬🇧 Time management of the error message
  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg(null);
      }, 5000); // 5000 milliseconds = 5 seconds
      //🇫🇷 Sauvegarde le timer de référence pour l'effacer si besoin
      //🇬🇧 Save the timer reference so we can clear it if needed
      setErrorTimer(timer);
    }

    //🇫🇷 Efface le timer si le composant est démonté ou si errorMsg change
    //🇬🇧 Clear the timer if the component is unmounted or if errorMsg changes
    return () => {
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [errorMsg]);

  //🇫🇷 Envoi de la requête pour s'inscrire.
  //🇬🇧 Sending async request for signing up.
  const requestRegister = async () => {
    //🇫🇷 On remet le profil à null pour éviter les bugs
    //🇬🇧 Reset the profile to null to avoid bugs
    setProfile(null);

    //🇫🇷 On remet le message d'erreur à null pour éviter les bugs
    //🇬🇧 Reset the error message to null to avoid bugs
    setErrorMsg(null);

    //🇫🇷 Si le champs du mot de passe n'est pas rempli
    //🇬🇧 If the password field is not filled in
    if (!password) {
      setErrorMsg(
        JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty
      );
      return;
    }

    //🇫🇷 Si le champs du mot de passe est rempli
    //🇬🇧 If the password field is filled in
    if (password) {
      try {
        const response = await axios.post(`${hostname}/api/v1/signup`, {
          email: email,
          password: password,
        });
        if (response) {
          //🇫🇷 Si la réponse est positive, on passe à la page de vérification du mail
          //🇬🇧 If the answer is positive, we go to the verification page of the mail
          // console.log("ffff", response.data, response.data.user.token);
          setNumber(1);
          //setRole(nonVerifiedUser);
          setToken(response.data.user.token);
          // console.log(response.data.user);
        }
      } catch (error) {
        //🇫🇷 Si la réponse est négative, on affiche le message d'erreur
        //🇬🇧 If the answer is negative, the error message is displayed
        if (error.response.status === 400) {
          // console.log("monErreur:", error.response.data.error);
          if (
            error.response.data.error.errors &&
            error.response.data.error.errors.length > 0
          ) {
            const serverErrorMsg = error.response.data.error.errors[0].msg;
            if (
              serverErrorMsg ===
              "password field should be at least 8 characters"
            ) {
              setErrorMsg(
                JsonLoginRegisterPages.JsonPasswordScreen
                  .JsonPasswordErrorMessageTooShort
              );
            } else {
              // Handle other error messages or display the server error directly
              setErrorMsg(serverErrorMsg);
            }
          } else {
            setErrorMsg(JSON.stringify(error.response.data.error));
          }
        } else if (error.response.status === 401) {
          if (typeof error.response.data.error === "string") {
            setErrorMsg(error.response.data.error);
          } else if (
            error.response.data.error.errors &&
            error.response.data.error.errors.length > 0
          ) {
            // Extraire le message d'erreur du premier élément de l'array "errors"
            setErrorMsg(error.response.data.error.errors[0].msg);
          } else {
            // Convertir l'objet en chaîne ou afficher un message d'erreur générique
            setErrorMsg(JSON.stringify(error.response.data.error));
          }
        }
      }
    }
  };

  return (
    <ScrollView>
      <View style={styles.password}>
        {/*🇫🇷 Affichage des messages d'erreur */}
        {/*🇬🇧 Display of error messages */}
        {errorMsg && (
          <View style={styles.center}>
            <Text style={styles.error}>{errorMsg}</Text>
          </View>
        )}
        {/*🇫🇷 Affichage du champs pour saisir le mot de passe */}
        {/*🇬🇧 Display of the field to enter the password */}
        <Fields
          onSubmitEditing={requestRegister}
          text={
            JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder
          }
          icon={"locker"}
          state={password}
          setState={setPassword}
          pressed={pressed}
          setPressed={setPressed}
          errorPass={errorPassword}
          scr={scr}
        />
      </View>

      <View style={styles.btn}>
        {/*🇫🇷 Affichage du bouton Suivant */}
        {/*🇬🇧 Display of the next button */}
        <Pressable
          style={[styles.bigBtn, { backgroundColor: backgroundColor }]}
          disabled={btnDisable}
          onPress={() => {
            setPressed(true);
            requestRegister();
            setBtnDisable(false);
            //🇬🇧 Here we set the number to 1 to access the verificationMail screen by validating
            //🇫🇷 Nous avons mis le setNumber à 1 pour accéder à la vérification du Mail en cliquant sur s'enregistrer
            setNumber(1);
            //🇫🇷 Recupérer les informations du mail en les passant dans les props
            //🇬🇧 To keep the e-mail information by passing it into props
            setRegisterMailCall(email);
          }}
        >
          <Text style={styles.btnText}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
          </Text>
        </Pressable>
      </View>

      <View style={styles.centerTerms}>
        {/*🇫🇷 Affichage des conditions d'utilisation et de politique de confidentialité */}
        {/*🇬🇧 Display of the terms and conditions and privacy policy */}
        <View style={styles.termsContainer}>
          <Text style={styles.terms}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}
          </Text>
          <Pressable
            onPress={() =>
              Linking.openURL(
                ""
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}
            </Text>
          </Pressable>
          <Pressable
            onPress={() =>
              Linking.openURL(
                "https://sites.google.com/artem5.com/privacy-policy-erasmus-events/accueil"
              )
            }
          >
            <Text style={styles.underline}>
              {JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}
            </Text>
          </Pressable>
        </View>
      </View>

      <View style={styles.centerBot}>
        {/*🇫🇷 Affichage de la partie dédié au réinitialisation de mot de passe */}
        {/*🇬🇧 Display of the reset password section */}
        <View style={styles.bottomBoxTop}>
          <Text style={styles.bottomText}>
            {JsonLoginRegisterPages.JsonPasswordScreen.JsonForgotPassword}
          </Text>

          <Pressable
            onPress={() => {
              navigation.navigate("LogIn", {
                MsgE: JsonLoginRegisterPages.JsonEmailScreen
                  .JsonHeaderTitleResetPassword,
              });
            }}
            style={styles.smallBtn}
          >
            <Text style={styles.smallBtnText}>
              {
                JsonLoginRegisterPages.JsonPasswordScreen
                  .JsonResetPasswordButton
              }
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

export default PasswordScreenNoEmail;
