import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  text: {
    flexDirection: "row",
    marginTop: 30,
    marginBottom: 15,
    justifyContent: "space-between",
  },
  container: {
    flex: 1,
  },
  map: {
    height: 260,
  },
  image: {
    flex: 1,
  },
  commentsView: {
    width: "100%",
    marginTop: 30,
    padding: 10,
    backgroundColor: "white",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  commentsTxt: {
    paddingLeft: 15,
    fontWeight: "bold",
    fontSize: 20,
  },
  messageContainer: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  messageInput: {
    flex: 1,
    height: 50,
    position: "relative",
  },
  messageBox: {
    marginRight: 20,
    width: "100%",
    height: 50,
    paddingHorizontal: 15,
    borderRadius: 30,
    backgroundColor: "#EBEBEB",
  },
  selectOptionBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  chosenEmojiBtn: {
    marginRight: 10,
    position: "absolute",
    top: 10,
    right: 60,
  },
  locationBtn: {
    marginRight: 10,
    position: "absolute",
    top: 10,
    right: 30,
  },
  activityPhotView: {
    marginRight: 10,
    position: "absolute",
    top: 12,
    right: 2,
  },
  activityPhotView: {
    marginRight: 10,
    position: "absolute",
    top: 12,
    right: 2,
  },
  chosenEmojiBtnTwo: {
    marginRight: 10,
    position: "absolute",
    top: 10,
    right: 30,
  },
  locationPinBtn: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  chosenEmojiBtnThree: {
    marginRight: 10,
    position: "absolute",
    top: 10,
    right: 30,
  },
  participantsCommentsTxt: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  sendButton: {
    marginLeft: 20,
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: "#59c09b",
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  emojiInput: {
    flex: 1,
    position: "relative",
  },
  image: {
    width: 26,
    height: 26,
  },
});

export default styles;