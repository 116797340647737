import React from "react";
import { Text, View, Image, Pressable, ScrollView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components
import styles from "./StylesWeb";

// import des images
import { images } from "../../../constantes";



const MemberShipScreen = (props) => {
  const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
  const JsonAchievementsPages = scr.JsonAchievementsPages || {};
  const Separator = () => {
    return <View style={styles.separator} />;
  };
  const SeparatorPlat = () => {
    return <View style={styles.separatorPlat} />;
  };
  const SeparatorNoir = () => {
    return <View style={styles.SeparatorNoir} />;
  };
  const navigation = useNavigation();

  {/*Variable qui correspond au nombre d'actions que l'utilisateur a faites, elle doit être modifiée grâce au back-end à chaque fois que l'utilisateur fera une action. */}
  let Profilevisits =0;
  let Numberoffriends =0;
  let Activitiesattendance =0;
  let Coorganiserofactivities =0;
  let Organiserofactivities =0;
  let Sponsorship =0;

  {/*Variable qui correspond au points gagné par l'utilisateur */}
  let ProfilevisitsResultat= Profilevisits* 5;
  let NumberoffriendsResultat = Numberoffriends*20;
  let ActivitiesattendanceResultat = Activitiesattendance*25;
  let CoorganiserofactivitiesResultat =Coorganiserofactivities*50;
  let OrganiserofactivitiesResultat =Organiserofactivities*100;
  let SponsorshipResultat =Sponsorship*250;
  
{/* Partie haut de la page   */}
  return (
    <ScrollView>
      {/* Cups et points   */}
      <View style={styles.container}>
        <View style={styles.rowText}>
          <Text style={styles.text}>
            {JsonAchievementsPages.JsonMedalsScreen.JsonMedalsPresentation}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.card}>
            <Image source={images.coupe2} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json1000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonBronze1}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            <Image source={images.coupe3} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json2000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonBronze2}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            <Image source={images.coupe4} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json5000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonSilver1}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.card}>
            <Image source={images.coupe5} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json10000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonSilver2}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            <Image source={images.coupe6} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json20000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonMaster}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            <Image source={images.coupe7} style={styles.img} />
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.Json50000Points}
              </Text>
              <Text style={styles.textCard}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonGrandMaster}
              </Text>
            </View>
          </View>
        </View>
        {/**Notre boutton  */}
        <View style={styles.cardButton}>
          <Pressable
            style={styles.button}
            onPress={() => navigation.navigate('VipBadges')}
          >
            <Text style={styles.buttonText}>
              {JsonAchievementsPages.JsonMedalsScreen.JsonGetVIPBadges}
            </Text>
          </Pressable>
          {/**notre texte en bas du tableau qui vas s'afficher */}
          <View style={{ marginTop: 20 }}>
            <Text style={styles.text}>
              {JsonAchievementsPages.JsonMedalsScreen.JsonHowToGetMorePoints}
            </Text>
          </View>
        </View>
        {/**notre tablau */}
        <View style={styles.table}>
          <View style={styles.rowTab}>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonSources}
              </Text>
            </View>
            <Separator />
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonNumber}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonPoints}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonTotal}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonProfileVisit}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Profilevisits}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>5</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{ProfilevisitsResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonFriendsNumber}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Numberoffriends}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>20</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{NumberoffriendsResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesParticipation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Activitiesattendance}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>25</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{ActivitiesattendanceResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesOrganisation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Coorganiserofactivities}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>50</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{CoorganiserofactivitiesResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesCoOrganisation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Organiserofactivities}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>100</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{OrganiserofactivitiesResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {JsonAchievementsPages.JsonMedalsScreen.JsonSponsorship}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Sponsorship}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>250</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{SponsorshipResultat}</Text>
            </View>
          </View>
          
        </View>
      </View>
    </ScrollView>
  );
};

export default MemberShipScreen;
