//🇫🇷 Ce fichier gère le quatrième page de création de profil sur la photo de profil (FrameFigma12)
//🇬🇧 This file manages the fourth page of profile creation on the profile photo (FrameFigma12)

import React, { useState, useEffect } from "react";
import { Text, View, Pressable } from "react-native";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Backend
import { hostname } from "../../../../../front-mobile/backendconnect/hostname.js";

// Components
import styles from "./CreateProfileScreenStepFourCss";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture.js";

const CreateProfileScreenStepFour = ({
  profileState,
  navigation,
  setProfile,
  userToken,
  user,
  setUser,
  rolesList,
  scr,
}) => {
  //🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.
  //🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language
  const { JsonCreateProfilePages } = scr;

  const { accountType } = profileState;
  const image =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

  const [errorMsg, setErrorMsg] = useState(null);
  const [profilData, setProfilData] = useState();
  const [picture, setPicture] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);
  const [skipWarning, setSkipWarning] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  // console.log(accountType);

  //🇫🇷 Mise à jour des données utilisateur
  //🇬🇧 Updating user data
  useEffect(() => {
    UpdateData();
  }, []);

  //🇫🇷 Affichage du message d'erreur tant que l'utilisateur n'a pas mis de photo de profil
  //🇬🇧 Display error message until user has put profile picture
  useEffect(() => {
    if (!avatarImage) {
      setErrorMsg(JsonCreateProfilePages.JsonFakeUserMessage);
    }
  });

  const UpdateData = async () => {
    //🇫🇷 Récupération des données utilisateur dans le storage
    //🇬🇧 Recovery of user data in storage
    try {
      let res = JSON.parse(await AsyncStorage.getItem("id"));
      setProfilData(res);
      // console.log(profilData);
      const response = await axios.get(
        `${hostname}/api/v1/user/getuserinfo/${res._id}`
      );
      // console.log(response.data.user);
      //🇫🇷 Enregistrement des données utilisateur dans le storage
      //🇬🇧 Saving user data in storage
      const resultat = JSON.stringify(response.data.user);
      // console.log(resultat);
      await AsyncStorage.setItem("Profile", resultat);
      await AsyncStorage.setItem("user", resultat);
    } catch (error) {
      console.error(error);
    }
  };

  //🇫🇷 Cette fonction envoie les données utilisateur à la base de donnée
  //🇬🇧 This function sends user data to the database
  const sendInfoEditProfile = async () => {
    // console.log(avatarImage);
    const editProfilBody = {
      image: avatarImage === null ? image : avatarImage,
      avatar: avatarImage === null ? image : avatarImage,
    };
    let res = JSON.parse(await AsyncStorage.getItem("id"));
    // console.log(res);
    // console.log("ID utilisateur", profilData);
    // console.log("TOKEN", userToken);
    //id pour test: 64394c41879131c2c43375e0
    // token pour test: vyGhGEE6JA6ERnhHOid1eaEphgdb3YCN
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(editProfilBody),
      };
      const response = await fetch(
        `${hostname}/api/v1/user/info/${res._id}`,
        requestOptions
      );
      const data = await response.json();
      // console.log("data", data);
      //🇫🇷 remplacement de données sur user
      //🇬🇧 replacement of data on user
      Object.assign(user, editProfilBody);
      UpdateData();
      setUser(user);
    } catch (error) {
      console.log("CATCH :", error);
    }
  };

  //🇫🇷 Ce useEffect sert à désactiver ou activer le bouton "Enregistrer" en fonction de si on a mis une image de profil ou pas
  //🇬🇧 This useEffect is used to disable or activate the "Save" button depending on whether or not a profile image has been set
  useEffect(() => {
    avatarImage ? setBtnDisable(false) : setBtnDisable(true);
  }, [avatarImage]);

  return (
    <View style={styles.profile}>
      {/*🇫🇷 Affichage de la partie pour mettre sa photo de profil */}
      {/*🇬🇧 Display of the part where we put our profile photo */}
      <ProfilePicture
        avatarImage={avatarImage}
        setAvatarImage={setAvatarImage}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
        createProfile={JsonCreateProfilePages}
      />

      <View style={styles.bar}>
        <View
          style={[styles.done, { width: avatarImage ? "100%" : "10%" }]}
        ></View>
      </View>

      {/*🇫🇷 Affichage du message d'erreur tant qu'on a pas mis de photo de profil */}
      {/*🇬🇧 Display the error message as long as you have not put a profile picture */}
      {!avatarImage && errorMsg && !skipWarning && (
        <Text style={styles.error}>{errorMsg}</Text>
      )}

      {/*🇫🇷 Affichage du message d'alerte si on veut passer l'étape de la photo de profil */}
      {/*🇬🇧 Displaying the alert message if you want to skip the profile photo step */}
      {skipWarning && (
        <View style={!skipWarning ? styles.container : styles.warningContainer}>
          <Text style={styles.warningText}>
            {JsonCreateProfilePages.JsonSkipWarningMessage}
          </Text>
        </View>
      )}

      {/*🇫🇷 Affichage du bouton "Enregistrer" */}
      {/*🇬🇧 Display of the "Save" button */}
      <View style={styles.btn}>
        //🇫🇷 Tant qu'une photo de profil ne sera pas mise, affiche un message d'erreur sinon envoie les informations dans le backend 
        //🇬🇧 As long as a profile picture is not set, displays an error message otherwise sends the information to the backend
        <Pressable
          onPress={() => {
            if (!avatarImage) {
              // console.log("ok");
              setErrorMsg(JsonCreateProfilePages.JsonFakeUserMessage);
            } else {
              sendInfoEditProfile();
              setProfile("done");
            }
          }}
          style={[
            styles.bigBtn,
            { backgroundColor: btnDisable ? "grey" : "#59c09b" },
          ]}
          disabled={btnDisable}
        >
          <Text style={styles.btnText}>
            {JsonCreateProfilePages.JsonSaveButton}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default CreateProfileScreenStepFour;
