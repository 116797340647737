//🇫🇷 Ce fichier contient les différentes popups au niveau de la page d'activité (FrameFigma41)
//🇬🇧 This file contains the different popups at the activity page (FrameFigma41)

import React from "react";
import { Text, View, Pressable, Dimensions } from "react-native";
import { Dialog } from "@rneui/themed";
import Icon from "react-native-vector-icons/Ionicons";

// Components
import styles from "./DialogsCss";

const screenWidth = Dimensions.get("screen").width;
const premiumRoles = ["admin", "moderator"];

const DeleteActivityDialog = ({
  scr,
  dialogVisible,
  displayModal,
  deleteActivity,
}) => {
  {
    /*🇫🇷 scr est le paramètre qui permet de passer la traduction, il a été géré dans App.js. Il prend le bon fichier json de langue dans l'application quand on a sélectionné la langue.*/
  }
  {
    /*🇬🇧 scr is the parameter who allow to pass the translation, he is managed in App.js. He take the right json language file in the application when we select a language */
  }

  const { JsonActivityPages } = scr;

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="ionicons" color="#59b09c" size={24} />
      </Pressable>
      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonDeleteConfirmation
        }
      </Text>
      <View
        style={{
          marginTop: 15,
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {/*Valider la suppression*/}
        {/* A la validation, supprimer l'activité dans la base de données */}
        <Pressable
          onPress={() => deleteActivity()} //console.log("Activity deleted")
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonYesButton
            }
          </Text>
        </Pressable>
        {/*****************************Annuler la suppression *****************/}
        <Pressable
          onPress={() => displayModal(false)}
          style={[styles.dialogButton, { backgroundColor: "#59c09b" }]}
        >
          <Text style={[styles.dialogButtonText, { color: "white" }]}>
            {JsonActivityPages.JsonActivityScreen.JsonCancel}
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

const CancelParticipationDialog = ({
  scr,
  dialogVisible,
  displayModal,
  unsubscribe,
}) => {
  const { JsonActivityPages } = scr;

  const handleParticipationCancelling = () => {
    unsubscribe();
    //setIsParticipating(!isParticipating);
    displayModal(!dialogVisible);
  };

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="ionicons" color="#59b09c" size={24} />
      </Pressable>

      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonCancelParticipationConfirmation
        }
      </Text>

      <View style={styles.deleteContainer}>
        {/* Supprimer sa participation*/}
        <Pressable
          onPress={() => handleParticipationCancelling()}
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonConfirmButton
            }
          </Text>
        </Pressable>
        {/* Rester dans la liste des participants */}
        <Pressable
          onPress={() => displayModal(!dialogVisible)}
          style={[styles.dialogButton, { backgroundColor: "#59c09b" }]}
        >
          <Text style={[styles.dialogButtonText, { color: "white" }]}>
            {JsonActivityPages.JsonActivityScreen.JsonCancel}
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

//Add display and setDisplay props to directly see the address when the user presses on "Participate now"?
const AddressAlertDialog = ({
  scr,
  dialogVisible,
  displayModal,
  subscribe,
}) => {
  //The dialog disappears and the value "isParticipating" in "ActivityScreen" sets to true or false

  const { JsonActivityPages } = scr;

  const handleParticipation = () => {
    //setIsParticipating(!isParticipating);
    subscribe();
    displayModal(!dialogVisible);
  };

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 20 }]}
      style={{ backgroundColor: "#59c09b" }}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="ionicons" color="#59c09b" size={24} />
      </Pressable>

      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonAdressOnlyForParticipants
        }
      </Text>
      <View style={styles.activateContainer}>
        {/*Activer la participation*/}
        <Pressable
          onPress={() => handleParticipation()}
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonParticipateNowButton
            }
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

const FriendsInfos = ({ scr, isVisible, setIsVisible }) => {
  const { JsonActivityPages } = scr;

  const handleClose = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Dialog
      isVisible={isVisible}
      onBackdropPress={setIsVisible}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
      style={{ backgroundColor: "#59c09b" }}
    >
      <Pressable style={{ alignSelf: "flex-end" }} onPress={handleClose}>
        <Icon name="close" type="ionicons" color="#59c09b" size={24} />
      </Pressable>

      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonOtherPeopleComing
        }
      </Text>
      <View
        style={{
          marginTop: 15,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {/*Activer la participation*/}
        <Pressable onPress={() => handleClose()} style={styles.dialogButton}>
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonGotItButton
            }
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

const DeleteRepeatEventDialog = ({
  scr,
  dialogVisible,
  displayModal,
  state,
  setState,
}) => {
  const { JsonActivityPages } = scr;

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
      style={{ backgroundColor: "#59c09b" }}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="ionicons" color="white" size={24} />
      </Pressable>
      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonDeleteAllRepeatedActivityOrThisOne
        }
      </Text>
      <View style={styles.deleteContainerTwo}>
        {/* Supprimer l'activité du jour mais garder ses autres dates */}
        <Pressable
          onPress={() =>
            console.log("Deleted activities; the other ones are still on")
          }
          style={[styles.dialogButton, { backgroundColor: "#59c09b" }]}
        >
          <Text style={[styles.dialogButtonText, { color: "white" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonDeleteOnlyThisActivity
            }
          </Text>
        </Pressable>
        {/* Supprimer l'activité et ses autres dates*/}
        <Pressable
          onPress={() => console.log("Activity deleted")}
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonDeleteAllActivitiesOccurences
            }
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

const EmailCheckDialog = ({
  dialogVisible,
  displayModal,
  goToMailCheckScreen,
  scr,
}) => {
  const { JsonActivityPages } = scr;

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="font-awesome" color="#59c09b" size={24} />
      </Pressable>
      <Text style={styles.dialogText}>
        {
          JsonActivityPages.JsonActivityScreen.JsonPopupMessage
            .JsonConfirmEmailMessage
        }
      </Text>
      <View style={styles.navigationContainer}>
        {/*Navigation vers la page de check d'email*/}
        <Pressable
          onPress={() => goToMailCheckScreen()}
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonConfirmEmailCheckButton
            }
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

const ReLoginDialog = ({
  dialogVisible,
  displayModal,
  backToActivitiesScreen,
  scr,
  props,
}) => {
  const { JsonActivityPages, JsonLoginRegisterPages } = scr;

  return (
    <Dialog
      isVisible={dialogVisible}
      onBackdropPress={displayModal}
      overlayStyle={[styles.dialog, { width: screenWidth - 10 }]}
    >
      <Pressable
        style={{ alignSelf: "flex-end" }}
        onPress={() => displayModal(!dialogVisible)}
      >
        <Icon name="close" type="font-awesome" color="#59c09b" size={24} />
      </Pressable>
      <Text style={styles.dialogText}>
        {JsonLoginRegisterPages.JsonVerificationCode.JsonReLoginMessage}
      </Text>
      <View style={styles.navigationContainer}>
        {/*Navigation vers la page de check d'email*/}
        <Pressable
          onPress={async () => {
            try {
              await Promise.all([
                props && props.setToken && props.setToken(null),
                localStorage.removeItem("userToken"),
                props && props.setUser && props.setUser("undefined"),
                localStorage.removeItem("user"),
                props && props.setGender && props.setGender(null),
                localStorage.removeItem("gender"),
                props && props.setAccountType && props.setAccountType(null),
                localStorage.removeItem("accountType"),
                props && props.setFirstName && props.setFirstName(null),
                localStorage.removeItem("firstName"),
                props && props.setLastName && props.setLastName(null),
                localStorage.removeItem("lastName"),
                props && props.setNickName && props.setNickName(null),
                localStorage.removeItem("nickName"),
                props && props.setCity && props.setCity(null),
                localStorage.removeItem("city"),
                props && props.setRole && props.setRole(null),
                localStorage.removeItem("role"),
                localStorage.removeItem("userProfile"),
              ]);

              // console.log("Successfully logged out");
              // navigation.navigate("EmailScreen");
              // Recharger la page entière
              window.location.reload();
            } catch (e) {
              console.error("Error logging out:", e);
            }
          }}
          style={styles.dialogButton}
        >
          <Text style={[styles.dialogButtonText, { color: "#59c09b" }]}>
            {
              JsonActivityPages.JsonActivityScreen.JsonPopupMessage
                .JsonGotItButton
            }
          </Text>
        </Pressable>
      </View>
    </Dialog>
  );
};

export default DeleteActivityDialog;
export { CancelParticipationDialog };
export { AddressAlertDialog };
export { DeleteRepeatEventDialog, FriendsInfos };
export { EmailCheckDialog };
export { ReLoginDialog };
