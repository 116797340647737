import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalInnerContainer: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 20, // Augmentez la valeur du border-radius pour obtenir des coins plus arrondis
    width: 314, // Ajustez la largeur selon vos besoins
    height: 235, // Ajustez la hauteur selon vos besoins
    alignItems: "center",
  },
  modalText: {
    fontSize: 18,
    marginBottom: 20,
    marginTop: 30,
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "red",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: "white",
    textAlign: "center",
    fontSize: 18,
  },
  loadingText: {
    fontSize: 18,
    color: "#333",
  },
  Title: {
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 30,
    margiRight: 20,
    marginLeft: 20,
    textAlign: "center",
  },
  questionText: {
    fontSize: 16,
    //fontWeight: "bold",
    marginBottom: 20,
    mmargiRight: 20,
    marginLeft: 20,
    textAlign: "center",
  },
  picker: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  textInput: {
    height: 178,
    width: 335,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    paddingHorizontal: 10,
    textAlign: "center",
    marginLeft: "10%",
  },

  buttonContainer1: {
    flexDirection: "row",
    marginTop: 10,
    justifyContent: "center",
  },
  buttonYes: {
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#59C09B",
    justifyContent: "center",
    marginHorizontal: 30,
    marginBottom: 20,
    width: 60,
  },
  buttonNo: {
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    alignItems: "center",
    borderWidth: 1,
    borderColor: "red",
    justifyContent: "center",
    marginHorizontal: 30,
    marginBottom: 20,
    width: 60,
  },
  button: {
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
    //borderWidth: 1,
    backgroundColor: "red",
    justifyContent: "center",
    paddingHorizontal: 10,
  },
  yesButtonPressed: {
    backgroundColor: "#59C09B", // Style pour le bouton "Yes" pressé
  },
  noButtonPressed: {
    backgroundColor: "red", // Style pour le bouton "No" pressé
  },
  buttonText1: {
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },
  textPressed: {
    color: "white", // Couleur du texte quand "Yes" est pressé
  },
  textNoPressed: {
    color: "white", // Couleur du texte quand "No" est pressé
  },
  noButtonText: {
    color: "red", // Couleur du texte "No" par défaut
  },
  buttonContainer2: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    width: 200,
  },
  leftButtons: {
    marginRight: 10,
    marginBottom: 20,
    width: 200,
  },
  rightButtons: {
    marginLeft: 10,
    marginBottom: 20,
  },
  button2: {
    padding: 10,
    paddingHorizontal: 20, // espace horizontal autour du texte du bouton
    borderRadius: 11,
    alignItems: "center",
    borderWidth: 1,
    borderColor: "black", // couleur du bord du bouton
    marginHorizontal: 5, // espace entre les boutons
    marginBottom: 20,
  },
  buttonText2: {
    fontSize: 16,
    color: "black",
    fontWeight: "bold",
  },
  issueButtonPressed: {
    backgroundColor: "#59C09B", // couleur d'arrière-plan du bouton lorsqu'il est pressé
  },
  textPressed: {
    color: "white", // couleur du texte du bouton lorsqu'il est pressé
  },
  buttonPressed: {
    borderWidth: 0,
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
  },

  buttonCancel: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#59C09B",
    marginHorizontal: 30,
    paddingHorizontal: 30,
  },
  buttonText3: {
    color: "white",
    fontSize: 18,
    //fontWeight: "bold",
  },
  buttonRowContainer: {
    flexDirection: "row", // Met les boutons en ligne
    justifyContent: "space-between", // Espace équitable entre les boutons
    marginTop: 10, // Espace au-dessus des boutons
  },

  buttonYesNo: {
    flex: 1, // Prend tout l'espace disponible et le divise également entre les boutons
  },
  buttonTextDelete: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  buttonTextCancel: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  buttonCancel1: {
    backgroundColor: "#59C09B",
    marginHorizontal: 10,
    justifyContent: "center", // Centrer le texte verticalement
    alignItems: "center", // Centrer le texte horizontalement
    padding: 10, // Espace à l'intérieur du bouton
    //borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 30,
  },
  buttonDelete1: {
    backgroundColor: "red",
    marginHorizontal: 10,
    justifyContent: "center", // Centrer le texte verticalement
    alignItems: "center", // Centrer le texte horizontalement
    padding: 10, // Espace à l'intérieur du bouton
    //borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 30,
  },
  backButtonWrapper: {
    position: "absolute", // Position absolue
    top: 10, // Ajuster en fonction de vos besoins
    left: 10, // Ajuster en fonction de vos besoins
    padding: 10, // Espacement autour de l'icône pour faciliter le toucher
  },
  leftArrowIcon: {
    width: 30, // Ajuster en fonction de vos besoins
    height: 30, // Ajuster en fonction de vos besoins
  },
  modalTextOk: {
    fontSize: 18,
    marginBottom: 20,
    marginTop: 30,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  buttonTextOk: {
    fontSize: 16,
    color: "white",
    fontWeight: "bold",
  },
  buttonOk: {
    backgroundColor: "#59C09B",
    padding: 10,
    paddingHorizontal: 30,
    borderRadius: 5,
  },
});

export default styles;
